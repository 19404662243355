import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export default function Head(props) {
  function getAlternateLink(altLink, langCode) {
    return (<link rel="alternate" href={altLink} hrefLang={langCode} key={langCode} />);
  }
  const generalConfig = props.services.configService.getGeneralConfig();
  const page = props.services.configService.getPageByPath(window.location.pathname);
  const title = `${props.headData.label || page.label} | ${generalConfig.brand.metaTitleSuffix}`;
  const description = props.headData.description || page.metaDescription;
  const pageUrl = `${generalConfig.baseUrl}${window.location.pathname}`;
  const canonicalUrl = props.headData.canonicalUrl
    || props.services.configService.getCanconicalUrlByPage(page);
  const translationPaths = props.services.i18nService.getPageTranslationPaths(
    window.location.pathname, props.dataStorage
  );
  const alternateLinks = Object.keys(translationPaths).map((languageCode) => getAlternateLink(`${generalConfig.baseUrl}${translationPaths[languageCode]}`, languageCode));
  const image = props.headData.image || page.image;
  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: props.locale }}>
        <title>{title}</title>
        {page.robotsTag && (<meta name="robots" content={page.robotsTag} />)}
        <meta name="theme-color" content={generalConfig.brand.ci.primaryColor} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={pageUrl} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
        <meta property="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={canonicalUrl} />
        {alternateLinks}
        {props.headData.jsonLd && (
          <script type="application/ld+json">
            {JSON.stringify(props.headData.jsonLd)}
          </script>
        )}
      </Helmet>
      {props.children}
    </Fragment>
  );
}

Head.propTypes = {
  headData: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

Head.defaultProps = {
  headData: {},
};
