import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Layout/Icon';
import './Message.css';

export default function Message(props) {
  const iconType = {
    success: 'check',
    warning: 'warning-triangle',
    error: 'close',
    info: 'information',
  };
  return (
    <div className={`grid-message message message--${props.type}`}>
      <div className="message-icon">
        <Icon name={iconType[props.type]} size={props.iconSize} />
      </div>
      <div className="message-content">
        {props.children}
      </div>
    </div>
  );
}

Message.propTypes = {
  type: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

Message.defaultProps = {
  iconSize: undefined,
};
