import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Address from './Address';
import Button from '../Button/Button';
import Icon from '../Layout/Icon';
import './Address.css';

export default function AddresscollectionItem(props) {
  let classNames = 'address-collection-item';
  if (props.selected) {
    classNames = `${classNames} address-collection-item--selected`;
  }
  return (
    <div className={classNames}>
      {props.selected && (
        <div className="address-collection-item-check-container">
          <Icon name="check" />
        </div>
      )}
      <Address address={props.address} classNames="address-collection-item-address" showTaxNo={props.showTaxNo} showSettings={props.showSettings} />
      {(!props.selected && props.showEditAndDeleteButton) && (
        <div className="address-collection-item-actions">
          {props.setSelected && (<Button classNames="button button--small button--secondary" type="submit" onClick={() => props.setSelected(props.address.id)}><FormattedMessage id={props.selectButtonTranslationId} /></Button>)}
          <Button classNames="button button--small button--secondary button--right" type="submit" onClick={() => props.setModalItemId(props.address.id)}><Icon name="trash" /></Button>
          <Button classNames="button button--small button--secondary button--right margin-r5" type="submit" onClick={() => props.editAddressHandler(props.address.id)}><Icon name="edit" /></Button>
        </div>
      )}
      {(!props.selected && props.setSelected && !props.showEditAndDeleteButton) && (
        <div className="address-collection-item-actions">
          {props.setSelected && (<Button classNames="button button--small button--secondary" type="submit" onClick={() => props.setSelected(props.address.id)}><FormattedMessage id={props.selectButtonTranslationId} /></Button>)}
        </div>
      )}
      {(props.selected && props.showEditAndDeleteButton) && (
        <div className="address-collection-item-actions text-align-right">
          <Button classNames="button button--small button--secondary" type="submit" onClick={() => props.editAddressHandler(props.address.id)}><Icon name="edit" /></Button>
        </div>
      )}
    </div>
  );
}

AddresscollectionItem.propTypes = {
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  setModalItemId: PropTypes.func.isRequired,
  editAddressHandler: PropTypes.func.isRequired,
  address: PropTypes.objectOf(PropTypes.any).isRequired,
  selectButtonTranslationId: PropTypes.string,
  showSettings: PropTypes.bool,
  showTaxNo: PropTypes.bool,
  showEditAndDeleteButton: PropTypes.bool,
};

AddresscollectionItem.defaultProps = {
  selected: false,
  setSelected: undefined,
  selectButtonTranslationId: 'select',
  showSettings: false,
  showTaxNo: false,
  showEditAndDeleteButton: true,
};
