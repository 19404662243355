import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Checkbox from '../Form/Checkbox';
import ChangeUserForm from './ChangeUserForm';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';
import './Account.css';

function ChangeAccountDetails(props) {
  const intl = useIntl();
  const [emailFormActive, setEmailFormActive] = useState(false);
  const [passwordFormActive, setPasswordFormActive] = useState(false);

  const emailFormActiveChangeHandler = (newEmailFormActive) => {
    setEmailFormActive(newEmailFormActive);
  };
  const passwordFormActiveChangeHandler = (newPasswordFormActive) => {
    setPasswordFormActive(newPasswordFormActive);
  };

  return (
    <div className="grid-account-change-details">
      <div>
        <ChangeUserForm
          services={props.services}
          locale={props.locale}
          dataStorage={props.dataStorage}
          setDataStorage={props.setDataStorage}
        />
      </div>
      <div className="account-change-email-and-password">
        <Checkbox
          id="changeEmail"
          divClassNames="form-checkbox margin-t30"
          checked={emailFormActive}
          label={intl.formatMessage({ id: 'changeEmailAddress' })}
          callback={emailFormActiveChangeHandler}
        />
        {emailFormActive && (
        <ChangeEmailForm
          services={props.services}
          locale={props.locale}
        />
        )}
        <Checkbox
          id="passwordFormActive"
          divClassNames="form-checkbox margin-t30"
          checked={passwordFormActive}
          label={intl.formatMessage({ id: 'changePassword' })}
          callback={passwordFormActiveChangeHandler}
        />
        {passwordFormActive && (
        <ChangePasswordForm
          services={props.services}
          locale={props.locale}
          dataStorage={props.dataStorage}
        />
        )}
      </div>
    </div>
  );
}

ChangeAccountDetails.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};

export default ChangeAccountDetails;
