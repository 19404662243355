import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CustomLink from '../Link/CustomLink';
import Icon from '../Layout/Icon';
import './List.css';

export default function Pages(props) {
  const intl = useIntl();
  const startItemIndex = props.currentPageIndex * props.elementsPerPage;
  function getItems() {
    return props.items.slice(startItemIndex, startItemIndex + props.elementsPerPage);
  }
  function getPageCount() {
    return Math.ceil(props.items.length / props.elementsPerPage);
  }
  function getPageLinks() {
    let pageLinks = [];
    const pageCount = getPageCount();
    for (let i = 0; i < pageCount; i++) {
      if (i === props.currentPageIndex) {
        pageLinks.push(
          <div className="list-page-link list-page-link--active" key={`pagelink${i}`}>{i + 1}</div>
        );
      } else {
        pageLinks.push((
          <div className="list-page-link" key={`pagelink${i}`}>
            <CustomLink
              href={props.hrefForHistory}
              onClickHandler={props.currentPageIndexChangeHandler}
              onClickHandlerParams={{ index: i }}
            >{` ${i + 1}`}
            </CustomLink>
          </div>
        ));
      }
    }
    if (getPageCount() <= 5) {
      return pageLinks;
    } else if (props.currentPageIndex + 1 === getPageCount()) {
      const startIndex = props.currentPageIndex - 4;
      pageLinks = pageLinks.slice(startIndex, startIndex + 5);
    } else if (props.currentPageIndex + 2 === getPageCount()) {
      const startIndex = props.currentPageIndex - 3;
      pageLinks = pageLinks.slice(startIndex, startIndex + 5);
    } else if (props.currentPageIndex > 2) {
      const startIndex = props.currentPageIndex - 2;
      pageLinks = pageLinks.slice(startIndex, startIndex + 5);
    } else {
      pageLinks = pageLinks.slice(0, 5);
    }
    return pageLinks;
  }
  return (
    <Fragment>
      <div className={props.containerClassName}>
        {props.headingRow}
        {getItems()}
      </div>
      {props.itemTypeLabel && (
        <div className="list-page-item-count">
          {intl.formatMessage({ id: 'itemsXToYOfZ' }, {
            itemType: props.itemTypeLabel,
            x: startItemIndex + 1,
            y: (
              (startItemIndex + props.elementsPerPage) > props.items.length
            ) ? props.items.length : startItemIndex + props.elementsPerPage,
            z: props.items.length,
          })}
        </div>
      )}
      {(props.items.length > props.elementsPerPage) && (
        <div className="list-page-links">
          {(props.currentPageIndex > 0) && (
            <div className="list-page-link">
              <CustomLink
                href={props.hrefForHistory}
                onClickHandler={props.currentPageIndexChangeHandler}
                onClickHandlerParams={{ index: props.currentPageIndex - 1 }}
              >
                <Icon name="angle-left" size={28} />
              </CustomLink>
            </div>
          )}
          <span>{getPageLinks()}</span>
          {(props.currentPageIndex + 1 < getPageCount()) && (
            <div className="list-page-link">
              <CustomLink
                href={props.hrefForHistory}
                onClickHandler={props.currentPageIndexChangeHandler}
                onClickHandlerParams={{ index: props.currentPageIndex + 1 }}
              >
                <Icon name="angle-right" size={28} />
              </CustomLink>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

Pages.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingRow: PropTypes.objectOf(PropTypes.any),
  containerClassName: PropTypes.string.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  currentPageIndexChangeHandler: PropTypes.func.isRequired,
  elementsPerPage: PropTypes.number.isRequired,
  itemTypeLabel: PropTypes.string,
  hrefForHistory: PropTypes.string,
};

Pages.defaultProps = {
  headingRow: undefined,
  itemTypeLabel: undefined,
  hrefForHistory: undefined,
};
