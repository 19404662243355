import React from 'react';
import PropTypes from 'prop-types';
import AccountContentWrapper from '../Account/AccountContentWrapper';
import OrderList from '../Account/OrderList';
import './Page.css';

export default function AccountOrders(props) {
  return (
    <AccountContentWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <OrderList services={props.services} dataStorage={props.dataStorage} locale={props.locale} />
    </AccountContentWrapper>
  );
}

AccountOrders.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
