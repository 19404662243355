import React, { useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import OrderDetails from './OrderDetails';
import Message from '../Message/Message';
import Button from '../Button/Button';
import LinkButton from '../Button/LinkButton';

export default function Order(props) {
  const intl = useIntl();
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleReorder = () => {
    props.services.salesService.reorderCart(
      props.order.positions, props.dataStorage, props.services.dataStorageService
    );
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
    }, 2000);
  };
  function getMessage() {
    return (
      <div className="add-product-message add-product-message-fade-out">
        <Message type="success">
          <FormattedMessage id="itemsAddedToCart" />
        </Message>
      </div>
    );
  }
  if (!props.order) {
    return (
      <Redirect to={props.pathToParentPage} />
    );
  }
  return (
    <Fragment>
      <OrderDetails
        order={props.order}
        showStatus={props.showStatus}
        locale={props.locale}
        services={props.services}
      />
      {!props.showOrderAgainButton && (
        <LinkButton
          href={props.pathToParentPage}
          classNames="linkbutton linkbutton--secondary"
        >{intl.formatMessage({ id: 'back' })}
        </LinkButton>
      )}
      {props.showOrderAgainButton && (
        <Fragment>
          <div className="grid-order-details-buttons">
            <LinkButton
              href={props.pathToParentPage}
              classNames="linkbutton linkbutton--secondary"
            >{intl.formatMessage({ id: 'back' })}
            </LinkButton>
            <Button
              classNames="button"
              onClick={handleReorder}
              disabled={buttonClicked}
            >
              <FormattedMessage id="reorderButtonText" />
            </Button>
          </div>
          {buttonClicked && getMessage()}
        </Fragment>
      )}
    </Fragment>
  );
}

Order.propTypes = {
  order: PropTypes.objectOf(PropTypes.any).isRequired,
  pathToParentPage: PropTypes.string.isRequired,
  showStatus: PropTypes.bool,
  showOrderAgainButton: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any),
};

Order.defaultProps = {
  showStatus: false,
  showOrderAgainButton: false,
  dataStorage: undefined,
};
