import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Product from './Product';

export default function ProductDialog(props) {
  return (
    <Modal
      active={props.active}
      showOverlayAndCloseX={true}
      closeHandler={props.closeHandler}
      closeHandlerParams={props.closeHandlerParams}
    >
      <Product
        isPopup={true}
        locale={props.locale}
        product={props.product}
        showQuantity={props.showQuantity}
        showSimilarProducts={false}
        showFitsToProducts={false}
        selectHandler={props.selectHandler}
        buttonTextTranslationId={props.buttonTextTranslationId}
        customOrderQuantityInfo={props.customOrderQuantityInfo}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    </Modal>
  );
}

ProductDialog.propTypes = {
  active: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  closeHandlerParams: PropTypes.objectOf(PropTypes.any).isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  showQuantity: PropTypes.bool,
  selectHandler: PropTypes.func.isRequired,
  buttonTextTranslationId: PropTypes.string.isRequired,
  customOrderQuantityInfo: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any),
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ProductDialog.defaultProps = {
  showQuantity: true,
  active: false,
  customOrderQuantityInfo: undefined,
  dataStorage: undefined,
};
