import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import Icon from '../Layout/Icon';
import './Modal.css';

export default function Modal(props) {
  const modalClassNames = `modal ${((props.showOverlay || props.showOverlayAndCloseX) ? 'modal--bg-blocked' : 'modal--bg-clickable')}${((props.active) ? ' modal--active' : '')}`;
  const modalContentClassNames = `modal__content${((props.classNames) ? ` ${props.classNames}` : '')}`;
  function getOverlay() {
    if (props.closeHandler) {
      return (<div className="modal__overlay" onClick={() => props.closeHandler(props.closeHandlerParams)} aria-hidden="true" />);
    }
    return (<div className="modal__overlay" />);
  }
  return (
    <div className={modalClassNames}>
      {(props.showOverlay || props.showOverlayAndCloseX) && (getOverlay())}
      <div className={modalContentClassNames}>
        {(props.showCloseX || props.showOverlayAndCloseX) && (
          <div className="modal-close-x">
            <CustomLink
              href="#"
              onClickHandler={props.closeHandler}
              onClickHandlerParams={props.closeHandlerParams}
            ><Icon name="close" size={20} />
            </CustomLink>
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  classNames: PropTypes.string,
  active: PropTypes.bool,
  showOverlay: PropTypes.bool,
  showCloseX: PropTypes.bool,
  showOverlayAndCloseX: PropTypes.bool,
  closeHandler: PropTypes.func,
  closeHandlerParams: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

Modal.defaultProps = {
  classNames: '',
  active: false,
  showOverlay: true,
  showCloseX: false,
  showOverlayAndCloseX: false,
  closeHandler: undefined,
  closeHandlerParams: undefined,
};
