import React from 'react';
import PropTypes from 'prop-types';
import './Product.css';

export default function Discount(props) {
  return (
    <div className={`discount${(props.classNames) ? ` ${props.classNames}` : ''}`}><strong>{props.discount}</strong></div>
  );
}

Discount.propTypes = {
  discount: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};

Discount.defaultProps = {
  classNames: undefined,
};
