function isValidFilename(filename, extension) {
  if (filename
    && extension
    && filename.toUpperCase().includes(`.${extension.toUpperCase()}`)
    && filename.length > `.${extension}`.length
  ) {
    return true;
  } else if (filename && filename.includes('.') && filename.length > 3) {
    return true;
  }
  return false;
}

function containsAtLeastOneLetter(str) {
  return /[a-zA-Z]/g.test(str);
}

function capitalizeFirstLetter(str) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

module.exports = {
  isValidFilename,
  containsAtLeastOneLetter,
  capitalizeFirstLetter,
};
