import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal/Modal';
import LoginForm from './LoginForm';

export default function LoginDialog(props) {
  return (
    <Modal
      active={props.active}
      showOverlayAndCloseX={true}
      closeHandler={props.closeHandler}
      closeHandlerParams={props.closeHandlerParams}
    >
      <h2 className="center"><FormattedMessage id="loginPage" /></h2>
      <LoginForm
        classNames="form form-centered"
        services={props.services}
        locale={props.locale}
        showPwdForgottenAndStayLoggedIn={true}
        pwdForgottenLink={props.services.configService.getPageByTranslationCodeAndLocale('password-forgotten', props.locale).path}
        showNoAccountYet={true}
        noAccountYetLink={props.services.configService.getPageByTranslationCodeAndLocale('registration', props.locale).path}
        dataStorage={props.dataStorage}
        setDataStorage={props.setDataStorage}
        closeHandler={props.closeHandler}
        closeHandlerParams={props.closeHandlerParams}
      />
    </Modal>
  );
}

LoginDialog.propTypes = {
  active: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  closeHandlerParams: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};

LoginDialog.defaultProps = {
  active: false,
};
