import React from 'react';
import PropTypes from 'prop-types';
import VeMeDropdownHeading from './VeMeDropdownHeading';
import VeMeDropdownListitems from './VeMeDropdownListitems';
import '../Dropdown.css';

export default function VeMeDropdown(props) {
  const dropDownContentClasses = 'dropdown__content';
  function clickDropdownIsExpanded(dropDownPath) {
    return props.expandedMenuPath && props.expandedMenuPath.indexOf(dropDownPath) === 0;
  }
  return (
    <li className="dropdown dropdown--veme">
      <VeMeDropdownHeading
        layout={props.headingLayout}
        expandedMenuPath={props.expandedMenuPath}
        setExpandedMenuPath={props.setExpandedMenuPath}
        heading={props.heading}
        headingPrefixIcon={props.headingPrefixIcon}
        headingSuffixIcon={props.headingSuffixIcon}
      />
      <ul className={clickDropdownIsExpanded(props.heading) ? `${dropDownContentClasses} dropdown__content--active` : `${dropDownContentClasses}`}>
        <VeMeDropdownListitems
          heading={props.heading}
          items={props.items}
          expandedMenuPath={props.expandedMenuPath}
          setExpandedMenuPath={props.setExpandedMenuPath}
          dropDownContentClasses={dropDownContentClasses}
          clickDropdownIsExpanded={clickDropdownIsExpanded}
        />
      </ul>
    </li>
  );
}

VeMeDropdown.propTypes = {
  heading: PropTypes.string.isRequired,
  headingLayout: PropTypes.string,
  headingPrefixIcon: PropTypes.string,
  headingSuffixIcon: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func,
  })),
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func,
};

VeMeDropdown.defaultProps = {
  headingLayout: undefined,
  headingPrefixIcon: undefined,
  headingSuffixIcon: undefined,
  items: undefined,
  expandedMenuPath: null,
  setExpandedMenuPath: undefined,
};
