import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Validator from './Validator';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextarea from './FormTextarea';
import FormLegalCheckbox from './FormLegalCheckbox';
import FormSpamProtection from './FormSpamProtection';
import RequestProgress from '../Progress/RequestProgress';
import Button from '../Button/Button';
import CustomLink from '../Link/CustomLink';

export default function ContactForm(props) {
  const intl = useIntl();
  const [company, setCompany] = useState('');
  const [salutation, setSalutation] = useState('mr');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [consentToPrivacyPolicy, setConsentToPrivacyPolicy] = useState(false);
  const [isRightAntiSpamAnswer, setIsRightAntiSpamAnswer] = useState(false);
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [companyIsValid, setCompanyIsValid] = useState(false);
  const [firstnameIsValid, setFirstnameIsValid] = useState(false);
  const [lastnameIsValid, setLastnameIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [messageIsValid, setMessageIsValid] = useState(false);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');
  const companyChangeHandler = (newCompany) => {
    setCompany(newCompany);
    setCompanyIsValid(Validator.isValidCompanyName(newCompany));
  };
  const salutationChangeHandler = (newSalutation) => {
    setSalutation(newSalutation);
  };
  const firstnameChangeHandler = (newFirstname) => {
    setFirstname(newFirstname);
    setFirstnameIsValid(Validator.isValidPersonName(newFirstname));
  };
  const lastnameChangeHandler = (newLastname) => {
    setLastname(newLastname);
    setLastnameIsValid(Validator.isValidPersonName(newLastname));
  };
  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
    setEmailIsValid(Validator.isValidEmailAddress(newEmail));
  };
  const phoneChangeHandler = (newPhone) => {
    setPhone(newPhone);
    setPhoneIsValid(Validator.isValidPhoneNumber(newPhone));
  };
  const messageChangeHandler = (newMessage) => {
    setMessage(newMessage);
    setMessageIsValid(newMessage.length > 10);
  };
  const consentToPrivacyPolicyChangeHandler = (newPrivacyPolicyState) => {
    setConsentToPrivacyPolicy(newPrivacyPolicyState);
  };
  const isRightAntiSpamAnswerChangeHandler = (newAntiSpamAnswer) => {
    setIsRightAntiSpamAnswer(newAntiSpamAnswer);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (companyIsValid && firstnameIsValid && lastnameIsValid && emailIsValid && message
      && isRightAntiSpamAnswer && consentToPrivacyPolicy) {
      setViewState('loading');
      props.services.apiService.post('/facade/frontend/contact-form', {
        formId: props.formId,
        salutation,
        firstname,
        lastname,
        email,
        message,
        additionalFields: [
          { i18nId: 'company', value: company },
          { i18nId: 'phone', value: phone },
        ],
        locale: props.locale,
      }).then((res) => {
        setViewState('result');
        setResultMessageType(res.data.resultStatus || 'error');
        if (res.data.resultStatus === 'success') {
          setResultMessageText(intl.formatMessage({ id: 'contactFormSuccessMsg' }));
        } else {
          setResultMessageText(intl.formatMessage({ id: 'contactFormErrorMsg' }));
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className="form" onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormInput
              id="company"
              value={company}
              showIsRequired={true}
              callback={companyChangeHandler}
              isValid={companyIsValid}
              errorMessageId="invalidCompanyNameMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormSelect
              id="salutation"
              values={[
                ['mr', intl.formatMessage({ id: 'mr' })],
                ['mrs', intl.formatMessage({ id: 'mrs' })],
              ]}
              currentValue={salutation}
              showIsRequired={true}
              callback={salutationChangeHandler}
            />
            <FormInput
              id="firstname"
              value={firstname}
              showIsRequired={true}
              callback={firstnameChangeHandler}
              isValid={firstnameIsValid}
              errorMessageId="invalidNameMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormInput
              id="lastname"
              value={lastname}
              showIsRequired={true}
              callback={lastnameChangeHandler}
              isValid={lastnameIsValid}
              errorMessageId="invalidNameMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormInput
              id="email"
              value={email}
              showIsRequired={true}
              callback={emailChangeHandler}
              isValid={emailIsValid}
              errorMessageId="invalidEmailMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormInput
              id="phone"
              value={phone}
              callback={phoneChangeHandler}
              isValid={phoneIsValid}
              errorMessageId="invalidPhoneMessage"
              noErrorMessageIfEmpty={true}
            />
            <FormTextarea
              id="message"
              value={message}
              showIsRequired={true}
              callback={messageChangeHandler}
              isValid={messageIsValid}
              errorMessageId="invalidContactFormMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              rows={6}
              maxLength={600}
            />
            <FormSpamProtection
              showIsRequired={true}
              callback={isRightAntiSpamAnswerChangeHandler}
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
          </div>
          <FormLegalCheckbox
            id="privacyPolicy"
            checked={consentToPrivacyPolicy}
            label={(
              <Fragment>
                <FormattedMessage id="textCheckboxPrivacyPolicy1" values={{ action: intl.formatMessage({ id: 'send' }) }} /> <CustomLink href={props.privacyPolicyLink} target="_blank"><FormattedMessage id="privacyPolicy" /></CustomLink> <FormattedMessage id="textCheckboxPrivacyPolicy2" values={{ company: props.siteOwner }} />
              </Fragment>
            )}
            callback={consentToPrivacyPolicyChangeHandler}
            errorMessageId="mustConfirmPrivacyPolicyMessage"
            submitButtonIsClicked={submitButtonIsClicked}
          />
          <Button classNames="button button--full-width" type="submit"><FormattedMessage id="send" /></Button>
        </form>
      )}
    </Fragment>
  );
}

ContactForm.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  siteOwner: PropTypes.string.isRequired,
  privacyPolicyLink: PropTypes.string.isRequired,
};
