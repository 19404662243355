import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function H1(props) {
  const text = props.configService.getPageByPath(useLocation().pathname).label;
  if (props.classNames) {
    return (<h1 className={props.classNames}>{text}</h1>);
  }
  return (<h1>{text}</h1>);
}

H1.propTypes = {
  configService: PropTypes.objectOf(PropTypes.any).isRequired,
  classNames: PropTypes.string,
};

H1.defaultProps = {
  classNames: undefined,
};
