import React from 'react';
import PropTypes from 'prop-types';
import SingleMenuItem from './SingleMenuItem';
import './Menu.css';

export default function FlatSubMenu(props) {
  const menuItems = props.items.map((menuItem) => (
    <SingleMenuItem
      classNames={props.classNames.subli}
      iconClassNames={menuItem.iconClassNames}
      link={menuItem.link}
      label={menuItem.label}
      isExternal={menuItem.isExternal}
      onClickHandler={menuItem.onClickHandler}
      onClickHandlerParams={menuItem.onClickHandlerParams}
      key={menuItem.label}
    />
  ));
  return (
    <li className={props.classNames.li}>
      <div className={props.classNames.heading}>
        {props.heading}
      </div>
      <ul>
        {menuItems}
      </ul>
    </li>
  );
}

FlatSubMenu.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  classNames: PropTypes.objectOf(PropTypes.string).isRequired,
};
