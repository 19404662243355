import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import RequestProgress from './RequestProgress';

export default function LoadWebpageParams(props) {
  const intl = useIntl();
  const { pathname } = useLocation();
  const [viewState, setViewState] = useState('loading');
  const [resultMessageType] = useState('');
  const [resultMessageText] = useState('');
  useEffect(() => {
    props.services.dataStorageService.getWebpageParams(props.services.configService.getPageByPath(
      pathname
    ).id, props.dataStorage).then((params) => {
      if (params) {
        props.webpageParamsHandler(params);
        setViewState('form');
      }
    });
  }, []);
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'dataIsLoaded' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form') && (props.children)}
    </Fragment>
  );
}

LoadWebpageParams.propTypes = {
  webpageParamsHandler: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};
