import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import HeadingBar from './HeadingBar';

export default function DefaultHeadingBar(props) {
  const { pathname } = useLocation();
  return (
    <HeadingBar
      heading={props.services.configService.getPageByPath(pathname).label}
      breadcrumbsData={props.services.configService.getBreadcrumbsDataByPath(
        pathname, props.locale
      )}
      classNames={props.classNames}
      locale={props.locale}
      services={props.services}
    />
  );
}

DefaultHeadingBar.propTypes = {
  classNames: PropTypes.string,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
};

DefaultHeadingBar.defaultProps = {
  classNames: undefined,
};
