import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './Cart.css';

export default function CartSummaryRow(props) {
  return (
    <Fragment>
      <div>
        <span className={props.labelClassNames}><FormattedMessage id={props.labelI18nId} />{(props.labelAddition) ? ` ${props.labelAddition}` : '' }:</span>
      </div>
      <div className={`cart-summary-price${(props.valueClassNames) ? ` ${props.valueClassNames}` : ''}`}>
        {props.value}
      </div>
    </Fragment>
  );
}

CartSummaryRow.propTypes = {
  labelI18nId: PropTypes.string.isRequired,
  labelAddition: PropTypes.string,
  labelClassNames: PropTypes.string,
  value: PropTypes.string.isRequired,
  valueClassNames: PropTypes.string,
};

CartSummaryRow.defaultProps = {
  labelAddition: undefined,
  labelClassNames: undefined,
  valueClassNames: undefined,
};
