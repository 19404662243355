import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CustomLink from '../Link/CustomLink';
import './Cart.css';

export default function PositionTitle(props) {
  const intl = useIntl();
  const title = props.services.salesService.getPositionTitle(
    props.product, props.locale, intl, props.customProductType
  );
  if (props.hasProductLinks) {
    return (
      <CustomLink
        href={props.services.salesService.getPositionLink(
          props.product, props.locale, props.customProductType
        )}
      >
        {title}
      </CustomLink>
    );
  }
  return title;
}

PositionTitle.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  customProductType: PropTypes.string,
  hasProductLinks: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

PositionTitle.defaultProps = {
  customProductType: undefined,
  hasProductLinks: true,
};
