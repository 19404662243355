import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

export default function Select(props) {
  const intl = useIntl();
  return (
    <select
      id={props.id}
      value={props.currentValue}
      disabled={props.disabled}
      readOnly={props.readOnly}
      onChange={({ target: { value } }) => props.callback(value)}
    >
      {props.noPreselect && (
        <option disabled selected value="" aria-label={intl.formatMessage({ id: 'pleaseSelect' })}>{intl.formatMessage({ id: 'pleaseSelect' })}</option>
      )}
      {props.values.map(([value, text]) => (
        <option value={value} key={value}>
          {text}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.array).isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  noPreselect: PropTypes.bool,
  currentValue: PropTypes.string.isRequired,
};

Select.defaultProps = {
  disabled: false,
  readOnly: false,
  noPreselect: false,
};
