import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DropdownWrapper from './DropdownWrapper';
import DropdownHeading from './DropdownHeading';
import DropdownContent from './DropdownContent';
import './Dropdown.css';

export default function Dropdown(props) {
  const history = useHistory();
  const dropdownId = (props.type === 'click') ? props.dropdownId || `drpdwn-${props.heading || ((props.headingPrefixIcon) ? props.headingPrefixIcon.replace(' ', '') : undefined)}` : undefined;
  const [dropdownIsExpanded, setDropdownIsExpanded] = useState(props.isExpanded);
  let dropDownContentClasses = `dropdown__content dropdown__content--default ${(props.layout === 'wide') ? ' dropdown__content--wide' : ''}`;
  if (props.dropdownContentClasses) {
    dropDownContentClasses = `${dropDownContentClasses} ${props.dropdownContentClasses}`;
  }
  function dropdownIsExpandedHandler(isExpanded) {
    setDropdownIsExpanded(isExpanded);
    if (props.expandedFilterChangeHandler) {
      props.expandedFilterChangeHandler({
        attributeId: props.attributeId, isExpanded, dropdownId,
      });
    }
  }
  function dropdownIsExpandedToggle() {
    const newIsExpandedValue = !dropdownIsExpanded;
    dropdownIsExpandedHandler(newIsExpandedValue);
  }
  useEffect(() => history.listen(() => {
    if (props.closeOnHistoryApiChange) {
      setDropdownIsExpanded(false);
    }
  }), [history]);
  useEffect(() => {
    if (props.type === 'click') {
      const eventListener = (event) => {
        const el = document.getElementById(dropdownId);
        if (el) {
          const isClickInside = el.contains(event.target);
          if (!isClickInside) {
            dropdownIsExpandedHandler(false);
          }
        }
      };
      document.addEventListener('click', eventListener);
      return () => {
        document.removeEventListener('click', eventListener);
      };
    }
    return () => {};
  }, []);
  const dropdownHeadingElement = (
    <DropdownHeading
      type={props.type}
      layout={props.headingLayout}
      headingText={props.heading}
      headingLink={props.headingLink}
      headingPrefixIcon={props.headingPrefixIcon}
      headingSuffixIcon={props.headingSuffixIcon}
      dropdownIsExpandedToggle={dropdownIsExpandedToggle}
    />
  );
  return (
    <DropdownWrapper
      isListItem={props.isListItem}
      type={props.type}
      classNames={props.mainClassNames}
      dropdownIsExpandedHandler={dropdownIsExpandedHandler}
      id={dropdownId}
    >
      {dropdownHeadingElement}
      {(props.type !== 'disabled') && (
        <DropdownContent
          layout={props.layout}
          items={props.items}
          classNames={dropdownIsExpanded ? `${dropDownContentClasses} dropdown__content--active` : `${dropDownContentClasses}`}
          heading={props.heading}
          headingLink={props.headingLink}
        >
          {props.children}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
}

Dropdown.propTypes = {
  dropdownId: PropTypes.string,
  isListItem: PropTypes.bool,
  type: PropTypes.string.isRequired,
  layout: PropTypes.string,
  mainClassNames: PropTypes.string,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  headingLink: PropTypes.string,
  headingLayout: PropTypes.string,
  headingPrefixIcon: PropTypes.string,
  headingSuffixIcon: PropTypes.string,
  dropdownContentClasses: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]),
  isExpanded: PropTypes.bool,
  attributeId: PropTypes.string,
  expandedFilterChangeHandler: PropTypes.func,
  closeOnHistoryApiChange: PropTypes.bool,
};

Dropdown.defaultProps = {
  dropdownId: undefined,
  isListItem: false,
  layout: undefined,
  mainClassNames: undefined,
  heading: undefined,
  headingLink: undefined,
  headingLayout: undefined,
  headingPrefixIcon: undefined,
  headingSuffixIcon: undefined,
  dropdownContentClasses: undefined,
  items: undefined,
  children: undefined,
  isExpanded: false,
  expandedFilterChangeHandler: undefined,
  attributeId: undefined,
  closeOnHistoryApiChange: true,
};
