import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Validator from '../Form/Validator';
import FormInput from '../Form/FormInput';
import FormNewPassword from '../Form/FormNewPassword';
import RequestProgress from '../Progress/RequestProgress';
import Button from '../Button/Button';
import CustomLink from '../Link/CustomLink';

export default function ChangePasswordForm(props) {
  const intl = useIntl();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');

  const currentPasswordChangeHandler = (newCurrentPassword) => {
    setCurrentPassword(newCurrentPassword);
  };
  const passwordChangeHandler = (newPassword) => {
    setPassword(newPassword);
    setPasswordIsValid(Validator.isValidPassword(newPassword));
  };
  const passwordConfirmationChangeHandler = (newPasswordConfirmation) => {
    setPasswordConfirmation(newPasswordConfirmation);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (passwordIsValid && password === passwordConfirmation) {
      setViewState('loading');
      props.services.apiService.post('/user/pwd-new', {
        resetCode: props.resetCode,
        newPassword: password,
        currentPassword,
        email: props.dataStorage.user.email,
        locale: props.locale,
      }).then((res) => {
        setViewState('result');
        if (res.data) {
          setResultMessageType('success');
          if (props.pwdForgotten) {
            setResultMessageText((
              <Fragment>
                <FormattedMessage id="passwordChangeSuccessMsg1" />
                <FormattedMessage id="passwordChangeSuccessMsg2" />
                <CustomLink
                  href={props.services.configService.getPageByTranslationCodeAndLocale('login', props.locale).path}
                >{intl.formatMessage({ id: 'registrationOptInSuccessMsg2' })}
                </CustomLink>.
              </Fragment>
            ));
          } else {
            setResultMessageText(intl.formatMessage({ id: 'passwordChangeSuccessMsg1' }));
          }
        } else {
          setResultMessageType('error');
          setResultMessageText(intl.formatMessage({ id: (props.resetCode) ? 'errorHasOccurred' : 'invalidCurrentPasswordMessage' }));
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className={(props.pwdForgotten) ? 'form form-centered' : 'form'} onSubmit={handleSubmit}>
          <div className="grid-form">
            {!props.pwdForgotten && (
            <FormInput
              id="currentPassword"
              type="password"
              value={currentPassword}
              callback={currentPasswordChangeHandler}
              isValid={true}
              errorMessageId="invalidPasswordMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            )}
            <FormNewPassword
              newPassword={true}
              password={password}
              passwordConfirmation={passwordConfirmation}
              passwordChangeHandler={passwordChangeHandler}
              passwordConfirmationChangeHandler={passwordConfirmationChangeHandler}
              passwordIsValid={passwordIsValid}
              submitButtonIsClicked={submitButtonIsClicked}
            />
          </div>
          <Button classNames={(props.pwdForgotten) ? 'button button--full-width' : 'button'} type="submit"><FormattedMessage id={(props.pwdForgotten) ? 'save' : 'changePassword'} /></Button>
        </form>
      )}
    </Fragment>
  );
}

ChangePasswordForm.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  pwdForgotten: PropTypes.bool,
  resetCode: PropTypes.string,
};

ChangePasswordForm.defaultProps = {
  pwdForgotten: false,
  resetCode: undefined,
};
