import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function HeaderAndFooter(props) {
  const brandConfig = props.services.configService.getGeneralConfig().brand;
  return (
    <Fragment>
      <Header
        siteName={brandConfig.shortName}
        menus={props.services.configService.getMenus(props.locale)}
        locale={props.locale}
        setLocale={props.setLocale}
        services={props.services}
        dataStorage={props.dataStorage}
        setDataStorage={props.setDataStorage}
      />
      <main>
        {props.children}
      </main>
      <Footer
        menus={props.services.configService.getMenus(props.locale)}
        socialChannels={props.services.configService.getGeneralConfig()
          .brand.socialMedia[props.locale]}
        siteOwner={brandConfig.longName}
        locale={props.locale}
        setLocale={props.setLocale}
        services={props.services}
      />
    </Fragment>
  );
}

HeaderAndFooter.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};

export default HeaderAndFooter;
