import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Validator from '../Form/Validator';
import FormBuyer from '../Form/FormBuyer';
import FormNewPassword from '../Form/FormNewPassword';
import FormLegalCheckbox from '../Form/FormLegalCheckbox';
import FormSpamProtection from '../Form/FormSpamProtection';
import RequestProgress from '../Progress/RequestProgress';
import Button from '../Button/Button';
import CustomLink from '../Link/CustomLink';

export default function RegisterForm(props) {
  const intl = useIntl();
  const [company, setCompany] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [salutation, setSalutation] = useState('mr');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [hasInvoiceEmail, setHasInvoiceEmail] = useState(false);
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [consentToPrivacyPolicy, setConsentToPrivacyPolicy] = useState(false);
  const [isRightAntiSpamAnswer, setIsRightAntiSpamAnswer] = useState(false);
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [companyIsValid, setCompanyIsValid] = useState(false);
  const [customerIdIsValid, setCustomerIdIsValid] = useState(true);
  const [firstnameIsValid, setFirstnameIsValid] = useState(false);
  const [lastnameIsValid, setLastnameIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [invoiceEmailIsValid, setInvoiceEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');

  const companyChangeHandler = (newCompany) => {
    setCompany(newCompany);
    setCompanyIsValid(Validator.isValidCompanyName(newCompany));
  };
  const customerIdChangeHandler = (newCustomerId) => {
    setCustomerId(newCustomerId);
    setCustomerIdIsValid(Validator.isValidCustomerId(newCustomerId, true));
  };
  const salutationChangeHandler = (newSalutation) => {
    setSalutation(newSalutation);
  };
  const firstnameChangeHandler = (newFirstname) => {
    setFirstname(newFirstname);
    setFirstnameIsValid(Validator.isValidPersonName(newFirstname));
  };
  const lastnameChangeHandler = (newLastname) => {
    setLastname(newLastname);
    setLastnameIsValid(Validator.isValidPersonName(newLastname));
  };
  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
    setEmailIsValid(Validator.isValidEmailAddress(newEmail));
  };
  const hasInvoiceEmailChangeHandler = (newHasInvoiceEmail) => {
    setHasInvoiceEmail(newHasInvoiceEmail);
    setInvoiceEmail('');
    setInvoiceEmailIsValid(true);
  };
  const invoiceEmailChangeHandler = (newInvoiceEmail) => {
    setInvoiceEmail(newInvoiceEmail);
    setInvoiceEmailIsValid(Validator.isValidEmailAddress(newInvoiceEmail, !hasInvoiceEmail));
  };
  const passwordChangeHandler = (newPassword) => {
    setPassword(newPassword);
    setPasswordIsValid(Validator.isValidPassword(newPassword));
  };
  const passwordConfirmationChangeHandler = (newPasswordConfirmation) => {
    setPasswordConfirmation(newPasswordConfirmation);
  };
  const consentToPrivacyPolicyChangeHandler = (newPrivacyPolicyState) => {
    setConsentToPrivacyPolicy(newPrivacyPolicyState);
  };
  const isRightAntiSpamAnswerChangeHandler = (newAntiSpamAnswer) => {
    setIsRightAntiSpamAnswer(newAntiSpamAnswer);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (companyIsValid && customerIdIsValid && firstnameIsValid && lastnameIsValid && emailIsValid
      && invoiceEmailIsValid && passwordIsValid && password === passwordConfirmation
      && isRightAntiSpamAnswer && consentToPrivacyPolicy) {
      setViewState('loading');
      props.services.apiService.post('/user/register', {
        company,
        customerId,
        salutation,
        firstname,
        lastname,
        email,
        invoiceEmail,
        password,
        locale: props.locale,
      }).then((res) => {
        setViewState('result');
        setResultMessageType(res.data.resultStatus);
        if (res.data.errmsg) {
          setResultMessageText(res.data.errmsg);
        } else {
          setResultMessageText(intl.formatMessage({ id: 'registrationSuccessMsg' }));
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className="form" onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormBuyer
              company={company}
              customerId={customerId}
              salutation={salutation}
              firstname={firstname}
              lastname={lastname}
              email={email}
              hasInvoiceEmail={hasInvoiceEmail}
              invoiceEmail={invoiceEmail}
              companyChangeHandler={companyChangeHandler}
              customerIdChangeHandler={customerIdChangeHandler}
              salutationChangeHandler={salutationChangeHandler}
              firstnameChangeHandler={firstnameChangeHandler}
              lastnameChangeHandler={lastnameChangeHandler}
              emailChangeHandler={emailChangeHandler}
              hasInvoiceEmailChangeHandler={hasInvoiceEmailChangeHandler}
              invoiceEmailChangeHandler={invoiceEmailChangeHandler}
              companyIsValid={companyIsValid}
              customerIdIsValid={customerIdIsValid}
              firstnameIsValid={firstnameIsValid}
              lastnameIsValid={lastnameIsValid}
              emailIsValid={emailIsValid}
              invoiceEmailIsValid={invoiceEmailIsValid}
              submitButtonIsClicked={submitButtonIsClicked}
            />
            <FormNewPassword
              password={password}
              passwordConfirmation={passwordConfirmation}
              passwordChangeHandler={passwordChangeHandler}
              passwordConfirmationChangeHandler={passwordConfirmationChangeHandler}
              passwordIsValid={passwordIsValid}
              showIsRequired={true}
              submitButtonIsClicked={submitButtonIsClicked}
            />
            <FormSpamProtection
              showIsRequired={true}
              callback={isRightAntiSpamAnswerChangeHandler}
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
          </div>
          <FormLegalCheckbox
            id="privacyPolicy"
            checked={consentToPrivacyPolicy}
            label={(
              <Fragment>
                <FormattedMessage id="textCheckboxPrivacyPolicy1" values={{ action: intl.formatMessage({ id: 'register' }) }} /> <CustomLink href={props.privacyPolicyLink} target="_blank"><FormattedMessage id="privacyPolicy" /></CustomLink> <FormattedMessage id="textCheckboxPrivacyPolicy2" values={{ company: props.siteOwner }} />
              </Fragment>
            )}
            callback={consentToPrivacyPolicyChangeHandler}
            errorMessageId="mustConfirmPrivacyPolicyMessage"
            submitButtonIsClicked={submitButtonIsClicked}
          />
          <Button classNames="button button--full-width" type="submit"><FormattedMessage id="register" /></Button>
        </form>
      )}
    </Fragment>
  );
}

RegisterForm.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  siteOwner: PropTypes.string.isRequired,
  privacyPolicyLink: PropTypes.string.isRequired,
};
