import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import './Layout.css';

export default function AdvancedHeroRow(props) {
  props.services.urlService.enrichPageBuilderParam(props.item, props.locale, props.dataStorage);
  return (
    <CustomLink
      href={props.item.href || '#'}
      isExternal={props.item.hrefIsExternal}
    >
      <div className={`advanced-hero-row-container${(props.item.mainClassNames) ? ` ${props.item.mainClassNames}` : ''}`}>
        <div className="grid-advanced-hero-row">
          <div className="advanced-hero-row-info-container center">
            <div className={`advanced-hero-row-info${(props.item.imgClassNames === 'shape-circle-45') ? ' advanced-hero-row-info--circle-45' : ''}`}>
              <div className="advanced-hero-row-heading">
                {props.item.heading}
              </div>
              <div className="advanced-hero-row-subheading">
                {props.item.subheading}
              </div>
            </div>
          </div>
          <div className="advanced-hero-row-img-container">
            <img
              className={`${props.item.imgClassNames} clip-path-desktop-only`}
              src={props.item.imgSrc}
              alt={props.item.heading}
            />
          </div>
        </div>
      </div>
    </CustomLink>
  );
}

AdvancedHeroRow.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
