function isEmptyArray(array) {
  return Array.isArray(array) && array.length === 0;
}

function isPopulatedArray(array) {
  return Array.isArray(array) && array.length > 0;
}

function removeElement(array, value) {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
}

function removeElements(array, values) {
  values.forEach((value) => removeElement(array, value));
}

function addItemToArrIfExists(arr, item) {
  if (item) {
    arr.push(item);
  }
}

function concatInnerArray(originalArray) {
  let newArray = [];
  originalArray.forEach((item) => {
    if (Array.isArray(item)) {
      const tmpArray = newArray;
      newArray = tmpArray.concat(item);
    } else {
      newArray.push(item);
    }
  });
  return newArray;
}

function compareSimpleArrays(array1, array2) {
  return Array.isArray(array1) && Array.isArray(array2)
    && array1.length === array2.length && array1.every((v, i) => v === array2[i]);
}

function compareObjectArraysByProperty(array1, array2, property) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i][property] !== array2[i][property]) {
      return false;
    }
  }
  return true;
}

function sortAndCompareObjectArraysByProperty(array1, array2, property) {
  if (array1.length === array2.length) {
    const propValuesA = array1.map((item) => item[property]).sort();
    const propValuesB = array2.map((item) => item[property]).sort();
    for (let i = 0; i < propValuesA.length; i++) {
      if (propValuesA[i] !== propValuesB[i]) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function findObjectByPropNameAndValue(array, propName, propValue) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][propName] === propValue) {
      return array[i];
    }
  }
  return undefined;
}

module.exports = {
  isEmptyArray,
  isPopulatedArray,
  removeElement,
  removeElements,
  addItemToArrIfExists,
  concatInnerArray,
  compareSimpleArrays,
  compareObjectArraysByProperty,
  sortAndCompareObjectArraysByProperty,
  findObjectByPropNameAndValue,
};
