import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import ProductOtyInput from './ProductOtyInput';
import Message from '../Message/Message';
import numberHelper from '../../utils/helper/number-helper';
import './Product.css';

export default function AddProduct(props) {
  const [orderQuantity, setOrderQuantity] = useState(props.qty || 1);
  const [lastInputComma, setLastInputComma] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const orderQuantityInfo = props.customOrderQuantityInfo
    || props.services.salesService.getOrderQuantityInfo(props.product, props.customProductType);
  const qtyChangeHandler = (newQty) => {
    let qty = props.services.salesService.unifyInputIfDecimal(
      props.product, props.customProductType, newQty, props.locale
    );
    if (lastInputComma && qty.includes('.0') && !qty.endsWith('.0')) {
      qty = qty.replace('.0', '.');
      setLastInputComma(false);
    } else {
      setLastInputComma(false);
    }
    if (props.services.salesService.isValidOrderQuantity(qty, props.product, props.customProductType, props.customOrderQuantityInfo) || qty === '' || qty === '0' || qty === '0.0') {
      if (orderQuantityInfo.hasDecimalOrderQuantity) {
        if (!lastInputComma
          && newQty.endsWith(numberHelper.getDecimalSeparatorByLocale(props.locale))
        ) {
          setLastInputComma(true);
        }
      }
      setOrderQuantity(qty);
      if (qty !== '' && qty !== '0' && qty !== '0.0') {
        props.qtyChangeHandler(qty);
      }
    }
  };
  const incrementHandler = (e) => {
    e.preventDefault(e);
    qtyChangeHandler((Number(props.qty) + orderQuantityInfo.increment).toString());
  };
  const decrementHandler = (e) => {
    e.preventDefault(e);
    qtyChangeHandler((Number(props.qty) - orderQuantityInfo.increment).toString());
  };
  const handleClick = (e) => {
    e.preventDefault();
    setButtonClicked(false);
    props.addHandler(
      {
        product: props.product,
        customProductType: props.customProductType,
        orderQuantity: Number(orderQuantity),
        dataStorage: props.dataStorage,
      }
    );
    if (props.dataStorage) {
      setButtonClicked(true);
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
  };
  function getMessage() {
    return (
      <div className="add-product-message add-product-message-fade-out">
        <Message type="success">
          <FormattedMessage id="itemAddedToCart" />
        </Message>
      </div>
    );
  }
  useEffect(() => {
    if (orderQuantity && orderQuantity !== props.qty) {
      setOrderQuantity(props.qty || 1);
      setLastInputComma(false);
    }
  }, [props.qty]);
  return (
    <Fragment>
      <form className="form add-product-container">
        <ProductOtyInput
          id="orderQuantity"
          value={orderQuantity}
          unitOfQuantity={props.services.salesService.getPositionUnitOfQuantity(
            props.product, props.locale, props.customProductType
          )}
          qtyChangeHandler={qtyChangeHandler}
          incrementHandler={(orderQuantityInfo.showButtons) ? incrementHandler : undefined}
          decrementHandler={(orderQuantityInfo.showButtons) ? decrementHandler : undefined}
          disableDecrementButton={orderQuantityInfo.increment
            && Number(props.qty) - orderQuantityInfo.increment <= (orderQuantityInfo.min || 0)}
          numberFormat={(orderQuantityInfo.hasDecimalOrderQuantity)
            ? { locale: props.locale, minimumFractionDigits: (lastInputComma) ? 1 : undefined }
            : undefined}
          showQuantity={props.showQuantity}
        />
        <Button
          classNames="button"
          onClick={handleClick}
          disabled={orderQuantity <= 0 || buttonClicked || props.buttonDisabled}
        >
          <FormattedMessage id={props.buttonTextTranslationId} />
        </Button>
      </form>
      {buttonClicked && getMessage()}
    </Fragment>
  );
}

AddProduct.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  customProductType: PropTypes.string,
  showQuantity: PropTypes.bool,
  qty: PropTypes.number,
  addHandler: PropTypes.func.isRequired,
  qtyChangeHandler: PropTypes.func,
  buttonTextTranslationId: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  customOrderQuantityInfo: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any),
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

AddProduct.defaultProps = {
  customProductType: undefined,
  showQuantity: true,
  qty: undefined,
  qtyChangeHandler: undefined,
  buttonTextTranslationId: 'addToShoppingCart',
  buttonDisabled: false,
  customOrderQuantityInfo: undefined,
  dataStorage: undefined,
};
