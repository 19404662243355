import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import ProductList from '../Product/ProductList';
import Message from '../Message/Message';
import Button from '../Button/Button';
import FormSelect from '../Form/FormSelect';
import PositionRow from './PositionRow';
import stringHelper from '../../utils/helper/string-helper';
import '../HoseConfigurator/HoseConfigurator.css';

export default function AutomationPartSelection(props) {
  const intl = useIntl();
  const fenceInitalLengthA = 2195;
  const fenceInitalLengthB = 3260;
  const fenceInitialHatchWidth = 450;
  const sortAttributeSettings = props.services.productService.getSortAttributeSettings([]);
  const [fenceLengthA, setFenceLengthA] = useState(fenceInitalLengthA);
  const [fenceLengthB, setFenceLengthB] = useState(fenceInitalLengthB);
  const [fenceHatchWidth, setFenceHatchWidth] = useState(fenceInitialHatchWidth);
  const [hasFenceDoor, setHasFenceDoor] = useState('yes');
  const [hasFenceHatch, setHasFenceHatch] = useState('yes');
  const [fenceComponents, setFenceComponents] = useState(
    props.services.automationConfiguratorService.getFenceComponents(
      fenceInitalLengthA, fenceInitalLengthB, fenceInitialHatchWidth, 'yes', 'yes', props.automationProducts
    )
  );
  const [installation, setInstallation] = useState('yes');
  const [training, setTraining] = useState('yes');
  const [approval, setApproval] = useState('no');
  const [country, setCountry] = useState('de');
  const [zipArea, setZipArea] = useState(7);
  const fenceLengthAChangeHandler = (newLength) => {
    setFenceLengthA(newLength);
    setFenceComponents(props.services.automationConfiguratorService.getFenceComponents(
      newLength,
      fenceLengthB,
      fenceHatchWidth,
      hasFenceDoor,
      hasFenceHatch,
      props.automationProducts
    ));
  };
  const fenceLengthBChangeHandler = (newLength) => {
    setFenceLengthB(newLength);
    setFenceComponents(props.services.automationConfiguratorService.getFenceComponents(
      fenceLengthA,
      newLength,
      fenceHatchWidth,
      hasFenceDoor,
      hasFenceHatch,
      props.automationProducts
    ));
  };
  const fenceHatchWidthChangeHandler = (newLength) => {
    console.log(fenceHatchWidthChangeHandler);
    console.log(newLength);
    setFenceHatchWidth(newLength);
    setFenceComponents(props.services.automationConfiguratorService.getFenceComponents(
      fenceInitalLengthA,
      fenceInitalLengthB,
      newLength,
      hasFenceDoor,
      hasFenceHatch,
      props.automationProducts
    ));
  };
  const hasFenceDoorChangeHandler = (newVal) => {
    setHasFenceDoor(newVal);
    setFenceComponents(props.services.automationConfiguratorService.getFenceComponents(
      fenceInitalLengthA,
      fenceInitalLengthB,
      fenceHatchWidth,
      newVal,
      hasFenceHatch,
      props.automationProducts
    ));
  };
  const hasFenceHatchChangeHandler = (newVal) => {
    setHasFenceHatch(newVal);
    setFenceComponents(props.services.automationConfiguratorService.getFenceComponents(
      fenceInitalLengthA,
      fenceInitalLengthB,
      fenceHatchWidth,
      hasFenceDoor,
      newVal,
      props.automationProducts
    ));
  };
  const installationChangeHandler = (newVal) => {
    setInstallation(newVal);
  };
  const trainingChangeHandler = (newVal) => {
    setTraining(newVal);
  };
  const approvalChangeHandler = (newVal) => {
    setApproval(newVal);
  };
  const countryChangeHandler = (newVal) => {
    setCountry(newVal);
    if (newVal === 'xx') {
      setInstallation('no');
      setTraining('no');
      setApproval('no');
    }
  };
  const zipAreaChangeHandler = (newVal) => {
    setZipArea(newVal);
  };
  const setProductHandler = (params) => {
    if (params.product) {
      const newAutomationConfiguration = JSON.parse(JSON.stringify(props.automationConfiguration));
      if (!newAutomationConfiguration[props.mainStep]) {
        newAutomationConfiguration[props.mainStep] = {};
      }
      newAutomationConfiguration[props.mainStep][props.secondaryStep] = params.product;
      props.handlers.automationConfigurationChangeHandler({
        automationConfiguration: newAutomationConfiguration,
      });
    }
  };
  const addFence = () => {
    setApproval('yes');
    const newAutomationConfiguration = JSON.parse(JSON.stringify(props.automationConfiguration));
    if (!newAutomationConfiguration[props.mainStep]) {
      newAutomationConfiguration[props.mainStep] = {};
    }
    newAutomationConfiguration[props.mainStep][props.secondaryStep] = fenceComponents;
    props.handlers.automationConfigurationChangeHandler({
      automationConfiguration: newAutomationConfiguration,
    });
  };
  function getServiceArticles() {
    let noCE = false;
    if (props.automationConfiguration.safetyArea === undefined) {
      noCE = true;
    }
    return props.services.automationConfiguratorService.getServiceArticles(
      installation, training, (noCE) ? 'no' : approval, country, zipArea, props.automationProducts
    );
  }
  const addRoboticServices = () => {
    const serviceArticles = getServiceArticles();
    if (serviceArticles.length > 0) {
      const newAutomationConfiguration = JSON.parse(JSON.stringify(props.automationConfiguration));
      if (!newAutomationConfiguration[props.mainStep]) {
        newAutomationConfiguration[props.mainStep] = {};
      }
      newAutomationConfiguration[props.mainStep][props.secondaryStep] = serviceArticles;
      props.handlers.automationConfigurationChangeHandler({
        automationConfiguration: newAutomationConfiguration,
      });
    } else {
      props.handlers.skipStep();
    }
  };
  function getSelection() {
    if (props.secondaryStep === 'safetyFence') {
      return (
        <div className="grid-ac-multi-selection">
          <div className="grid-ac-multi-selection-left-col">
            <div className="center">
              <div style={{ display: 'block' }}>
                <strong><FormattedMessage id="acInstructionSafetyFence2" /></strong>
                <div className="ac-safety-fence-sketch">
                  <img
                    src={`/img/Schutzzaun-Skizze-${props.locale}.png`}
                    alt={intl.formatMessage({ id: 'acSafetyFence' })}
                  />
                </div>
                <div className="grid-form ac-safety-fence-input-grid">
                  <FormSelect
                    id="fenceLengthA"
                    label={intl.formatMessage({ id: 'acFenceLengthDimA' })}
                    values={props.services.automationConfiguratorService.getFenceLengthValues(
                      props.locale
                    )}
                    unit="mm"
                    currentValue={fenceLengthA}
                    callback={fenceLengthAChangeHandler}
                  />
                  <FormSelect
                    id="fenceLengthB"
                    label={intl.formatMessage({ id: 'acFenceLengthDimB' })}
                    values={props.services.automationConfiguratorService.getFenceLengthValues(
                      props.locale
                    )}
                    unit="mm"
                    currentValue={fenceLengthB}
                    callback={fenceLengthBChangeHandler}
                  />
                  <FormSelect
                    id="fenceDoor"
                    label={intl.formatMessage({ id: 'acFenceDoorIncluded' })}
                    values={[
                      ['yes', intl.formatMessage({ id: 'yes' })],
                      ['no', intl.formatMessage({ id: 'no' })],
                    ]}
                    currentValue={hasFenceDoor}
                    callback={hasFenceDoorChangeHandler}
                  />
                  <FormSelect
                    id="fenceHatch"
                    label={intl.formatMessage({ id: 'acFenceHatchIncluded' })}
                    values={[
                      ['yes', intl.formatMessage({ id: 'yes' })],
                      ['no', intl.formatMessage({ id: 'no' })],
                    ]}
                    currentValue={hasFenceHatch}
                    callback={hasFenceHatchChangeHandler}
                  />
                  {(hasFenceHatch === 'yes') && (
                    <FormSelect
                      id="fenceHatchWidth"
                      label={intl.formatMessage({ id: 'acFenceHatchWidth' })}
                      values={[
                        [450, 450],
                        [600, 600],
                      ]}
                      unit="mm"
                      currentValue={fenceHatchWidth}
                      callback={fenceHatchWidthChangeHandler}
                    />
                  )}
                </div>
                <Button
                  classNames="button button--secondary button-ec-reset margin-r5"
                  onClick={() => props.handlers.back()}
                >
                  <FormattedMessage id="back" />
                </Button>
                <Button
                  classNames="button"
                  onClick={() => addFence()}
                >
                  <FormattedMessage id="next" />
                </Button>
              </div>
            </div>
          </div>
          <div className="grid-ac-multi-selection-right-col">
            <Message type="info" iconSize={28}>
              <FormattedMessage id="acInstructionSafetyFence3" />
            </Message>
            {fenceComponents.map((position) => (
              <PositionRow
                product={position.product}
                qty={position.qty}
                locale={props.locale}
                services={props.services}
                dataStorage={props.dataStorage}
              />
            ))}
            {(hasFenceDoor === 'yes' || hasFenceHatch === 'yes') && (
              <Message type="info" iconSize={28}>
                <FormattedMessage id="acInstructionSafetyFence4" />
              </Message>
            )}
          </div>
        </div>
      );
    } else if (props.secondaryStep === 'serviceRobotics') {
      const serviceArticles = getServiceArticles();
      return (
        <div className="grid-ac-multi-selection">
          <div className="grid-ac-multi-selection-left-col">
            <div className="center">
              <div style={{ display: 'block' }}>
                <strong><FormattedMessage id="acInstructionServiceRobotics2" /></strong>
                <div className="grid-form grid-form--service-robotics">
                  <FormSelect
                    id="installation"
                    label={intl.formatMessage({ id: 'acInstallation' })}
                    values={[
                      ['yes', intl.formatMessage({ id: 'yes' })],
                      ['no', intl.formatMessage({ id: 'no' })],
                    ]}
                    currentValue={installation}
                    disabled={country === 'xx'}
                    callback={installationChangeHandler}
                  />
                  <FormSelect
                    id="training"
                    label={intl.formatMessage({ id: 'acTraining' })}
                    values={[
                      ['yes', intl.formatMessage({ id: 'yes' })],
                      ['no', intl.formatMessage({ id: 'no' })],
                    ]}
                    currentValue={training}
                    disabled={country === 'xx'}
                    callback={trainingChangeHandler}
                  />
                  {(props.automationConfiguration.safetyArea !== undefined) && (
                    <FormSelect
                      id="approval"
                      label={intl.formatMessage({ id: 'acApproval' })}
                      values={[
                        ['yes', intl.formatMessage({ id: 'yes' })],
                        ['no', intl.formatMessage({ id: 'no' })],
                      ]}
                      currentValue={approval}
                      disabled={country === 'xx'}
                      callback={approvalChangeHandler}
                    />
                  )}
                </div>
                <div className="ac-heading-address">
                  <FormattedMessage id="acInstructionServiceRobotics3" />
                </div>
                <div className="grid-form">
                  <FormSelect
                    id="country"
                    label={intl.formatMessage({ id: 'acShippingCountry' })}
                    values={[
                      ['de', intl.formatMessage({ id: 'CC_DE' })],
                      ['at', intl.formatMessage({ id: 'CC_AT' })],
                      ['ch', intl.formatMessage({ id: 'CC_CH' })],
                      ['xx', intl.formatMessage({ id: 'acOtherCountry' })],
                    ]}
                    currentValue={country}
                    callback={countryChangeHandler}
                  />
                  {(country === 'de') && (
                    <FormSelect
                      id="zipArea"
                      label={intl.formatMessage({ id: 'acShippingZipArea' })}
                      values={props.services.automationConfiguratorService.getZipAreaValues()}
                      currentValue={zipArea}
                      callback={zipAreaChangeHandler}
                    />
                  )}
                </div>
                <Button
                  classNames="button button--secondary button-ec-reset margin-r5"
                  onClick={() => props.handlers.back()}
                >
                  <FormattedMessage id="back" />
                </Button>
                <Button
                  classNames="button"
                  onClick={() => addRoboticServices()}
                >
                  <FormattedMessage id="next" />
                </Button>
              </div>
            </div>
          </div>
          <div className="grid-ac-multi-selection-right-col">
            {(serviceArticles.length > 0) && (
              <Message type="info" iconSize={28}>
                <FormattedMessage id="acInstructionServiceRobotics5" />
              </Message>
            )}
            {serviceArticles.map((position) => (
              <PositionRow
                product={position.product}
                qty={position.qty}
                locale={props.locale}
                services={props.services}
                dataStorage={props.dataStorage}
              />
            ))}
            {(country === 'xx') && (
              <Message type="warning" iconSize={28}>
                <FormattedMessage id="acInstructionServiceRobotics4" />
              </Message>
            )}
            {(serviceArticles.length === 0 && country !== 'xx') && (
              <Message type="info" iconSize={28}>
                <FormattedMessage id="acInstructionServiceRobotics6" />
              </Message>
            )}
          </div>
        </div>
      );
    }
    return (
      <ProductList
        products={props.services.automationConfiguratorService.getProductsForSecondaryStep(
          props.mainStep, props.secondaryStep, props.automationProducts
        )}
        currency={props.services.configService.getGeneralConfig().sales.currency}
        changeHandlers={{
          filterChangeHandler: props.handlers.filterChangeHandler,
          sortChangeHandler: props.handlers.sortChangeHandler,
          selectHandler: setProductHandler,
        }}
        presets={{
          filter: {},
          sort: props.services.productService.getDefaultProductListSortOption(
            sortAttributeSettings, intl, props.locale
          ),
        }}
        filterAttributeIds={props.services.productService.getFilterAttributes(props.secondaryStep)}
        sortOptions={props.services.productService.getProductListSortOptions(
          sortAttributeSettings, intl, props.locale
        )}
        showOrderQuantity={props.services.automationConfiguratorService
          .showOrderQuantityForSecondaryStep(props.mainStep, props.secondaryStep)}
        buttonTextTranslationId="select"
        showSearch={false}
        showProductCount={false}
        locale={props.locale}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
  }
  return (
    <Fragment>
      <div className="hc-grid-hose-part-instructions">
        <Message type="info">
          <span><FormattedMessage id={`acInstruction${stringHelper.capitalizeFirstLetter(props.secondaryStep)}`} /></span>
        </Message>
        {props.secondaryStep !== 'industrialRobot' && (
          <Button
            classNames="button button--secondary button--beside-message"
            onClick={props.handlers.skipStep}
          >
            <FormattedMessage id="skip" />
          </Button>
        )}
      </div>
      {getSelection()}
      {(props.secondaryStep !== 'industrialRobot' && props.secondaryStep !== 'safetyFence' && props.secondaryStep !== 'serviceRobotics') && (
        <Button
          classNames="button button--secondary button-ec-reset margin-r5"
          onClick={() => props.handlers.back()}
        >
          <FormattedMessage id="back" />
        </Button>
      )}
    </Fragment>
  );
}

AutomationPartSelection.propTypes = {
  automationConfiguration: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainStep: PropTypes.string.isRequired,
  secondaryStep: PropTypes.string.isRequired,
  automationProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  handlers: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
