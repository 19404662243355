import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FormSelect from './FormSelect';
import './Form.css';

export default function FormSelectCountry(props) {
  const intl = useIntl();
  return (
    <FormSelect
      id="country"
      values={[
        ['DE', intl.formatMessage({ id: 'CC_DE' })],
        ['AT', intl.formatMessage({ id: 'CC_AT' })],
        ['CH', intl.formatMessage({ id: 'CC_CH' })],
        ['AF', intl.formatMessage({ id: 'CC_AF' })],
        ['EG', intl.formatMessage({ id: 'CC_EG' })],
        ['AL', intl.formatMessage({ id: 'CC_AL' })],
        ['DZ', intl.formatMessage({ id: 'CC_DZ' })],
        ['AD', intl.formatMessage({ id: 'CC_AD' })],
        ['AO', intl.formatMessage({ id: 'CC_AO' })],
        ['GQ', intl.formatMessage({ id: 'CC_GQ' })],
        ['AR', intl.formatMessage({ id: 'CC_AR' })],
        ['AM', intl.formatMessage({ id: 'CC_AM' })],
        ['AZ', intl.formatMessage({ id: 'CC_AZ' })],
        ['ET', intl.formatMessage({ id: 'CC_ET' })],
        ['AU', intl.formatMessage({ id: 'CC_AU' })],
        ['BS', intl.formatMessage({ id: 'CC_BS' })],
        ['BH', intl.formatMessage({ id: 'CC_BH' })],
        ['BD', intl.formatMessage({ id: 'CC_BD' })],
        ['BE', intl.formatMessage({ id: 'CC_BE' })],
        ['BZ', intl.formatMessage({ id: 'CC_BZ' })],
        ['BJ', intl.formatMessage({ id: 'CC_BJ' })],
        ['BM', intl.formatMessage({ id: 'CC_BM' })],
        ['BT', intl.formatMessage({ id: 'CC_BT' })],
        ['BO', intl.formatMessage({ id: 'CC_BO' })],
        ['BA', intl.formatMessage({ id: 'CC_BA' })],
        ['BW', intl.formatMessage({ id: 'CC_BW' })],
        ['BR', intl.formatMessage({ id: 'CC_BR' })],
        ['BN', intl.formatMessage({ id: 'CC_BN' })],
        ['BG', intl.formatMessage({ id: 'CC_BG' })],
        ['BF', intl.formatMessage({ id: 'CC_BF' })],
        ['BI', intl.formatMessage({ id: 'CC_BI' })],
        ['CL', intl.formatMessage({ id: 'CC_CL' })],
        ['CN', intl.formatMessage({ id: 'CC_CN' })],
        ['CR', intl.formatMessage({ id: 'CC_CR' })],
        ['DK', intl.formatMessage({ id: 'CC_DK' })],
        ['DO', intl.formatMessage({ id: 'CC_DO' })],
        ['DJ', intl.formatMessage({ id: 'CC_DJ' })],
        ['EC', intl.formatMessage({ id: 'CC_EC' })],
        ['SV', intl.formatMessage({ id: 'CC_SV' })],
        ['CI', intl.formatMessage({ id: 'CC_CI' })],
        ['ER', intl.formatMessage({ id: 'CC_ER' })],
        ['EE', intl.formatMessage({ id: 'CC_EE' })],
        ['FO', intl.formatMessage({ id: 'CC_FO' })],
        ['FJ', intl.formatMessage({ id: 'CC_FJ' })],
        ['FR', intl.formatMessage({ id: 'CC_FR' })],
        ['GA', intl.formatMessage({ id: 'CC_GA' })],
        ['GM', intl.formatMessage({ id: 'CC_GM' })],
        ['GE', intl.formatMessage({ id: 'CC_GE' })],
        ['GH', intl.formatMessage({ id: 'CC_GH' })],
        ['GR', intl.formatMessage({ id: 'CC_GR' })],
        ['GU', intl.formatMessage({ id: 'CC_GU' })],
        ['GT', intl.formatMessage({ id: 'CC_GT' })],
        ['GN', intl.formatMessage({ id: 'CC_GN' })],
        ['GY', intl.formatMessage({ id: 'CC_GY' })],
        ['HT', intl.formatMessage({ id: 'CC_HT' })],
        ['HN', intl.formatMessage({ id: 'CC_HN' })],
        ['HK', intl.formatMessage({ id: 'CC_HK' })],
        ['IN', intl.formatMessage({ id: 'CC_IN' })],
        ['ID', intl.formatMessage({ id: 'CC_ID' })],
        ['IQ', intl.formatMessage({ id: 'CC_IQ' })],
        ['IR', intl.formatMessage({ id: 'CC_IR' })],
        ['IE', intl.formatMessage({ id: 'CC_IE' })],
        ['IS', intl.formatMessage({ id: 'CC_IS' })],
        ['IL', intl.formatMessage({ id: 'CC_IL' })],
        ['IT', intl.formatMessage({ id: 'CC_IT' })],
        ['JM', intl.formatMessage({ id: 'CC_JM' })],
        ['JP', intl.formatMessage({ id: 'CC_JP' })],
        ['YE', intl.formatMessage({ id: 'CC_YE' })],
        ['JO', intl.formatMessage({ id: 'CC_JO' })],
        ['KH', intl.formatMessage({ id: 'CC_KH' })],
        ['CM', intl.formatMessage({ id: 'CC_CM' })],
        ['CA', intl.formatMessage({ id: 'CC_CA' })],
        ['KZ', intl.formatMessage({ id: 'CC_KZ' })],
        ['QA', intl.formatMessage({ id: 'CC_QA' })],
        ['KE', intl.formatMessage({ id: 'CC_KE' })],
        ['KG', intl.formatMessage({ id: 'CC_KG' })],
        ['CO', intl.formatMessage({ id: 'CC_CO' })],
        ['CG', intl.formatMessage({ id: 'CC_CG' })],
        ['HR', intl.formatMessage({ id: 'CC_HR' })],
        ['CU', intl.formatMessage({ id: 'CC_CU' })],
        ['KW', intl.formatMessage({ id: 'CC_KW' })],
        ['LA', intl.formatMessage({ id: 'CC_LA' })],
        ['LS', intl.formatMessage({ id: 'CC_LS' })],
        ['LV', intl.formatMessage({ id: 'CC_LV' })],
        ['LB', intl.formatMessage({ id: 'CC_LB' })],
        ['LR', intl.formatMessage({ id: 'CC_LR' })],
        ['LY', intl.formatMessage({ id: 'CC_LY' })],
        ['LI', intl.formatMessage({ id: 'CC_LI' })],
        ['LT', intl.formatMessage({ id: 'CC_LT' })],
        ['LU', intl.formatMessage({ id: 'CC_LU' })],
        ['MY', intl.formatMessage({ id: 'CC_MY' })],
        ['ML', intl.formatMessage({ id: 'CC_ML' })],
        ['MT', intl.formatMessage({ id: 'CC_MT' })],
        ['MA', intl.formatMessage({ id: 'CC_MA' })],
        ['MR', intl.formatMessage({ id: 'CC_MR' })],
        ['MK', intl.formatMessage({ id: 'CC_MK' })],
        ['MX', intl.formatMessage({ id: 'CC_MX' })],
        ['MD', intl.formatMessage({ id: 'CC_MD' })],
        ['MC', intl.formatMessage({ id: 'CC_MC' })],
        ['MN', intl.formatMessage({ id: 'CC_MN' })],
        ['MZ', intl.formatMessage({ id: 'CC_MZ' })],
        ['MM', intl.formatMessage({ id: 'CC_MM' })],
        ['NA', intl.formatMessage({ id: 'CC_NA' })],
        ['NP', intl.formatMessage({ id: 'CC_NP' })],
        ['NZ', intl.formatMessage({ id: 'CC_NZ' })],
        ['NI', intl.formatMessage({ id: 'CC_NI' })],
        ['NL', intl.formatMessage({ id: 'CC_NL' })],
        ['NE', intl.formatMessage({ id: 'CC_NE' })],
        ['NG', intl.formatMessage({ id: 'CC_NG' })],
        ['KP', intl.formatMessage({ id: 'CC_KP' })],
        ['NO', intl.formatMessage({ id: 'CC_NO' })],
        ['OM', intl.formatMessage({ id: 'CC_OM' })],
        ['PK', intl.formatMessage({ id: 'CC_PK' })],
        ['PA', intl.formatMessage({ id: 'CC_PA' })],
        ['PG', intl.formatMessage({ id: 'CC_PG' })],
        ['PY', intl.formatMessage({ id: 'CC_PY' })],
        ['PE', intl.formatMessage({ id: 'CC_PE' })],
        ['PH', intl.formatMessage({ id: 'CC_PH' })],
        ['PL', intl.formatMessage({ id: 'CC_PL' })],
        ['PT', intl.formatMessage({ id: 'CC_PT' })],
        ['PR', intl.formatMessage({ id: 'CC_PR' })],
        ['RW', intl.formatMessage({ id: 'CC_RW' })],
        ['RO', intl.formatMessage({ id: 'CC_RO' })],
        ['RU', intl.formatMessage({ id: 'CC_RU' })],
        ['ZM', intl.formatMessage({ id: 'CC_ZM' })],
        ['WS', intl.formatMessage({ id: 'CC_WS' })],
        ['SM', intl.formatMessage({ id: 'CC_SM' })],
        ['SA', intl.formatMessage({ id: 'CC_SA' })],
        ['SE', intl.formatMessage({ id: 'CC_SE' })],
        ['SN', intl.formatMessage({ id: 'CC_SN' })],
        ['CS', intl.formatMessage({ id: 'CC_RS' })],
        ['SL', intl.formatMessage({ id: 'CC_SL' })],
        ['ZW', intl.formatMessage({ id: 'CC_ZW' })],
        ['SG', intl.formatMessage({ id: 'CC_SG' })],
        ['SK', intl.formatMessage({ id: 'CC_SK' })],
        ['SI', intl.formatMessage({ id: 'CC_SI' })],
        ['SO', intl.formatMessage({ id: 'CC_SO' })],
        ['ES', intl.formatMessage({ id: 'CC_ES' })],
        ['LK', intl.formatMessage({ id: 'CC_LK' })],
        ['ZA', intl.formatMessage({ id: 'CC_ZA' })],
        ['SD', intl.formatMessage({ id: 'CC_SD' })],
        ['KR', intl.formatMessage({ id: 'CC_KR' })],
        ['SZ', intl.formatMessage({ id: 'CC_SZ' })],
        ['SY', intl.formatMessage({ id: 'CC_SY' })],
        ['TJ', intl.formatMessage({ id: 'CC_TJ' })],
        ['TW', intl.formatMessage({ id: 'CC_TW' })],
        ['TZ', intl.formatMessage({ id: 'CC_TZ' })],
        ['TH', intl.formatMessage({ id: 'CC_TH' })],
        ['TG', intl.formatMessage({ id: 'CC_TG' })],
        ['TO', intl.formatMessage({ id: 'CC_TO' })],
        ['TT', intl.formatMessage({ id: 'CC_TT' })],
        ['TD', intl.formatMessage({ id: 'CC_TD' })],
        ['CZ', intl.formatMessage({ id: 'CC_CZ' })],
        ['TN', intl.formatMessage({ id: 'CC_TN' })],
        ['TR', intl.formatMessage({ id: 'CC_TR' })],
        ['TM', intl.formatMessage({ id: 'CC_TM' })],
        ['UG', intl.formatMessage({ id: 'CC_UG' })],
        ['UA', intl.formatMessage({ id: 'CC_UA' })],
        ['HU', intl.formatMessage({ id: 'CC_HU' })],
        ['UY', intl.formatMessage({ id: 'CC_UY' })],
        ['UZ', intl.formatMessage({ id: 'CC_UZ' })],
        ['VE', intl.formatMessage({ id: 'CC_VE' })],
        ['AE', intl.formatMessage({ id: 'CC_AE' })],
        ['GB', intl.formatMessage({ id: 'CC_GB' })],
        ['US', intl.formatMessage({ id: 'CC_US' })],
        ['VN', intl.formatMessage({ id: 'CC_VN' })],
        ['BY', intl.formatMessage({ id: 'CC_BY' })],
        ['CF', intl.formatMessage({ id: 'CC_CF' })],
        ['CY', intl.formatMessage({ id: 'CC_CY' })],
      ]}
      currentValue={props.currentValue}
      callback={props.callback}
      showIsRequired={props.showIsRequired}
    />
  );
}

FormSelectCountry.propTypes = {
  currentValue: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  showIsRequired: PropTypes.bool,
};

FormSelectCountry.defaultProps = {
  showIsRequired: false,
};
