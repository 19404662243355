import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './Product.css';

export default function PriceAndAvailability(props) {
  return (
    <div className="product-view-price-availability-container">
      <div className="product-view-price center">
        <strong>
          {props.formattedPrice}
        </strong>
      </div>
      <div className="product-view-price-info">
        <div>
          <FormattedMessage id="plusVat" />
        </div>
        <div>
          <span><FormattedMessage id="priceFor" /> {props.formattedQuantityAndUnit}</span>
        </div>
      </div>
      <div className="product-view-availability-container">
        {props.children}
      </div>
    </div>
  );
}

PriceAndAvailability.propTypes = {
  formattedPrice: PropTypes.string.isRequired,
  formattedQuantityAndUnit: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};
