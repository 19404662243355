import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Layout/Icon';
import CustomLink from '../Link/CustomLink';

export default function SingleMenuItem(props) {
  if (props.link) {
    return (
      <li className={props.classNames}>
        <CustomLink
          href={props.link}
          target={props.target}
          isExternal={props.isExternal}
          onClickHandler={props.onClickHandler}
          onClickHandlerParams={props.onClickHandlerParams}
        >
          {props.iconClassNames
            && <Icon name={props.iconClassNames} /> }<span>{props.label}</span>
        </CustomLink>
      </li>
    );
  }
  return (
    <li className={props.classNames}>
      <strong>{props.label}</strong>
    </li>
  );
}

SingleMenuItem.propTypes = {
  classNames: PropTypes.string,
  iconClassNames: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  isExternal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.any),
};

SingleMenuItem.defaultProps = {
  classNames: undefined,
  iconClassNames: undefined,
  link: undefined,
  target: undefined,
  isExternal: false,
  onClickHandler: undefined,
  onClickHandlerParams: undefined,
};
