import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Message from '../Message/Message';
import HosePartLabel from './HosePartLabel';
import Button from '../Button/Button';
import ProductOtyInput from '../Product/ProductOtyInput';
import AddProduct from '../Product/AddProduct';
import numberHelper from '../../utils/helper/number-helper';

export default function SecondaryNav(props) {
  const intl = useIntl();
  const [hoseLength, setHoseLength] = useState();
  const [hoseLengthLastInputComma, setHoseLengthLastInputComma] = useState(false);
  const [saveConfigMessage, setSaveConfigMessage] = useState(undefined);
  const handleChangeLengthSubmit = (e) => {
    e.preventDefault();
  };
  const lengthChangeHandler = (newLength) => {
    let length = props.services.salesService.unifyInputIfDecimal(
      props.hoseConfiguration.hose.hose, undefined, newLength, props.locale
    );
    if (hoseLengthLastInputComma && length.includes('.0') && !length.endsWith('.0')) {
      length = length.replace('.0', '.');
      setHoseLengthLastInputComma(false);
    } else {
      setHoseLengthLastInputComma(false);
    }
    if (props.services.productService.isValidOrderQuantity(
      length,
      props.hoseConfiguration.hose.hose,
      props.services.hoseConfiguratorService.getHoseOrderQuantityInfo()
    ) || length === '' || length === '0' || length === '0.0') {
      if (!hoseLengthLastInputComma
        && newLength.endsWith(numberHelper.getDecimalSeparatorByLocale(props.locale))
      ) {
        setHoseLengthLastInputComma(true);
      }
      setHoseLength(length);
      if (length !== '' && length !== '0' && length !== '0.0') {
        props.hoseConfiguration.hose.hose.length = length;
        props.handlers.hoseConfigurationChangeHandler({
          hoseConfiguration: props.hoseConfiguration,
        });
      }
    }
  };
  const saveConfigHandler = () => {
    let saved = false;
    const savedConfigs = (localStorage.hcSavedConfigs)
      ? JSON.parse(localStorage.hcSavedConfigs) : undefined;
    if (savedConfigs) {
      if (!savedConfigs.includes(props.hoseCode)) {
        savedConfigs.push(props.hoseCode);
        localStorage.hcSavedConfigs = JSON.stringify(savedConfigs);
        saved = true;
      }
    } else {
      localStorage.hcSavedConfigs = JSON.stringify([props.hoseCode]);
      saved = true;
    }
    if (saved) {
      setSaveConfigMessage('success');
    } else {
      setSaveConfigMessage('warning');
    }
    setTimeout(() => {
      setSaveConfigMessage(undefined);
    }, 2000);
  };
  function getSaveConfigMessage(messageType, translationId) {
    return (
      <div className="add-product-message add-product-message-fade-out">
        <Message type={messageType}>
          <FormattedMessage id={translationId} />
        </Message>
      </div>
    );
  }
  if (props.configuratorState.includes('selectHose')) {
    if (props.configuratorState === 'selectHoseEndA') {
      return (
        <div className="hc-select-hose-part-name center">
          <span><FormattedMessage id="hcPleaseSelectEnd1" /> <HosePartLabel hosePartId="endA" bold={true} /></span>
        </div>
      );
    } else if (props.configuratorState === 'selectHoseEndB') {
      return (
        <div className="hc-select-hose-part-name center">
          <span><FormattedMessage id="hcPleaseSelectEnd1" /> <HosePartLabel hosePartId="endB" bold={true} /></span>
        </div>
      );
    }
    return (
      <div className="hc-select-hose-part-name center">
        <span><strong><FormattedMessage id="hcPleaseSelectCenterpiece" /></strong></span>
      </div>
    );
  } else if (props.configuratorState === 'selectNextStep') {
    const unCompletedStepNames = [];
    if (!props.hoseConfiguration.endA) {
      unCompletedStepNames.push(`${intl.formatMessage({ id: 'hcEnd' })} A`);
    }
    if (!props.hoseConfiguration.hose) {
      unCompletedStepNames.push(intl.formatMessage({ id: 'hcHose' }));
    }
    if (!props.hoseConfiguration.endB) {
      unCompletedStepNames.push(`${intl.formatMessage({ id: 'hcEnd' })} B`);
    }
    if (unCompletedStepNames.length === 2) {
      return (
        <div className="hc-900px-container">
          <Message type="info" iconSize={28}>
            <span><FormattedMessage id="hcInstructionNextStep1a" /> {unCompletedStepNames[0]} <FormattedMessage id="hcAnd" /> {unCompletedStepNames[1]} <FormattedMessage id="hcInstructionNextStep2" /></span>
          </Message>
        </div>
      );
    }
    return (
      <div className="hc-900px-container">
        <Message type="info" iconSize={28}>
          <span><FormattedMessage id="hcInstructionNextStep1b" /> {unCompletedStepNames[0]} <FormattedMessage id="hcInstructionNextStep2" /></span>
        </Message>
      </div>
    );
  } else if (props.configuratorState === 'completed') {
    return (
      <div className="hc-900px-container">
        <div className="grid-hc-config-completed grid-hc-config-completed--row1">
          <div>
            <form className="form add-product-container" onSubmit={handleChangeLengthSubmit}>
              <div className="hc-completed-hose-length-label"><FormattedMessage id="hcHoseLength" />: </div>
              <ProductOtyInput
                id="hoseLength"
                value={(hoseLength || hoseLength === '')
                  ? hoseLength : props.hoseConfiguration.hose.hose.length}
                unitOfQuantity={props.services.salesService.getPositionUnitOfQuantity(
                  props.hoseConfiguration.hose.hose, props.locale
                )}
                qtyChangeHandler={lengthChangeHandler}
                numberFormat={{
                  locale: props.locale,
                  minimumFractionDigits: (hoseLengthLastInputComma) ? 1 : undefined,
                }}
              />
            </form>
          </div>
          <div className="hc-completed-messages">
            <Message type="info">
              <span><FormattedMessage id="hcHoseCode" />: {props.hoseCode}</span>
            </Message>
            {((!hoseLength && hoseLength !== '') || hoseLength > 0) && (
              <Message type="success">
                <FormattedMessage id="hcInstructionSuccess" />
              </Message>
            )}
            {(hoseLength <= 0) && (
              <Message type="error">
                <FormattedMessage id="hcInstructionInvalidHoseLength" />
              </Message>
            )}
          </div>
        </div>
        <div className="grid-hc-config-completed">
          <div className="hc-completed-save-config">
            <Button
              classNames="button button--secondary button-hc-save-config"
              onClick={() => props.handlers.resetHandler()}
              disabled={saveConfigMessage !== undefined}
            >
              <FormattedMessage id="hcConfigureNextHose" />
            </Button>
            {saveConfigMessage === 'success' && getSaveConfigMessage('success', 'configSaved')}
            {saveConfigMessage === 'warning' && getSaveConfigMessage('warning', 'configAlreadySaved')}
          </div>
          <div className="hc-completed-add-to-cart">
            <AddProduct
              product={props.services.hoseConfiguratorService.getAddToCartProductObject(
                props.hoseCode, props.hoseConfiguration
              )}
              customProductType={props.services.hoseConfiguratorService.getConfigurationType()}
              showQuantity={true}
              addHandler={props.services.dataStorageService.addToCart}
              buttonDisabled={hoseLength <= 0}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          </div>
        </div>
        <div className="hc-completed-info-messages">
          <Message type="info" iconSize={28}>
            <FormattedMessage id="hcInfoCompletedHoseLength" />
          </Message>
          <Message type="info" iconSize={28}>
            <FormattedMessage id="hcInfoCompletedHoseCode" />
          </Message>
          <Message type="info" iconSize={28}>
            <FormattedMessage id="hcInfoCompletedConfectioning" />
          </Message>
          <Message type="info" iconSize={28}>
            <FormattedMessage id="hcInfoCompletedOffer" />
          </Message>
        </div>
      </div>
    );
  }
  return (
    <div className="hc-900px-container">
      <Message type="info" iconSize={28}>
        <FormattedMessage id="hcInstructionStart1" />
      </Message>
      <Message type="info" iconSize={28}>
        <FormattedMessage id="hcInstructionStart2" />
      </Message>
    </div>
  );
}

SecondaryNav.propTypes = {
  configuratorState: PropTypes.string.isRequired,
  hoseConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
  hoseCode: PropTypes.string,
  handlers: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

SecondaryNav.defaultProps = {
  hoseCode: undefined,
};
