import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import Drawer from '../Drawer/Drawer';
import Backdrop from '../Backdrop/Backdrop';
import Menu from '../Menu/Menu';
import LoginDialog from '../Account/LoginDialog';
import './Header.css';

export default function Header(props) {
  const intl = useIntl();
  const layout = props.services.configService.getGeneralConfig().design.headerType;
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [drawerExpandedPath, setDrawerExpandedPath] = useState(null);
  const [loginModalActive, setLoginModalActive] = useState(false);
  const loginModalActiveHandler = (params) => {
    setLoginModalActive(params.loginModalActive);
  };
  function addLogoutLinkToAccountSubmenu(newAccountSubmenu) {
    if (newAccountSubmenu[newAccountSubmenu.length - 1].label !== intl.formatMessage({ id: 'logout' })) {
      newAccountSubmenu.push({
        label: intl.formatMessage({ id: 'logout' }),
        link: '#',
        onClickHandler: () => {
          props.services.apiService.post('/user/logout', {});
          props.services.dataStorageService.logout(props.dataStorage);
        },
      });
    }
  }
  const getAccountMenuItems = () => {
    let editedSubitems = [];
    if (!props.dataStorage.user.firstname) {
      props.menus.drawerAccountMenu.forEach((accountItem) => {
        if (accountItem.hasLoginDialog) {
          accountItem.link = '#';
          accountItem.onClickHandler = loginModalActiveHandler;
          accountItem.onClickHandlerParams = { loginModalActive: true };
        }
        editedSubitems.push(accountItem);
      });
    } else {
      editedSubitems = props.services.configService.getAccountSubmenuLoggedIn(
        props.locale
      ).subitems;
      addLogoutLinkToAccountSubmenu(editedSubitems);
    }
    return editedSubitems;
  };
  const getSecondaryMenuItems = () => {
    if (!props.dataStorage.user.firstname) {
      props.menus.desktopSecondaryMenu.forEach((secondarMenuItem) => {
        if (secondarMenuItem.subitems) {
          secondarMenuItem.subitems.forEach((secondarMenuSubitem) => {
            if (secondarMenuSubitem.hasLoginDialog) {
              secondarMenuSubitem.link = '#';
              secondarMenuSubitem.onClickHandler = loginModalActiveHandler;
              secondarMenuSubitem.onClickHandlerParams = { loginModalActive: true };
            }
          });
        }
      });
      return props.menus.desktopSecondaryMenu;
    }
    return props.menus.desktopSecondaryMenu.map((secondaryMenuItem) => {
      if (!secondaryMenuItem.isAccountSubmenu) {
        return secondaryMenuItem;
      }
      secondaryMenuItem = props.services.configService.getAccountSubmenuLoggedIn(props.locale);
      secondaryMenuItem.label = `${props.dataStorage.user.firstname[0]}${props.dataStorage.user.lastname[0]}`;
      addLogoutLinkToAccountSubmenu(secondaryMenuItem.subitems);
      return secondaryMenuItem;
    });
  };
  function getLogo() {
    return (
      <CustomLink href={props.services.configService.getPageByTranslationCodeAndLocale('home', props.locale).path}><img src="/logo.svg" alt={`${props.siteName} logo`} className="header-logo" /></CustomLink>
    );
  }
  const getDesktopMainMenuItems = () => {
    const desktopMainMenuItems = [];
    props.menus.desktopMainMenu.forEach((mainMenuItem) => {
      if (!mainMenuItem.search) {
        desktopMainMenuItems.push(mainMenuItem);
      }
    });
    return desktopMainMenuItems;
  };
  function getDesktopMainMenu(headerType) {
    return (
      <Menu
        classNames={{ nav: 'nav-desktop-main', sli: 'desktop-nav-single-item' }}
        type="dropdownHover"
        items={(headerType === 'type2') ? getDesktopMainMenuItems() : props.menus.desktopMainMenu}
        locale={props.locale}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
  }
  function getSecondaryMenu() {
    return (
      <Menu
        classNames={{ nav: 'nav-desktop-main nav-desktop-main--right', sli: 'desktop-nav-single-item' }}
        type="dropdownHover"
        items={getSecondaryMenuItems()}
        locale={props.locale}
        setLocale={props.setLocale}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
  }
  function getMobileMainNav() {
    return (
      <Menu
        classNames={{ nav: 'nav-mobile-main' }}
        type="click"
        items={props.menus.mobileMainMenu}
        locale={props.locale}
        setLocale={props.setLocale}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
  }
  function getLoginDialog() {
    return (
      <LoginDialog
        active={loginModalActive}
        showOverlayAndCloseX={true}
        closeHandler={loginModalActiveHandler}
        closeHandlerParams={{ loginModalActive: false }}
        services={props.services}
        locale={props.locale}
        dataStorage={props.dataStorage}
        setDataStorage={props.setDataStorage}
      />
    );
  }
  const history = useHistory();
  function drawerToggleClickHandler() {
    setDrawerExpandedPath(null);
    setDrawerIsOpen(!drawerIsOpen);
  }
  function getHamburgerButton() {
    return (
      <button type="button" className="hamburger-button" onClick={drawerToggleClickHandler}>
        <div className="hamburger-button__line" />
        <div className="hamburger-button__line" />
        <div className="hamburger-button__line" />
        <span className="sr-only">{intl.formatMessage({ id: 'mobileMenu' })}</span>
      </button>
    );
  }
  function backdropClickHandler() {
    setDrawerExpandedPath(null);
    setDrawerIsOpen(false);
  }
  function expandPathHandler(togglePath) {
    if (drawerExpandedPath && drawerExpandedPath.indexOf(togglePath) === 0) {
      const pathArray = togglePath.split('|');
      pathArray.pop();
      setDrawerExpandedPath(pathArray.join('|'));
    } else {
      setDrawerExpandedPath(togglePath);
    }
  }
  function getDrawer() {
    return (
      <Fragment>
        <Drawer
          isVisible={drawerIsOpen}
          mainMenuItems={props.menus.drawerMainMenu}
          accountMenuItems={getAccountMenuItems()}
          languageMenuItems={props.menus.drawerLanguageMenu}
          locale={props.locale}
          setLocale={props.setLocale}
          expandedMenuPath={drawerExpandedPath}
          setExpandedMenuPath={expandPathHandler}
          dataStorage={props.dataStorage}
          services={props.services}
        />
        {drawerIsOpen
          ? <Backdrop backdropClickHandler={backdropClickHandler} />
          : undefined}
      </Fragment>
    );
  }
  useEffect(() => history.listen(() => {
    setDrawerIsOpen(false);
  }), [history]);
  if (layout === 'type2') {
    return (
      <header className="header-type2">
        <div className="desktop-only">
          <div className="header-type2-stripe">
            <div className="header-type2-content-container">
              <div className="grid-header-type2-main">
                <div className="grid-header__item">
                  {getLogo()}
                </div>
                <div className="grid-header__item header-type2-search-hamburger">
                  <Menu
                    classNames={{ nav: 'nav-desktop-main nav-desktop-main--right', sli: 'desktop-nav-single-item' }}
                    type="dropdownHover"
                    items={[{ search: 'desktop' }]}
                    locale={props.locale}
                    setLocale={props.setLocale}
                    dataStorage={props.dataStorage}
                    services={props.services}
                  />
                  <div className="header-type2-hamburger">
                    {getDrawer()}
                    <button type="button" className="header-type2-hamburger-button" onClick={drawerToggleClickHandler}>
                      <div className="header-type2-hamburger-button-lines">
                        <div className="hamburger-button__line" />
                        <div className="hamburger-button__line" />
                        <div className="hamburger-button__line" />
                      </div>
                      <span className="sr-only">{intl.formatMessage({ id: 'mobileMenu' })}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid-header-type2-bottom">
                {getDesktopMainMenu(layout)}
                <div>
                  {getSecondaryMenu()}
                  {getLoginDialog()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-header mobile-only">
          <div className="grid-header__item grid-header__item--1">
            {getLogo()}
          </div>
          <div className="grid-header__item grid-header__item--3">
            <nav className="nav-main">
              {getDrawer()}
              {getSecondaryMenu()}
              {getMobileMainNav()}
              {getHamburgerButton()}
              {getLoginDialog()}
            </nav>
          </div>
        </div>
      </header>
    );
  }
  return (
    <header className="grid-header">
      <div className="grid-header__item grid-header__item--1">
        {getLogo()}
      </div>
      <div className="grid-header__item grid-header__item--2">
        {getDesktopMainMenu()}
      </div>
      <div className="grid-header__item grid-header__item--3">
        <nav className="nav-main">
          {getDrawer()}
          {getSecondaryMenu()}
          {getMobileMainNav()}
          {getHamburgerButton()}
          {getLoginDialog()}
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteName: PropTypes.string.isRequired,
  menus: PropTypes.objectOf(PropTypes.array).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
