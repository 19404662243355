import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';

function LinkButton(props) {
  return (
    <div className={(props.classNames) ? props.classNames : 'linkbutton'}>
      <CustomLink
        href={props.href}
        isExternal={props.hrefIsExternal}
        onClickHandler={props.onClickHandler}
        onClickHandlerParams={props.onClickHandlerParams}
        download={props.download}
      >{props.children}
      </CustomLink>
    </div>
  );
}

LinkButton.propTypes = {
  classNames: PropTypes.string,
  href: PropTypes.string.isRequired,
  hrefIsExternal: PropTypes.string,
  download: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
  onClickHandler: PropTypes.func,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.string),
};

LinkButton.defaultProps = {
  classNames: undefined,
  hrefIsExternal: undefined,
  download: undefined,
  onClickHandler: undefined,
  onClickHandlerParams: undefined,
};

export default LinkButton;
