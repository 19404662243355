import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Icon from '../Layout/Icon';
import './Search.css';

export default function SearchInput(props) {
  const intl = useIntl();
  function getSearchInputAndButton() {
    return (
      <Fragment>
        <input
          value={props.value}
          placeholder={props.placeholder || intl.formatMessage({ id: 'search' })}
          onFocus={(props.onFocusHandler) ? (() => props.onFocusHandler()) : undefined}
          onChange={({ target: { value } }) => props.onChangeHandler({ searchTerm: value })}
        />
        <button type="submit" aria-label={intl.formatMessage({ id: 'search' })}><Icon name="search" size={22} /></button>
      </Fragment>
    );
  }
  if (props.onSubmitHandler) {
    return (
      <form className="search-form center" onSubmit={props.onSubmitHandler}>
        {getSearchInputAndButton()}
      </form>
    );
  }
  return (
    <div className="search-form center">
      {getSearchInputAndButton()}
    </div>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSubmitHandler: PropTypes.func,
  onChangeHandler: PropTypes.func.isRequired,
  onFocusHandler: PropTypes.func,
};

SearchInput.defaultProps = {
  placeholder: undefined,
  onSubmitHandler: undefined,
  onFocusHandler: false,
};
