import stringHelper from '../utils/helper/string-helper';
import numberHelper from '../utils/helper/number-helper';
import salesHelper from '../utils/helper/sales-helper';

const automationProductTypeI18nIds = [
  'industrialRobot',
  'vacuumGenerator',
  'seventhAxisForIndustrialRobot',
  'pedestalForIndustrialRobot',
  'deviceForOpticalComponentTesting',
  'conveyor',
  'safetyFencePost',
  'safetyFenceGridAndPost',
  'safetyFenceGirdDoorAndPost',
  'safetyFenceGirdHatchAndPost',
  'safetyPackageRobotics',
  'serviceRobotics',
];
const steps = [
  { id: 'robot', secondarySteps: [{ id: 'industrialRobot' }, { id: 'seventhAxisOrPedestalForIndustrialRobot', productTypes: ['seventhAxisForIndustrialRobot', 'pedestalForIndustrialRobot'] }, { id: 'vacuumGenerator' }] },
  { id: 'opticalPartsInspection', secondarySteps: [{ id: 'deviceForOpticalComponentTesting' }] },
  { id: 'conveyor', secondarySteps: [{ id: 'conveyor' }] },
  { id: 'safetyArea', secondarySteps: [{ id: 'safetyFence', productTypes: ['safetyFenceGridAndPost', 'safetyFenceGirdDoorAndPost', 'safetyFenceGirdHatchAndPost', 'safetyFencePost', 'safetyPackageRobotics'] }] },
  { id: 'installationCeTraining', secondarySteps: [{ id: 'serviceRobotics' }] },
];
const fenceCompositions = {
  930: [{ id: 'NA0323', qty: 1 }],
  1130: [{ id: 'NA0324', qty: 1 }],
  1195: [{ id: 'NA0323', qty: 1 }, { id: 'NA0320', qty: 1 }],
  1295: [{ id: 'NA0323', qty: 1 }, { id: 'NA0321', qty: 1 }],
  1395: [{ id: 'NA0324', qty: 1 }, { id: 'NA0320', qty: 1 }],
  1495: [{ id: 'NA0324', qty: 1 }, { id: 'NA0321', qty: 1 }],
  1595: [{ id: 'NA0324', qty: 1 }, { id: 'NA0322', qty: 1 }],
  1695: [{ id: 'NA0323', qty: 1 }, { id: 'NA0321', qty: 2 }],
  1795: [{ id: 'NA0323', qty: 2 }],
  1860: [{ id: 'NA0324', qty: 1 }, { id: 'NA0321', qty: 2 }],
  1965: [{ id: 'NA0324', qty: 1 }, { id: 'NA0323', qty: 1 }],
  2060: [{ id: 'NA0324', qty: 1 }, { id: 'NA0322', qty: 2 }],
  2195: [{ id: 'NA0324', qty: 2 }],
  2225: [{ id: 'NA0324', qty: 1 }, { id: 'NA0321', qty: 3 }],
  2460: [{ id: 'NA0324', qty: 2 }, { id: 'NA0320', qty: 1 }],
  2560: [{ id: 'NA0324', qty: 2 }, { id: 'NA0321', qty: 1 }],
  2660: [{ id: 'NA0324', qty: 2 }, { id: 'NA0322', qty: 1 }],
  2830: [{ id: 'NA0324', qty: 1 }, { id: 'NA0323', qty: 2 }],
  3060: [{ id: 'NA0324', qty: 2 }, { id: 'NA0323', qty: 1 }],
  3125: [{ id: 'NA0324', qty: 2 }, { id: 'NA0322', qty: 2 }],
  3260: [{ id: 'NA0324', qty: 3 }],
  3525: [{ id: 'NA0324', qty: 3 }, { id: 'NA0320', qty: 1 }],
  3625: [{ id: 'NA0324', qty: 3 }, { id: 'NA0321', qty: 1 }],
  3725: [{ id: 'NA0324', qty: 3 }, { id: 'NA0322', qty: 1 }],
  3925: [{ id: 'NA0324', qty: 2 }, { id: 'NA0323', qty: 2 }],
  4125: [{ id: 'NA0324', qty: 3 }, { id: 'NA0323', qty: 1 }],
  4325: [{ id: 'NA0324', qty: 4 }],
  4590: [{ id: 'NA0324', qty: 4 }, { id: 'NA0320', qty: 1 }],
  4790: [{ id: 'NA0324', qty: 4 }, { id: 'NA0322', qty: 1 }],
  4990: [{ id: 'NA0324', qty: 3 }, { id: 'NA0323', qty: 2 }],
  5190: [{ id: 'NA0324', qty: 4 }, { id: 'NA0323', qty: 1 }],
  5390: [{ id: 'NA0324', qty: 5 }],
  5655: [{ id: 'NA0324', qty: 5 }, { id: 'NA0320', qty: 1 }],
  5855: [{ id: 'NA0324', qty: 5 }, { id: 'NA0322', qty: 1 }],
  6055: [{ id: 'NA0324', qty: 4 }, { id: 'NA0323', qty: 2 }],
  6255: [{ id: 'NA0324', qty: 5 }, { id: 'NA0323', qty: 1 }],
  6455: [{ id: 'NA0324', qty: 6 }],
  6720: [{ id: 'NA0324', qty: 6 }, { id: 'NA0320', qty: 1 }],
  6920: [{ id: 'NA0324', qty: 6 }, { id: 'NA0322', qty: 1 }],
  7120: [{ id: 'NA0324', qty: 5 }, { id: 'NA0323', qty: 2 }],
  7320: [{ id: 'NA0324', qty: 6 }, { id: 'NA0323', qty: 1 }],
  7520: [{ id: 'NA0324', qty: 7 }],
  7785: [{ id: 'NA0324', qty: 7 }, { id: 'NA0320', qty: 1 }],
  7985: [{ id: 'NA0324', qty: 7 }, { id: 'NA0322', qty: 1 }],
  8185: [{ id: 'NA0324', qty: 6 }, { id: 'NA0323', qty: 2 }],
  8385: [{ id: 'NA0324', qty: 7 }, { id: 'NA0323', qty: 1 }],
  8585: [{ id: 'NA0324', qty: 8 }],
  8850: [{ id: 'NA0324', qty: 8 }, { id: 'NA0320', qty: 1 }],
  9050: [{ id: 'NA0324', qty: 8 }, { id: 'NA0322', qty: 1 }],
  9250: [{ id: 'NA0324', qty: 7 }, { id: 'NA0323', qty: 2 }],
  9450: [{ id: 'NA0324', qty: 8 }, { id: 'NA0323', qty: 1 }],
  9650: [{ id: 'NA0324', qty: 9 }],
  9915: [{ id: 'NA0324', qty: 9 }, { id: 'NA0320', qty: 1 }],
  10115: [{ id: 'NA0324', qty: 9 }, { id: 'NA0322', qty: 1 }],
  10315: [{ id: 'NA0324', qty: 8 }, { id: 'NA0323', qty: 2 }],
  10515: [{ id: 'NA0324', qty: 9 }, { id: 'NA0323', qty: 1 }],
  10715: [{ id: 'NA0324', qty: 10 }],
};
const fenceToDoorMap = {
  NA0323: 'NA0331',
  NA0324: 'NA0330',
};
const fenceToHatchMap = {
  NA0323: 'NA0340',
  NA0324: 'NA0341',
};
const zipAreaHDuration = {
  0: 12,
  1: 16,
  2: 14,
  3: 10,
  4: 10,
  5: 8,
  6: 6,
  7: 4,
  8: 7,
  9: 6,
};
let configService;
let productService;

function setDependencies(dependencies) {
  ({ configService, productService } = dependencies);
  return this;
}

function getInitialConfiguratorState() {
  return { steps: steps.map((step) => step.id) };
}

function getConfigurationSteps(selectionSteps) {
  return selectionSteps.map(
    (step) => ({ labelTranslationId: `ac${stringHelper.capitalizeFirstLetter(step)}` })
  );
}

function getI18nIdSecondaryStep(secondaryStepId) {
  return `ac${stringHelper.capitalizeFirstLetter(secondaryStepId)}`;
}

function getCurrentStepId(stepperNumber) {
  return steps[stepperNumber - 1].id;
}

function getIndexSecondaryStep(indexMainStep, secondaryStepId) {
  let indexSecondaryStep;
  steps[indexMainStep].secondarySteps.forEach((secondaryStep, index) => {
    if (secondaryStep.id === secondaryStepId) {
      indexSecondaryStep = index;
    }
  });
  return indexSecondaryStep;
}

function getSecondaryStepConfig(mainStepId, secondaryStepId) {
  const mainStep = steps.filter((mainStepConfig) => mainStepConfig.id === mainStepId)[0];
  return mainStep.secondarySteps.filter((secondaryStep) => secondaryStep.id === secondaryStepId)[0];
}

function getPreviousStepInfo(indexMainStep, secondaryStepId) {
  const secondaryStepIndex = getIndexSecondaryStep(indexMainStep, secondaryStepId);
  let previousMainStepConfig;
  let previousSecondaryStepConfig;
  if (secondaryStepIndex === 0) {
    previousMainStepConfig = steps[indexMainStep - 1];
    previousSecondaryStepConfig = previousMainStepConfig
      .secondarySteps[previousMainStepConfig.secondarySteps.length - 1];
    return {
      mainStepBack: true,
      mainStepId: previousMainStepConfig.id,
      secondaryStepId: previousSecondaryStepConfig.id,
    };
  }
  previousMainStepConfig = steps[indexMainStep];
  previousSecondaryStepConfig = previousMainStepConfig
    .secondarySteps[secondaryStepIndex - 1];
  return {
    mainStepId: previousMainStepConfig.id,
    secondaryStepId: previousSecondaryStepConfig.id,
  };
}

function getConfigurationSecondarySteps(index) {
  return steps[index].secondarySteps.map((step) => step.id);
}

function getAutomationConfiguratorProducts(dataStorage) {
  const automationProducts = {};
  dataStorage.products.forEach((product) => {
    if (automationProductTypeI18nIds.includes(product.productType.i18nId)) {
      if (!automationProducts[product.productType.i18nId]) {
        automationProducts[product.productType.i18nId] = [];
      }
      automationProducts[product.productType.i18nId].push(product);
    }
  });
  return automationProducts;
}

function getProductsForSecondaryStep(mainStepId, secondaryStepId, automationProducts) {
  const secondaryStep = getSecondaryStepConfig(mainStepId, secondaryStepId);
  const productTypeI18nIds = secondaryStep.productTypes || [secondaryStep.id];
  let secondaryStepProducts = [];
  productTypeI18nIds.forEach((productTypeI18nId) => {
    secondaryStepProducts = secondaryStepProducts.concat(automationProducts[productTypeI18nId]);
  });
  return secondaryStepProducts;
}

function showOrderQuantityForSecondaryStep(mainStepId, secondaryStepId) {
  const secondaryStep = getSecondaryStepConfig(mainStepId, secondaryStepId);
  return secondaryStep.showOrderQuantity || false;
}

function getProductsFromConfiguration(automationConfiguration) {
  const products = [];
  const mainStepIds = Object.keys(automationConfiguration);
  mainStepIds.forEach((mainStepId) => {
    Object.keys(automationConfiguration[mainStepId]).forEach((secondaryStepId) => {
      if (Array.isArray(automationConfiguration[mainStepId][secondaryStepId])) {
        automationConfiguration[mainStepId][secondaryStepId].forEach((secStepConf) => {
          products.push({ product: secStepConf.product, qty: secStepConf.qty });
        });
      } else {
        products.push({ product: automationConfiguration[mainStepId][secondaryStepId], qty: 1 });
      }
    });
  });
  return products;
}

function getNetPriceInfo(
  automationConfiguration, dataStorage, locale, withoutInstallationCeTraining
) {
  const mainStepIds = Object.keys(automationConfiguration);
  let netSum = 0;
  mainStepIds.forEach((mainStepId) => {
    if (!withoutInstallationCeTraining || mainStepId !== 'installationCeTraining') {
      Object.keys(automationConfiguration[mainStepId]).forEach((secondaryStepId) => {
        if (Array.isArray(automationConfiguration[mainStepId][secondaryStepId])) {
          automationConfiguration[mainStepId][secondaryStepId].forEach((secStepConf) => {
            netSum += productService.getPositionPriceInfo(
              secStepConf.product,
              secStepConf.qty,
              dataStorage,
              locale
            ).price;
          });
        } else {
          netSum += productService.getPositionPriceInfo(
            automationConfiguration[mainStepId][secondaryStepId], 1, dataStorage, locale
          ).price;
        }
      });
    }
  });
  return productService.getPositionPriceInfoObject(locale, netSum, 0, netSum);
}

function getRentPurchasePriceInfo(automationConfiguration, dataStorage, locale) {
  const { currency } = configService.getGeneralConfig().sales;
  const totalPrice = getNetPriceInfo(automationConfiguration, dataStorage, locale).price;
  const priceWithoutServices = getNetPriceInfo(
    automationConfiguration, dataStorage, locale, true
  ).price;
  const servicesPrice = totalPrice - priceWithoutServices;
  const montlyRateHardware = priceWithoutServices * 0.075;
  const rate = Math.ceil(montlyRateHardware + (servicesPrice / 6));
  const price = Math.ceil((priceWithoutServices - (montlyRateHardware * 6)) * 1.05);
  return {
    rate,
    price,
    formatteRate: salesHelper.formatPrice(rate, locale, currency),
    formattePrice: salesHelper.formatPrice(price, locale, currency),
  };
}
function getFenceLengthValues() {
  return Object.keys(fenceCompositions).map((key) => [key, key]);
}

function getZipAreaValues() {
  const selectValues = [];
  for (let i = 0; i < 10; i++) {
    selectValues.push([i, i]);
  }
  return selectValues;
}

function getFenceComponents(
  fenceInitalLengthA,
  fenceInitalLengthB,
  fenceHatchWidth,
  hasFenceDoor,
  hasFenceHatch,
  automationProducts
) {
  const fenceComponents = [];
  const safetyFenceProducts = {};
  let doorHatchCount = 0;
  if (hasFenceDoor === 'yes') {
    fenceComponents.push({ type: 'door', product: automationProducts.safetyFenceGirdDoorAndPost.filter((product) => product.id === fenceToDoorMap[fenceCompositions[fenceInitalLengthA][0].id])[0], qty: 1 });
    fenceComponents.push({ type: 'doorSp', product: automationProducts.safetyPackageRobotics.filter((product) => product.id === 'NA0251')[0], qty: 1 });
    doorHatchCount += 1;
  }
  if (hasFenceHatch === 'yes') {
    const hatchSpProductId = (Number(fenceHatchWidth) === 450) ? 'NA0252' : 'NA0253';
    fenceComponents.push({ type: 'hatch', product: automationProducts.safetyFenceGirdHatchAndPost.filter((product) => product.id === fenceToHatchMap[fenceCompositions[fenceInitalLengthA][0].id])[0], qty: 1 });
    fenceComponents.push({ type: 'hatchSp', product: automationProducts.safetyPackageRobotics.filter((product) => product.id === hatchSpProductId)[0], qty: 1 });
    doorHatchCount += 1;
  }
  if (hasFenceDoor === 'yes' || hasFenceHatch === 'yes') {
    fenceComponents.unshift({ type: 'basicSp', product: automationProducts.safetyPackageRobotics.filter((product) => product.id === 'NA0250')[0], qty: 1 });
  }
  fenceCompositions[fenceInitalLengthB].forEach((composition) => {
    safetyFenceProducts[composition.id] = composition.qty;
  });
  fenceCompositions[fenceInitalLengthA].forEach((composition, index) => {
    let qty = composition.qty * 2;
    if (index === 0) {
      qty -= doorHatchCount;
    }
    if (safetyFenceProducts[composition.id]) {
      safetyFenceProducts[composition.id] += qty;
    } else {
      safetyFenceProducts[composition.id] = qty;
    }
  });
  Object.keys(safetyFenceProducts).forEach((productId) => {
    fenceComponents.unshift({ type: 'fence', product: automationProducts.safetyFenceGridAndPost.filter((product) => product.id === productId)[0], qty: safetyFenceProducts[productId] });
  });
  fenceComponents.unshift({ type: 'fencePost', product: automationProducts.safetyFencePost.filter((product) => product.id === 'NA0339')[0], qty: 1 });
  return fenceComponents;
}

function getServiceArticles(
  installation, training, approval, country, zipArea, automationProducts
) {
  const fenceComponents = [];
  if (country !== 'xx') {
    if (installation === 'yes') {
      fenceComponents.push({ product: automationProducts.serviceRobotics.filter((product) => product.id === 'NA0900')[0], qty: 1 });
    }
    if (training === 'yes') {
      fenceComponents.push({ product: automationProducts.serviceRobotics.filter((product) => product.id === 'NA0910')[0], qty: 1 });
    }
    if (approval === 'yes') {
      fenceComponents.push({ product: automationProducts.serviceRobotics.filter((product) => product.id === 'NA0920')[0], qty: 1 });
    }
    if (installation === 'yes'
      || training === 'yes'
      || approval === 'yes'
    ) {
      if (country === 'ch') {
        fenceComponents.push({ product: automationProducts.serviceRobotics.filter((product) => product.id === 'NA0901')[0], qty: 10 });
      }
      if (country === 'at') {
        fenceComponents.push({ product: automationProducts.serviceRobotics.filter((product) => product.id === 'NA0901')[0], qty: 14 });
      }
      if (country === 'de') {
        fenceComponents.push({ product: automationProducts.serviceRobotics.filter((product) => product.id === 'NA0901')[0], qty: zipAreaHDuration[zipArea] });
      }
    }
  }
  return fenceComponents;
}

function getOrderPositions(positions, locale) {
  return positions.map((position) => {
    position.price = position.product.price * position.qty;
    position.productName = position.product.name[locale];
    position.translatedQty = `${position.qty} ${productService.getAttributeValueTranslation(
      position.product.unitOfQuantity.i18nId,
      undefined,
      locale
    )}`;
    position.priceLocaleString = salesHelper.formatPrice(
      position.price,
      locale,
      configService.getGeneralConfig().sales.currency
    );
    return position;
  });
}

function getProcurementTime(positions) {
  let procurementTime = 0;
  positions.forEach((position) => {
    if (
      numberHelper.canBeConvertedToNumber(
        position.product.procurementTime
      ) && position.product.quantityInStock !== undefined
      && position.qty > position.product.quantityInStock
      && Number(position.product.procurementTime) > procurementTime) {
      procurementTime = position.product.procurementTime;
    }
  });
  return procurementTime;
}

export default {
  setDependencies,
  getInitialConfiguratorState,
  getConfigurationSteps,
  getI18nIdSecondaryStep,
  getCurrentStepId,
  getPreviousStepInfo,
  getConfigurationSecondarySteps,
  getAutomationConfiguratorProducts,
  getProductsForSecondaryStep,
  showOrderQuantityForSecondaryStep,
  getProductsFromConfiguration,
  getNetPriceInfo,
  getRentPurchasePriceInfo,
  getFenceLengthValues,
  getZipAreaValues,
  getFenceComponents,
  getServiceArticles,
  getOrderPositions,
  getProcurementTime,
};
