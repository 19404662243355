import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SlideOverlayEffectImage from '../Image/SlideOverlayEffectImage';
import HosePartMenuButtons from './HosePartMenuButtons';
import HosePartLabel from './HosePartLabel';
import Icon from '../Layout/Icon';

export default function MainNavStepItem(props) {
  function showOverlayImage() {
    return !props.configuratorState.includes('selectHose');
  }
  function getImagePath() {
    return props.services.hoseConfiguratorService.getPartialImagePath(
      props.hosePartId, props.hoseConfiguration
    );
  }
  function hosePartIsDefined() {
    return props.services.hoseConfiguratorService.hosePartIsDefined(
      props.hoseConfiguration[props.hosePartId]
    );
  }
  function getImageAltText() {
    return (hosePartIsDefined()) ? props.hosePartId : props.hosePartUndefinedImgAltText;
  }
  function getImageClassNames() {
    return (hosePartIsDefined()) ? 'img-overlay-effect-img' : 'img-overlay-effect-img hc-undefined-part';
  }
  function getOverlayContainerClassNames() {
    return (hosePartIsDefined()) ? 'img-overlay-container hc-img-overlay-container' : 'img-overlay-container hc-img-undef-overlay-container';
  }
  return (
    <Fragment>
      <div className="desktop-only">
        {showOverlayImage() && (
          <SlideOverlayEffectImage
            onClickHandler={props.handlers.selectHosePartHandler}
            onClickHandlerParams={{ part: props.hosePartId }}
            imgSrc={getImagePath()}
            imgAlt={getImageAltText()}
            imageClassNames={getImageClassNames()}
            overlayContainerClassNames={getOverlayContainerClassNames()}
          >
            <Icon name="edit" size={22} />
            <HosePartLabel hosePartId={props.hosePartId} />
          </SlideOverlayEffectImage>
        )}
        {!showOverlayImage() && (
          <img className="hc-undefined-part" src={getImagePath()} alt={getImageAltText()} />
        )}
        <HosePartMenuButtons
          configuratorState={props.configuratorState}
          hosePartId={props.hosePartId}
          hosePartConfig={props.hoseConfiguration[props.hosePartId]}
          selectButtonText={props.selectButtonText}
          containerClassNames={(props.hosePartId === 'hose') ? 'desktop-only hc-desktop-step-nav hc-desktop-step-nav--hose' : 'desktop-only hc-desktop-step-nav'}
          handlers={props.handlers}
          services={props.services}
        />
      </div>
      <div className="mobile-only">
        {hosePartIsDefined() && (
          <img src={getImagePath()} alt={getImageAltText()} />
        )}
        {!hosePartIsDefined() && (
          <img className="hc-undefined-part" src={getImagePath()} alt={getImageAltText()} />
        )}
      </div>
    </Fragment>
  );
}

MainNavStepItem.propTypes = {
  configuratorState: PropTypes.string.isRequired,
  hosePartId: PropTypes.string.isRequired,
  hoseConfiguration: PropTypes.objectOf(PropTypes.object).isRequired,
  hosePartUndefinedImgAltText: PropTypes.string.isRequired,
  selectButtonText: PropTypes.string.isRequired,
  handlers: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
