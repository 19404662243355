function getOptionCounts(values, isNumber, listItemCount, locale, replaceDivisionInSortValueFunc) {
  const countsObject = {};
  for (let i = 0; i < values.length; i++) {
    const num = values[i];
    countsObject[num] = countsObject[num] ? countsObject[num] + 1 : 1;
    if (countsObject[num] === listItemCount) {
      delete countsObject[num];
    }
  }
  delete countsObject.undefined;
  const countsArray = Object.keys(countsObject).map((value) => (
    { value, count: countsObject[value] }
  ));
  if (isNumber) {
    countsArray.sort((a, b) => a.value - b.value);
  } else {
    countsArray.sort((a, b) => {
      const aVal = replaceDivisionInSortValueFunc(a.value);
      const bVal = replaceDivisionInSortValueFunc(b.value);
      return aVal.localeCompare(bVal, locale, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }
  return countsArray;
}

function convert(value, isNumber) {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }
  return (isNumber) ? Number(value) : value;
}

module.exports = {
  getOptionCounts,
  convert,
};
