import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './CookieModal.css';

export default function CookieCategory(props) {
  return (
    <div className="cookie-category">
      <div className="grid-cookie-category-main">
        <div className="cookie-category-info">
          <strong><FormattedMessage id={`cookieModal${props.id}Heading`} /></strong><br />
          <FormattedMessage id={`cookieModal${props.id}Description`} />
        </div>
        <div className="center">
          <input
            id={`cookieSet${props.id}`}
            type="checkbox"
            checked={props.enabled}
            disabled={props.readOnly}
            onChange={({ target: { checked } }) => props.changeHandler(checked)}
          />
        </div>
      </div>
    </div>
  );
}

CookieCategory.propTypes = {
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

CookieCategory.defaultProps = {
  readOnly: false,
};
