function convertTimeZone(date, locale, timeZone) {
  return new Date(date.toLocaleString(locale, { timeZone }));
}

function addDays(date, days) {
  return date.setDate(date.getDate() + days);
}

function getDatePlusDays(days) {
  const date = new Date();
  return addDays(date, days);
}

function isAPublicHoliday(date, publicHolidays) {
  if (publicHolidays) {
    for (let i = 0; i < publicHolidays.fixed.length; i++) {
      const fixedHoliday = publicHolidays.fixed[i];
      if ((date.getMonth() + 1) === fixedHoliday[0] && date.getDate() === fixedHoliday[1]) {
        console.log('fixed');
        return true;
      }
    }
    for (let i = 0; i < publicHolidays.variable.length; i++) {
      const variableHoliday = publicHolidays.variable[i];
      if (date.getFullYear() === variableHoliday[0] && (date.getMonth() + 1) === variableHoliday[1]
        && date.getDate() === variableHoliday[2]
      ) {
        return true;
      }
    }
  }
  return false;
}

function getCurrentOrNextWorkingDay(date, publicHolidays) {
  let currentOrNextWorkingDay = date;
  if (date.getDay() === 6) {
    currentOrNextWorkingDay = new Date(date.setDate(date.getDate() + 2));
  } else if (date.getDay() === 0) {
    currentOrNextWorkingDay = new Date(date.setDate(date.getDate() + 1));
  }
  if (isAPublicHoliday(currentOrNextWorkingDay, publicHolidays)) {
    getCurrentOrNextWorkingDay(new Date(date.setDate(date.getDate() + 1)), publicHolidays);
  }
  return currentOrNextWorkingDay;
}

function addWorkingDays(date, daysToAdd, publicHolidays) {
  const startDate = date;
  let endDate;
  let count = 0;
  while (count < daysToAdd) {
    endDate = new Date(startDate.setDate(startDate.getDate() + 1));
    if (endDate.getDay() !== 0 && endDate.getDay() !== 6
      && !isAPublicHoliday(endDate, publicHolidays)
    ) {
      count += 1;
    }
  }
  return endDate;
}

function getLocalWorkingDatePlusWorkingDays(daysToAdd, locale, publicHolidays) {
  const today = new Date();
  return addWorkingDays(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
    daysToAdd,
    publicHolidays
  ).toLocaleString(locale).split(',')[0];
}

function getWorkingDatePlusCalendarDays(daysToAdd, publicHolidays, date) {
  const timestamp = new Date();
  const today = date || new Date(
    timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(), 0, 0, 0
  );
  const dayInFuture = new Date(today.setDate(today.getDate() + daysToAdd));
  return getCurrentOrNextWorkingDay(dayInFuture, publicHolidays);
}

function getLocalWorkingDatePlusCalendarDays(daysToAdd, locale, publicHolidays, date) {
  return getWorkingDatePlusCalendarDays(daysToAdd, publicHolidays, date).toLocaleString(locale).split(',')[0];
}

function secondsToDDHHMiSS(seconds) {
  const days = Math.floor(seconds / 86400);
  const currentDaySeconds = seconds - (days * 86400);
  const date = new Date(0);
  date.setSeconds(currentDaySeconds);
  return `${days} days ${date.toISOString().substr(11, 8)}`;
}

function getFormattedTimestamp(timestamp, locale) {
  if (!timestamp) {
    return '';
  }
  return (new Date(timestamp.substr(0, 19))).toLocaleDateString(
    locale, {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
    }
  );
}

function convertToTwoDigits(value) {
  const valuePlusLeadingZero = `${'0'}${value}`;
  return valuePlusLeadingZero.slice(-2);
}

function convertToThreeDigits(value) {
  const valuePlusLeadingZero = `${'00'}${value}`;
  return valuePlusLeadingZero.slice(-3);
}

function getCurrentDateTime() {
  const systemTime = new Date();
  const germanTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
  germanTime.setMilliseconds(systemTime.getMilliseconds());
  return germanTime;
}

function getCurrentDateTimeString(format) {
  const currentDateTime = getCurrentDateTime();
  let dateString;
  if (format === 'YYYY-MM-DD-HH-MI-SS') {
    dateString = `${currentDateTime.getFullYear()}-${convertToTwoDigits(currentDateTime.getMonth() + 1)}-${convertToTwoDigits(currentDateTime.getDate())}`;
    const timeString = `${convertToTwoDigits(currentDateTime.getHours())}-${convertToTwoDigits(currentDateTime.getMinutes())}-${convertToTwoDigits(currentDateTime.getSeconds())}`;
    return `${dateString}-${timeString}`;
  } else if (format === 'YYYY-MM-DD HH:MI') {
    dateString = `${currentDateTime.getFullYear()}-${convertToTwoDigits(currentDateTime.getMonth() + 1)}-${convertToTwoDigits(currentDateTime.getDate())}`;
    const timeString = `${convertToTwoDigits(currentDateTime.getHours())}:${convertToTwoDigits(currentDateTime.getMinutes())}`;
    return `${dateString} ${timeString}`;
  }
  if (format === 'YYYY-MM-DD HH:MI (MSS)') {
    dateString = `${currentDateTime.getFullYear()}.${convertToTwoDigits(currentDateTime.getMonth() + 1)}.${convertToTwoDigits(currentDateTime.getDate())}`;
  } else {
    dateString = `${convertToTwoDigits(currentDateTime.getDate())}.${convertToTwoDigits(currentDateTime.getMonth() + 1)}.${currentDateTime.getFullYear()}`;
  }
  const timeString = `${convertToTwoDigits(currentDateTime.getHours())}:${convertToTwoDigits(currentDateTime.getMinutes())}:${convertToTwoDigits(currentDateTime.getSeconds())} (${convertToThreeDigits(currentDateTime.getMilliseconds())})`;
  return `${dateString} ${timeString}`;
}

function matchesTimePeriod(from, to) {
  if (!from && !to) {
    return true;
  }
  const date = new Date();
  if (from && from > date) {
    return false;
  }
  if (to && to < date) {
    return false;
  }
  return true;
}

module.exports = {
  convertTimeZone,
  addDays,
  getDatePlusDays,
  addWorkingDays,
  getLocalWorkingDatePlusWorkingDays,
  getWorkingDatePlusCalendarDays,
  getLocalWorkingDatePlusCalendarDays,
  secondsToDDHHMiSS,
  getFormattedTimestamp,
  getCurrentDateTimeString,
  matchesTimePeriod,
};
