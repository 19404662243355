import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './Heading.css';

export default function HeadingBar(props) {
  return (
    <div className={`heading-bar${(props.classNames) ? ` ${props.classNames}` : ''}`}>
      <div className="grid-heading-bar content-container">
        <div>
          <h1>{props.heading}</h1>
        </div>
        <div className="center-vertically">
          <Breadcrumbs
            data={props.breadcrumbsData}
            locale={props.locale}
            services={props.services}
          />
        </div>
      </div>
      <h1 className="mobile-only content-container">{props.heading}</h1>
    </div>
  );
}

HeadingBar.propTypes = {
  heading: PropTypes.string.isRequired,
  breadcrumbsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  classNames: PropTypes.string,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
};

HeadingBar.defaultProps = {
  classNames: undefined,
};
