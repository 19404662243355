import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import UserDetails from './UserDetails';
import OrderList from './OrderList';
import Address from '../Address/Address';
import CustomLink from '../Link/CustomLink';
import './Account.css';

function Dashboard(props) {
  const intl = useIntl();
  function getStandardAddress(addressType) {
    for (let i = 0; i < props.dataStorage.user.addresses.length; i++) {
      if (props.dataStorage.user.addresses[i].isStandardShippingAddress && addressType === 'shipping') {
        return (
          <Address
            address={props.dataStorage.user.addresses[i]}
            showTaxNo={true}
            showSettings={true}
          />
        );
      }
      if (props.dataStorage.user.addresses[i].isStandardBillingAddress && addressType === 'billing') {
        return (
          <Address
            address={props.dataStorage.user.addresses[i]}
            showTaxNo={true}
            showSettings={true}
          />
        );
      }
    }
    return false;
  }
  return (
    <div className="grid-dashboard">
      <div className="dashboard-container">
        <h2><FormattedMessage id="accountDetails" /></h2>
        <UserDetails user={props.dataStorage.user} /><br />
        <CustomLink
          href={props.services.configService.getPageByTranslationCodeAndLocale('user-details', props.locale).path}
        >{intl.formatMessage({ id: 'editUserDetailsOrPassword' })}
        </CustomLink>
      </div>
      <div className="dashboard-container">
        <h2><FormattedMessage id="standardShippingAddress" /></h2>
        {getStandardAddress('shipping') || <Fragment><FormattedMessage id="notSetStandardShippingAddress" /><br /></Fragment>}<br />
        <CustomLink
          href={props.services.configService.getPageByTranslationCodeAndLocale('addressbook', props.locale).path}
        >{intl.formatMessage({ id: 'editAddresses' })}
        </CustomLink>
      </div>
      <div className="dashboard-container">
        <h2><FormattedMessage id="standardBillingAddress" /></h2>
        {getStandardAddress('billing') || <Fragment><FormattedMessage id="notSetStandardBillingAddress" /><br /></Fragment>}<br />
        <CustomLink
          href={props.services.configService.getPageByTranslationCodeAndLocale('addressbook', props.locale).path}
        >{intl.formatMessage({ id: 'editAddresses' })}
        </CustomLink>
      </div>
      <div className="dashboard-order">
        <h2><FormattedMessage id="orders" /></h2>
        <OrderList
          services={props.services}
          dataStorage={props.dataStorage}
          locale={props.locale}
        />
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Dashboard;
