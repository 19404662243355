import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../../Link/CustomLink';
import VeMeDropdownHeading from './VeMeDropdownHeading';
import '../Dropdown.css';

export default function VeMeDropdownListItems(props) {
  return props.items.map((item) => {
    let subitems;
    if (item.subitems) {
      subitems = item.subitems.map((subitem) => (
        <li className="dropdown__third-level-item" key={`${item.label}${subitem.label}`}>
          <CustomLink
            href={subitem.link}
            onClickHandler={subitem.onClickHandler}
            onClickHandlerParams={subitem.onClickHandlerParams}
            isActive={subitem.isActive}
          >{subitem.label}
          </CustomLink>
        </li>
      ));
      return (
        <li key={`${item.label}subitems`}>
          <VeMeDropdownHeading
            layout="subitem"
            parentHeading={props.heading}
            expandedMenuPath={props.expandedMenuPath}
            setExpandedMenuPath={props.setExpandedMenuPath}
            heading={item.label}
          />
          <ul className={props.clickDropdownIsExpanded(`${props.heading}|${item.label}`) ? `${props.dropDownContentClasses} dropdown__content--active` : `${props.dropDownContentClasses}`}>
            {subitems}
          </ul>
        </li>
      );
    }
    return (
      <li key={`${item.label}`}>
        <CustomLink
          href={item.link}
          onClickHandler={item.onClickHandler}
          onClickHandlerParams={item.onClickHandlerParams}
          isActive={item.isActive}
          isExternal={item.isExternal}
          key={item.label}
        >{item.label}
        </CustomLink>
      </li>
    );
  });
}

VeMeDropdownListItems.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func,
  })).isRequired,
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func,
  dropDownContentClasses: PropTypes.string.isRequired,
  clickDropdownIsExpanded: PropTypes.func.isRequired,
};

VeMeDropdownListItems.defaultProps = {
  expandedMenuPath: null,
  setExpandedMenuPath: undefined,
};
