import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import DrawerMenuContent from './DrawerMenuContent';
import Icon from '../Layout/Icon';
import './Drawer.css';

export default function Drawer(props) {
  const intl = useIntl();
  const menuStandardClasses = 'grid-drawer__row1-item grid-drawer__row1-item--1';
  const accountStandardClasses = 'grid-drawer__row1-item grid-drawer__row1-item--2';
  const languageStandardClasses = 'grid-drawer__row1-item grid-drawer__row1-item--3';
  const [state, setState] = useState({
    menuClasses: `${menuStandardClasses} grid-drawer__row1-item--active`,
    accountClasses: accountStandardClasses,
    languageClasses: languageStandardClasses,
    menuId: 'main-menu',
  });
  function changeActiveTabHandler(tab) {
    props.setExpandedMenuPath(undefined);
    if (tab === 'main-menu') {
      setState({
        menuClasses: `${menuStandardClasses} grid-drawer__row1-item--active`,
        accountClasses: accountStandardClasses,
        languageClasses: languageStandardClasses,
        menuId: tab,
      });
    } else if (tab === 'account-menu') {
      setState({
        menuClasses: menuStandardClasses,
        accountClasses: `${accountStandardClasses} grid-drawer__row1-item--active`,
        languageClasses: languageStandardClasses,
        menuId: tab,
      });
    } else {
      setState({
        menuClasses: menuStandardClasses,
        accountClasses: accountStandardClasses,
        languageClasses: `${languageStandardClasses} grid-drawer__row1-item--active`,
        menuId: tab,
      });
    }
  }
  function getMenuItems() {
    if (state.menuId === 'main-menu') {
      return props.mainMenuItems;
    } else if (state.menuId === 'account-menu') {
      return props.accountMenuItems;
    }
    return props.languageMenuItems;
  }
  const drawerClasses = ['drawer'];
  if (props.isVisible) {
    drawerClasses.push('drawer--active');
  }
  return (
    <nav className={drawerClasses.join(' ')}>
      <div className="grid-drawer">
        <div className={state.menuClasses}>
          <button className="grid-drawer__row1__heading" type="button" onClick={() => changeActiveTabHandler('main-menu')} aria-label={intl.formatMessage({ id: 'menu' })}>
            <FormattedMessage id="menu" />
          </button>
        </div>
        <div className={state.accountClasses}>
          <button className="grid-drawer__row1__heading" type="button" onClick={() => changeActiveTabHandler('account-menu')} aria-label={intl.formatMessage({ id: 'account' })}>
            <FormattedMessage id="account" />
          </button>
        </div>
        <div className={state.languageClasses}>
          <button className="grid-drawer__row1__heading" type="button" onClick={() => changeActiveTabHandler('language-menu')}>
            <Icon name="globe" size={40} />
            <span className="sr-only">Language menu</span>
          </button>
        </div>
        <div className="grid-drawer__row2">
          <DrawerMenuContent
            menuId={state.menuId}
            menuItems={getMenuItems()}
            expandedMenuPath={props.expandedMenuPath}
            setExpandedMenuPath={props.setExpandedMenuPath}
            locale={props.locale}
            setLocale={props.setLocale}
            dataStorage={props.dataStorage}
            services={props.services}
          />
        </div>
      </div>
    </nav>
  );
}

Drawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  mainMenuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  accountMenuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  languageMenuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

Drawer.defaultProps = {
  expandedMenuPath: null,
  setExpandedMenuPath: undefined,
};
