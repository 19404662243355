import React from 'react';
import PropTypes from 'prop-types';
import Head from './Head';
import Pavi from './Pavi';
import HeaderAndFooter from '../Layout/HeaderAndFooter';
import CookieModal from '../CookieModal/CookieModal';

export default function PageWrapper(props) {
  const cookieModalActive = props.services.configService.cookieSettingsNotSet();
  if (props.usePavi) {
    return (
      <Head
        locale={props.locale}
        dataStorage={props.dataStorage}
        services={props.services}
        headData={props.headData}
      >
        <Pavi apiService={props.services.apiService}>
          <HeaderAndFooter
            locale={props.locale}
            setLocale={props.setLocale}
            services={props.services}
            dataStorage={props.dataStorage}
            setDataStorage={props.setDataStorage}
          >
            {props.children}
            <CookieModal
              active={cookieModalActive}
              locale={props.locale}
              services={props.services}
            />
          </HeaderAndFooter>
        </Pavi>
      </Head>
    );
  }
  return (
    <Head
      locale={props.locale}
      dataStorage={props.dataStorage}
      services={props.services}
      headData={props.headData}
    >
      <HeaderAndFooter
        locale={props.locale}
        setLocale={props.setLocale}
        services={props.services}
        dataStorage={props.dataStorage}
        setDataStorage={props.setDataStorage}
      >
        {props.children}
        <CookieModal active={cookieModalActive} locale={props.locale} services={props.services} />
      </HeaderAndFooter>
    </Head>
  );
}

PageWrapper.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
  usePavi: PropTypes.bool,
  headData: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

PageWrapper.defaultProps = {
  usePavi: true,
  headData: {},
};
