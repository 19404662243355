import arrayHelper from '../utils/helper/array-helper';

const imgFileType = 'png';
const hoseItemSettings = [
  { path: ['endA', 'coupling'] },
  { path: ['endA', 'screwFitting'] },
  { path: ['endA', 'socketForHose'] },
  { path: ['endA', 'hoseClip'] },
  { path: ['endA', 'socketForHoseInsulation'] },
  { path: ['endB', 'coupling'] },
  { path: ['endB', 'screwFitting'] },
  { path: ['endB', 'socketForHose'] },
  { path: ['endB', 'hoseClip'] },
  { path: ['endB', 'socketForHoseInsulation'] },
  { path: ['hose', 'hose'], hasLength: true },
  { path: ['hose', 'hoseInsulation'], hasLength: true },
];
const elastomerHoseTypes = ['epdm', 'nbr', 'pvc', 'goldSnake'];
const stainlessSteelHoseTypes = ['wf', 'tn1', 'hif'];
let configService;
let apiService;
let productService;

function setDependencies(dependencies) {
  ({ configService, apiService, productService } = dependencies);
  return this;
}

function getConfigurationType() {
  return 'HC';
}

function getUnitOfQuantityI18nId() {
  return 'pieces';
}

function getHoseTypes() {
  return { elastomerHoseTypes, stainlessSteelHoseTypes };
}

function getHoseConfiguratorConfig() {
  return configService.getGeneralConfig().hoseConfigurator;
}

function getHoseOrderQuantityInfo() {
  return configService.getGeneralConfig().hoseConfigurator.hoseOrderQuantityInfo;
}

function showAssemblyInstructions(hosePartConfiguration, currentProductCategory, locale) {
  if (
    (currentProductCategory === 'screwFitting'
    && hosePartConfiguration.screwFitting
    && (
      (!hosePartConfiguration.coupling.withoutHosePart && productService.getAttributeValue(hosePartConfiguration.coupling, 'angle', locale) !== 0)
      || (!hosePartConfiguration.screwFitting.withoutHosePart && productService.getAttributeValue(hosePartConfiguration.screwFitting, 'angle', locale) !== 0)
    ))
    || (currentProductCategory === 'coupling' && !hosePartConfiguration.coupling.withoutHosePart
    && ['hoseNozzle', 'pushlock'].includes(hosePartConfiguration.coupling.additionalAttributes.i18nId.connection)
    && productService.getAttributeValue(hosePartConfiguration.coupling, 'angle', locale) !== 0)
  ) {
    return true;
  }
  return false;
}

function showMirrorHoseOption(hoseConfiguration, hoseEnd, mirrorHoseOptionIsActive) {
  if (
    ((hoseEnd === 'endA' && hoseConfiguration.endB === undefined)
    || (hoseEnd === 'endB' && hoseConfiguration.endA === undefined))
    && !mirrorHoseOptionIsActive
  ) {
    return true;
  }
  return false;
}

function hosePartIsDefined(hosePartConfig) {
  if (hosePartConfig
    && (hosePartConfig.coupling || hosePartConfig.screwFitting || hosePartConfig.hose)
  ) {
    return true;
  }
  return false;
}

function getHoseEndAssemblyCosts(hoseEnd, hoseConfiguration) {
  const assemblyCostConfig = getHoseConfiguratorConfig().assemblyCosts;
  if (hoseConfiguration[hoseEnd].screwFitting.id) {
    return (hoseConfiguration.hose.hoseInsulation.id)
      ? assemblyCostConfig.insulation + assemblyCostConfig.stainless
      : assemblyCostConfig.stainless;
  } else if (hoseConfiguration[hoseEnd].coupling.id) {
    return assemblyCostConfig.elastomer;
  }
  return 0;
}

function getAssemblyCosts(hoseConfiguration) {
  let assemblyCosts = getHoseConfiguratorConfig().assemblyCosts.base;
  assemblyCosts += getHoseEndAssemblyCosts('endA', hoseConfiguration);
  assemblyCosts += getHoseEndAssemblyCosts('endB', hoseConfiguration);
  return assemblyCosts;
}

function getHoseItem(hoseConfiguration, hoseRegion, hoseComponent) {
  if (hoseConfiguration[hoseRegion]
    && hoseConfiguration[hoseRegion][hoseComponent]
    && !hoseConfiguration[hoseRegion][hoseComponent].withoutHosePart
  ) {
    return hoseConfiguration[hoseRegion][hoseComponent];
  }
  return undefined;
}

function getSummedAttributeValue(hoseConfiguration, attributeId) {
  let sum = 0;
  let hoseLength;
  hoseItemSettings.forEach((hoseItemSetting) => {
    const hoseItem = getHoseItem(
      hoseConfiguration, hoseItemSetting.path[0], hoseItemSetting.path[1]
    );
    if (hoseItem) {
      if (hoseItemSetting.hasLength) {
        sum += hoseItem[attributeId] * (hoseLength || hoseItem.length);
        hoseLength = hoseItem.length;
      } else {
        sum += hoseItem[attributeId];
      }
    }
  });
  return sum;
}

function getPositionPriceInfo(hoseConfiguration, orderQuantity, dataStorage, locale) {
  const assemblyCosts = getAssemblyCosts(hoseConfiguration) * orderQuantity;
  let price = 0;
  let listPrice = 0;
  let discount;
  let hoseLength;
  hoseItemSettings.forEach((hoseItemSetting) => {
    const hoseItem = getHoseItem(
      hoseConfiguration, hoseItemSetting.path[0], hoseItemSetting.path[1]
    );
    if (hoseItem) {
      let hoseItemQty = 1;
      if (hoseItemSetting.hasLength) {
        hoseItemQty = hoseLength || hoseItem.length;
        hoseLength = hoseItem.length;
      }
      const positionPriceInfo = productService.getPositionPriceInfo(
        hoseItem, hoseItemQty * orderQuantity, dataStorage, locale
      );
      price += positionPriceInfo.price;
      listPrice += positionPriceInfo.listPrice || positionPriceInfo.price;
    }
  });
  price += assemblyCosts;
  listPrice += assemblyCosts;
  if (price === listPrice) {
    listPrice = undefined;
  } else {
    discount = (price / listPrice - 1) * 100;
  }
  return productService.getPositionPriceInfoObject(locale, price, discount, listPrice);
}

function getTotalWeight(hoseConfiguration) {
  return getSummedAttributeValue(hoseConfiguration, 'weight');
}

function getHosePartId(hosePartStep) {
  if (hosePartStep === 'selectHose') {
    return 'hose';
  } else if (hosePartStep === 'selectHoseEndA') {
    return 'endA';
  }
  return 'endB';
}

function getFilterAttributes(productCategory) {
  if (getHoseConfiguratorConfig().filters !== undefined) {
    return getHoseConfiguratorConfig().filters[productCategory]
    || productService.getFilterAttributes(productCategory);
  }
  return productService.getFilterAttributes(productCategory);
}

function getPartialUndefinedImagePath(hosePart) {
  return configService.getProductImgPath(
    getHoseConfiguratorConfig().placeholderImages[hosePart]
  );
}

function getReplaceString() {
  return `-${productService.getProductConfig().image.defaultImgSizes.base}.${productService.getProductConfig().image.defaultImgExtentions.base}`;
}

function getImgFilename(product) {
  return productService.getImgFilename(product).replace(getReplaceString(), '');
}

function getAssemblyDirectionForImgFilename(hosePartConfig) {
  return (hosePartConfig.assemblyDirection) ? `-${hosePartConfig.assemblyDirection}` : '';
}

function getSingleProductHoseEndImagePath(product, endKey, hosePartConfig) {
  const productImgName = getImgFilename(product);
  return configService.getProductImgPath(`${endKey}-${productImgName}${getAssemblyDirectionForImgFilename(hosePartConfig)}.${imgFileType}`);
}

function getHoseEndImagePath(hosePartConfig, endKey) {
  if (hosePartConfig.screwFitting.withoutHosePart && hosePartConfig.coupling.withoutHosePart) {
    return configService.getProductImgPath('none.jpg');
  } else if (hosePartConfig.screwFitting.withoutHosePart) {
    return getSingleProductHoseEndImagePath(hosePartConfig.coupling, endKey, hosePartConfig);
  } else if (hosePartConfig.coupling.withoutHosePart) {
    return getSingleProductHoseEndImagePath(hosePartConfig.screwFitting, endKey, hosePartConfig);
  }
  let fittingImgName = getImgFilename(hosePartConfig.screwFitting);
  if (hosePartConfig.screwFitting.definitionPostpowned) {
    fittingImgName = 'FL0866';
  }
  const couplingImgName = getImgFilename(hosePartConfig.coupling);
  return configService.getProductImgPath(`${endKey}-${fittingImgName}-${couplingImgName}${getAssemblyDirectionForImgFilename(hosePartConfig)}.${imgFileType}`);
}

function getPartialImagePath(hosePart, hoseConfiguration, newHosePartConfig) {
  const hosePartConfig = newHosePartConfig || hoseConfiguration[hosePart];
  if (hosePartIsDefined(hosePartConfig)) {
    if (hosePart === 'endA') {
      return getHoseEndImagePath(hosePartConfig, 'L');
    } else if (hosePart === 'endB') {
      return getHoseEndImagePath(hosePartConfig, 'R');
    } else if (!hosePartConfig.hoseInsulation.withoutHosePart) {
      return productService.getImgPath(hosePartConfig.hoseInsulation).replace(getReplaceString(), `-schlauch.${imgFileType}`);
    } else if (
      stainlessSteelHoseTypes.includes(hosePartConfig.hose.additionalAttributes.i18nId.hoseType)
      && hoseConfiguration.endA && hoseConfiguration.endA.screwFitting
      && hoseConfiguration.endA.screwFitting.id
      && hoseConfiguration.endB && hoseConfiguration.endB.screwFitting
      && hoseConfiguration.endB.screwFitting.id
    ) {
      return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch-press.${imgFileType}`);
    } else if (
      stainlessSteelHoseTypes.includes(hosePartConfig.hose.additionalAttributes.i18nId.hoseType)
      && hoseConfiguration.endA && hoseConfiguration.endA.screwFitting
      && hoseConfiguration.endA.screwFitting.id
      && !(hoseConfiguration.endB && hoseConfiguration.endB.screwFitting
      && hoseConfiguration.endB.screwFitting.id)
    ) {
      return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch-press-li.${imgFileType}`);
    } else if (
      stainlessSteelHoseTypes.includes(hosePartConfig.hose.additionalAttributes.i18nId.hoseType)
      && !(hoseConfiguration.endA && hoseConfiguration.endA.screwFitting
      && hoseConfiguration.endA.screwFitting.id)
      && hoseConfiguration.endB && hoseConfiguration.endB.screwFitting
      && hoseConfiguration.endB.screwFitting.id
    ) {
      return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch-press-re.${imgFileType}`);
    } else if (elastomerHoseTypes.includes(hosePartConfig.hose.additionalAttributes.i18nId.hoseType)
      && hoseConfiguration.endA && hoseConfiguration.endA.coupling
      && hoseConfiguration.endA.coupling.additionalAttributes
      && hoseConfiguration.endA.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle'
      && hoseConfiguration.endB && hoseConfiguration.endB.coupling
      && hoseConfiguration.endB.coupling.additionalAttributes
      && hoseConfiguration.endB.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle'
    ) {
      return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch-schelle.${imgFileType}`);
    } else if (elastomerHoseTypes.includes(hosePartConfig.hose.additionalAttributes.i18nId.hoseType)
      && hoseConfiguration.endA && hoseConfiguration.endA.coupling
      && hoseConfiguration.endA.coupling.additionalAttributes
      && hoseConfiguration.endA.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle'
      && !(hoseConfiguration.endB && hoseConfiguration.endB.coupling
        && hoseConfiguration.endB.coupling.additionalAttributes
      && hoseConfiguration.endB.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle')
    ) {
      return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch-schelle-li.${imgFileType}`);
    } else if (elastomerHoseTypes.includes(hosePartConfig.hose.additionalAttributes.i18nId.hoseType)
      && !(hoseConfiguration.endA && hoseConfiguration.endA.coupling
        && hoseConfiguration.endA.coupling.additionalAttributes
      && hoseConfiguration.endA.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle')
      && hoseConfiguration.endB && hoseConfiguration.endB.coupling
      && hoseConfiguration.endB.coupling.additionalAttributes
      && hoseConfiguration.endB.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle'
    ) {
      return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch-schelle-re.${imgFileType}`);
    }
    return productService.getImgPath(hosePartConfig.hose).replace(getReplaceString(), `-schlauch.${imgFileType}`);
  }
  return getPartialUndefinedImagePath(hosePart);
}

function getCustomProductPath(customProduct, locale) {
  return `${configService.getPageByTranslationCodeAndLocale('hose-configurator', locale).path}?hc=${customProduct.id}`;
}

function getProductsIdsByConstrains(products, constrains, productIds, locale, dataStorage) {
  const filters = {};
  constrains.forEach((constrain) => {
    filters[constrain.attributeId] = constrain.i18nIds.map(
      (i18nId) => productService.getAttributeValueTranslation(i18nId, undefined, locale)
    );
  });
  const filteredProducts = productService.getFilteredProductList(
    products, filters, locale, dataStorage
  );
  const filteredProductIds = filteredProducts.map((filteredProduct) => filteredProduct.id);
  productIds.forEach((productId) => {
    if (!filteredProductIds.includes(productId)) {
      filteredProductIds.push(productId);
    }
  });
  return filteredProductIds;
}

function getHoseConfiguratorProducts(dataStorage, locale) {
  const productsConfig = getHoseConfiguratorConfig().products;
  const monoCouplings = [];
  const hoses = [];
  let screwFittings = [];
  let productIdsToExclude = [];
  let filteredProducts = [];
  if (productsConfig.exclude.ids.length || productsConfig.exclude.filters.length) {
    productIdsToExclude = getProductsIdsByConstrains(
      dataStorage.products,
      productsConfig.exclude.filters,
      productsConfig.exclude.ids,
      locale,
      dataStorage
    );
  }
  dataStorage.products.forEach((product) => {
    if (!productIdsToExclude.includes(product)) {
      if (product.productType.i18nId === 'monoCoupling'
        && product.additionalAttributes.i18nId.connection !== 'femaleThread'
        && !product.description.de.includes('NPT')
      ) {
        monoCouplings.push(product);
      } else if (product.productType.i18nId === 'screwFitting') {
        screwFittings.push(product);
      } else if (product.productType.i18nId === 'hose') {
        hoses.push(product);
      } else if (product.productType.i18nId === 'hoseInsulation') {
        filteredProducts.push(product);
      } else if (product.productType.i18nId === 'hoseClip') {
        filteredProducts.push(product);
      } else if (product.productType.i18nId === 'hoseSocket') {
        filteredProducts.push(product);
      }
    }
  });
  let productTypeConfig = productService.getProductTypeConfigById('screwFitting');
  const screwFittingsTmp1 = [];
  screwFittings.forEach((screwFitting) => {
    if (productService.getRelatedProducts(
      productTypeConfig, screwFitting, 'fitsTo', hoses, locale, 10000
    ).length > 0) {
      screwFittingsTmp1.push(screwFitting);
    }
  });
  const screwFittingsTmp2 = [];
  screwFittingsTmp1.forEach((screwFitting) => {
    if (screwFitting.name.de.includes('VA-VA') || screwFitting.name.de.includes('ST-ST')) {
      let vaStMatch = false;
      for (let i = 0; i < screwFittingsTmp1.length && !vaStMatch; i++) {
        if (screwFittingsTmp1[i].name.de.includes('VA-ST')
          && screwFittingsTmp1[i].additionalAttributes.noI18n
            .thread === screwFitting.additionalAttributes.noI18n.thread
          && screwFittingsTmp1[i].additionalAttributes.noI18n
            .angle === screwFitting.additionalAttributes.noI18n.angle
          && arrayHelper.compareSimpleArrays(
            screwFittingsTmp1[i].additionalAttributes.noI18n.compatibleHoses,
            screwFitting.additionalAttributes.noI18n.compatibleHoses
          )
        ) {
          vaStMatch = true;
        }
      }
      if (!vaStMatch) {
        screwFittingsTmp2.push(screwFitting);
      }
    } else {
      screwFittingsTmp2.push(screwFitting);
    }
  });
  screwFittings = screwFittingsTmp2;
  productTypeConfig = productService.getProductTypeConfigById('hose');
  const hosesTmp = [];
  hoses.forEach((hose) => {
    if (['wf', 'tn1', 'hif'].includes(hose.additionalAttributes.i18nId.hoseType)) {
      const relatedFittings = productService.getRelatedProducts(
        productTypeConfig, hose, 'fitsTo', screwFittings, locale, 10000
      );
      let match = false;
      for (let i = 0; i < relatedFittings.length && !match; i++) {
        for (let k = 0; k < monoCouplings.length && !match; k++) {
          if (relatedFittings[i].additionalAttributes.noI18n.thread === monoCouplings[k]
            .additionalAttributes.noI18n.thread
          ) {
            if (hose.additionalAttributes.i18nId.hoseType === 'hif') {
              const hifHose = JSON.parse(JSON.stringify(hose));
              hifHose.isOnlyOnRequest = false;
              hosesTmp.push(hifHose);
            } else {
              hosesTmp.push(hose);
            }
            match = true;
          }
        }
      }
    }
    if (['epdm', 'nbr', 'pvc', 'goldSnake'].includes(hose.additionalAttributes.i18nId.hoseType)) {
      if (productService.getRelatedProducts(
        productTypeConfig, hose, 'fitsTo', monoCouplings, locale, 10000
      ).length > 0) {
        hosesTmp.push(hose);
      }
    }
  });
  filteredProducts = filteredProducts.concat(hosesTmp);
  productTypeConfig = productService.getProductTypeConfigById('monoCoupling');
  monoCouplings.forEach((monoCoupling) => {
    if (['hoseNozzle', 'pushlock'].includes(monoCoupling.additionalAttributes.i18nId.connection)) {
      if (productService.getRelatedProducts(
        productTypeConfig, monoCoupling, 'fitsTo', hoses, locale, 10000
      ).length > 0) {
        filteredProducts.push(monoCoupling);
      }
    } else if (productService.getRelatedProducts(
      productTypeConfig, monoCoupling, 'fitsTo', screwFittings, locale, 10000
    ).length > 0) {
      filteredProducts.push(monoCoupling);
    }
  });
  return filteredProducts.concat(screwFittings);
}

function getAttributeValueTranslation(translationId, locale) {
  return productService.getAttributeValueTranslation(translationId, undefined, locale);
}

function hosesFilterFuncForPostpownedFittings(product, products, coupling, locale) {
  if (product.productType.i18nId === 'hose'
    && ['wf', 'tn1', 'hif'].includes(product.additionalAttributes.i18nId.hoseType)
    && products.filter((tmpProduct) => {
      if (
        tmpProduct.productType.i18nId === 'screwFitting'
        && tmpProduct.additionalAttributes.noI18n.thread === coupling
          .additionalAttributes.noI18n.thread
        && tmpProduct.additionalAttributes.noI18n.compatibleHoses
        && tmpProduct.additionalAttributes.noI18n.compatibleHoses.includes(product.name[locale])
      ) {
        if (coupling.additionalAttributes.noI18n.angle !== 0) {
          return tmpProduct.additionalAttributes.noI18n.angle === 0;
        }
        return true;
      }
      return false;
    }).length > 0
  ) {
    return true;
  }
  return false;
}

function getFilteredProducts(
  products, productCategory, hoseConfiguration, hosePartConfiguration, intl, locale, dataStorage
) {
  const filters = {};
  if (productCategory === 'hose') {
    filters.productType = [intl.formatMessage({ id: 'hcHose' })];
    if (
      (hoseConfiguration.endA && hoseConfiguration.endA.screwFitting
        && hoseConfiguration.endA.screwFitting.id)
    ) {
      return productService.getRelatedProducts(
        undefined,
        hoseConfiguration.endA.screwFitting,
        'fitsTo',
        products, locale,
        20,
        getHoseConfiguratorConfig().rules.hose
      );
    } else if (
      (hoseConfiguration.endB && hoseConfiguration.endB.screwFitting
        && hoseConfiguration.endB.screwFitting.id)
    ) {
      return productService.getRelatedProducts(
        undefined,
        hoseConfiguration.endB.screwFitting,
        'fitsTo',
        products, locale,
        20,
        getHoseConfiguratorConfig().rules.hose
      );
    } else if (hoseConfiguration.endA && hoseConfiguration.endA.coupling
      && hoseConfiguration.endA.coupling.additionalAttributes
      && hoseConfiguration.endA.coupling.additionalAttributes.i18nId.connection === 'maleThread'
    ) {
      return products.filter((product) => hosesFilterFuncForPostpownedFittings(
        product, products, hoseConfiguration.endA.coupling, locale
      ));
    } else if (hoseConfiguration.endB && hoseConfiguration.endB.coupling
      && hoseConfiguration.endB.coupling.additionalAttributes
      && hoseConfiguration.endB.coupling.additionalAttributes.i18nId.connection === 'maleThread'
    ) {
      return products.filter((product) => hosesFilterFuncForPostpownedFittings(
        product, products, hoseConfiguration.endB.coupling, locale
      ));
    } else if (
      (hoseConfiguration.endA && hoseConfiguration.endA.coupling
        && hoseConfiguration.endA.coupling.additionalAttributes
        && hoseConfiguration.endA.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle')
      || (hoseConfiguration.endB && hoseConfiguration.endB.coupling
        && hoseConfiguration.endB.coupling.additionalAttributes
        && hoseConfiguration.endB.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle')
    ) {
      filters.hoseType = [getAttributeValueTranslation('epdm', locale), getAttributeValueTranslation('nbr', locale), getAttributeValueTranslation('pvc', locale), getAttributeValueTranslation('goldSnake', locale)];
      if (hoseConfiguration.endA && hoseConfiguration.endA.coupling) {
        filters.compatibleNozzleSizes = [hoseConfiguration
          .endA.coupling.additionalAttributes.noI18n.nozzleDiameter];
      } else if (hoseConfiguration.endB && hoseConfiguration.endB.coupling) {
        filters.compatibleNozzleSizes = [hoseConfiguration
          .endB.coupling.additionalAttributes.noI18n.nozzleDiameter];
      }
    } else if (
      (hoseConfiguration.endA && hoseConfiguration.endA.coupling
        && hoseConfiguration.endA.coupling.additionalAttributes
        && hoseConfiguration.endA.coupling.additionalAttributes.i18nId.connection === 'pushlock')
      || (hoseConfiguration.endB && hoseConfiguration.endB.coupling
        && hoseConfiguration.endB.coupling.additionalAttributes
        && hoseConfiguration.endB.coupling.additionalAttributes.i18nId.connection === 'pushlock')
    ) {
      filters.hoseType = [getAttributeValueTranslation('epdm', locale), getAttributeValueTranslation('nbr', locale)];
      if (hoseConfiguration.endA && hoseConfiguration.endA.coupling) {
        filters.compatibleNozzleSizes = [hoseConfiguration
          .endA.coupling.additionalAttributes.noI18n.nozzleDiameter];
      } else if (hoseConfiguration.endB && hoseConfiguration.endB.coupling) {
        filters.compatibleNozzleSizes = [hoseConfiguration
          .endB.coupling.additionalAttributes.noI18n.nozzleDiameter];
      }
    }
  } else if (productCategory === 'hoseInsulation') {
    return productService.getRelatedProducts(
      undefined,
      hosePartConfiguration.hose,
      'fitsTo',
      products, locale,
      10,
      getHoseConfiguratorConfig().rules.hoseInsulation
    );
  } else if (productCategory === 'coupling') {
    filters.productType = [intl.formatMessage({ id: 'hcMonoCoupling' })];
    if (hoseConfiguration.hose && hoseConfiguration.hose.hose) {
      const couplings = productService.getRelatedProducts(
        undefined,
        hoseConfiguration.hose.hose,
        'fitsTo',
        products, locale,
        1000,
        getHoseConfiguratorConfig().rules.coupling
      );
      if (stainlessSteelHoseTypes.includes(
        hoseConfiguration.hose.hose.additionalAttributes.i18nId.hoseType
      )) {
        const filteredCouplings = [];
        couplings.forEach((coupling) => {
          let match = false;
          for (let i = 0; i < products.length && !match; i++) {
            if (products[i].productType.i18nId === 'screwFitting'
              && products[i].additionalAttributes.noI18n.thread === coupling
                .additionalAttributes.noI18n.thread
              && products[i].additionalAttributes.noI18n.compatibleHoses.includes(
                hoseConfiguration.hose.hose.name[locale]
              )
            ) {
              filteredCouplings.push(coupling);
              match = true;
            }
          }
        });
        return filteredCouplings;
      }
      return couplings;
    } else if (hoseConfiguration.endA
      && hoseConfiguration.endA.coupling
      && hoseConfiguration.endA.coupling.id
    ) {
      return productService.getRelatedProducts(
        undefined,
        hoseConfiguration.endA.coupling,
        'fitsTo',
        products, locale,
        1000,
        getHoseConfiguratorConfig().rules.coupling
      );
    } else if (hoseConfiguration.endB
      && hoseConfiguration.endB.coupling
      && hoseConfiguration.endB.coupling.id
    ) {
      return productService.getRelatedProducts(
        undefined,
        hoseConfiguration.endB.coupling,
        'fitsTo',
        products, locale,
        1000,
        getHoseConfiguratorConfig().rules.coupling
      );
    } else if (
      (hoseConfiguration.endA && hoseConfiguration.endA.screwFitting
        && hoseConfiguration.endA.screwFitting.id)
      || (hoseConfiguration.endB && hoseConfiguration.endB.screwFitting
        && hoseConfiguration.endB.screwFitting.id)
    ) {
      filters.connection = [getAttributeValueTranslation('maleThread', locale)];
    }
  } else if (productCategory === 'screwFitting') {
    filters.productType = [intl.formatMessage({ id: 'hcScrewFitting' })];
    if (hoseConfiguration.hose && hoseConfiguration.hose.hose && hoseConfiguration.hose.hose.id
      && hoseConfiguration.hose.hose.additionalAttributes.i18nId.hoseType === 'hif'
    ) {
      filters.material = [getAttributeValueTranslation('stainlessSteel', locale)];
    }
    if (hosePartConfiguration.coupling && hosePartConfiguration.coupling.additionalAttributes) {
      filters.thread = [hosePartConfiguration.coupling.additionalAttributes.noI18n.thread];
      if (hosePartConfiguration.coupling.additionalAttributes.noI18n.angle !== 0) {
        filters.angle = [0];
      }
    }
    if (hoseConfiguration.hose && hoseConfiguration.hose.hose && hoseConfiguration.hose.hose.id) {
      filters.compatibleHoses = [hoseConfiguration.hose.hose.name[locale]];
    }
  } else if (productCategory === 'hoseClip') {
    return productService.getRelatedProducts(
      undefined,
      hoseConfiguration.hose.hose,
      'fitsTo',
      products, locale,
      1000,
      getHoseConfiguratorConfig().rules.hoseClip
    );
  } else if (productCategory === 'socketForHose') {
    filters.productType = [intl.formatMessage({ id: 'hcHoseSocket' })];
    filters.compatibleHoses = [hoseConfiguration.hose.hose.name.de];
    filters.material = [getAttributeValueTranslation(
      hosePartConfiguration.screwFitting.additionalAttributes.i18nId.material,
      locale
    )];
  } else if (productCategory === 'socketForHoseInsulation') {
    filters.productType = [intl.formatMessage({ id: 'hcHoseSocket' })];
    filters.compatibleInsulationDiameter = [hoseConfiguration.hose.hoseInsulation
      .additionalAttributes.noI18n.innerDiameter];
  }
  return productService.getFilteredProductList(
    products,
    filters,
    locale,
    dataStorage
  );
}

function getAdditinalSortSettings(hosePart) {
  const sortConfig = getHoseConfiguratorConfig().sortOptions;
  if (sortConfig && sortConfig[hosePart]) {
    return sortConfig[hosePart];
  }
  return undefined;
}

function getSortAttributeSettings(hosePart) {
  return productService.getSortAttributeSettings(getAdditinalSortSettings(hosePart));
}

function getDefaultProductCategorySortOption(productCategory, intl, locale) {
  return productService.getDefaultProductListSortOption(
    getSortAttributeSettings(productCategory), intl, locale
  );
}

function getProductCategorySortOptions(productCategory, intl, locale) {
  return productService.getProductListSortOptions(
    getSortAttributeSettings(productCategory), intl, locale
  );
}

function getFlatHoseConfigurationObject(hoseConfiguration) {
  const flatObject = JSON.parse(JSON.stringify(hoseConfiguration));
  if (!flatObject.endA.coupling.withoutHosePart) {
    flatObject.endA.coupling = { id: flatObject.endA.coupling.id };
  }
  if (!flatObject.endA.screwFitting.withoutHosePart) {
    flatObject.endA.screwFitting = { id: flatObject.endA.screwFitting.id };
  }
  if (!flatObject.endA.hoseClip.withoutHosePart) {
    flatObject.endA.hoseClip = { id: flatObject.endA.hoseClip.id };
  }
  if (!flatObject.endA.socketForHose.withoutHosePart) {
    flatObject.endA.socketForHose = { id: flatObject.endA.socketForHose.id };
  }
  if (!flatObject.endA.socketForHoseInsulation.withoutHosePart) {
    flatObject.endA.socketForHoseInsulation = { id: flatObject.endA.socketForHoseInsulation.id };
  }
  if (!flatObject.endB.coupling.withoutHosePart) {
    flatObject.endB.coupling = { id: flatObject.endB.coupling.id };
  }
  if (!flatObject.endB.screwFitting.withoutHosePart) {
    flatObject.endB.screwFitting = { id: flatObject.endB.screwFitting.id };
  }
  if (!flatObject.endB.hoseClip.withoutHosePart) {
    flatObject.endB.hoseClip = { id: flatObject.endB.hoseClip.id };
  }
  if (!flatObject.endB.socketForHose.withoutHosePart) {
    flatObject.endB.socketForHose = { id: flatObject.endB.socketForHose.id };
  }
  if (!flatObject.endB.socketForHoseInsulation.withoutHosePart) {
    flatObject.endB.socketForHoseInsulation = { id: flatObject.endB.socketForHoseInsulation.id };
  }
  flatObject.hose.hose = {
    id: flatObject.hose.hose.id,
    length: flatObject.hose.hose.length,
  };
  if (!flatObject.hose.hoseInsulation.withoutHosePart) {
    flatObject.hose.hoseInsulation = {
      id: flatObject.hose.hoseInsulation.id,
    };
  }
  return flatObject;
}

function addProductIdsFromHoseEndToArr(arr, hoseEndConfiguration) {
  arrayHelper.addItemToArrIfExists(arr, hoseEndConfiguration.coupling.id);
  arrayHelper.addItemToArrIfExists(arr, hoseEndConfiguration.screwFitting.id);
  arrayHelper.addItemToArrIfExists(arr, hoseEndConfiguration.hoseClip.id);
  arrayHelper.addItemToArrIfExists(arr, hoseEndConfiguration.socketForHose.id);
  arrayHelper.addItemToArrIfExists(arr, hoseEndConfiguration.socketForHoseInsulation.id);
}

function getProductIdsFromFlatConfiguration(flatHoseConfiguration) {
  const productIds = [];
  addProductIdsFromHoseEndToArr(productIds, flatHoseConfiguration.endA);
  addProductIdsFromHoseEndToArr(productIds, flatHoseConfiguration.endB);
  arrayHelper.addItemToArrIfExists(productIds, flatHoseConfiguration.hose.hose.id);
  arrayHelper.addItemToArrIfExists(productIds, flatHoseConfiguration.hose.hoseInsulation.id);
  return productIds;
}
function isAvailableHoseConfiguration(flatHoseConfiguration, dataStorage) {
  const productIds = getProductIdsFromFlatConfiguration(flatHoseConfiguration);
  for (let i = 0; i < productIds.length; i++) {
    if (!productService.getProduct(productIds[i], dataStorage)) {
      console.log(`Artikel ${productIds[i]} nicht mehr verfügbar.`);
      return false;
    }
  }
  return true;
}

function getFlatConfiguration(hoseConfiguration) {
  return getFlatHoseConfigurationObject(hoseConfiguration);
}

function getDeepConfigurationObject(flatHoseConfiguration, dataStorage) {
  const deepObject = JSON.parse(JSON.stringify(flatHoseConfiguration));
  if (!deepObject.endA.coupling.withoutHosePart) {
    deepObject.endA.coupling = productService.getProduct(
      deepObject.endA.coupling.id, dataStorage
    );
  }
  if (!deepObject.endA.screwFitting.withoutHosePart) {
    deepObject.endA.screwFitting = productService.getProduct(
      deepObject.endA.screwFitting.id, dataStorage
    );
  }
  if (!deepObject.endA.hoseClip.withoutHosePart) {
    deepObject.endA.hoseClip = productService.getProduct(
      deepObject.endA.hoseClip.id, dataStorage
    );
  }
  if (!deepObject.endA.socketForHose.withoutHosePart) {
    deepObject.endA.socketForHose = productService.getProduct(
      deepObject.endA.socketForHose.id, dataStorage
    );
  }
  if (!deepObject.endA.socketForHoseInsulation.withoutHosePart) {
    deepObject.endA.socketForHoseInsulation = productService.getProduct(
      deepObject.endA.socketForHoseInsulation.id, dataStorage
    );
  }
  if (!deepObject.endB.coupling.withoutHosePart) {
    deepObject.endB.coupling = productService.getProduct(
      deepObject.endB.coupling.id, dataStorage
    );
  }
  if (!deepObject.endB.screwFitting.withoutHosePart) {
    deepObject.endB.screwFitting = productService.getProduct(
      deepObject.endB.screwFitting.id, dataStorage
    );
  }
  if (!deepObject.endB.hoseClip.withoutHosePart) {
    deepObject.endB.hoseClip = productService.getProduct(
      deepObject.endB.hoseClip.id, dataStorage
    );
  }
  if (!deepObject.endB.socketForHose.withoutHosePart) {
    deepObject.endB.socketForHose = productService.getProduct(
      deepObject.endB.socketForHose.id, dataStorage
    );
  }
  if (!deepObject.endB.socketForHoseInsulation.withoutHosePart) {
    deepObject.endB.socketForHoseInsulation = productService.getProduct(
      deepObject.endB.socketForHoseInsulation.id, dataStorage
    );
  }
  if (!deepObject.hose.hoseInsulation.withoutHosePart) {
    deepObject.hose.hoseInsulation = productService.getProduct(
      deepObject.hose.hoseInsulation.id, dataStorage
    );
  }
  const { length } = deepObject.hose.hose;
  deepObject.hose.hose = JSON.parse(JSON.stringify(productService.getProduct(
    deepObject.hose.hose.id, dataStorage
  )));
  deepObject.hose.hose.length = length;
  return deepObject;
}

function addProductTypeToTitle(title, productType, configuration, locale) {
  if (configuration.endA[productType].name && configuration.endB[productType].name
    && configuration.endA[productType].name[locale] === configuration.endB[productType].name[locale]
  ) {
    title = `${title}, 2x ${configuration.endA[productType].name[locale]}`;
  } else {
    if (configuration.endA[productType].name) {
      title = `${title}, ${configuration.endA[productType].name[locale]}`;
    }
    if (configuration.endB[productType].name) {
      title = `${title}, ${configuration.endB[productType].name[locale]}`;
    }
  }
  return title;
}

function getCustomProductTitle(customProduct, intl, locale) {
  if (!locale) {
    return `${intl.formatMessage({ id: 'hcHose' })} ${customProduct.id}`;
  }
  const configuration = customProduct.customConfiguration;
  let title = `${intl.formatMessage({ id: 'hcHose' })} ${customProduct.id}: ${configuration.hose.hose.name[locale]}`;
  if (configuration.hose.hoseInsulation.name) {
    title = `${title}, ${configuration.hose.hoseInsulation.name[locale].split(' ')[0]}`;
  }
  title = addProductTypeToTitle(title, 'coupling', configuration, locale);
  title = addProductTypeToTitle(title, 'screwFitting', configuration, locale);
  title = addProductTypeToTitle(title, 'hoseClip', configuration, locale);
  title = addProductTypeToTitle(title, 'socketForHose', configuration, locale);
  title = addProductTypeToTitle(title, 'socketForHoseInsulation', configuration, locale);
  return `${title}, ${intl.formatMessage({ id: 'hcLength' })}: ${Number(configuration.hose.hose.length).toLocaleString(locale)}m.`;
}

function getPositionImgArray(customProduct, locale, intl) {
  const path = getCustomProductPath(customProduct, locale);
  return [
    {
      src: getPartialImagePath('endA', customProduct.customConfiguration),
      alt: `${intl.formatMessage({ id: 'hcHoseEnd' })} A ${customProduct.id}`,
      href: path,
    },
    {
      src: getPartialImagePath('hose', customProduct.customConfiguration),
      alt: getCustomProductTitle(customProduct, intl),
      href: path,
    },
    {
      src: getPartialImagePath('endB', customProduct.customConfiguration),
      alt: `${intl.formatMessage({ id: 'hcHoseEnd' })} B ${customProduct.id}`,
      href: path,
    },
  ];
}

function getAddToCartProductObject(hoseCode, hoseConfiguration) {
  return {
    id: hoseCode,
    customProductType: getConfigurationType(),
    flatConfiguration: getFlatHoseConfigurationObject(
      hoseConfiguration
    ),
    unitOfQuantity: { i18nId: getUnitOfQuantityI18nId() },
  };
}

function addProductOrderQuantities(
  hoseConfiguration,
  productAndOrderQuantities,
  positionOrderQuantity,
  addProductAndOrderQuantityFunc
) {
  let hoseLength;
  hoseItemSettings.forEach((hoseItemSetting) => {
    const hoseItem = getHoseItem(
      hoseConfiguration, hoseItemSetting.path[0], hoseItemSetting.path[1]
    );
    if (hoseItem) {
      let hoseItemLength = 1;
      if (hoseItemSetting.hasLength) {
        hoseItemLength = hoseLength || hoseItem.length;
        hoseLength = hoseItem.length;
      }
      productAndOrderQuantities = addProductAndOrderQuantityFunc(
        productAndOrderQuantities, hoseItem, hoseItemLength * positionOrderQuantity
      );
    }
  });
  return productAndOrderQuantities;
}

function isHoseEndWithPostpownedDefinition(hoseEnd, hoseConfiguration) {
  return hoseConfiguration[hoseEnd]
    && hoseConfiguration[hoseEnd].screwFitting
    && hoseConfiguration[hoseEnd].screwFitting.definitionPostpowned;
}

function addFittingToHoseEndIfNecessary(
  hoseEnd, hoseConfiguration, products, intl, locale, dataStorage
) {
  if (isHoseEndWithPostpownedDefinition(hoseEnd, hoseConfiguration)) {
    const screwFittings = getFilteredProducts(
      products,
      'screwFitting',
      hoseConfiguration,
      hoseConfiguration[hoseEnd],
      intl,
      locale,
      dataStorage
    ).filter((screwFitting) => productService.getFilteredProductList(
      products,
      {
        productType: [intl.formatMessage({ id: 'hcHoseSocket' })],
        compatibleHoses: [hoseConfiguration.hose.hose.name.de],
        material: [getAttributeValueTranslation(
          screwFitting.additionalAttributes.i18nId.material,
          locale
        )],
      },
      locale,
      dataStorage
    ).length > 0);
    if (screwFittings.length > 0) {
      const straightFittings = screwFittings.filter(
        (screwFitting) => screwFitting.additionalAttributes.noI18n.angle === 0
      );
      if (straightFittings.length > 0) {
        [hoseConfiguration[hoseEnd].screwFitting] = straightFittings;
      } else {
        [hoseConfiguration[hoseEnd].screwFitting] = screwFittings;
      }
      if (!straightFittings.length > 0
        || !(hoseConfiguration[hoseEnd].coupling.additionalAttributes.noI18n.angle === 0)
      ) {
        hoseConfiguration[hoseEnd].assemblyDirection = 'down';
      } else {
        delete hoseConfiguration[hoseEnd].assemblyDirection;
      }
    } else {
      apiService.post('/system/frontend-error', {
        errMsg: 'Keine passende Verschraubung',
        errInfo: { hoseConfiguration, hoseEnd, method: 'hoseConfigurator addFittingToHoseEndIfNecessary' },
      });
      const errMsg = `addFittingToHoseEndIfNecessary | Keine passende Verschraubung | hoseEnd: ${hoseEnd}`;
      console.error(errMsg);
      console.error(hoseConfiguration);
    }
  }
}

function addFittingsToConfigIfNecessary(hoseConfiguration, products, intl, locale, dataStorage) {
  if (hoseConfiguration.hose
    && ['wf', 'tn1', 'hif'].includes(hoseConfiguration.hose.hose.additionalAttributes.i18nId.hoseType)
  ) {
    addFittingToHoseEndIfNecessary('endA', hoseConfiguration, products, intl, locale, dataStorage);
    addFittingToHoseEndIfNecessary('endB', hoseConfiguration, products, intl, locale, dataStorage);
  }
}

function hoseClipNeededOnHoseEnd(hoseEnd, hoseConfiguration) {
  return hoseConfiguration[hoseEnd]
    && hoseConfiguration[hoseEnd].coupling
    && hoseConfiguration[hoseEnd].coupling.additionalAttributes
    && hoseConfiguration[hoseEnd].coupling.additionalAttributes.i18nId.connection === 'hoseNozzle'
    && hoseConfiguration.hose && hoseConfiguration.hose.hose
    && hoseConfiguration.hose.hose.additionalAttributes;
}

function setHoseClipOnHoseEnd(
  hoseEnd, hoseConfiguration, products, intl, locale, dataStorage
) {
  if (hoseClipNeededOnHoseEnd(hoseEnd, hoseConfiguration)) {
    const hoseClips = getFilteredProducts(
      products,
      'hoseClip',
      hoseConfiguration,
      hoseConfiguration[hoseEnd],
      intl,
      locale,
      dataStorage
    );
    if (hoseClips.length > 0) {
      [hoseConfiguration[hoseEnd].hoseClip] = hoseClips;
    } else {
      apiService.post('/system/frontend-error', {
        errMsg: 'Keine passende Schlauchschelle',
        errInfo: { hoseConfiguration, hoseEnd, method: 'hoseConfigurator setHoseClipOnHoseEnd' },
      });
      const errMsg = `setHoseClipOnHoseEnd | Keine passende Schlauchschelle | hoseEnd: ${hoseEnd}`;
      console.error(errMsg);
      console.error(hoseConfiguration);
    }
  } else if (hoseConfiguration[hoseEnd]) {
    hoseConfiguration[hoseEnd].hoseClip = { withoutHosePart: true };
  }
}

function setHoseClips(hoseConfiguration, products, intl, locale, dataStorage) {
  setHoseClipOnHoseEnd('endA', hoseConfiguration, products, intl, locale, dataStorage);
  setHoseClipOnHoseEnd('endB', hoseConfiguration, products, intl, locale, dataStorage);
}

function socketForHoseNeededOnHoseEnd(hoseEnd, hoseConfiguration) {
  return hoseConfiguration[hoseEnd] && hoseConfiguration[hoseEnd].screwFitting
    && hoseConfiguration[hoseEnd].screwFitting.additionalAttributes;
}

function socketForInsulationNeededOnHoseEnd(hoseEnd, hoseConfiguration) {
  return hoseConfiguration.hose && hoseConfiguration.hose.hoseInsulation
    && hoseConfiguration.hose.hoseInsulation.additionalAttributes
    && hoseConfiguration[hoseEnd] && hoseConfiguration[hoseEnd].screwFitting
    && hoseConfiguration[hoseEnd].screwFitting.additionalAttributes;
}

function setHoseSocketsOnHoseEnd(
  hoseEnd, hoseConfiguration, products, intl, locale, dataStorage
) {
  if (socketForHoseNeededOnHoseEnd(hoseEnd, hoseConfiguration)) {
    const hoseSockets = getFilteredProducts(
      products,
      'socketForHose',
      hoseConfiguration,
      hoseConfiguration[hoseEnd],
      intl,
      locale,
      dataStorage
    );
    if (hoseSockets.length > 0) {
      [hoseConfiguration[hoseEnd].socketForHose] = hoseSockets;
    } else {
      apiService.post('/system/frontend-error', {
        errMsg: 'Keine passende Fassung für Schlauch',
        errInfo: { hoseConfiguration, hoseEnd, method: 'hoseConfigurator setHoseSocketsOnHoseEnd' },
      });
      const errMsg = `setHoseSocketsOnHoseEnd | Keine passende Fassung für Schlauch | hoseEnd: ${hoseEnd}`;
      console.error(errMsg);
      console.error(hoseConfiguration);
    }
  } else if (hoseConfiguration[hoseEnd]) {
    hoseConfiguration[hoseEnd].socketForHose = { withoutHosePart: true };
  }

  if (socketForInsulationNeededOnHoseEnd(hoseEnd, hoseConfiguration)) {
    const hoseSockets = getFilteredProducts(
      products,
      'socketForHoseInsulation',
      hoseConfiguration,
      hoseConfiguration[hoseEnd],
      intl,
      locale,
      dataStorage
    );
    if (hoseSockets.length > 0) {
      [hoseConfiguration[hoseEnd].socketForHoseInsulation] = hoseSockets;
    } else {
      apiService.post('/system/frontend-error', {
        errMsg: 'Keine passende Fassung für Isolierung',
        errInfo: { hoseConfiguration, hoseEnd, method: 'hoseConfigurator setHoseSocketsOnHoseEnd' },
      });
      const errMsg = `setHoseSocketsOnHoseEnd | Keine passende Fassung für Isolierung | hoseEnd: ${hoseEnd}`;
      console.error(errMsg);
      console.error(hoseConfiguration);
    }
  } else if (hoseConfiguration[hoseEnd]) {
    hoseConfiguration[hoseEnd].socketForHoseInsulation = { withoutHosePart: true };
  }
}

function setHoseSockets(hoseConfiguration, products, intl, locale, dataStorage) {
  setHoseSocketsOnHoseEnd('endA', hoseConfiguration, products, intl, locale, dataStorage);
  setHoseSocketsOnHoseEnd('endB', hoseConfiguration, products, intl, locale, dataStorage);
}

function setFittingsAndHoseClipsAndSocketsIfNecessary(
  hoseConfiguration, products, intl, locale, dataStorage
) {
  addFittingsToConfigIfNecessary(hoseConfiguration, products, intl, locale, dataStorage);
  setHoseClips(hoseConfiguration, products, intl, locale, dataStorage);
  setHoseSockets(hoseConfiguration, products, intl, locale, dataStorage);
}

export default {
  setDependencies,
  getHoseOrderQuantityInfo,
  getConfigurationType,
  getUnitOfQuantityI18nId,
  getHoseTypes,
  showAssemblyInstructions,
  showMirrorHoseOption,
  hosePartIsDefined,
  getAssemblyCosts,
  getPositionPriceInfo,
  getTotalWeight,
  getHosePartId,
  getFilterAttributes,
  getPartialUndefinedImagePath,
  getPartialImagePath,
  getPositionImgArray,
  getCustomProductTitle,
  getCustomProductPath,
  getHoseConfiguratorProducts,
  getFilteredProducts,
  getDefaultProductCategorySortOption,
  getProductCategorySortOptions,
  getFlatHoseConfigurationObject,
  isAvailableHoseConfiguration,
  getFlatConfiguration,
  getDeepConfigurationObject,
  getAddToCartProductObject,
  addProductOrderQuantities,
  hoseClipNeededOnHoseEnd,
  setFittingsAndHoseClipsAndSocketsIfNecessary,
};
