import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProtectedRoute from './ProtectedRoute';
import CustomRedirect from './CustomRedirect';

export default function Routes(props) {
  function getLanguageVersionPath() {
    const i18nConfig = props.services.configService.getGeneralConfig().i18n;
    const slugs = [];
    for (let i = 0; i < i18nConfig.languages.length; i++) {
      if (i18nConfig.languages[i].code !== i18nConfig.defaultLocale) {
        slugs.push(`/${i18nConfig.languages[i].code}`);
      }
    }
    slugs.push('/');
    return slugs;
  }
  function validatePermission(redirectIf) {
    if (
      (redirectIf === 'not-logged-in' && props.dataStorage.user.firstname)
      || (redirectIf === 'logged-in' && !props.dataStorage.user.firstname)
      || (redirectIf === 'admin-not-logged-in' && props.dataStorage.adminUser.firstname)
      || (redirectIf === 'admin-logged-in' && !props.dataStorage.adminUser.firstname)
      || (redirectIf === 'not-installed' && props.services.configService.getHomepage(props.locale))
      || (redirectIf === 'installed' && !props.services.configService.getHomepage(props.locale))
    ) {
      return true;
    }
    return false;
  }
  const redirectRoutes = props.dataStorage.redirects.map((redirect) => (
    <Route
      path={redirect.path}
      exact
      render={() => <CustomRedirect href={redirect.target} />}
      key={redirect.path}
    />
  ));
  const notFoundRoutes = getLanguageVersionPath().map((path) => (
    <Route
      path={path}
      render={() => <CustomRedirect href={path} />}
      key={path}
    />
  ));
  const webpageRoutes = props.services.configService.getPages().map((page) => {
    let component;
    if (page.redirectIf) {
      component = (
        <ProtectedRoute
          component={page.component}
          grantAccess={validatePermission(page.redirectIf)}
          redirectTo={page.redirectTo}
          services={props.services}
          locale={props.locale}
          setLocale={props.setLocale}
          dataStorage={props.dataStorage}
          setDataStorage={props.setDataStorage}
        />
      );
    } else {
      component = (
        <page.component
          services={props.services}
          locale={props.locale}
          setLocale={props.setLocale}
          dataStorage={props.dataStorage}
          setDataStorage={props.setDataStorage}
        />
      );
    }
    if (page.isNotExactPath) {
      return (
        <Route
          path={page.path}
          render={() => component}
          key={page.id}
        />
      );
    }
    return (
      <Route
        path={page.path}
        exact
        render={() => component}
        key={page.id}
      />
    );
  });
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      {redirectRoutes.concat(webpageRoutes).concat(notFoundRoutes)}
    </Switch>
  );
}

Routes.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
