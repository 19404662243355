import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import Dropdown from '../Dropdown/Dropdown';
import 'rc-slider/assets/index.css';
import './List.css';

export default function RangeFilter(props) {
  const { createSliderWithTooltip } = Slider;
  const Range = createSliderWithTooltip(Slider.Range);
  const hasDifferentValues = () => {
    if (props.values.length < 2) {
      return false;
    }
    for (let i = 1; i < props.values.length; i++) {
      if (props.hasFromToValues) {
        if (props.values[0].to !== props.values[i].to
          || props.values[0].from !== props.values[i].from) {
          return true;
        }
      } else if (props.values[0] !== props.values[i]) {
        return true;
      }
    }
    return false;
  };
  const getRangeFilterMin = () => {
    let values = props.values.concat(props.filteredOutValues);
    if (props.hasFromToValues) {
      values = values.map((value) => value.from);
    }
    return Math.floor(Math.min(...values));
  };
  const getRangeFilterMax = () => {
    let values = props.values.concat(props.filteredOutValues);
    if (props.hasFromToValues) {
      values = values.map((value) => value.to);
    }
    return Math.ceil(Math.max(...values));
  };
  const getDefaultValue = () => {
    if (props.selectedRange) {
      return [props.selectedRange.min, props.selectedRange.max];
    }
    let lower;
    let lowerValues = props.values;
    let upper;
    let upperValues = props.values;
    if (props.hasFromToValues) {
      lowerValues = lowerValues.map((value) => value.from);
      lower = Math.ceil(Math.max(...lowerValues));
      upperValues = upperValues.map((value) => value.to);
      upper = Math.floor(Math.min(...upperValues));
    } else {
      lower = Math.floor(Math.min(...lowerValues));
      upper = Math.ceil(Math.max(...upperValues));
    }
    return [lower, upper];
  };
  const minValueRange = getRangeFilterMin();
  const maxValueRange = getRangeFilterMax();
  const defaultValue = getDefaultValue();
  const handleChange = (sliderValues) => {
    if (
      (!props.hasFromToValues && minValueRange === sliderValues[0]
        && maxValueRange === sliderValues[1])
      || (props.hasFromToValues && defaultValue[0] === sliderValues[0]
        && defaultValue[1] === sliderValues[1])
    ) {
      props.handlers.deleteHandler({
        attributeId: props.attributeId,
      });
    } else {
      props.handlers.changeHandler({
        attributeId: props.attributeId,
        changedValue: { min: sliderValues[0], max: sliderValues[1] },
      });
    }
  };
  return (
    <Dropdown
      dropdownId={props.dropdownId}
      type={(hasDifferentValues()) ? 'click' : 'disabled'}
      mainClassNames="dropdown dropdown--default dropdown-filter"
      heading={props.heading}
      headingLayout="filter"
      headingSuffixIcon="angle-down"
      dropdownContentClasses="dropdown-filter-content"
      isExpanded={props.isExpanded}
      expandedFilterChangeHandler={props.handlers.expandedFilterChangeHandler}
      attributeId={props.attributeId}
      closeOnHistoryApiChange={false}
    >
      <div className="sliderArea">
        <Range
          min={minValueRange}
          max={maxValueRange}
          defaultValue={defaultValue}
          tipFormatter={(value) => ((props.currency)
            ? value.toLocaleString(props.locale, { style: 'currency', currency: props.currency })
            : `${value}${(props.measuringUnit) ? ` ${props.measuringUnit}` : ''}`)}
          tipProps={{
            placement: 'top',
            visible: true,
          }}
          onAfterChange={handleChange}
        />
      </div>
    </Dropdown>
  );
}

RangeFilter.propTypes = {
  dropdownId: PropTypes.string,
  attributeId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedRange: PropTypes.objectOf(PropTypes.any),
  filteredOutValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  handlers: PropTypes.objectOf(PropTypes.func).isRequired,
  measuringUnit: PropTypes.string,
  currency: PropTypes.string,
  locale: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  hasFromToValues: PropTypes.bool,
};

RangeFilter.defaultProps = {
  dropdownId: undefined,
  selectedRange: undefined,
  measuringUnit: undefined,
  currency: undefined,
  locale: undefined,
  hasFromToValues: false,
};
