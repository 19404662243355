import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import FormFileInput from '../Form/FormFileInput';
import Button from '../Button/Button';
import RequestProgress from '../Progress/RequestProgress';

export default function CartImport(props) {
  const intl = useIntl();
  const [file, setFile] = useState(undefined);
  const [fileIsValid, setFileIsValid] = useState(false);
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');
  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
    setFileIsValid(e.target.files[0]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      setSubmitButtonIsClicked(true);
      setViewState('loading');
      props.services.apiService.postFormData('/sales/cart/products/import', {
        file,
      }).then((res) => {
        if (res.data.positions) {
          const importedPosCount = props.services.salesService.importCart(
            res.data.positions, props.dataStorage, props.services.dataStorageService
          );
          setViewState('result');
          if (importedPosCount > 0) {
            setResultMessageType('success');
            setResultMessageText(intl.formatMessage({ id: 'cartImportSuccessMsg' }, { count: importedPosCount }));
          } else {
            setResultMessageType('error');
            setResultMessageText(intl.formatMessage({ id: 'cartImportFailedMsg' }));
          }
        } else {
          setViewState('result');
          setResultMessageType('error');
          setResultMessageText(intl.formatMessage({ id: 'cartImportFailedMsg' }));
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className="form" onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormFileInput
              id="file"
              selectedFile={file}
              callback={fileChangeHandler}
              isValid={fileIsValid}
              errorMessageId="mustEnterValidFilename"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
          </div>
          <Button classNames="button" type="submit"><FormattedMessage id="cartImport" /></Button>
        </form>
      )}
    </Fragment>
  );
}

CartImport.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
