import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Layout/Icon';
import CustomLink from '../Link/CustomLink';

export default function DropdownHeading(props) {
  const headingContent = (
    <Fragment>
      {props.headingPrefixIcon && <Icon name={props.headingPrefixIcon} />}
      {props.headingText && (<span className="dropdown__heading-text">{props.headingText}</span>)}
      {props.headingSuffixIcon && <Icon name={props.headingSuffixIcon} />}
    </Fragment>
  );
  let classNames = 'dropdown__heading';
  if (props.layout === 'filter') {
    classNames = `${classNames} dropdown__heading--filter`;
  }
  if (props.type === 'disabled') {
    return (
      <div className="dropdown__heading dropdown__heading--disabled">
        {headingContent}
      </div>
    );
  } else if (props.type === 'click') {
    return (
      <button
        type="button"
        className="dropdown-heading-button-reset dropdown__heading dropdown__heading--filter"
        onClick={() => props.dropdownIsExpandedToggle()}
      >
        {headingContent}
      </button>
    );
  } else if (props.headingLink) {
    return (
      <div className={classNames}>
        <CustomLink href={props.headingLink}>{headingContent}</CustomLink>
      </div>
    );
  }
  return (
    <div className={classNames}>
      {headingContent}
    </div>
  );
}

DropdownHeading.propTypes = {
  type: PropTypes.string.isRequired,
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  layout: PropTypes.string,
  headingLink: PropTypes.string,
  headingPrefixIcon: PropTypes.string,
  headingSuffixIcon: PropTypes.string,
  dropdownIsExpandedToggle: PropTypes.func,
};

DropdownHeading.defaultProps = {
  headingText: undefined,
  layout: undefined,
  headingLink: undefined,
  headingPrefixIcon: undefined,
  headingSuffixIcon: undefined,
  dropdownIsExpandedToggle: undefined,
};
