import React from 'react';
import PropTypes from 'prop-types';
import './Dropdown.css';

export default function DropdownWrapper(props) {
  const dropDownClasses = 'dropdown dropdown--default';
  function getDropDownClasses() {
    if (props.type === 'disabled') {
      return `${props.classNames || dropDownClasses} dropdown--disabled`;
    }
    return props.classNames || dropDownClasses;
  }
  if (props.type === 'hover' && props.isListItem) {
    return (
      <li
        className={getDropDownClasses()}
        onMouseEnter={() => props.dropdownIsExpandedHandler(true)}
        onMouseLeave={() => props.dropdownIsExpandedHandler(false)}
      >
        {props.children}
      </li>
    );
  } else if (props.type === 'hover' && !props.isListItem) {
    return (
      <div
        className={getDropDownClasses()}
        onMouseEnter={() => props.dropdownIsExpandedHandler(true)}
        onMouseLeave={() => props.dropdownIsExpandedHandler(false)}
      >
        {props.children}
      </div>
    );
  }
  if (props.type === 'click' && props.isListItem) {
    return (
      <li id={props.id} className={getDropDownClasses()}>
        {props.children}
      </li>
    );
  }
  return (
    <div id={props.id} className={getDropDownClasses()}>
      {props.children}
    </div>
  );
}

DropdownWrapper.propTypes = {
  isListItem: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  dropdownIsExpandedHandler: PropTypes.func.isRequired,
  classNames: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
  id: PropTypes.string,
};

DropdownWrapper.defaultProps = {
  classNames: false,
  id: undefined,
};
