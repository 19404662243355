import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import Message from '../Message/Message';

export default function RequestProgress(props) {
  const getInnerHtml = () => {
    if (props.viewState === 'loading') {
      if (props.centerMessage) {
        return (<div className="center"><Spinner label={props.processingLabel} /></div>);
      }
      return (<Spinner label={props.processingLabel} />);
    } else if (props.centerMessage) {
      return (<div className="center"><Message type={props.messageType}>{props.messageText}</Message></div>);
    }
    return (<Message type={props.messageType}>{props.messageText}</Message>);
  };
  return (
    (props.viewState === 'loading' || props.viewState === 'result' || props.messageType === 'error') && getInnerHtml()
  );
}

RequestProgress.propTypes = {
  viewState: PropTypes.string.isRequired,
  processingLabel: PropTypes.string,
  messageType: PropTypes.string,
  messageText: PropTypes.oneOfType([
    PropTypes.any,
  ]),
  centerMessage: PropTypes.bool,
};

RequestProgress.defaultProps = {
  processingLabel: undefined,
  messageType: undefined,
  messageText: undefined,
  centerMessage: true,
};
