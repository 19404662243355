import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'js-cookie';

let configService;
let apiCode;
let intLevel;
let apiBaseUrl;

function setDependencies(dependencies) {
  ({ configService } = dependencies);
  ({ apiCode, apiBaseUrl } = configService.getGeneralConfig());
  axios.interceptors.request.use((config) => {
    config.headers.apiCode = apiCode;
    return config;
  });
  return this;
}

function setInitLevel() {
  ({ intLevel } = configService.getGeneralConfig());
  return this;
}

function getIid() {
  return (configService.visitorStatsIsEnabled()) ? localStorage.int : undefined;
}

function getAqus() {
  return (configService.visitorStatsIsEnabled())
    ? { initial: localStorage.aqu, current: sessionStorage.aqu } : {};
}

function getUrl(routePath) {
  return `${apiBaseUrl}${routePath}`;
}

function axiosPost(routePath, params, config) {
  return axios.post(getUrl(routePath), params, config).catch((err) => {
    const failedUrl = getUrl(routePath);
    console.error(`ERROR | axiosPost | URL: ${failedUrl}`);
    console.error(err.message);
    if (err.response) {
      console.error(`ERROR | axiosPost | Response Status: ${err.response.status}`);
      console.error(err.response.data);
      console.error(err.response.headers);
    }
    if (err.request) {
      console.error('ERROR | axiosPost | Request');
      console.error(err.request);
    }
    axios.post(getUrl('/system/frontend-error'), {
      errMsg: 'Axios Post failed',
      errInfo: {
        errMsg: err.message,
        url: failedUrl,
        params,
        cookies: {
          cookieConsent: Cookies.get('cookieConsent'),
          webApp: Cookies.get('webApp'),
        },
      },
    }, config).catch((error) => {
      console.error(`ERROR | axiosPost | Send Error Mail failed URL: failedUrl | Error message: ${error.message}`);
    });
  });
}

function post(routePath, params, config) {
  params.iid = getIid();
  params.aqus = getAqus();
  return axiosPost(routePath, params, config);
}

function get(routePath, responseType, config) {
  const basicSettings = { method: 'get', url: getUrl(routePath) };
  if (responseType) {
    basicSettings.responseType = 'blob';
  }
  return axios(
    basicSettings,
    config
  ).catch((err) => console.error(err.message));
}

function postFormData(routePath, params) {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  formData.append('iid', getIid());
  formData.append('aqus', getAqus());
  return axiosPost(routePath, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function int(params) {
  if (intLevel !== 'off') {
    params.param3 = queryString.parse(window.location.search);
    return post('/int/add', params);
  }
  return false;
}

function pavi(param2) {
  int({
    param1: 'pavi',
    param2,
  });
}

function ctin(interaction, cart, product, customProductType, qty, user) {
  int({
    param1: 'ctin',
    param2: product.id,
    param4: {
      cart,
      interaction,
      product,
      customProductType,
      qty,
      userId: (user) ? user.id : undefined,
    },
  });
}

export default {
  setDependencies,
  setInitLevel,
  post,
  postFormData,
  get,
  int,
  pavi,
  ctin,
};
