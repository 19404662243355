import React from 'react';
import PropTypes from 'prop-types';
import ProductContent from '../Product';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';

export default function Product(props) {
  return (
    <div className="product-page content-container">
      <Breadcrumbs
        data={props.services.productService.getProductBreadcrumbsData(
          props.product, props.dataStorage.productCategories, props.locale
        )}
        locale={props.locale}
        services={props.services}
      />
      <ProductContent
        locale={props.locale}
        product={props.product}
        selectHandler={props.services.dataStorageService.addToCart}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    </div>
  );
}

Product.propTypes = {
  locale: PropTypes.string.isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
