import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import Message from '../Message/Message';
import SearchResultTabs from './SearchResultTabs';

export default function SearchResultsPage(props) {
  console.log(props.dataStorage.searchTerm);
  const intl = useIntl();
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.search) {
      props.services.dataStorageService.setSearchTerm(query.search, props.dataStorage);
    }
  }, []);
  function getSearchResults() {
    return props.services.searchService.getSearchResults(
      props.dataStorage.products,
      props.dataStorage.productCategories,
      props.dataStorage.searchTerm,
      props.locale,
      intl
    );
  }
  const searchResults = getSearchResults();
  return (
    <Fragment>
      <h1 className="search-dropdown-text"><span><FormattedMessage id="searchFor" />: &quot;{props.dataStorage.searchTerm}&quot;</span></h1>
      {(props.dataStorage.searchTerm.length < 2) && (
        <div className="search-dropdown-text">
          <Message type="warning">
            <FormattedMessage id="searchTermTooShort" />
          </Message>
        </div>
      )}
      {(props.dataStorage.searchTerm.length > 1) && (
        <SearchResultTabs
          searchResults={searchResults}
          activeTabId={queryString.parse(window.location.search).tab}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
        />
      )}
    </Fragment>
  );
}

SearchResultsPage.propTypes = {
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
