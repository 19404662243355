import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import CartPosition from './CartPosition';
import CartSummaryRow from './CartSummaryRow';
import CartSummaryDiscountRow from './CartSummaryDiscountRow';
import CustomLink from '../Link/CustomLink';
import LinkButton from '../Button/LinkButton';
import Button from '../Button/Button';
import Spinner from '../Progress/Spinner';
import Message from '../Message/Message';
import './Cart.css';
import '../Product/Product.css';

export default function Cart(props) {
  const intl = useIntl();
  const [productsWithNoQty, setProductsWithNoQty] = useState([]);
  const [offerIsBeingPlaced, setOfferIsBeingPlaced] = useState(false);
  const [offerPlacedResult, setOfferPlacedResult] = useState(undefined);
  const salesConfig = props.services.configService.getGeneralConfig().sales;
  const positions = props.services.salesService.getPositionsWithProducts(props.dataStorage);
  const cartPriceInfo = props.services.salesService.getCartPriceInfo(
    positions, props.additionalOrderCostParams, props.couponRule,
    props.locale, props.dataStorage, intl
  );
  const positionStatusHandler = (productId, hasQty) => {
    const newProductsWithNoQty = [];
    productsWithNoQty.forEach((productWithNoQty) => {
      if (productWithNoQty !== productId) {
        newProductsWithNoQty.push(productWithNoQty);
      }
    });
    if (!hasQty) {
      newProductsWithNoQty.push(productId);
    }
    setProductsWithNoQty(newProductsWithNoQty);
  };
  const positionDeleteHandler = (params) => {
    props.services.dataStorageService.deleteFromCart(params);
    positionStatusHandler(params.product.id, true);
  };
  const placeOfferHandler = () => {
    const readyForShipmentInDays = props.services.salesService.getCartProcurementTime(
      props.dataStorage
    );
    setOfferIsBeingPlaced(true);
    props.services.apiService.post('/order/offer/new', {
      offer: {
        buyer: props.dataStorage.user,
        positions: props.services.salesService.getOrderPositions(
          props.dataStorage, props.locale, intl
        ),
        readyForShipmentMsg: (readyForShipmentInDays === 0)
          ? intl.formatMessage({ id: 'deliverableImmediately' })
          : intl.formatMessage({ id: 'readyForShipmentWithinXDays' }, { days: readyForShipmentInDays }),
        price: {
          itemTotal: cartPriceInfo.itemTotal,
          shoppingCartValue: cartPriceInfo.shoppingCartValue,
        },
      },
      locale: props.locale,
    }).then((res) => {
      setOfferIsBeingPlaced(false);
      setOfferPlacedResult(res.data.resultStatus);
      setTimeout(() => {
        setOfferPlacedResult(undefined);
      }, 2000);
    });
  };
  function getHeading() {
    if (props.useCase !== 'own-page') {
      return (<div className="cart-heading"><strong><FormattedMessage id="cart" /></strong></div>);
    }
    return (<h1><FormattedMessage id="cart" /></h1>);
  }
  function showEnabledCheckoutButton() {
    return props.useCase !== 'checkout' && productsWithNoQty.length === 0
      && cartPriceInfo.minOrderValueIsReached;
  }
  function showDisabledCheckoutButton() {
    return props.useCase !== 'checkout' && (productsWithNoQty.length > 0
      || !cartPriceInfo.minOrderValueIsReached);
  }
  function showEnabledOfferButton() {
    return props.useCase !== 'checkout' && productsWithNoQty.length === 0
      && cartPriceInfo.minOfferValueIsReached;
  }
  function getOfferButton() {
    return (
      <div>
        {!offerIsBeingPlaced && (
          <Button
            type="button"
            disabled={!showEnabledOfferButton()}
            onClick={placeOfferHandler}
            classNames={`button button--secondary margin-r10${(props.useCase === 'dropdown') ? ' button--small' : ''}`}
          >{intl.formatMessage({ id: 'sendOfferToYourEmail' })}
          </Button>
        )}
        {offerIsBeingPlaced && (
          <div className="center"><Spinner label="Loading" /></div>
        )}
        {offerPlacedResult === 'success' && (
          <div className="add-product-message add-product-message-fade-out">
            <Message type="success">
              <FormattedMessage id="offerPlacedSuccess" />
            </Message>
          </div>
        )}
        {offerPlacedResult === 'error' && (
          <div className="add-product-message add-product-message-fade-out">
            <Message type="error">
              <FormattedMessage id="offerPlacedFailure" />
            </Message>
          </div>
        )}
      </div>
    );
  }
  function getPositions() {
    if (positions === undefined) {
      return undefined;
    }
    return positions.map((position, index) => (
      <CartPosition
        index={index}
        product={position.product}
        customProductType={position.customProductType}
        qty={position.qty}
        positionStatusHandler={positionStatusHandler}
        positionDeleteHandler={positionDeleteHandler}
        hasProductLinks={props.hasProductLinks}
        locale={props.locale}
        services={props.services}
        dataStorage={props.dataStorage}
        key={`${position.product.id}`}
      />
    ));
  }
  return (
    <div className={`cart-container cart-${props.useCase}`}>
      {getHeading()}
      {(!props.dataStorage.cart.positions || props.dataStorage.cart.positions.length === 0) && (
        <Fragment>
          <p><FormattedMessage id="cartEmpty" /></p>
          {salesConfig.cartExportImport && (
            <div className="cart-import-export-link">
              <CustomLink
                href={props.services.configService.getPageByTranslationCodeAndLocale('cart-import-export', props.locale).path}
              >{intl.formatMessage({ id: 'cartImport' })}
              </CustomLink>
            </div>
          )}
        </Fragment>
      )}
      {(props.dataStorage.cart.positions && props.dataStorage.cart.positions.length > 0) && (
        <Fragment>
          <div className="grid-summary-and-buttons">
            {props.additionalOrderCostParams && (
              <div className="grid-cart-summary">
                <CartSummaryRow labelI18nId="goodsValue" value={cartPriceInfo.formattedItemTotal} />
                {cartPriceInfo.discountAmount && (
                  <CartSummaryDiscountRow cartPriceInfo={cartPriceInfo} />
                )}
                <CartSummaryRow labelI18nId="shipping" value={cartPriceInfo.formattedShippingCosts} />
                {cartPriceInfo.paymentCosts > 0 && (
                  <CartSummaryRow
                    labelI18nId="fee"
                    labelAddition={props.additionalOrderCostParams.paymentMethod.name[props.locale]}
                    value={cartPriceInfo.formattedPaymentCosts}
                  />
                )}
                <CartSummaryRow labelI18nId="netSum" value={cartPriceInfo.formattedSubtotal} valueClassNames="cart-summary-price--netsum" />
                <CartSummaryRow
                  labelI18nId="vat"
                  labelAddition={`(${cartPriceInfo.formattedVatRate})`}
                  value={cartPriceInfo.formattedVat}
                />
                <CartSummaryRow labelI18nId="grossSum" value={cartPriceInfo.formattedTotal} valueClassNames="cart-summary-price--grosssum strong" />
              </div>
            )}
            {!props.additionalOrderCostParams && (
              <div>
                <div className="grid-cart-summary">
                  {!cartPriceInfo.discountAmount && (
                    <CartSummaryRow labelI18nId="goodsValue" labelClassNames="cart-net-sum" value={cartPriceInfo.formattedItemTotal} valueClassNames="strong" />
                  )}
                  {cartPriceInfo.discountAmount && (
                    <Fragment>
                      <CartSummaryRow labelI18nId="goodsValue" value={cartPriceInfo.formattedItemTotal} />
                      <CartSummaryDiscountRow cartPriceInfo={cartPriceInfo} />
                      <CartSummaryRow labelI18nId="goodsValueDiscounted" labelClassNames="strong" value={cartPriceInfo.formattedShoppingCartValue} valueClassNames="strong" />
                    </Fragment>
                  )}
                </div>
                <span className="cartVatShippingNote"><FormattedMessage id="plusVatAndShipping" /></span>
              </div>
            )}
            <div className="cart-buttons">
              {props.useCase === 'dropdown' && (
                <LinkButton
                  href={props.services.configService.getPageByTranslationCodeAndLocale('cart', props.locale).path}
                  classNames="linkbutton linkbutton--secondary linkbutton--small margin-r10"
                >{intl.formatMessage({ id: 'cartEdit' })}
                </LinkButton>
              )}
              {(!['dropdown', 'checkout'].includes(props.useCase) && salesConfig.enableOffer && props.dataStorage.user.firstname) && getOfferButton()}
              {showEnabledCheckoutButton() && (
                <LinkButton
                  href={props.services.configService.getPageByTranslationCodeAndLocale('checkout', props.locale).path}
                  classNames={`linkbutton${(props.useCase === 'dropdown') ? ' linkbutton--small button--right' : ''}`}
                >{intl.formatMessage({ id: 'toTheCheckout' })}
                </LinkButton>
              )}
              {showDisabledCheckoutButton() && (
                <LinkButton
                  href="#"
                  classNames={`linkbutton linkbutton--disabled${(props.useCase === 'dropdown') ? ' linkbutton--small button--right' : ''}`}
                >{intl.formatMessage({ id: 'toTheCheckout' })}
                </LinkButton>
              )}
            </div>
            {(props.useCase === 'dropdown' && salesConfig.enableOffer && props.dataStorage.user.firstname) && (
              <div className="cart-buttons">
                {getOfferButton()}
              </div>
            )}
          </div>
          {productsWithNoQty.length > 0 && (
            <Message type="error">
              <FormattedMessage id="invalidOrderQuantityEmpty" />
            </Message>
          )}
          {!cartPriceInfo.minOrderValueIsReached && (
            <Message type="warning">
              <FormattedMessage id="minOrderValueNotReachedMsg" values={{ minimalOrderValue: props.services.salesService.getFormattedMinimalOrderValue(props.locale) }} />
            </Message>
          )}
          <span className="cart-position-count"><FormattedMessage id="positions" />: {props.dataStorage.cart.positions.length}</span>
          {getPositions()}
          {salesConfig.cartExportImport && (
            <div className="cart-import-export-link">
              <CustomLink
                href={props.services.configService.getPageByTranslationCodeAndLocale('cart-import-export', props.locale).path}
              >{intl.formatMessage({ id: 'cartImportExport' })}
              </CustomLink>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

Cart.propTypes = {
  useCase: PropTypes.string,
  hasProductLinks: PropTypes.bool,
  additionalOrderCostParams: PropTypes.objectOf(PropTypes.any),
  couponRule: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

Cart.defaultProps = {
  useCase: 'own-page',
  hasProductLinks: true,
  additionalOrderCostParams: undefined,
  couponRule: undefined,
};
