import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import LoginForm from './LoginForm';
import LinkButton from '../Button/LinkButton';
import Button from '../Button/Button';

export default function LoginRegisterOrOther(props) {
  const intl = useIntl();
  return (
    <div className="content-container grid-50-50 ">
      <section>
        <div className="form-and-heading">
          <div className="form-logo-container center"><img src="/logo192.png" alt={`${props.services.configService.getGeneralConfig().brand} logo`} className="form-logo" /></div>
          {!props.otherActionTranslationId && (<h1 className="center"><FormattedMessage id="loginPage" /></h1>)}
          {props.otherActionTranslationId && (<h2 className="center"><FormattedMessage id="loginPage" /></h2>)}
          <LoginForm
            classNames="form"
            services={props.services}
            locale={props.locale}
            showPwdForgottenAndStayLoggedIn={true}
            pwdForgottenLink={props.services.configService.getPageByTranslationCodeAndLocale('password-forgotten', props.locale).path}
            closeHandler={props.loginSuccessHandler}
            closeHandlerParams={props.loginSuccessHandlerParams}
            dataStorage={props.dataStorage}
            setDataStorage={props.setDataStorage}
          />
        </div>
      </section>
      <section>
        <h2 className="center"><FormattedMessage id="noAccountYet" /></h2>
        <p className="margin-t0-b30"><FormattedMessage id="noAccountYetText" /></p>
        {props.otherActionTranslationId && (
          <Button classNames="button button--secondary button--right" type="submit" onClick={() => props.otherActionHandler(props.otherActionHandlerParams)}>{intl.formatMessage({ id: props.otherActionTranslationId })}</Button>
        )}
        <LinkButton
          href={props.services.configService.getPageByTranslationCodeAndLocale('registration', props.locale).path}
        >{intl.formatMessage({ id: 'register' })}
        </LinkButton>
      </section>
    </div>
  );
}

LoginRegisterOrOther.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
  loginSuccessHandler: PropTypes.func,
  loginSuccessHandlerParams: PropTypes.objectOf(PropTypes.any),
  otherActionTranslationId: PropTypes.string,
  otherActionHandler: PropTypes.func,
  otherActionHandlerParams: PropTypes.objectOf(PropTypes.any),
};

LoginRegisterOrOther.defaultProps = {
  loginSuccessHandler: undefined,
  loginSuccessHandlerParams: undefined,
  otherActionTranslationId: undefined,
  otherActionHandler: undefined,
  otherActionHandlerParams: undefined,
};
