import React from 'react';
import PropTypes from 'prop-types';
import CartSummaryRow from './CartSummaryRow';

export default function CartSummaryDiscountRow(props) {
  if (props.cartPriceInfo.couponCode) {
    return (
      <CartSummaryRow
        labelI18nId="voucher"
        labelAddition={`(${props.cartPriceInfo.couponCode})`}
        value={props.cartPriceInfo.formattedDiscountAmount}
      />
    );
  }
  return (
    <CartSummaryRow
      labelI18nId="discount"
      labelAddition={(props.cartPriceInfo.discountPercentage) ? `(${props.cartPriceInfo.formattedDiscountPercentage})` : undefined}
      value={props.cartPriceInfo.formattedDiscountAmount}
    />
  );
}

CartSummaryDiscountRow.propTypes = {
  cartPriceInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};
