let apiService;
let setDataStorage;
let uniqueIdentifier;
let cartTTL;

function setDependencies(dependencies) {
  ({ apiService, setDataStorage, uniqueIdentifier } = dependencies);
  return this;
}

function customProductIsAvailable(customConfigurationSting, products) {
  const customConfigTokens = customConfigurationSting.split('id":"');
  if (customConfigTokens.length > 1) {
    customConfigTokens.shift();
    const productIds = [];
    customConfigTokens.forEach((token) => {
      const [productId] = token.split('"');
      if (!productIds.includes(productId)) {
        productIds.push(productId);
      }
    });
    for (let i = 0; i < productIds.length; i++) {
      let match = false;
      for (let k = 0; k < products.length && !match; k++) {
        if (productIds[i] === products[k].id) {
          match = true;
        }
      }
      if (!match) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function initCart(products, cartTTLParam) {
  const savedCart = (localStorage.cart) ? JSON.parse(localStorage.cart) : undefined;
  if (localStorage.cart) {
    savedCart.dateUpdated = new Date(parseInt(savedCart.dateUpdated));
  }
  if (savedCart) {
    const cartValidTo = savedCart.dateUpdated;
    cartValidTo.setDate(savedCart.dateUpdated.getDate() + (cartTTLParam || cartTTL));
    if (savedCart.positions && products) {
      const positions = [];
      for (let i = 0; i < savedCart.positions.length; i++) {
        if (savedCart.positions[i].customProductType) {
          if (customProductIsAvailable(
            JSON.stringify(savedCart.positions[i].customConfiguration), products
          )) {
            positions.push(savedCart.positions[i]);
          }
        } else {
          let match = false;
          for (let k = 0; k < products.length && !match; k++) {
            if (savedCart.positions[i].productId === products[k].id) {
              positions.push(savedCart.positions[i]);
              match = true;
            }
          }
        }
      }
      savedCart.positions = positions;
    }
    if (
      savedCart.positions && savedCart.positions.length > 0
      && (cartValidTo) > new Date()
    ) {
      return savedCart;
    }
    delete localStorage.cart;
  }
  return {};
}

function getInitialDataStorage(resData, cartTTLParam) {
  cartTTL = cartTTLParam;
  return {
    initialDataLoaded: true,
    redirects: resData.redirects,
    webpageInputs: {},
    user: resData.user,
    defaultPriceRules: resData.defaultPriceRules,
    groupPriceRules: resData.groupPriceRules,
    orders: resData.orders,
    adminUser: resData.adminUser,
    adminData: resData.adminData,
    productCategories: resData.productCategories,
    products: resData.products,
    cart: initCart(resData.products, cartTTL),
    searchTerm: '',
  };
}

function copyDataStorage(currentDataStorage) {
  return {
    initialDataLoaded: true,
    redirects: currentDataStorage.redirects,
    webpageInputs: currentDataStorage.webpageInputs,
    user: currentDataStorage.user,
    defaultPriceRules: currentDataStorage.defaultPriceRules,
    groupPriceRules: currentDataStorage.groupPriceRules,
    orders: currentDataStorage.orders,
    adminUser: currentDataStorage.adminUser,
    adminData: currentDataStorage.adminData,
    productCategories: currentDataStorage.productCategories,
    products: currentDataStorage.products,
    cart: currentDataStorage.cart,
    searchTerm: currentDataStorage.searchTerm,
  };
}

function setUser(newUser, currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.user = newUser;
  setDataStorage(newDataStorage);
}

function setUserAndRelatedData(newUser, newOrders, newGroupPriceRules, currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.user = newUser;
  newDataStorage.groupPriceRules = newGroupPriceRules;
  newDataStorage.orders = newOrders;
  setDataStorage(newDataStorage);
}

function setAdminUserAndData(newAdminUser, newAdminData, currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.adminUser = newAdminUser;
  newDataStorage.adminData = newAdminData;
  setDataStorage(newDataStorage);
}

function setAdminDataProperty(propertyName, newData, currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.adminData[propertyName] = newData;
  setDataStorage(newDataStorage);
}

function isLoggedIn(user) {
  return user.firstname !== undefined;
}

function logout(currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.user = {};
  newDataStorage.groupPriceRules = [];
  newDataStorage.orders = [];
  newDataStorage.adminUser = {};
  newDataStorage.adminDate = {};
  newDataStorage.cart = initCart(currentDataStorage.products);
  setDataStorage(newDataStorage);
}

async function getWebpageParams(id, currentDataStorage) {
  if (!currentDataStorage.webpageInputs[id]) {
    const result = await apiService.post('/webpage/params', { id });
    if (result.data.success) {
      const newDataStorage = copyDataStorage(currentDataStorage);
      newDataStorage.webpageInputs[id] = result.data.params;
      setDataStorage(newDataStorage);
      return result.data.params;
    }
  }
  return currentDataStorage.webpageInputs[id];
}

function setProducts(newProducts, currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.products = newProducts;
  setDataStorage(newDataStorage);
}

function addOrder(newOrder, currentDataStorage) {
  delete localStorage.cart;
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.orders.push(newOrder);
  newDataStorage.cart = initCart(currentDataStorage.products);
  setDataStorage(newDataStorage);
}

function setSearchTerm(newSearchTerm, currentDataStorage) {
  const newDataStorage = copyDataStorage(currentDataStorage);
  newDataStorage.searchTerm = newSearchTerm;
  setDataStorage(newDataStorage);
}

function saveCart(interaction, positions, newDataStorage) {
  newDataStorage.cart.dateUpdated = new Date();
  setDataStorage(newDataStorage);
  positions.forEach((position) => {
    apiService.ctin(interaction, newDataStorage.cart, position.product, position.customProductType,
      position.orderQuantity, newDataStorage.user);
  });
  newDataStorage.cart.dateUpdated = newDataStorage.cart.dateUpdated.getTime();
  localStorage.cart = JSON.stringify(newDataStorage.cart);
}

function getCartPositionObject(params) {
  if (params.customProductType) {
    return {
      productId: params.product.id,
      customProductType: params.customProductType,
      customConfiguration: params.product.flatConfiguration,
      qty: params.orderQuantity,
    };
  }
  return { productId: params.product.id, qty: params.orderQuantity };
}

function addToCart(params) {
  const newDataStorage = copyDataStorage(params.dataStorage);
  newDataStorage.cart = initCart(newDataStorage.products);
  if (newDataStorage.cart.positions) {
    if (params.positions) {
      params.positions.forEach((newPosition) => {
        let productMatch = false;
        newDataStorage.cart.positions.forEach((position) => {
          if (position.productId === newPosition.product.id) {
            productMatch = true;
            position.qty += newPosition.orderQuantity;
          }
        });
        if (!productMatch) {
          newDataStorage.cart.positions.push(
            getCartPositionObject(newPosition)
          );
        }
      });
    } else {
      let productMatch = false;
      newDataStorage.cart.positions.forEach((position) => {
        if (position.productId === params.product.id) {
          productMatch = true;
          position.qty += params.orderQuantity;
        }
      });
      if (!productMatch) {
        newDataStorage.cart.positions.push(
          getCartPositionObject(params)
        );
      }
    }
  } else {
    newDataStorage.cart.positions = (params.positions)
      ? params.positions.map((position) => getCartPositionObject(position))
      : [getCartPositionObject(params)];
    if (!newDataStorage.cart.id) {
      newDataStorage.cart.id = uniqueIdentifier();
    }
  }
  saveCart('add', params.positions || [{
    product: params.product,
    customProductType: params.customProductType,
    orderQuantity: params.orderQuantity,
  }], newDataStorage);
}

function deleteFromCart(params) {
  const newDataStorage = copyDataStorage(params.dataStorage);
  newDataStorage.cart = initCart(newDataStorage.products, 999);
  const newPositions = [];
  let qty = 0;
  let customProductType;
  newDataStorage.cart.positions.forEach((position) => {
    if (position.productId !== params.product.id) {
      newPositions.push(position);
    } else {
      qty += position.qty;
      customProductType = position.customProductType;
    }
  });
  newDataStorage.cart.positions = (newPositions.length > 0) ? newPositions : undefined;
  saveCart('delete', [{
    product: params.product,
    customProductType,
    orderQuantity: qty,
  }], newDataStorage);
}

function updateCart(product, newQty, dataStorage) {
  const newDataStorage = copyDataStorage(dataStorage);
  newDataStorage.cart = initCart(newDataStorage.products, 999);
  const newPositions = [];
  let qty = 0;
  let customProductType;
  newDataStorage.cart.positions.forEach((position) => {
    if (position.productId === product.id) {
      qty = position.qty;
      position.qty = newQty;
      customProductType = position.customProductType;
    }
    newPositions.push(position);
  });
  newDataStorage.cart.positions = newPositions;
  saveCart('update', [{
    product,
    customProductType,
    orderQuantity: qty,
  }], newDataStorage);
}

export default {
  setDependencies,
  customProductIsAvailable,
  getInitialDataStorage,
  logout,
  isLoggedIn,
  setUser,
  setUserAndRelatedData,
  setAdminUserAndData,
  setAdminDataProperty,
  addOrder,
  getWebpageParams,
  setProducts,
  setSearchTerm,
  addToCart,
  deleteFromCart,
  updateCart,
};
