import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function PositionRow(props) {
  return (
    <div className="grid-hc-summary-product">
      <div className="hc-summary-product-image-container">
        <img
          src={props.services.productService.getImgPath(props.product)}
          alt={props.product.name[props.locale]}
        />
      </div>
      <div className="hc-summary-product-description-container">
        <div className="hc-summary-product-type">{props.services.productService.getAttributeValueTranslation(props.product.productType.i18nId, undefined, props.locale)}</div>
        {props.product.name[props.locale]}
        <div className="hc-summary-product-id"><span>{(props.qty > 1) && (<strong>{props.qty}x</strong>)} <FormattedMessage id="article" /> {props.product.id}</span></div>
      </div>
      <div className="hc-summary-product-price-container">
        {props.services.productService.getPositionPriceInfo(
          props.product, props.qty || 1, props.dataStorage, props.locale
        ).formattedPrice}
      </div>
    </div>
  );
}

PositionRow.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  qty: PropTypes.number,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

PositionRow.defaultProps = {
  qty: undefined,
};
