import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ProtectedRoute(props) {
  return props.grantAccess ? (
    <props.component
      services={props.services}
      locale={props.locale}
      setLocale={props.setLocale}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    />
  ) : (
    <Redirect to={{ pathname: props.redirectTo }} />
  );
}

ProtectedRoute.propTypes = {
  grantAccess: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
