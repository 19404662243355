import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Image.css';

export default function ImageHoverGalery(props) {
  const [largeImageId, setLargeImageId] = useState(0);
  const largeImageIdChangeHandler = (imageId) => {
    if (imageId !== largeImageId) {
      setLargeImageId(imageId);
    }
  };
  if (props.images.length === 1) {
    return (
      <img
        src={props.images[0].src}
        alt={props.images[0].alt}
      />
    );
  }
  return (
    <div className="image-hover-gallery">
      <div>
        <img
          src={props.images[largeImageId].src}
          alt={props.images[largeImageId].alt}
        />
      </div>
      <div className={`grid-image-hover-gallery${(props.classNames) ? ` ${props.classNames}` : ''}`}>
        {props.images.map((image, index) => (
          <div
            onClick={() => largeImageIdChangeHandler(index)}
            aria-disabled={true}
            onMouseOver={() => largeImageIdChangeHandler(index)}
            onFocus={() => largeImageIdChangeHandler(index)}
            onKeyPress={() => {}}
            onMouseOut={() => largeImageIdChangeHandler(0)}
            onBlur={() => largeImageIdChangeHandler(0)}
            role="button"
          >
            <img
              src={image.src}
              alt={image.alt}
              className={`grid-image-hover-gallery-img${(index === largeImageId) ? ' grid-image-hover-gallery-img--active' : ''}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

ImageHoverGalery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  classNames: PropTypes.string,
};

ImageHoverGalery.defaultProps = {
  classNames: undefined,
};
