import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import H1 from '../Heading/H1';
import MainNav from './MainNav';
import SecondaryNav from './SecondaryNav';
import HoseCodeSearch from './HoseCodeSearch';
import Summary from './Summary';
import HosePartSelection from './HosePartSelection';
import Spinner from '../Progress/Spinner';
import Message from '../Message/Message';
import urlHelper from '../../utils/helper/url-helper';
import './HoseConfigurator.css';

export default function HoseConfigurator(props) {
  const intl = useIntl();
  const [state, setState] = useState('start');
  const [hoseCode, setHoseCode] = useState(undefined);
  const [isValidHoseCode, setIsValidHoseCode] = useState(true);
  const [hoseConfiguration, setHoseConfiguration] = useState({});
  const [sortSettings, setSortSettings] = useState({});
  const [filterSettings, setFilterSettings] = useState({});
  const selectHosePartHandler = (params) => {
    setFilterSettings({});
    if (params.part === 'endA') {
      setState('selectHoseEndA');
    } else if (params.part === 'endB') {
      setState('selectHoseEndB');
    } else {
      setState('selectHose');
    }
  };
  const getCompletedStepsCount = () => (
    (hoseConfiguration.endA !== undefined)
    + (hoseConfiguration.hose !== undefined)
    + (hoseConfiguration.endB !== undefined)
  );
  const hoseCodeChangeHandler = (newHoseCode) => {
    setHoseCode(newHoseCode);
    if (!newHoseCode) {
      return;
    }
    if (newHoseCode && newHoseCode.length === 8 && newHoseCode.startsWith('SC')) {
      setIsValidHoseCode(true);
      props.services.apiService.post('/product/get/custom-product', {
        productId: newHoseCode,
      }).then((res) => {
        if (res.data.customProduct) {
          if (props.services.hoseConfiguratorService.isAvailableHoseConfiguration(
            res.data.customProduct.configuration, props.dataStorage
          )) {
            setHoseConfiguration(props.services.hoseConfiguratorService.getDeepConfigurationObject(
              res.data.customProduct.configuration, props.dataStorage
            ));
            setState('completed');
          } else {
            setIsValidHoseCode(false);
          }
        } else {
          setIsValidHoseCode(false);
        }
      });
    } else {
      setIsValidHoseCode(false);
    }
  };
  const resetHandler = () => {
    setHoseConfiguration({});
    setHoseCode(undefined);
    setState('start');
    setIsValidHoseCode(true);
    setSortSettings({});
    setFilterSettings({});
  };
  const deleteHosePartHandler = (params) => {
    const newHoseConfiguration = { ...hoseConfiguration };
    delete newHoseConfiguration[params.part];
    setHoseConfiguration(newHoseConfiguration);
    if (getCompletedStepsCount() === 1) {
      setHoseCode(undefined);
      setState('start');
    } else {
      setState('selectNextStep');
      hoseCodeChangeHandler(undefined);
    }
  };
  const hoseConfigurationChangeHandler = (params) => {
    const endAIsDefined = params.hoseConfiguration.endA !== undefined;
    const hoseIsDefined = params.hoseConfiguration.hose !== undefined;
    const endBIsDefined = params.hoseConfiguration.endB !== undefined;
    props.services.hoseConfiguratorService.setFittingsAndHoseClipsAndSocketsIfNecessary(
      params.hoseConfiguration, props.products, intl, props.locale, props.dataStorage
    );
    setHoseConfiguration(params.hoseConfiguration);
    if ((endAIsDefined + hoseIsDefined + endBIsDefined) === 3) {
      props.services.apiService.post('/product/get/custom-product-id', {
        configType: props.services.hoseConfiguratorService.getConfigurationType(),
        configuration: props.services.hoseConfiguratorService.getFlatHoseConfigurationObject(
          params.hoseConfiguration
        ),
      }).then((res) => {
        setHoseCode(res.data.id);
      });
      setState('completed');
    } else {
      setState('selectNextStep');
      hoseCodeChangeHandler(undefined);
    }
  };
  const filterChangeHandler = (newFilterSettings) => {
    setFilterSettings(newFilterSettings);
  };
  const sortChangeHandler = (params) => {
    const newSortSettings = { ...sortSettings };
    if (state === 'selectHose') {
      newSortSettings.hose = params.sortOption;
    } else {
      newSortSettings.hoseEnd = params.sortOption;
    }
    setSortSettings(newSortSettings);
  };
  useEffect(() => {
    const paramHoseCode = urlHelper.getQueryParamValue('hc');
    if (paramHoseCode) {
      hoseCodeChangeHandler(paramHoseCode);
    }
  }, []);
  function showHosePartSelection() {
    return state.includes('selectHose');
  }
  if (props.dataStorage.products.length) {
    return (
      <div className="grid-hose-configurator">
        <div className="hc-main-nav">
          <H1 classNames="center" configService={props.services.configService} />
          {(state !== 'start') && (
            <div className="hc-900px-container">
              <Message type="info" iconSize={28}>
                <FormattedMessage id="hcInfoImageNotToScale" />
              </Message>
            </div>
          )}
          <MainNav
            locale={props.locale}
            configuratorState={state}
            hoseConfiguration={hoseConfiguration}
            handlers={{ selectHosePartHandler, deleteHosePartHandler }}
            services={props.services}
          />
          <SecondaryNav
            configuratorState={state}
            hoseConfiguration={hoseConfiguration}
            hoseCode={hoseCode}
            handlers={{ resetHandler, hoseConfigurationChangeHandler }}
            locale={props.locale}
            dataStorage={props.dataStorage}
            services={props.services}
          />
          {state === 'start' && (
            <div className="hc-900px-container">
              <div className="hc-hose-code-search">
                <HoseCodeSearch
                  hoseCode={hoseCode}
                  hoseCodeChangeHandler={hoseCodeChangeHandler}
                  isValidHoseCode={isValidHoseCode}
                />
              </div>
            </div>
          )}
        </div>
        {showHosePartSelection() && (
          <div className="hc-product-list-container">
            <HosePartSelection
              hosePartId={props.services.hoseConfiguratorService.getHosePartId(state)}
              hoseConfiguration={hoseConfiguration}
              products={props.products}
              handlers={{
                filterChangeHandler,
                sortChangeHandler,
                hoseConfigurationChangeHandler,
                selectHosePartHandler,
              }}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          </div>
        )}
        <div className="hc-summary">
          <div className="hc-summary-container">
            <Summary
              locale={props.locale}
              currency={props.services.configService.getGeneralConfig().sales.currency}
              configuratorState={state}
              hoseConfiguration={hoseConfiguration}
              services={props.services}
              dataStorage={props.dataStorage}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="center"><Spinner label="Loading" /></div>
  );
}

HoseConfigurator.propTypes = {
  products: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
