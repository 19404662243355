import React from 'react';
import PropTypes from 'prop-types';
import PageWrapper from '../Wrapper/PageWrapper';
import SearchResultsPage from '../Search/SearchResultsPage';
import './Page.css';

export default function Search(props) {
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
      headData={props.services.searchService.getHeadData()}
    >
      <div className="content-container">
        <SearchResultsPage
          locale={props.locale}
          services={props.services}
          dataStorage={props.dataStorage}
        />
      </div>
    </PageWrapper>
  );
}

Search.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
