import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadWebpageParams from '../Progress/LoadWebpageParams';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';
import Kpis from './Kpis';
import ContentGrid from './ContentGrid';
import Slider from '../Slider/Slider';
import SimpleHeroRow from './SimpleHeroRow';
import SimpleVideoRow from './SimpleVideoRow';
import DiagonalBox from './DiagonalBox';

export default function PageBuilder(props) {
  const [data, setData] = useState(undefined);
  const dataChangeHandler = (newData) => {
    setData(newData);
  };
  function getBlocks() {
    if (data) {
      return data.blocks.map((block) => {
        if (block.type === 'Slider') {
          return (
            <Slider
              slides={block.slides}
              interval={block.interval}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          );
        } else if (block.type === 'ContentGrid') {
          return (
            <ContentGrid
              items={block.items}
              classNames={block.classNames}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          );
        } else if (block.type === 'DefaultHeadingBar') {
          return (
            <DefaultHeadingBar
              classNames={block.classNames}
              locale={props.locale}
              services={props.services}
            />
          );
        } else if (block.type === 'H1') {
          return (
            <h1 className={`pagebuilder-h1${(block.classNames) ? ` ${block.classNames}` : ''}`}>{block.text}</h1>
          );
        } else if (block.type === 'H2') {
          return (
            <h2 className={`pagebuilder-h2${(block.classNames) ? ` ${block.classNames}` : ''}`}>{block.text}</h2>
          );
        } else if (block.type === 'Kpis') {
          return (
            <Kpis items={block.items} classNames={block.classNames} />
          );
        } else if (block.type === 'Image') {
          return (
            <img className={block.classNames} src={block.src} alt={block.alt} />
          );
        } else if (block.type === 'SimpleHeroRow') {
          return (
            <SimpleHeroRow
              item={block.item}
              classNames={block.classNames}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          );
        } else if (block.type === 'DiagonalHeroRow') {
          return (
            <DiagonalBox classNames={block.classNames.db}>
              <SimpleHeroRow
                item={block.item}
                classNames={block.classNames.shr}
                locale={props.locale}
                services={props.services}
              />
            </DiagonalBox>
          );
        } else if (block.type === 'SimpleVideoRow') {
          return (
            <SimpleVideoRow item={block.item} classNames={block.classNames} />
          );
        } else if (block.type === 'DiagonalVideoRow') {
          return (
            <DiagonalBox>
              <SimpleVideoRow item={block.item} classNames="content-container bg-transparent" />
            </DiagonalBox>
          );
        }
        return undefined;
      });
    }
    return undefined;
  }
  return (
    <LoadWebpageParams
      webpageParamsHandler={dataChangeHandler}
      services={props.services}
      dataStorage={props.dataStorage}
    >
      {getBlocks()}
    </LoadWebpageParams>
  );
}

PageBuilder.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
