import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Validator from '../Form/Validator';
import FormBuyer from '../Form/FormBuyer';
import RequestProgress from '../Progress/RequestProgress';
import Button from '../Button/Button';

export default function ChangeUserForm(props) {
  const intl = useIntl();
  const [company, setCompany] = useState(props.dataStorage.user.company);
  const [customerId, setCustomerId] = useState(props.dataStorage.user.customerId);
  const [salutation, setSalutation] = useState(props.dataStorage.user.salutation);
  const [firstname, setFirstname] = useState(props.dataStorage.user.firstname);
  const [lastname, setLastname] = useState(props.dataStorage.user.lastname);
  const [email, setEmail] = useState(props.dataStorage.user.email);
  const [hasInvoiceEmail, setHasInvoiceEmail] = useState(
    props.dataStorage.user.invoiceEmail
  );
  const [invoiceEmail, setInvoiceEmail] = useState(props.dataStorage.user.invoiceEmail);
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [companyIsValid, setCompanyIsValid] = useState(true);
  const [customerIdIsValid, setCustomerIdIsValid] = useState(true);
  const [firstnameIsValid, setFirstnameIsValid] = useState(true);
  const [lastnameIsValid, setLastnameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [invoiceEmailIsValid, setInvoiceEmailIsValid] = useState(true);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');
  const companyChangeHandler = (newCompany) => {
    setCompany(newCompany);
    setCompanyIsValid(Validator.isValidCompanyName(newCompany));
  };
  const customerIdChangeHandler = (newCustomerId) => {
    setCustomerId(newCustomerId);
    setCustomerIdIsValid(Validator.isValidCustomerId(newCustomerId, true));
  };
  const salutationChangeHandler = (newSalutation) => {
    setSalutation(newSalutation);
  };
  const firstnameChangeHandler = (newFirstname) => {
    setFirstname(newFirstname);
    setFirstnameIsValid(Validator.isValidPersonName(newFirstname));
  };
  const lastnameChangeHandler = (newLastname) => {
    setLastname(newLastname);
    setLastnameIsValid(Validator.isValidPersonName(newLastname));
  };
  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
    setEmailIsValid(Validator.isValidEmailAddress(newEmail));
  };
  const hasInvoiceEmailChangeHandler = (newHasInvoiceEmail) => {
    setHasInvoiceEmail(newHasInvoiceEmail);
    setInvoiceEmail(undefined);
    setInvoiceEmailIsValid(true);
  };
  const invoiceEmailChangeHandler = (newInvoiceEmail) => {
    setInvoiceEmail(newInvoiceEmail);
    setInvoiceEmailIsValid(Validator.isValidEmailAddress(newInvoiceEmail, !hasInvoiceEmail));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (companyIsValid && firstnameIsValid && lastnameIsValid && emailIsValid
      && invoiceEmailIsValid) {
      setViewState('loading');
      props.services.apiService.post('/user/edit', {
        company,
        salutation,
        firstname,
        lastname,
        email,
        invoiceEmail,
        locale: props.locale,
      }).then((res) => {
        setViewState('result');
        if (res.data.user) {
          setResultMessageText(intl.formatMessage({ id: 'userMasterDataEditedSuccessMsg' }));
          setResultMessageType('success');
          props.services.dataStorageService.setUser(res.data.user, props.dataStorage);
        } else {
          setResultMessageText(intl.formatMessage({ id: 'errorHasOccurred' }));
          setResultMessageType('error');
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className="form" onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormBuyer
              customerIdIsDisabled={true}
              emailIsDisabled={true}
              company={company}
              customerId={customerId}
              salutation={salutation}
              firstname={firstname}
              lastname={lastname}
              email={email}
              hasInvoiceEmail={hasInvoiceEmail}
              invoiceEmail={invoiceEmail}
              companyChangeHandler={companyChangeHandler}
              customerIdChangeHandler={customerIdChangeHandler}
              salutationChangeHandler={salutationChangeHandler}
              firstnameChangeHandler={firstnameChangeHandler}
              lastnameChangeHandler={lastnameChangeHandler}
              emailChangeHandler={emailChangeHandler}
              hasInvoiceEmailChangeHandler={hasInvoiceEmailChangeHandler}
              invoiceEmailChangeHandler={invoiceEmailChangeHandler}
              companyIsValid={companyIsValid}
              customerIdIsValid={customerIdIsValid}
              firstnameIsValid={firstnameIsValid}
              lastnameIsValid={lastnameIsValid}
              emailIsValid={emailIsValid}
              invoiceEmailIsValid={invoiceEmailIsValid}
              submitButtonIsClicked={submitButtonIsClicked}
            />
          </div>
          <Button
            classNames="button button--full-width"
            type="submit"
            disabled={!(
              props.dataStorage.user.company !== company
              || props.dataStorage.user.salutation !== salutation
              || props.dataStorage.user.firstname !== firstname
              || props.dataStorage.user.lastname !== lastname
              || props.dataStorage.user.invoiceEmail !== invoiceEmail
            )}
          >
            <FormattedMessage id="saveChanges" />
          </Button>
        </form>
      )}
    </Fragment>
  );
}

ChangeUserForm.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
