import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Dropdown from '../Dropdown/Dropdown';

export default function SortBy(props) {
  const intl = useIntl();
  return (
    <div className="list-sortby">
      <Dropdown
        isListItem={true}
        type="click"
        heading={intl.formatMessage({ id: 'sortBy' })}
        items={props.items}
        headingSuffixIcon="angle-down"
      />
    </div>
  );
}

SortBy.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};
