import urlHelper from '../utils/helper/url-helper';

const userListSortOptions = [
  {
    name: {
      de: 'Standard',
      en: 'Default',
    },
    preset: true,
  },
  {
    name: {
      de: 'Nachname',
      en: 'Lastname',
    },
    propertyPath: ['lastname'],
    orders: ['ASC', 'DESC'],
    type: 'String',
  },
  {
    name: {
      de: 'Unternehmen',
      en: 'Company',
    },
    propertyPath: ['company'],
    orders: ['ASC', 'DESC'],
    type: 'String',
  },
];
const orderListSortOptions = [
  {
    name: {
      de: 'Standard',
      en: 'Default',
    },
    preset: true,
  },
  {
    name: {
      de: 'Bestellnr.',
      en: 'Order Id',
    },
    propertyPath: ['id'],
    orders: ['ASC', 'DESC'],
    type: 'String',
  },
];

let configService;
let listService;
let salesService;

function setDependencies(dependencies) {
  ({ configService, listService, salesService } = dependencies);
  return this;
}

function getOrderStatisticByBuyerId(dataStorage, id, locale) {
  const orders = dataStorage.adminData.orders.filter((order) => order.buyer.id === id);
  let numberValidOrders = 0;
  orders.forEach((order) => {
    if (order.status !== 'canceled') {
      numberValidOrders += 1;
    }
  });
  const numberAllOrders = orders.length;
  let numberLabel = numberValidOrders;
  if (numberValidOrders !== numberAllOrders) {
    numberLabel = `${numberValidOrders}/${numberAllOrders}`;
  }
  return {
    orders,
    numberValidOrders,
    numberAllOrders,
    numberLabel,
    summedSubtotal: salesService.getSummedAndFormattedSubtotal(orders, locale),
  };
}

function searchUsers(users, searchTerm, logicOperator, locale, sortList) {
  return listService.search(
    users,
    searchTerm,
    logicOperator,
    locale,
    listService.basicSearchCompareFunc,
    [
      {
        path: ['email'],
        exact: 50,
        partial: 5,
      },
      {
        path: ['firstname'],
        exact: 15,
        partial: 5,
      },
      {
        path: ['lastname'],
        exact: 15,
        partial: 5,
      },
      {
        path: ['company'],
        exact: 15,
        partial: 5,
      },
    ],
    sortList
  );
}

function searchOrders(orders, searchTerm, logicOperator, locale, sortList) {
  return listService.search(
    orders,
    searchTerm,
    logicOperator,
    locale,
    listService.basicSearchCompareFunc,
    [
      {
        path: ['id'],
        exact: 50,
        partial: 5,
      },
      {
        path: ['buyer', 'email'],
        exact: 50,
        partial: 5,
      },
      {
        path: ['buyer', 'lastname'],
        exact: 15,
        partial: 5,
      },
      {
        path: ['buyer', 'company'],
        exact: 15,
        partial: 5,
      },
    ],
    sortList
  );
}

function getUserListSortOptions(intl, locale) {
  return listService.getSortOptions(userListSortOptions, intl, locale);
}

function getOrderListSortOptions(intl, locale) {
  return listService.getSortOptions(orderListSortOptions, intl, locale);
}

function getDefaultUserListSortOption(intl, locale) {
  return listService.getDefaultSortOption(
    userListSortOptions, intl, locale
  );
}

function getDefaultOrderListSortOption(intl, locale) {
  return listService.getDefaultSortOption(
    orderListSortOptions, intl, locale
  );
}

function getFrontendUserByUrlQuery(users) {
  return urlHelper.getItemByUrlQuery('uid', 'id', users);
}

function getPathToOrderParentPage(locale, userId) {
  const parentPageTransCode = urlHelper.getQueryParamValue('pptc');
  const { path } = configService.getPageByTranslationCodeAndLocale(parentPageTransCode, locale);
  return (parentPageTransCode === 'admin-orders') ? path : `${path}?uid=${userId}&tabId=orders`;
}

function getMonthsStatistics(statistic) {
  const lastTwoMonthsStat = [];
  if (statistic.timeperiods) {
    Object.keys(statistic.timeperiods).forEach((timeperiod) => {
      if (timeperiod.length === 7) {
        lastTwoMonthsStat.push({ timeperiod, content: statistic.timeperiods[timeperiod] });
      }
    });
  }
  lastTwoMonthsStat.reverse();
  return lastTwoMonthsStat;
}

export default {
  setDependencies,
  getOrderStatisticByBuyerId,
  searchUsers,
  searchOrders,
  getUserListSortOptions,
  getOrderListSortOptions,
  getDefaultUserListSortOption,
  getDefaultOrderListSortOption,
  getFrontendUserByUrlQuery,
  getPathToOrderParentPage,
  getMonthsStatistics,
};
