import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';

export default function AssemblySelection(props) {
  function gethoseEndConfig(assemblyDirection) {
    const hoseEndConfig = { ...props.products };
    hoseEndConfig.assemblyDirection = assemblyDirection;
    return hoseEndConfig;
  }
  return (
    <div className="grid-hc-assembly-selection">
      <div className="hc-assembly-option">
        <CustomLink
          href="#"
          onClickHandler={props.setAssemblyDirectionHandler}
          onClickHandlerParams={{ direction: 'up' }}
        >
          <img className="" src={props.services.hoseConfiguratorService.getPartialImagePath(props.hosePartId, props.hoseConfiguration, gethoseEndConfig('up'))} alt="up" />
        </CustomLink>
      </div>
      <div className="hc-assembly-option">
        <CustomLink
          href="#"
          onClickHandler={props.setAssemblyDirectionHandler}
          onClickHandlerParams={{ direction: 'down' }}
        >
          <img className="" src={props.services.hoseConfiguratorService.getPartialImagePath(props.hosePartId, props.hoseConfiguration, gethoseEndConfig('down'))} alt="down" />
        </CustomLink>
      </div>
    </div>
  );
}

AssemblySelection.propTypes = {
  hosePartId: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAssemblyDirectionHandler: PropTypes.objectOf(PropTypes.any).isRequired,
  hoseConfiguration: PropTypes.objectOf(PropTypes.object).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
