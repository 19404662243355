import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProductContentWrapper from '../Product/Block/ProductContentWrapper';
import Configurator from '../EjectorConfigurator/EjectorConfigurator';
import H1 from '../Heading/H1';
import './Page.css';

export default function EjectorConfigurator(props) {
  const [expandedMenuPath, setExpandedMenuPath] = useState(undefined);
  function expandPathHandler(togglePath) {
    if (expandedMenuPath && expandedMenuPath.indexOf(togglePath) === 0) {
      const pathArray = togglePath.split('|');
      pathArray.pop();
      setExpandedMenuPath(pathArray.join('|'));
    } else {
      setExpandedMenuPath(togglePath);
    }
  }
  return (
    <ProductContentWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
      expandedMenuPath={expandedMenuPath}
      setExpandedMenuPath={expandPathHandler}
    >
      <H1 configService={props.services.configService} />
      <Configurator
        productTypeSettings={props.services.ejectorConfiguratorService.getProductTypeSettings(
          props.dataStorage.products
        )}
        locale={props.locale}
        services={props.services}
        dataStorage={props.dataStorage}
      />
    </ProductContentWrapper>
  );
}

EjectorConfigurator.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
