import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import FormInput from '../Form/FormInput';
import FormSpamProtection from '../Form/FormSpamProtection';
import Button from '../Button/Button';
import Checkbox from '../Form/Checkbox';
import CustomLink from '../Link/CustomLink';
import RequestProgress from '../Progress/RequestProgress';
import './Account.css';

export default function LoginForm(props) {
  const intl = useIntl();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRightAntiSpamAnswer, setIsRightAntiSpamAnswer] = useState(false);
  const [isPermanentLogin, setIsPermanentLogin] = useState(true);
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');
  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
  };
  const passwordChangeHandler = (newPassword) => {
    setPassword(newPassword);
  };
  const isRightAntiSpamAnswerChangeHandler = (newAntiSpamAnswer) => {
    setIsRightAntiSpamAnswer(newAntiSpamAnswer);
  };
  const isPermanentLoginHandler = (newIsPermanentLogin) => {
    setIsPermanentLogin(newIsPermanentLogin);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (isRightAntiSpamAnswer) {
      setViewState('loading');
      props.services.apiService.post(props.apiPath, {
        email,
        password,
        isPermanentLogin,
        locale: props.locale,
      }).then((res) => {
        setViewState('result');
        if (res.data.user) {
          setResultMessageType('success');
          setResultMessageText(intl.formatMessage({ id: 'loginSuccessMsg' }));
          if (props.apiPath === '/facade/frontend/login') {
            props.services.dataStorageService.setUserAndRelatedData(
              res.data.user, res.data.orders, res.data.groupPriceRules, props.dataStorage
            );
          } else {
            props.services.dataStorageService.setAdminUserAndData(
              res.data.user, res.data.adminData, props.dataStorage
            );
          }
          if (props.closeHandler) {
            window.setTimeout(() => {
              props.closeHandler(props.closeHandlerParams || res.data.user);
              setEmail('');
              setPassword('');
              setIsPermanentLogin(true);
              setSubmitButtonIsClicked(false);
              setViewState('form');
            }, 2000);
          } else {
            history.push(props.services.configService.getPageByTranslationCodeAndLocale(
              (props.apiPath === '/facade/frontend/login') ? 'dashboard' : 'admin-dashboard',
              props.locale
            ).path);
          }
        } else {
          setResultMessageType('error');
          setResultMessageText(intl.formatMessage({ id: 'loginErrorMsg' }));
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className={props.classNames} onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormInput
              id="email"
              value={email}
              callback={emailChangeHandler}
              isValid
              errorMessageId="mustEnterEmail"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormInput
              id="password"
              type="password"
              value={password}
              callback={passwordChangeHandler}
              isValid
              errorMessageId="mustEnterPassword"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormSpamProtection
              callback={isRightAntiSpamAnswerChangeHandler}
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
          </div>
          {(props.showPwdForgottenAndStayLoggedIn) && (
            <div className="account-login-options">
              <div className="account-permanent-login">
                <Checkbox
                  id="isPermanentLogin"
                  divClassNames="form-checkbox"
                  checked={isPermanentLogin}
                  label={intl.formatMessage({ id: 'loginPermanent' })}
                  callback={isPermanentLoginHandler}
                />
              </div>
              <div className="account-pwd-fogotten-link">
                <CustomLink
                  href={props.pwdForgottenLink}
                >{intl.formatMessage({ id: 'passwordForgotten' })}
                </CustomLink>
              </div>
            </div>
          )}
          <Button classNames="button button--full-width" type="submit"><FormattedMessage id="login" /></Button>
          {(props.showNoAccountYet) && (
            <div className="account-login-no-account">
              <CustomLink
                href={props.noAccountYetLink}
              >{intl.formatMessage({ id: 'noAccountYet' })}
              </CustomLink>
            </div>
          )}
        </form>
      )}
    </Fragment>
  );
}

LoginForm.propTypes = {
  classNames: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  apiPath: PropTypes.string,
  locale: PropTypes.string.isRequired,
  showPwdForgottenAndStayLoggedIn: PropTypes.bool,
  pwdForgottenLink: PropTypes.string,
  showNoAccountYet: PropTypes.bool,
  noAccountYetLink: PropTypes.string,
  closeHandler: PropTypes.func,
  closeHandlerParams: PropTypes.objectOf(PropTypes.any),
};

LoginForm.defaultProps = {
  apiPath: '/facade/frontend/login',
  showPwdForgottenAndStayLoggedIn: false,
  pwdForgottenLink: undefined,
  showNoAccountYet: false,
  noAccountYetLink: undefined,
  closeHandler: undefined,
  closeHandlerParams: undefined,
};
