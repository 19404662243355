import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import HosePartLabel from './HosePartLabel';
import Icon from '../Layout/Icon';

export default function HosePartMenuButtons(props) {
  function hosePartIsDefined() {
    return props.services.hoseConfiguratorService.hosePartIsDefined(props.hosePartConfig);
  }
  function getEditAndDeleteMenu() {
    if (props.configuratorState === 'selectNextStep' || props.configuratorState === 'completed') {
      return (
        <div className="hc-part-menu">
          <span className="hc-part-selected-label"><HosePartLabel hosePartId={props.hosePartId} />:</span>
          <Icon name="check-circle" size={36} />
          <Button
            classNames="button button--secondary margin-r5"
            onClick={() => props.handlers.selectHosePartHandler(
              { part: props.hosePartId }
            )}
          >
            <Icon name="edit" />
          </Button>
          <Button
            classNames="button button--secondary"
            onClick={() => props.handlers.deleteHosePartHandler(
              { part: props.hosePartId }
            )}
          >
            <Icon name="trash" />
          </Button>
        </div>
      );
    }
    return undefined;
  }
  function getSelectButton() {
    if (props.configuratorState === 'start' || props.configuratorState === 'selectNextStep') {
      return (
        <Button
          classNames="button button--full-width"
          onClick={() => props.handlers.selectHosePartHandler(
            { part: props.hosePartId }
          )}
        >
          {props.selectButtonText}
        </Button>
      );
    }
    return undefined;
  }
  return (
    <div className={props.containerClassNames}>
      {hosePartIsDefined() && getEditAndDeleteMenu()}
      {!hosePartIsDefined() && getSelectButton()}
    </div>
  );
}

HosePartMenuButtons.propTypes = {
  hosePartConfig: PropTypes.objectOf(PropTypes.object),
  configuratorState: PropTypes.string.isRequired,
  hosePartId: PropTypes.string.isRequired,
  containerClassNames: PropTypes.string.isRequired,
  selectButtonText: PropTypes.string.isRequired,
  handlers: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

HosePartMenuButtons.defaultProps = {
  hosePartConfig: undefined,
};
