import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Message from '../Message/Message';
import FiltersSearchAndSort from './FiltersSearchAndSort';
import Pages from './Pages';
import objectHelper from '../../utils/helper/object-helper';
import './List.css';

export default function List(props) {
  const intl = useIntl();
  const history = useHistory();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [indexWasSetByQueryParam, setIndexWasSetByQueryParam] = useState(false);
  const [previousActiveSort, setPreviousActiveSort] = useState(undefined);
  const currentPageIndexChangeHandler = (params) => {
    setCurrentPageIndex(Number(params.index));
    if (params.setByQuery) {
      setIndexWasSetByQueryParam(true);
    } else {
      setIndexWasSetByQueryParam(false);
    }
  };
  useEffect(() => {
    if (!indexWasSetByQueryParam) {
      setCurrentPageIndex(0);
    } else {
      setIndexWasSetByQueryParam(false);
    }
  }, [props.listItems.length, props.activeSortOption]);
  useEffect(() => {
    if (props.enableUrlParams && (previousActiveSort
      || (props.searchTerm || currentPageIndex > 0 || (
        (objectHelper.isObject(props.activeFilters)
        && !objectHelper.isEmptyObject(props.activeFilters))
      )))
    ) {
      history.replace(props.services.listService.getPathAndQueryParamsBasedOnListSettings(
        props.activeFilters,
        props.searchTerm,
        props.activeSortOption,
        currentPageIndex,
        props.activeLayout
      ));
    } else {
      setPreviousActiveSort(props.activeSortOption);
    }
  }, [
    props.activeFilters,
    props.searchTerm,
    props.activeSortOption,
    currentPageIndex,
    props.activeLayout,
  ]);
  useEffect(() => {
    if (props.enableUrlParams) {
      props.services.listService.setListSettingsByUrlQueryParameters(
        props.filters,
        props.sortOptions,
        props.handlers,
        currentPageIndexChangeHandler
      );
    }
  }, []);
  function getListItems() {
    if (props.listItems.length > 0) {
      return (
        <Pages
          items={props.listItems}
          headingRow={props.headingRow}
          containerClassName={props.classNames.pageContainer}
          currentPageIndex={currentPageIndex}
          currentPageIndexChangeHandler={currentPageIndexChangeHandler}
          elementsPerPage={props.elementsPerPage}
          itemTypeLabel={
            (props.showItemCount) ? intl.formatMessage({ id: props.i18nIds.itemType }) : undefined
          }
          hrefForHistory={`${window.location.pathname}${window.location.search}`}
        />
      );
    }
    Object.keys(props.activeFilters).forEach((filterId) => {
      if (props.getFilteredAndSortedItemsFunc(
        props.listItems,
        { [filterId]: props.activeFilters[filterId] },
        props.activeSortOption,
        props.locale,
        props.dataStorage
      ).length === 0) {
        props.handlers.filterDeleteHandler({ attributeId: filterId });
      }
    });
    return (
      <Message type="warning">
        <FormattedMessage id={props.i18nIds.empty} />
      </Message>
    );
  }
  return (
    <div className={`list-container${(props.classNames.listContainer) ? ` ${props.classNames.listContainer}` : ''}`}>
      <FiltersSearchAndSort
        filters={props.filters}
        activeFilters={props.activeFilters}
        sortOptions={props.sortOptions}
        activeSortOption={props.activeSortOption}
        searchTerm={props.searchTerm}
        expandedFilterId={props.expandedFilterId}
        listItemCount={props.listItems.length}
        showSearch={props.showSearch}
        activeLayout={props.activeLayout}
        handlers={props.handlers}
        locale={props.locale}
        services={props.services}
      />
      {getListItems()}
      {props.dialog !== undefined && (props.dialog)}
    </div>
  );
}

List.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  expandedFilterId: PropTypes.string,
  sortOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeSortOption: PropTypes.objectOf(PropTypes.any).isRequired,
  activeLayout: PropTypes.string,
  getFilteredAndSortedItemsFunc: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  handlers: PropTypes.objectOf(PropTypes.func).isRequired,
  headingRow: PropTypes.objectOf(PropTypes.any),
  listItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  elementsPerPage: PropTypes.number.isRequired,
  showItemCount: PropTypes.bool,
  enableUrlParams: PropTypes.bool,
  i18nIds: PropTypes.objectOf(PropTypes.string).isRequired,
  classNames: PropTypes.objectOf(PropTypes.string).isRequired,
  dialog: PropTypes.objectOf(PropTypes.any),
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
};

List.defaultProps = {
  expandedFilterId: undefined,
  showSearch: false,
  headingRow: undefined,
  showItemCount: false,
  enableUrlParams: false,
  dialog: undefined,
  activeLayout: 'grid',
};
