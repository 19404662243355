import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import StepperProgressBar from '../Progress/StepperProgressBar';
import Selection from './Selection';
import FormSelect from '../Form/FormSelect';
import FormInput from '../Form/FormInput';
import Product from '../Product/Product';
import ProductAttributeGroup from '../Product/ProductAttributeGroup';
import PriceAndAvailability from '../Product/PriceAndAvailability';
import AddProduct from '../Product/AddProduct';
import Message from '../Message/Message';
import Button from '../Button/Button';
import numberHelper from '../../utils/helper/number-helper';
import urlHelper from '../../utils/helper/url-helper';
import './EjectorConfigurator.css';

export default function EjectorConfigurator(props) {
  const intl = useIntl();
  const initialConfiguratorState = props.services.ejectorConfiguratorService
    .getInitialConfiguratorState();
  const [steps, setSteps] = useState(
    props.services.ejectorConfiguratorService.getConfigurationSteps(
      initialConfiguratorState.steps
    )
  );
  const [activeStep, setActiveStep] = useState(1);
  const [configuratorState, setConfiguratorState] = useState(initialConfiguratorState);
  const [ejectorLength, setEjectorLength] = useState('');
  const [ejectorLengthLastInputComma, setEjectorLengthLastInputComma] = useState(false);
  const [ejectorLengthLastInputCommaZero, setEjectorLengthLastInputCommaZero] = useState(false);
  const [lengthIsValid, setLengthIsValid] = useState(false);
  const [ejectorCode, setEjectorCode] = useState(undefined);
  const [isValidEjectorCode, setIsValidEjectorCode] = useState(true);
  const [codeCheapestSolution, setCodeCheapestSolution] = useState(undefined);
  const [codeFastestSolution, setCodeFastestSolution] = useState(undefined);
  const [orderQuantity, setOrderQuantity] = useState(1);
  const resetHandler = () => {
    setLengthIsValid(false);
    setIsValidEjectorCode(true);
    setCodeCheapestSolution(undefined);
    setCodeFastestSolution(undefined);
    setOrderQuantity(1);
    setActiveStep(1);
    setEjectorLength('');
    setEjectorLengthLastInputComma(false);
    setEjectorLengthLastInputCommaZero(false);
    setSteps(
      props.services.ejectorConfiguratorService.getConfigurationSteps(
        initialConfiguratorState.steps
      )
    );
    setConfiguratorState(
      props.services.ejectorConfiguratorService.getInitialConfiguratorState()
    );
    setEjectorCode(undefined);
  };
  const qtyChangeHandler = (newQty) => setOrderQuantity(newQty);
  function getEjectorConfigurationByCode(newEjectorCode) {
    props.services.apiService.post('/product/get/custom-product', {
      productId: newEjectorCode,
    }).then((res) => {
      if (res.data.customProduct) {
        setConfiguratorState(
          props.services.ejectorConfiguratorService.getConfiguratorStateFromSolutionObject(
            res.data.customProduct.configuration,
            props.dataStorage,
            props.locale
          )
        );
      } else {
        setIsValidEjectorCode(false);
      }
    });
  }
  const ejectorCodeChangeHandler = (newEjectorCode) => {
    setEjectorCode((newEjectorCode && newEjectorCode.length > 0)
      ? newEjectorCode.toUpperCase()
      : newEjectorCode);
    if (!newEjectorCode) {
      return;
    }
    if (newEjectorCode && newEjectorCode.length === 8 && newEjectorCode.toUpperCase().startsWith('AC')) {
      setIsValidEjectorCode(true);
      getEjectorConfigurationByCode(newEjectorCode.toUpperCase());
    } else {
      setIsValidEjectorCode(false);
    }
  };
  const configuratorStateChangeHandler = (params) => {
    const newState = props.services.ejectorConfiguratorService.getUpdatedConfiguratorState(
      params, configuratorState, props.productTypeSettings,
      orderQuantity, intl, props.locale, props.dataStorage
    );
    setSteps(props.services.ejectorConfiguratorService.getConfigurationSteps(
      newState.steps
    ));
    setConfiguratorState(newState);
    setActiveStep(newState.selection.length + 1);
  };
  function resetStateAfterDimChange(newState, dim) {
    if ((!props.services.ejectorConfiguratorService.customConfigIsFlatEjector(newState)
      && dim === newState.diameterAttribute) || dim === 'cadData-a' || dim === 'cadData-b'
    ) {
      setLengthIsValid(false);
      setEjectorLength('');
      setEjectorLengthLastInputComma(false);
      setEjectorLengthLastInputCommaZero(false);
    }
    setCodeCheapestSolution(undefined);
    setCodeFastestSolution(undefined);
    setOrderQuantity(1);
    setConfiguratorState(newState);
  }
  function getEjectorCode(newState, isCheapest) {
    const solutionObject = props.services.ejectorConfiguratorService.getSolutionObject(
      newState, isCheapest
    );
    if (solutionObject) {
      props.services.apiService.post('/product/get/custom-product-id', {
        configType: props.services.ejectorConfiguratorService.getConfigurationType(),
        configuration: solutionObject,
      }).then((res) => {
        if (isCheapest) {
          setCodeCheapestSolution(res.data.id);
        } else {
          setCodeFastestSolution(res.data.id);
        }
      });
    }
  }
  function setSolutions(newState) {
    if (props.services.ejectorConfiguratorService.isSuccessfullyCompleted(newState)) {
      getEjectorCode(newState, true);
      if (newState.fastestSolution
        && newState.fastestSolution.id !== newState.cheapestSolution.id
      ) {
        getEjectorCode(newState, false);
      }
    }
  }
  const diameterChangeHandler = (newDiameter) => {
    const newState = props.services.ejectorConfiguratorService.getUpdatedConfiguratorState(
      { attributeId: configuratorState.diameterAttribute, changedValue: newDiameter },
      configuratorState, props.productTypeSettings,
      orderQuantity, intl, props.locale, props.dataStorage
    );
    resetStateAfterDimChange(newState, configuratorState.diameterAttribute);
    setSolutions(newState);
  };
  const l2ChangeHandler = (newL2) => {
    const newState = props.services.ejectorConfiguratorService.getUpdatedConfiguratorState(
      { attributeId: 'cadData-l2', changedValue: newL2 },
      configuratorState, props.productTypeSettings,
      orderQuantity, intl, props.locale, props.dataStorage
    );
    resetStateAfterDimChange(newState, 'cadData-l2');
    setSolutions(newState);
  };
  const bChangeHandler = (newB) => {
    const newState = props.services.ejectorConfiguratorService.getUpdatedConfiguratorState(
      { attributeId: 'cadData-b', changedValue: newB },
      configuratorState, props.productTypeSettings,
      orderQuantity, intl, props.locale, props.dataStorage
    );
    resetStateAfterDimChange(newState, 'cadData-b');
  };
  const aChangeHandler = (newA) => {
    const newState = props.services.ejectorConfiguratorService.getUpdatedConfiguratorState(
      { attributeId: 'cadData-a', changedValue: newA },
      configuratorState, props.productTypeSettings,
      orderQuantity, intl, props.locale, props.dataStorage
    );
    resetStateAfterDimChange(newState, 'cadData-a');
  };
  const lengthChangeHandler = (newLength) => {
    let newEjectorLength = props.services.salesService.unifyDecimalInput(newLength, props.locale);
    if (ejectorLengthLastInputCommaZero && newEjectorLength.length > 1 && !newEjectorLength.includes('.')) {
      const lastNumber = newEjectorLength.slice(-1);
      newEjectorLength = newEjectorLength.replace(lastNumber, `.0${lastNumber}`);
    }
    if (newEjectorLength.endsWith('.00')) {
      setEjectorLengthLastInputCommaZero(true);
    } else {
      setEjectorLengthLastInputCommaZero(false);
    }
    if (ejectorLengthLastInputComma && newEjectorLength.includes('.0') && !newEjectorLength.endsWith('.0')) {
      newEjectorLength = newEjectorLength.replace('.0', '.');
      setEjectorLengthLastInputComma(false);
    } else {
      setEjectorLengthLastInputComma(false);
    }
    if (numberHelper.canBeConvertedToNumber(newEjectorLength) || newEjectorLength === '' || newEjectorLength === '0' || newEjectorLength === '0.0') {
      if (!ejectorLengthLastInputComma
        && newLength.endsWith(numberHelper.getDecimalSeparatorByLocale(props.locale))
      ) {
        setEjectorLengthLastInputComma(true);
      }
      setEjectorLength(newEjectorLength);
      if (newEjectorLength !== '' && newEjectorLength !== '0' && newEjectorLength !== '0.0') {
        const newState = props.services.ejectorConfiguratorService.getUpdatedConfiguratorState(
          { attributeId: configuratorState.lengthAttribute, changedValue: (newEjectorLength.endsWith('.0')) ? newEjectorLength.replace('.0', '') : newEjectorLength },
          configuratorState, props.productTypeSettings,
          orderQuantity, intl, props.locale, props.dataStorage
        );
        const isValidLength = props.services.ejectorConfiguratorService.isValidLengthValue(
          newEjectorLength, newState
        );
        if (isValidLength) {
          setActiveStep(newState.selection.length + 1);
        }
        setLengthIsValid(isValidLength);
        setConfiguratorState(newState);
        setCodeCheapestSolution(undefined);
        setCodeFastestSolution(undefined);
        setSolutions(newState);
      }
    }
  };
  useEffect(() => {
    const paramEjectorCode = urlHelper.getQueryParamValue('ec');
    if (paramEjectorCode) {
      setEjectorCode(paramEjectorCode);
      getEjectorConfigurationByCode(paramEjectorCode);
    }
  }, []);
  function getSelectionSummary() {
    return configuratorState.selection.map((translationId) => (
      <div className="ec-your-config-item"><FormattedMessage id={translationId} /></div>
    ));
  }
  function isShowCustomEjectortoGivenCode() {
    return ejectorCode && isValidEjectorCode && configuratorState.cheapestSolution;
  }
  function getResetButton() {
    return (
      <Button
        classNames="button button-ec-reset"
        onClick={() => resetHandler()}
      >
        <FormattedMessage id="ecRestartConfigurator" />
      </Button>
    );
  }
  function getDiameterInputHtml() {
    return (
      <FormSelect
        id={configuratorState.diameterAttribute}
        label={configuratorState.diameterAttribute.replace('cadData-', '')}
        values={props.services.ejectorConfiguratorService.getDiameterValues(
          configuratorState, props.locale
        )}
        unit="mm"
        currentValue={configuratorState[configuratorState.diameterAttribute] || ''}
        callback={diameterChangeHandler}
        noPreselect={true}
        disabled={props.services.ejectorConfiguratorService.disableDiameterInput(
          configuratorState
        )}
      />
    );
  }
  function getCustomEjectorHtml(code, isCheapest) {
    const customEjectorProduct = props.services.ejectorConfiguratorService.getCustomEjectorProduct(
      configuratorState, code, isCheapest, intl
    );
    const solutionObject = props.services.ejectorConfiguratorService.getSolutionObject(
      configuratorState, isCheapest
    );
    const deepConfigurationObject = props.services.ejectorConfiguratorService
      .getDeepConfigurationObject(solutionObject, props.dataStorage);
    const positionPriceInfo = props.services.ejectorConfiguratorService.getPositionPriceInfo(
      solutionObject, orderQuantity, props.dataStorage, props.locale
    );
    return (
      <div className={`ec-custom-ejector${(isShowCustomEjectortoGivenCode()) ? ' ec-grid-custom-ejector' : ''}`}>
        <div>
          {(codeCheapestSolution && codeFastestSolution) && (
            <strong><FormattedMessage id={(isCheapest) ? 'ecSmallPrice' : 'ecFastDelivery'} />:</strong>
          )}
          {(codeCheapestSolution || codeFastestSolution) && (
            <Message type="info">
              <span><FormattedMessage id="ecEjectorCode" />: {(isCheapest) ? codeCheapestSolution : codeFastestSolution}</span>
            </Message>
          )}
          <div className="product-view-second-level-heading"><strong>{customEjectorProduct.name[props.locale]}</strong></div>
          <ProductAttributeGroup
            product={customEjectorProduct}
            attributeIds={props.services.ejectorConfiguratorService.getAttributeIds(
              customEjectorProduct
            )}
            locale={props.locale}
            services={props.services}
          />
          <PriceAndAvailability
            formattedPrice={positionPriceInfo.formattedPrice}
            formattedQuantityAndUnit={props.services.productService.getFormattedQuantityAndUnit(
              orderQuantity, customEjectorProduct, props.locale
            )}
          >
            <div className="product-view-availability-info">
              <strong>
                <FormattedMessage id="readyForShipment" /> <FormattedMessage
                  id="withinXdays"
                  values={
                    {
                      days: props.services.ejectorConfiguratorService.getReadyForShipmentTime(
                        deepConfigurationObject, orderQuantity
                      ),
                    }
                  }
                />
              </strong>
            </div>
          </PriceAndAvailability>
          <AddProduct
            product={props.services.ejectorConfiguratorService.getAddToCartProductObject(
              code, configuratorState, isCheapest
            )}
            customProductType={props.services.ejectorConfiguratorService.getConfigurationType()}
            qty={orderQuantity}
            showQuantity={true}
            addHandler={props.services.dataStorageService.addToCart}
            qtyChangeHandler={qtyChangeHandler}
            locale={props.locale}
            dataStorage={props.dataStorage}
            services={props.services}
          />
        </div>
        {isShowCustomEjectortoGivenCode() && (
          <div>
            <img
              src={props.services.ejectorConfiguratorService.getDrawingPath(configuratorState)}
              alt={configuratorState.drawingLabel}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="grid-ejector-configurator">
      {activeStep === 1 && (
        <div className="ec-1200px-container">
          <Message type="info" iconSize={28}>
            <FormattedMessage id="ecInstructionStart1" />
          </Message>
          <Message type="info" iconSize={28}>
            <FormattedMessage id="ecInstructionStart2" />
          </Message>
          <div className="ec-ejector-code-search">
            <div className="grid-form">
              <FormInput
                id="ejectorCode"
                label="ecEjectorCode:"
                value={ejectorCode}
                callback={ejectorCodeChangeHandler}
                isValid={isValidEjectorCode}
                errorMessageId="ecInvalidEjectorCodeMessage"
                noErrorMessageIfEmpty={true}
              />
            </div>
          </div>
        </div>
      )}
      {isShowCustomEjectortoGivenCode() && (
        <Fragment>
          {getCustomEjectorHtml(ejectorCode, true)}
        </Fragment>
      )}
      {(!ejectorCode || !isValidEjectorCode) && (
        <Fragment>
          <StepperProgressBar steps={steps} activeStep={activeStep} />
          <h2 className="ec-h2"><FormattedMessage id={steps[activeStep - 1].labelTranslationId} /></h2>
          {((configuratorState.steps.length + 1) > activeStep) && (
            <Selection
              items={props.services.ejectorConfiguratorService.getSelectionItems(
                props.productTypeSettings, configuratorState, configuratorStateChangeHandler, intl
              )}
            />
          )}
          {((configuratorState.steps.length + 1) === activeStep) && (
            <div className="grid-ec-drawing-dimensions">
              <div>
                <img
                  src={props.services.ejectorConfiguratorService.getDrawingPath(configuratorState)}
                  alt={configuratorState.drawingLabel}
                />
              </div>
              <div>
                <div className="center">
                  <div style={{ display: 'block' }}>
                    <div className="grid-form">
                      {!props.services.ejectorConfiguratorService.customConfigIsFlatEjector(
                        configuratorState
                      ) && (
                        getDiameterInputHtml()
                      )}
                      {props.services.ejectorConfiguratorService.customConfigIsFlatEjector(
                        configuratorState
                      ) && (
                        <Fragment>
                          <FormSelect
                            id="cadData-b"
                            label="b"
                            values={props.services.ejectorConfiguratorService.getBValues(
                              configuratorState, props.locale
                            )}
                            unit="mm"
                            currentValue={configuratorState['cadData-b']}
                            callback={bChangeHandler}
                            noPreselect={true}
                          />
                          <FormSelect
                            id="cadData-a"
                            label="a"
                            values={props.services.ejectorConfiguratorService.getAValues(
                              configuratorState, props.locale
                            )}
                            unit="mm"
                            currentValue={configuratorState['cadData-a'] || ''}
                            callback={aChangeHandler}
                            noPreselect={true}
                            disabled={!configuratorState['cadData-b']}
                          />
                        </Fragment>
                      )}
                      {!props.services.ejectorConfiguratorService.lengthInputIsSelection(
                        configuratorState
                      ) && (
                        <FormInput
                          id={configuratorState.lengthAttribute}
                          label={configuratorState.lengthAttribute.replace('cadData-', '')}
                          value={(ejectorLength || ejectorLength === '')
                            ? ejectorLength : configuratorState[configuratorState.lengthAttribute]}
                          unit="mm"
                          numberFormat={{
                            locale: props.locale,
                            minimumFractionDigits: (ejectorLengthLastInputComma) ? 1 : undefined,
                          }}
                          callback={lengthChangeHandler}
                          isValid={lengthIsValid}
                          errorMessageId="ecInvalidLength"
                          noErrorMessageIfEmpty={true}
                          disabled={props.services.ejectorConfiguratorService.disableLengthInput(
                            configuratorState
                          )}
                        />
                      )}
                      {props.services.ejectorConfiguratorService.lengthInputIsSelection(
                        configuratorState
                      ) && (
                        <FormSelect
                          id={configuratorState.lengthAttribute}
                          label={configuratorState.lengthAttribute.replace('cadData-', '')}
                          values={props.services.ejectorConfiguratorService
                            .getLengthValuesForSelection(configuratorState)}
                          unit="mm"
                          currentValue={configuratorState[configuratorState.lengthAttribute] || ''}
                          callback={lengthChangeHandler}
                          noPreselect={true}
                          disabled={props.services.ejectorConfiguratorService.disableLengthInput(
                            configuratorState
                          )}
                        />
                      )}
                      {props.services.ejectorConfiguratorService.customConfigIsNotStraightEjector(
                        configuratorState
                      ) && (
                        <FormSelect
                          id="cadData-l2"
                          label="l2"
                          values={props.services.ejectorConfiguratorService.getL2Values(
                            configuratorState, props.locale
                          )}
                          unit="mm"
                          currentValue={configuratorState['cadData-l2'] || ''}
                          callback={l2ChangeHandler}
                          noPreselect={true}
                          disabled={!configuratorState[configuratorState.lengthAttribute]}
                        />
                      )}
                      {props.services.ejectorConfiguratorService.customConfigIsFlatEjector(
                        configuratorState
                      ) && (
                        getDiameterInputHtml()
                      )}
                    </div>
                  </div>
                </div>
                {!props.services.ejectorConfiguratorService.disableLengthInput(
                  configuratorState
                ) && (
                  <div className="ec-length-messages">
                    {!props.services.ejectorConfiguratorService.lengthInputIsSelection(
                      configuratorState
                    ) && (
                      <Message type="info" iconSize={28}>
                        <FormattedMessage id="ecLengthMinMaxMessage" values={{ measure: configuratorState.lengthAttribute.replace('cadData-', ''), values: props.services.ejectorConfiguratorService.getLengthValuesAsString(configuratorState) }} />
                      </Message>
                    )}
                    {(ejectorLength
                      && props.services.ejectorConfiguratorService.isValidLengthValue(
                        configuratorState[configuratorState.lengthAttribute], configuratorState
                      )
                    ) && (
                      <Message type="info" iconSize={28}>
                        <FormattedMessage id="ecToleranceMessage" values={{ measure: configuratorState.lengthAttribute.replace('cadData-', ''), tolerance: (props.services.ejectorConfiguratorService.isStandardLength(configuratorState)) ? '+2/0' : '0/+0.02' }} />
                      </Message>
                    )}
                  </div>
                )}
              </div>
              <div>
                <div className="ec-your-configuration">
                  <h2 className="ec-h2"><FormattedMessage id="hcYourConfiguration" /></h2>
                  {getSelectionSummary()}
                  {(!props.services.ejectorConfiguratorService
                    .customConfigIsFlatEjector(configuratorState)
                    && configuratorState[configuratorState.diameterAttribute]) && (
                    <div className="ec-your-config-item">{`${configuratorState.diameterAttribute.replace('cadData-', '')}: ${configuratorState[configuratorState.diameterAttribute]}mm`}</div>
                  )}
                  {(configuratorState['cadData-b']) && (
                    <div className="ec-your-config-item">{`b: ${configuratorState['cadData-b']}mm`}</div>
                  )}
                  {(configuratorState['cadData-a']) && (
                    <div className="ec-your-config-item">{`a: ${configuratorState['cadData-a']}mm`}</div>
                  )}
                  {(configuratorState[configuratorState.lengthAttribute] && lengthIsValid) && (
                    <div className="ec-your-config-item">{`${configuratorState.lengthAttribute.replace('cadData-', '')}: ${configuratorState[configuratorState.lengthAttribute]}mm`}</div>
                  )}
                  {(configuratorState['cadData-l2']) && (
                    <div className="ec-your-config-item">{`l2: ${configuratorState['cadData-l2']}mm`}</div>
                  )}
                  {(props.services.ejectorConfiguratorService.customConfigIsFlatEjector(
                    configuratorState
                  ) && configuratorState[configuratorState.diameterAttribute]) && (
                    <div className="ec-your-config-item">{`${configuratorState.diameterAttribute.replace('cadData-', '')}: ${configuratorState[configuratorState.diameterAttribute]}mm`}</div>
                  )}
                </div>
              </div>
            </div>
          )}
          {((configuratorState.steps.length + 1) === activeStep) && (
            <Fragment>
              {props.services.ejectorConfiguratorService.isSuccessfullyCompleted(
                configuratorState
              ) && (
                <Fragment>
                  <Message type="success" iconSize={28}>
                    <FormattedMessage id="hcInstructionSuccess" />
                  </Message>
                  {(props.services.configService.getGeneralConfig().sales.listPriceInfo.enable
                    && !props.dataStorage.user.firstname) && (
                    <Message type="warning">
                      <FormattedMessage id="listPriceLoginInfo" />
                    </Message>
                  )}
                </Fragment>
              )}
              {(codeCheapestSolution && !codeFastestSolution
                && props.services.ejectorConfiguratorService.isSuccessfullyCompleted(
                  configuratorState
                )) && (
                <Message type="info" iconSize={28}>
                  <FormattedMessage id="ecCustomProductMessage" />
                </Message>
              )}
              {(codeCheapestSolution && codeFastestSolution
                && props.services.ejectorConfiguratorService.isSuccessfullyCompleted(
                  configuratorState
                )) && (
                <Fragment>
                  <Message type="info" iconSize={28}>
                    <FormattedMessage id="ecCustomProductsMessage1" />
                  </Message>
                  <Message type="info" iconSize={28}>
                    <FormattedMessage id="ecCustomProductsMessage2" />
                  </Message>
                </Fragment>
              )}
              {((codeCheapestSolution || codeFastestSolution) && props.services
                .ejectorConfiguratorService.customConfigIsCoated(configuratorState))
              && (
                <Message type="info" iconSize={28}>
                  <FormattedMessage
                    id={props.services.ejectorConfiguratorService.getCoatingI18nId(
                      configuratorState
                    )}
                  />
                </Message>
              )}
              <div className="grid-ec-solutions">
                {(props.services.ejectorConfiguratorService.isSuccessfullyCompleted(
                  configuratorState
                ) && codeCheapestSolution) && (
                  getCustomEjectorHtml(codeCheapestSolution, true)
                )}
                {(props.services.ejectorConfiguratorService.isSuccessfullyCompleted(
                  configuratorState
                ) && codeFastestSolution) && (
                  getCustomEjectorHtml(codeFastestSolution, false)
                )}
              </div>
              {configuratorState.standardProduct && (
                <Fragment>
                  <Message type="info" iconSize={28}>
                    <FormattedMessage id="ecStandardProductMessage" />
                  </Message>
                  <div className="ec-standard-ejector">
                    <Product
                      product={configuratorState.standardProduct}
                      selectHandler={props.services.dataStorageService.addToCart}
                      isPopup={true}
                      showSimilarProducts={false}
                      showFitsToProducts={false}
                      dataStorage={props.dataStorage}
                      locale={props.locale}
                      services={props.services}
                    />
                  </div>
                </Fragment>
              )}
              {(configuratorState.standardProduct || (codeCheapestSolution && props.services
                .ejectorConfiguratorService.isSuccessfullyCompleted(configuratorState))) && (
                <Message type="info" iconSize={28}>
                  <FormattedMessage id="hcInfoCompletedOffer" />
                </Message>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {(activeStep !== 1 || isShowCustomEjectortoGivenCode()) && (
        getResetButton()
      )}
    </div>
  );
}

EjectorConfigurator.propTypes = {
  productTypeSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
