import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Message from '../Message/Message';
import SearchInput from './SearchInput';
import SearchResultTabs from './SearchResultTabs';
import Dropdown from '../Dropdown/Dropdown';
import './Search.css';

export default function SearchDropdown(props) {
  const intl = useIntl();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [dropdownIsExpanded, setDropdownIsExpanded] = useState(false);
  const dropDownContentClasses = 'search-dropdown-content';
  const searchTermChangeHandler = (params) => {
    setDropdownIsExpanded(true);
    setSearchTerm(params.searchTerm);
  };
  const openDropdown = () => {
    setDropdownIsExpanded(true);
  };
  useEffect(() => {
    if (props.type === 'desktop') {
      const eventListener = (event) => {
        const isClickInside = (document.getElementById(props.dropdownId))
          ? document.getElementById(props.dropdownId).contains(event.target) : false;
        if (!isClickInside) {
          setDropdownIsExpanded(false);
        }
      };
      document.addEventListener('click', eventListener);
      return () => {
        document.removeEventListener('click', eventListener);
      };
    }
    return () => {};
  }, []);
  useEffect(() => history.listen(() => {
    setDropdownIsExpanded(false);
  }), [history]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 1) {
        setSearchResults(props.services.searchService.getSearchResults(
          props.dataStorage.products,
          props.dataStorage.productCategories,
          searchTerm,
          props.locale,
          intl
        ));
      } else {
        setSearchResults({});
      }
    }, 350);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.length > 1) {
      setDropdownIsExpanded(false);
      props.services.dataStorageService.setSearchTerm(searchTerm, props.dataStorage);
      history.push(`${props.services.configService.getPageByTranslationCodeAndLocale('search', props.locale).path}?search=${searchTerm}`);
    }
  };
  function getDropdownContent() {
    return (
      <Fragment>
        {(props.type === 'mobile') && (
          <div className="search-dropdown-input-mobile">
            <SearchInput
              value={searchTerm}
              onFocusHandler={() => {}}
              onChangeHandler={searchTermChangeHandler}
              onSubmitHandler={handleSubmit}
            />
          </div>
        )}
        {!searchTerm.length && (<div className="search-dropdown-text center"><FormattedMessage id="searchTermPleaseEnter" /></div>)}
        {(searchTerm.length === 1) && (
          <div className="search-dropdown-message">
            <Message type="warning">
              <FormattedMessage id="searchTermTooShort" />
            </Message>
          </div>
        )}
        {searchTerm.length > 1 && (
          <Fragment>
            <div className="search-dropdown-text">
              <span><strong><FormattedMessage id="searchFor" />: &quot;{searchTerm}&quot;</strong></span>
            </div>
            <SearchResultTabs
              searchTerm={searchTerm}
              searchResults={searchResults}
              isDropdown={true}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
  if (props.type === 'mobile') {
    return (
      <Dropdown
        isListItem={true}
        type="click"
        headingPrefixIcon="search"
        dropdownContentClasses="dropdown__content--search-mobile-main"
      >
        <div className="search-dropdown-content-mobile">
          {getDropdownContent()}
        </div>
      </Dropdown>
    );
  }
  return (
    <li id={props.dropdownId} className="search-dropdown-container">
      <SearchInput
        value={searchTerm}
        onFocusHandler={openDropdown}
        onChangeHandler={searchTermChangeHandler}
        onSubmitHandler={handleSubmit}
      />
      <div className={dropdownIsExpanded ? `${dropDownContentClasses} search-dropdown-content--active` : `${dropDownContentClasses}`}>
        {getDropdownContent()}
      </div>
    </li>
  );
}

SearchDropdown.propTypes = {
  type: PropTypes.string,
  dropdownId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

SearchDropdown.defaultProps = {
  type: 'desktop',
};
