import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Button from './Button';
import RequestProgress from '../Progress/RequestProgress';
import downloadHelper from '../../utils/helper/download-helper';

export default function ButtonDownloadBlob(props) {
  const intl = useIntl();
  const [viewState, setViewState] = useState('form');
  const handleSubmit = (e) => {
    e.preventDefault();
    setViewState('loading');
    props.services.apiService.get(props.apiPath, 'blob').then((res) => {
      setViewState('form');
      downloadHelper.provideBlobDownload(props.filename, res.data);
    });
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} />
      {viewState === 'form' && (
        <form className="form" onSubmit={handleSubmit}>
          <Button classNames={`button${(props.secondaryButton) ? ' button--secondary' : ''}`} type="submit"><FormattedMessage id="cartExport" /></Button>
        </form>
      )}
    </Fragment>
  );
}

ButtonDownloadBlob.propTypes = {
  apiPath: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  secondaryButton: PropTypes.bool,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ButtonDownloadBlob.defaultProps = {
  secondaryButton: true,
};
