import React from 'react';
import PropTypes from 'prop-types';
import ProductCategoryMenuItem from './ProductCategoryMenuItem';

export default function ProductCategoryMenu(props) {
  function getCategoryMenuItems() {
    return props.services.productService.getProductCategoryMenuItemArray(
      props.categoryMenu, props.dataStorage.productCategories, props.sortSettings, props.locale
    ).map((item) => (
      <ProductCategoryMenuItem
        label={item.label}
        href={item.link}
        imgSrc={item.imgSrc}
        key={`produCatMenIt${item.label}`}
      />
    ));
  }
  return (
    <div className="product-category-menu">
      {getCategoryMenuItems()}
    </div>
  );
}

ProductCategoryMenu.propTypes = {
  categoryMenu: PropTypes.objectOf(PropTypes.any).isRequired,
  sortSettings: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ProductCategoryMenu.defaultProps = {
  sortSettings: undefined,
};
