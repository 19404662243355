const fileListSortOptions = [
  {
    name: {
      de: 'Standard',
      en: 'Default',
    },
    preset: true,
  },
  {
    name: {
      de: 'Dateiname',
      en: 'Filename',
    },
    propertyPath: ['name'],
    orders: ['ASC', 'DESC'],
    type: 'String',
  },
];

let listService;

function setDependencies(dependencies) {
  ({ listService } = dependencies);
  return this;
}

function turnFileListIntoObject(filelist) {
  const files = {};
  for (let i = 0; i < filelist.length; i++) {
    files[`file${i}`] = filelist[i];
  }
  return files;
}

function searchFiles(files, searchTerm, logicOperator, locale, sortList) {
  return listService.search(
    files,
    searchTerm,
    logicOperator,
    locale,
    listService.basicSearchCompareFunc,
    [
      {
        path: ['name'],
        exact: 50,
        partial: 5,
      },
      {
        path: ['filetype'],
        exact: 15,
        partial: 5,
      },
      {
        path: ['directory'],
        exact: 15,
        partial: 5,
      },
    ],
    sortList
  );
}

function getFileListSortOptions(intl, locale) {
  return listService.getSortOptions(fileListSortOptions, intl, locale);
}

function getDefaultFileListSortOption(intl, locale) {
  return listService.getDefaultSortOption(
    fileListSortOptions, intl, locale
  );
}

function getFileObjectByFilepath(filepath) {
  const tmpFilepath = filepath.replaceAll('\\', '/');
  const directory = tmpFilepath.substring(0, tmpFilepath.lastIndexOf('/') + 1);
  const tokens = filepath.split('.');
  const filetype = tokens[tokens.length - 1];
  return {
    href: tmpFilepath,
    name: tmpFilepath.replace(directory, ''),
    filetype,
    directory,
    serverFilepath: filepath,
  };
}

export default {
  setDependencies,
  turnFileListIntoObject,
  searchFiles,
  getFileListSortOptions,
  getDefaultFileListSortOption,
  getFileObjectByFilepath,
};
