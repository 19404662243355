import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../Layout/Icon';
import './Stepper.css';

export default function StepperProgressItem(props) {
  const intl = useIntl();
  let leftLine = null;
  let rightLine = null;
  let numberClassNames = 'stepper-progress-item-number';
  let labelClassNames = 'stepper-progress-item-label';
  if (props.number !== 1) {
    leftLine = (<div className="stepper-progress-item-line" />);
  }
  if (!props.isLastStep) {
    rightLine = (<div className="stepper-progress-item-line" />);
  }
  if (props.number > props.activeStep) {
    numberClassNames = `${numberClassNames} stepper-progress-item-number--todo`;
    labelClassNames = `${labelClassNames} stepper-progress-item-label--todo`;
  }
  return (
    <div className="stepper-progress-item">
      <div className="grid-stepper-progress-item-number">
        <div className="stepper-progress-item-line-container-l">
          {leftLine}
        </div>
        <div className={numberClassNames}>
          {(props.number < props.activeStep) ? (<Icon name="check" />) : props.number}
        </div>
        <div className="stepper-progress-item-line-container-r">
          {rightLine}
        </div>
      </div>
      <div className={labelClassNames}>
        {intl.formatMessage({ id: props.labelTranslationId })}
      </div>
    </div>
  );
}

StepperProgressItem.propTypes = {
  number: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool,
  labelTranslationId: PropTypes.string.isRequired,
};

StepperProgressItem.defaultProps = {
  isLastStep: false,
};
