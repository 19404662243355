import React from 'react';
import PropTypes from 'prop-types';

export default function Input(props) {
  return (
    <input
      id={props.id}
      className={props.classNames}
      value={(props.numberFormat)
        ? Number(props.value).toLocaleString(
          props.numberFormat.locale,
          {
            minimumFractionDigits: props.numberFormat.minimumFractionDigits,
            maximumFractionDigits: props.numberFormat.maximumFractionDigits,
          }
        ) : props.value}
      type={props.type}
      disabled={props.disabled}
      readOnly={props.readOnly}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onChange={({ target: { value } }) => props.callback(value)}
    />
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  numberFormat: PropTypes.objectOf(PropTypes.object),
  maxLength: PropTypes.number,
};

Input.defaultProps = {
  classNames: undefined,
  type: 'text',
  disabled: false,
  readOnly: false,
  placeholder: '',
  numberFormat: undefined,
  maxLength: undefined,
};
