import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import UserDetails from '../Account/UserDetails';
import Address from '../Address/Address';
import HorizontalImages from '../Image/HorizontalImages';
import PositionTitle from '../Cart/PositionTitle';
import ListPriceAndDiscount from '../Product/ListPriceAndDiscount';
import './Checkout.css';

export default function OrderDetails(props) {
  const intl = useIntl();
  function getPositions() {
    return props.order.positions.map((position) => (
      <div className="grid-order-position">
        <div className="order-position-image">
          <HorizontalImages
            imgArray={props.services.salesService.getPositionImgArray(
              position.product, props.locale, position.customProductType, intl
            )}
            hasLinks={props.hasProductLinks}
          />
        </div>
        <div className="order-position-product-name">
          <PositionTitle
            product={position.product}
            customProductType={position.customProductType}
            hasProductLinks={props.hasProductLinks}
            locale={props.locale}
            services={props.services}
          />
          <div className="order-position-product-id">(<FormattedMessage id="articleId" />: {position.product.id})</div>
        </div>
        <div className="order-position-qty">
          <span>
            {position.qty} {props.services.salesService.getPositionUnitOfQuantity(
              position.product, props.locale, position.customProductType
            )}
          </span>
        </div>
        <div className="order-position-discount">
          {position.discount && (
            <ListPriceAndDiscount
              listPrice={props.services.salesService.formatPrice(
                position.product.price * (position.qty / position.product.priceUnit), props.locale
              )}
              discount={props.services.salesService.formatPercentage(
                position.discount, props.locale
              )}
            />
          )}
        </div>
        <div className="order-position-price">
          <div className="order-position-price-inner">
            {props.services.salesService.formatPrice(
              position.price, props.locale
            )}
          </div>
        </div>
      </div>
    ));
  }
  function getPriceRow(price, i18nId, additionalClassName, labelElement) {
    return (
      <div className={`order-price-summary-row-grid${(additionalClassName) ? ` ${additionalClassName}` : ''}`}>
        <div className="order-price-summary-label">
          {i18nId && (<span><FormattedMessage id={i18nId} />:</span>)}
          {!i18nId && (labelElement)}
        </div>
        <div className="order-price-summary-price">
          {props.services.salesService.formatPrice(
            price, props.locale
          )}
        </div>
      </div>
    );
  }
  function getDiscountRow(couponCode, discountAmount, discountPercentage) {
    if (couponCode) {
      return (
        getPriceRow(discountAmount, undefined, undefined, (
          <span><FormattedMessage id="coupon" /> ({couponCode}):</span>
        ))
      );
    }
    if (discountPercentage) {
      return (
        getPriceRow(discountAmount, undefined, undefined, (
          <span>
            <FormattedMessage id="discount" /> ({props.services.salesService.formatPercentage(
              discountPercentage, props.locale
            )}):
          </span>
        ))
      );
    }
    return (
      getPriceRow(discountAmount, 'discount')
    );
  }
  return (
    <div className="order-details">
      <div className="grid-order-address">
        {(props.order.id) && (
          <div className="order-info-block">
            <strong><FormattedMessage id="orderId" /></strong><br />
            {props.order.id}<br /><br />
            <strong><FormattedMessage id="date" /></strong><br />
            {props.services.salesService.getFormattedOrderDate(
              props.order.orderDate, props.locale
            )}
            {(props.showStatus) && (
              <Fragment>
                <br /><br /><strong><FormattedMessage id="status" /></strong><br />
                {intl.formatMessage({ id: props.order.status })}
              </Fragment>
            )}
            {(props.showAquisitionIds && props.order.aquisitionIds.order) && (
              <Fragment>
                <br /><br /><strong><FormattedMessage id="aquisitionIdOrder" /></strong><br />
                {props.order.aquisitionIds.order}
              </Fragment>
            )}
            {(props.showAquisitionIds && props.order.aquisitionIds.initial) && (
              <Fragment>
                <br /><br /><strong><FormattedMessage id="aquisitionIdInitial" /></strong><br />
                {props.order.aquisitionIds.initial}
              </Fragment>
            )}
          </div>
        )}
        <div className="order-info-block">
          <strong><FormattedMessage id="buyerInformation" /></strong>
          <UserDetails user={props.order.buyer} /><br />
        </div>
        {(props.order.additionalInformation.customerOrderId) && (
          <div className="order-info-block">
            <strong><FormattedMessage id="yourOrderId" /></strong><br />
            {props.order.additionalInformation.customerOrderId}
          </div>
        )}
        {(props.order.additionalInformation.comment) && (
          <div className="order-info-block">
            <strong><FormattedMessage id="comment" /></strong><br />
            {props.order.additionalInformation.comment}
          </div>
        )}
      </div>
      <div className="grid-order-address">
        <div className="order-info-block">
          <strong><FormattedMessage id="shippingAddress" /></strong>
          <Address address={props.order.shippingAddress} showTaxNo={true} showSettings={false} />
        </div>
        <div className="order-info-block">
          <strong><FormattedMessage id="billingAddress" /></strong>
          <Address address={props.order.billingAddress} showTaxNo={true} showSettings={false} />
        </div>
        <div className="order-info-block">
          <strong><FormattedMessage id="shippingMethod" /></strong>
          <br />{props.order.shippingMethod.name[props.locale]}
          <br />{props.services.salesService.getDeliveryTimeText(
            props.order.shippingMethod.deliveryTimeInfo, intl
          )}
        </div>
        <div className="order-info-block">
          <strong><FormattedMessage id="paymentMethod" /></strong>
          <br />{props.order.paymentMethod.name[props.locale]}
        </div>
      </div>
      <div className="order-positions-heading"><strong><FormattedMessage id="positions" />:</strong></div>
      <div className="grid-order-positions">
        {getPositions()}
      </div>
      <div className="order-price-summary-container">
        <div className="order-price-summary">
          {getPriceRow(props.order.price.itemTotal, 'goodsValue')}
          {(props.order.price.discountAmount !== undefined) && (
            getDiscountRow(
              props.order.price.couponCode,
              props.order.price.discountAmount,
              props.order.price.discountPercentage
            )
          )}
          {getPriceRow(props.order.shippingMethod.price, 'shipping')}
          {(props.order.paymentMethod.fee > 0) && (
            getPriceRow(props.order.paymentMethod.fee, undefined, undefined, (
              <span><FormattedMessage id="fee" /> {props.order.paymentMethod.name[props.locale]}:</span>
            ))
          )}
          {getPriceRow(props.order.price.subtotal, 'netSum', 'order-price-summary-row--bt')}
          {getPriceRow(props.order.price.vat, undefined, undefined, (
            <span><FormattedMessage id="vat" /> ({(props.order.price.vatRate).toLocaleString(props.locale, { style: 'percent' })}):</span>
          ))}
          {getPriceRow(props.order.price.total, 'grossSum', 'order-price-summary-row--bt-s')}
        </div>
      </div>
    </div>
  );
}

OrderDetails.propTypes = {
  order: PropTypes.objectOf(PropTypes.any).isRequired,
  hasProductLinks: PropTypes.bool,
  showStatus: PropTypes.bool,
  showAquisitionIds: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

OrderDetails.defaultProps = {
  hasProductLinks: true,
  showStatus: false,
  showAquisitionIds: false,
};
