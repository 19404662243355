import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Input from './Input';
import FormInput from './FormInput';
import InvalidInputMessage from './InvalidInputMessage';
import './Form.css';

export default function FormNewPassword(props) {
  const intl = useIntl();
  return (
    <Fragment>
      <FormInput
        id="password"
        label={(props.newPassword) ? 'newPassword' : 'password'}
        type="password"
        value={props.password}
        showIsRequired={!props.newPassword}
        callback={props.passwordChangeHandler}
        isValid={props.passwordIsValid}
        errorMessageId="invalidPasswordMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      <div className="grid-form__label">
        <label htmlFor="passwordConfirmation">
          {
            !props.newPassword
              ? (
                <Fragment>
                  <FormattedMessage id={(props.newPassword) ? 'newPasswordConfirmation' : 'passwordConfirmation'} /><span className="is-required-asterisk"> *</span>
                </Fragment>
              ) : <FormattedMessage id={(props.newPassword) ? 'newPasswordConfirmation' : 'passwordConfirmation'} />
          }
        </label>
      </div>
      <div className="grid-form__input">
        <Input
          id="passwordConfirmation"
          type="password"
          classNames={((props.password === props.passwordConfirmation && props.passwordConfirmation !== '') || (props.passwordConfirmation === '' && !props.submitButtonIsClicked)) ? undefined : 'invalid-value'}
          callback={props.passwordConfirmationChangeHandler}
          value={props.passwordConfirmation}
        />
      </div>
      <InvalidInputMessage
        display={(props.password !== props.passwordConfirmation && props.passwordConfirmation !== '') || (props.passwordConfirmation === '' && props.submitButtonIsClicked)}
        message={intl.formatMessage({ id: 'passwordMismatchMessage' })}
      />
    </Fragment>
  );
}

FormNewPassword.propTypes = {
  password: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
  passwordChangeHandler: PropTypes.func.isRequired,
  passwordConfirmationChangeHandler: PropTypes.func.isRequired,
  passwordIsValid: PropTypes.bool.isRequired,
  newPassword: PropTypes.bool,
  submitButtonIsClicked: PropTypes.bool.isRequired,
};

FormNewPassword.defaultProps = {
  newPassword: false,
};
