import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import './Layout.css';

export default function ContentGrid(props) {
  function getItems() {
    return props.items.map((item) => {
      props.services.urlService.enrichPageBuilderParam(item, props.locale, props.dataStorage);
      return (
        <section className="content-grid-item links-in-primary-font-color">
          <CustomLink
            href={item.href || '#'}
            isExternal={item.hrefIsExternal}
            onClickHandler={item.clickHandler || undefined}
            onClickHandlerParams={item.clickHandlerParams || undefined}
          >
            <img
              src={item.imgSrc}
              alt={item.imgAlt || item.heading}
              className="content-grid-item-image"
            />
            <div className="content-grid-item-heading">{item.heading}</div>
            <div className="content-grid-item-text">{item.text}</div>
          </CustomLink>
        </section>
      );
    });
  }
  return (
    <div className={`content-grid ${props.classNames}`}>
      {getItems()}
    </div>
  );
}

ContentGrid.propTypes = {
  classNames: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ContentGrid.defaultProps = {
  classNames: undefined,
};
