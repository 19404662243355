import React from 'react';
import PropTypes from 'prop-types';
import PageWrapper from '../Wrapper/PageWrapper';
import LocationList from '../Location/LocationList';
import H1 from '../Heading/H1';
import './Page.css';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';

export default function Locations(props) {
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <DefaultHeadingBar
        locale={props.locale}
        services={props.services}
      />
      <LocationList
        locations={props.services.configService.getGeneralConfig().brand.locations}
        locale={props.locale}
      />
    </PageWrapper>
  );
}

Locations.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
