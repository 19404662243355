import React, { Fragment } from 'react';
import Message from '../Message/Message';
import SimpleTextWithLink from '../Layout/SimpleTextWithLink';

export default function ProductAdditionalInfoMsgs({ messages, locale, services, dataStorage }) {
  function getInfoMsgBox(message) {
    if (message.text) {
      return (
        <Message type="info">{message.text}</Message>
      );
    }
    return (
      <Message type="info">
        <SimpleTextWithLink
          textparts={message}
          locale={locale}
          services={services}
          dataStorage={dataStorage}
        />
      </Message>
    );
  }
  return (
    <Fragment>
      {messages.map((message) => getInfoMsgBox(message))}
    </Fragment>
  );
}
