import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import StepperProgressBar from '../Progress/StepperProgressBar';
import Message from '../Message/Message';
import AutomationPartSelection from './AutomationPartSelection';
import Summary from './Summary';
import Button from '../Button/Button';
import FormBuyer from '../Form/FormBuyer';
import Validator from '../Form/Validator';
import '../EjectorConfigurator/EjectorConfigurator.css';
import './AutomationConfigurator.css';

export default function AutomationConfigurator(props) {
  const intl = useIntl();
  const initialConfiguratorState = props.services.automationConfiguratorService
    .getInitialConfiguratorState();
  const [steps, setSteps] = useState(
    props.services.automationConfiguratorService.getConfigurationSteps(
      initialConfiguratorState.steps
    )
  );
  const [secondarySteps, setSecondarySteps] = useState(
    props.services.automationConfiguratorService.getConfigurationSecondarySteps(0)
  );
  const [activeStep, setActiveStep] = useState(1);
  const [activeSecondaryStep, setSecondaryActiveStep] = useState(0);
  const [sortSettings, setSortSettings] = useState({});
  const [filterSettings, setFilterSettings] = useState({});
  const [automationConfiguration, setAutomationConfiguration] = useState({});
  const [buyButtonClicked, setBuyButtonClicked] = useState(false);
  const [rentBuyButtonClicked, setRentBuyButtonClicked] = useState(false);
  const [leasingButtonClicked, setLeasingButtonClicked] = useState(false);
  const [showBuySuccessMsg, setShowBuySuccessMsg] = useState(false);
  const [showRentBuySuccessMsg, setShowRentBuySuccessMsg] = useState(false);
  const [showLeasingSuccessMsg, setShowLeasingSuccessMsg] = useState(false);
  const [cartButtonClicked, setCartButtonClicked] = useState(false);
  const [company, setCompany] = useState('');
  const [customerId, setCustomerId] = useState(props.dataStorage.user.customerId);
  const [salutation, setSalutation] = useState(props.dataStorage.user.salutation || 'mr');
  const [firstname, setFirstname] = useState(props.dataStorage.user.firstname);
  const [lastname, setLastname] = useState(props.dataStorage.user.lastname);
  const [email, setEmail] = useState(props.dataStorage.user.email);
  const [companyIsValid, setCompanyIsValid] = useState(true);
  const [customerIdIsValid, setCustomerIdIsValid] = useState(true);
  const [firstnameIsValid, setFirstnameIsValid] = useState(true);
  const [lastnameIsValid, setLastnameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const companyChangeHandler = (newCompany) => {
    setCompany(newCompany);
    setCompanyIsValid(Validator.isValidCompanyName(newCompany));
  };
  const customerIdChangeHandler = (newCustomerId) => {
    setCustomerId(newCustomerId);
    setCustomerIdIsValid(Validator.isValidCustomerId(newCustomerId, true));
  };
  const salutationChangeHandler = (newSalutation) => {
    setSalutation(newSalutation);
  };
  const firstnameChangeHandler = (newFirstname) => {
    setFirstname(newFirstname);
    setFirstnameIsValid(Validator.isValidPersonName(newFirstname));
  };
  const lastnameChangeHandler = (newLastname) => {
    setLastname(newLastname);
    setLastnameIsValid(Validator.isValidPersonName(newLastname));
  };
  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
    setEmailIsValid(Validator.isValidEmailAddress(newEmail));
  };
  const filterChangeHandler = (newFilterSettings) => {
    setFilterSettings(newFilterSettings);
  };
  const sortChangeHandler = (params) => {
    setSortSettings(params.sortOption);
  };
  const resetHandler = () => {
    setActiveStep(1);
    setSecondarySteps(
      props.services.automationConfiguratorService.getConfigurationSecondarySteps(0)
    );
    setAutomationConfiguration({});
    setBuyButtonClicked(false);
    setRentBuyButtonClicked(false);
    setLeasingButtonClicked(false);
    setShowBuySuccessMsg(false);
    setShowRentBuySuccessMsg(false);
    setShowLeasingSuccessMsg(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  const nextStep = () => {
    if (activeSecondaryStep + 1 === secondarySteps.length) {
      if (activeStep < steps.length) {
        setSecondarySteps(
          props.services.automationConfiguratorService.getConfigurationSecondarySteps(activeStep)
        );
        setSecondaryActiveStep(0);
      }
      setActiveStep(activeStep + 1);
    } else {
      setSecondaryActiveStep(activeSecondaryStep + 1);
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  const back = () => {
    const previousStepInfo = props.services.automationConfiguratorService.getPreviousStepInfo(
      activeStep - 1, secondarySteps[activeSecondaryStep]
    );
    if (previousStepInfo.mainStepBack) {
      const secondaryStepsConfig = props.services.automationConfiguratorService
        .getConfigurationSecondarySteps(activeStep - 2);
      setSecondarySteps(secondaryStepsConfig);
      setActiveStep(activeStep - 1);
      setSecondaryActiveStep(secondaryStepsConfig.length - 1);
    } else {
      setSecondaryActiveStep(activeSecondaryStep - 1);
    }
    if (automationConfiguration[previousStepInfo.mainStepId]
      && automationConfiguration[previousStepInfo.mainStepId][previousStepInfo.secondaryStepId]
    ) {
      const newAutomationConfiguration = JSON.parse(JSON.stringify(automationConfiguration));
      delete newAutomationConfiguration[previousStepInfo
        .mainStepId][previousStepInfo.secondaryStepId];
      if (Object.keys(newAutomationConfiguration[previousStepInfo.mainStepId]).length === 0) {
        delete newAutomationConfiguration[previousStepInfo.mainStepId];
      }
      setAutomationConfiguration(newAutomationConfiguration);
    }
  };
  const automationConfigurationChangeHandler = (params) => {
    setAutomationConfiguration(params.automationConfiguration);
    nextStep();
  };
  const addToCartHandler = (e) => {
    e.preventDefault();
    setCartButtonClicked(false);
    props.services.automationConfiguratorService.getProductsFromConfiguration(
      automationConfiguration
    ).forEach((position) => props.services.dataStorageService.addToCart(
      {
        product: position.product,
        orderQuantity: Number(position.qty),
        dataStorage: props.dataStorage,
      }
    ));
    setCartButtonClicked(true);
    setTimeout(() => {
      setCartButtonClicked(false);
    }, 2000);
  };
  function getOffer(type, rate, purchasePrice, months) {
    const positions = props.services.automationConfiguratorService.getProductsFromConfiguration(
      automationConfiguration
    );
    const cartPriceInfo = props.services.salesService.getCartPriceInfo(
      positions, undefined, undefined, props.locale, props.dataStorage, intl
    );
    const readyForShipmentInDays = props.services.automationConfiguratorService.getProcurementTime(
      positions
    );
    return {
      type,
      buyer: (props.dataStorage.user.firstname) ? props.dataStorage.user : {
        company,
        customerId: customerId || '',
        salutation,
        firstname,
        lastname,
        email,
      },
      positions: props.services.automationConfiguratorService.getOrderPositions(
        positions, props.locale
      ),
      readyForShipmentMsg: (readyForShipmentInDays === 0)
        ? intl.formatMessage({ id: 'deliverableImmediately' })
        : intl.formatMessage({ id: 'readyForShipmentWithinXDays' }, { days: readyForShipmentInDays }),
      price: {
        itemTotal: cartPriceInfo.itemTotal,
        shoppingCartValue: cartPriceInfo.shoppingCartValue,
        rate,
        purchasePrice,
        months,
      },
    };
  }
  const placeBuyOfferHandler = (e) => {
    e.preventDefault();
    setBuyButtonClicked(true);
    props.services.apiService.post('/order/offer/guest/new', {
      offer: getOffer(),
      locale: props.locale,
    }).then(() => {
      setShowBuySuccessMsg(true);
    });
  };
  const placeRentPurchaseOfferHandler = (e) => {
    e.preventDefault();
    setRentBuyButtonClicked(true);
    const rentPurchasePriceInfo = props.services.automationConfiguratorService
      .getRentPurchasePriceInfo(automationConfiguration, props.dataStorage, props.locale);
    props.services.apiService.post('/order/offer/guest/new', {
      offer: getOffer('rentalPurchaseOffer', rentPurchasePriceInfo.rate, rentPurchasePriceInfo.price, 6),
      locale: props.locale,
    }).then(() => {
      setShowRentBuySuccessMsg(true);
    });
  };
  const placeLeasingOfferHandler = (e) => {
    e.preventDefault();
    setLeasingButtonClicked(true);
    props.services.apiService.post('/order/offer/guest/new', {
      offer: getOffer('leasingInquiry'),
      locale: props.locale,
    }).then(() => {
      setShowLeasingSuccessMsg(true);
    });
  };
  function offerButtonsEnabled() {
    return props.dataStorage.user.firstname !== undefined
      || (companyIsValid && company
        && customerIdIsValid
        && firstnameIsValid && firstname
        && lastnameIsValid && lastname
        && emailIsValid && email
      );
  }
  function getSummary() {
    return (
      <Summary
        locale={props.locale}
        automationConfiguration={automationConfiguration}
        services={props.services}
        dataStorage={props.dataStorage}
      />
    );
  }
  function getMessage(i18nId) {
    return (
      <div className={`add-product-message ${(i18nId === 'acOptionBuyNowSuccessMsg ') ? 'add-product-message-fade-out' : ''}center`}>
        <Message type="success">
          <FormattedMessage id={i18nId} />
        </Message>
      </div>
    );
  }
  function getPurchaseOption(
    headingI18nId,
    textI18nId,
    textI18nValues,
    buttonI18nId,
    successMsgI18nId,
    enabled,
    onClick,
    buttonClicked,
    showSuccessMessage
  ) {
    return (
      <div className="ac-purchase-option">
        <div className="ac-purchase-option-heading center"><FormattedMessage id={headingI18nId} /></div>
        <div className="ac-purchase-option-text"><FormattedMessage id={textI18nId} values={textI18nValues} /></div>
        <div className="center">
          <Button
            classNames="button"
            disabled={showSuccessMessage || !enabled || buttonClicked}
            onClick={onClick}
          >
            <FormattedMessage id={buttonI18nId} />
          </Button>
        </div>
        {showSuccessMessage && getMessage(successMsgI18nId)}
      </div>
    );
  }
  return (
    <div className="automation-configurator">
      {secondarySteps[activeSecondaryStep] === 'industrialRobot' && (
        <div className="grid-ac-instruction-start center-vertically">
          <div>
            <img
              src={`/img/Automationkonfigurator-hero-${props.locale}.png`}
              alt={intl.formatMessage({ id: 'acAutomation' })}
            />
          </div>
          <div>
            <Message type="info" iconSize={28}>
              <FormattedMessage id="acInstructionStart1" />
            </Message>
            <Message type="info" iconSize={28}>
              <FormattedMessage id="acInstructionStart2" />
            </Message>
            <Message type="info" iconSize={28}>
              <FormattedMessage id="acInstructionStart3" />
            </Message>
          </div>
        </div>
      )}
      <StepperProgressBar steps={steps} activeStep={activeStep} />
      {(activeStep <= steps.length) && (
        <div className={(secondarySteps[activeSecondaryStep] === 'industrialRobot') ? 'grid-automation-configurator-step' : 'grid-automation-configurator-step-summary'}>
          <div>
            <div className="ac-heading-main-step center"><FormattedMessage id="step" /> {activeStep}: <FormattedMessage id={steps[activeStep - 1].labelTranslationId} /></div>
            <h2 className="ac-heading-secondary-step ec-h2 center"><FormattedMessage id="selection" /> <FormattedMessage id={props.services.automationConfiguratorService.getI18nIdSecondaryStep(secondarySteps[activeSecondaryStep])} /></h2>
            <AutomationPartSelection
              automationConfiguration={automationConfiguration}
              mainStep={props.services.automationConfiguratorService.getCurrentStepId(activeStep)}
              secondaryStep={secondarySteps[activeSecondaryStep]}
              automationProducts={props.automationProducts}
              handlers={{
                filterChangeHandler,
                sortChangeHandler,
                skipStep: nextStep,
                back,
                automationConfigurationChangeHandler,
              }}
              locale={props.locale}
              dataStorage={props.dataStorage}
              services={props.services}
            />
          </div>
          <div className={(secondarySteps[activeSecondaryStep] === 'industrialRobot') ? 'display-none' : 'ac-summary'}>
            <div className="ac-summary-container">
              {getSummary()}
            </div>
          </div>
        </div>
      )}
      {(activeStep > steps.length) && (
        <div className="grid-automation-configurator-completed">
          <div>
            <div className="center">
              <Message type="success">
                <FormattedMessage id="hcInstructionSuccess" />
              </Message>
            </div>
            <div className="ac-summary-container">
              {getSummary()}
            </div>
          </div>
          <div>
            {(!props.dataStorage.user.firstname) && (
              <div className="ac-purchase-option ac-user-data-imput-container">
                <Message type="warning">
                  <FormattedMessage id="acPleaseLogin" />
                </Message>
                <div className="grid-form">
                  <FormBuyer
                    showInvoiceEmail={false}
                    company={company}
                    customerId={customerId}
                    salutation={salutation}
                    firstname={firstname}
                    lastname={lastname}
                    email={email}
                    companyChangeHandler={companyChangeHandler}
                    customerIdChangeHandler={customerIdChangeHandler}
                    salutationChangeHandler={salutationChangeHandler}
                    firstnameChangeHandler={firstnameChangeHandler}
                    lastnameChangeHandler={lastnameChangeHandler}
                    emailChangeHandler={emailChangeHandler}
                    companyIsValid={companyIsValid}
                    customerIdIsValid={customerIdIsValid}
                    firstnameIsValid={firstnameIsValid}
                    lastnameIsValid={lastnameIsValid}
                    emailIsValid={emailIsValid}
                    submitButtonIsClicked={buyButtonClicked
                      || rentBuyButtonClicked
                      || leasingButtonClicked}
                  />
                </div>
              </div>
            )}
            {getPurchaseOption('acOptionBuyHeading', 'acOptionBuyText', {}, 'acOptionBuyButton', 'acOptionBuySuccessMsg', offerButtonsEnabled(), placeBuyOfferHandler, cartButtonClicked, showBuySuccessMsg)}
            {getPurchaseOption(
              'acOptionRentBuyHeading',
              'acOptionRentBuyText',
              props.services.automationConfiguratorService.getRentPurchasePriceInfo(
                automationConfiguration, props.dataStorage, props.locale
              ),
              'acOptionRentBuyButton',
              'acOptionRentBuySuccessMsg',
              offerButtonsEnabled(),
              placeRentPurchaseOfferHandler,
              rentBuyButtonClicked,
              showRentBuySuccessMsg
            )}
            {(props.services.automationConfiguratorService.getNetPriceInfo(
              automationConfiguration, props.dataStorage, props.locale
            ).price >= 30000) && (
              getPurchaseOption('acOptionLeasingHeading', 'acOptionLeasingText', {}, 'acOptionLeasingButton', 'acOptionLeasingSuccessMsg', offerButtonsEnabled(), placeLeasingOfferHandler, leasingButtonClicked, showLeasingSuccessMsg)
            )}
            {getPurchaseOption('acOptionBuyNowHeading', 'acOptionBuyNowText', {}, 'acOptionBuyNowButton', 'acOptionBuyNowSuccessMsg', true, addToCartHandler, cartButtonClicked, cartButtonClicked)}
            <Button
              classNames="button button--secondary"
              onClick={resetHandler}
            >
              <FormattedMessage id="acReset" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

AutomationConfigurator.propTypes = {
  automationProducts: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
