import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import RequestProgress from '../Progress/RequestProgress';

export default function SelfServiceConfirmation(props) {
  const intl = useIntl();
  const [viewState, setViewState] = useState('loading');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.ssc) {
      const reqParams = {
        code: query.ssc,
        locale: props.locale,
      };
      if (props.optionalParams) {
        Object.keys(props.optionalParams).forEach((key) => {
          reqParams[key] = props.optionalParams[key];
        });
      }
      props.services.apiService.post(props.apiPath, reqParams).then((res) => {
        setViewState('result');
        if (res.data) {
          setResultMessageType('success');
          setResultMessageText(props.successMsg);
          if (props.logoutAfterServerResponse) {
            props.services.dataStorageService.logout(props.dataStorage);
            props.services.apiService.post('/user/logout', {});
          }
        } else {
          setResultMessageType('error');
          setResultMessageText(props.errorMsg);
        }
      });
    } else {
      setViewState('result');
      setResultMessageType('error');
      setResultMessageText(props.errorMsg);
    }
  }, []);
  return (
    <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
  );
}

SelfServiceConfirmation.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  logoutAfterServerResponse: PropTypes.bool,
  apiPath: PropTypes.string.isRequired,
  successMsg: PropTypes.oneOfType([
    PropTypes.any,
  ]),
  errorMsg: PropTypes.string.isRequired,
  optionalParams: PropTypes.objectOf(PropTypes.any),
};

SelfServiceConfirmation.defaultProps = {
  logoutAfterServerResponse: false,
  successMsg: undefined,
  optionalParams: undefined,
};
