import React, { Fragment } from 'react';
import CustomLink from '../Link/CustomLink';

export default function SimpleTextWithLink({ textparts, locale, services, dataStorage }) {
  const linkInfo = JSON.parse(JSON.stringify(textparts.linkInfo));
  services.urlService.enrichPageBuilderParam(linkInfo, locale, dataStorage);
  return (
    <Fragment>
      {textparts.textBefore}
      <CustomLink
        href={linkInfo.href}
        isExternal={linkInfo.isExternal}
      >
        {linkInfo.label}
      </CustomLink>{textparts.textAfter}
    </Fragment>
  );
}
