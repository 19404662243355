import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Dropdown from '../Dropdown/Dropdown';
import VeMeDropdown from '../Dropdown/VerticalMenuDropdown/VeMeDropdown';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import CartDropdown from '../Cart/CartDropdown';
import SearchDropdown from '../Search/SearchDropdown';
import SingleMenuItem from './SingleMenuItem';
import FlatSubMenu from './FlatSubMenu';
import LinkImage from '../Image/LinkImage';
import CustomLink from '../Link/CustomLink';
import './Menu.css';

export default function Menu(props) {
  const intl = useIntl();
  function addHeadingToVeMeDropdownItems(menuItem) {
    const newItems = menuItem.subitems.map((item) => item);
    newItems.unshift({
      label: intl.formatMessage({ id: 'overview' }),
      link: menuItem.link,
    });
    return newItems;
  }
  const menuItems = props.items.map((menuItem) => {
    if (props.type === 'flat') {
      return (
        <FlatSubMenu
          classNames={props.classNames}
          heading={menuItem.label}
          items={menuItem.subitems}
          key={menuItem.label}
        />
      );
    } else if (menuItem.subitems && !menuItem.hasDropdownLoginModal) {
      if (props.type === 'verticalMenu') {
        return (
          <VeMeDropdown
            isListItem={true}
            expandedMenuPath={props.expandedMenuPath}
            setExpandedMenuPath={props.setExpandedMenuPath}
            layout={menuItem.layout}
            heading={menuItem.label}
            headingPrefixIcon={menuItem.headingPrefixIcon}
            items={(menuItem.link) ? addHeadingToVeMeDropdownItems(menuItem) : menuItem.subitems}
            key={menuItem.label}
          />
        );
      }
      return (
        <Dropdown
          isListItem={true}
          type={(props.type === 'dropdownHover') ? 'hover' : 'click'}
          expandedMenuPath={props.expandedMenuPath}
          setExpandedMenuPath={props.setExpandedMenuPath}
          layout={menuItem.layout}
          heading={menuItem.label}
          headingLink={menuItem.link}
          headingPrefixIcon={menuItem.headingPrefixIcon}
          items={menuItem.subitems}
          key={menuItem.label}
        >
          {menuItem.image && (
            <Fragment>
              <LinkImage
                href={menuItem.image.link}
                src={menuItem.image.imgSrc}
                alt={menuItem.image.label}
              />
              <div className="dropdown-menu-image-label">
                <CustomLink
                  href={menuItem.image.link}
                >{menuItem.image.label}
                </CustomLink>
              </div>
            </Fragment>
          )}
        </Dropdown>
      );
    } else if (menuItem.languages) {
      return (
        <LanguageSwitcher
          type="hover"
          useCodeAsLabel={true}
          locale={props.locale}
          setLocale={props.setLocale}
          key="DesktopMenu-LanguageSwitcher"
          dataStorage={props.dataStorage}
          services={props.services}
        />
      );
    } else if (menuItem.cart) {
      return (
        <CartDropdown
          type={menuItem.cart}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
          key="cartdropdown"
        />
      );
    } else if (menuItem.search === 'desktop') {
      return (
        <SearchDropdown
          dropdownId="mainsearch"
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
          key="mainsearch"
        />
      );
    } else if (menuItem.search === 'mobile') {
      return (
        <SearchDropdown
          dropdownId="mobilemainsearch"
          type="mobile"
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
          key="mobilemainsearch"
        />
      );
    }
    return (
      <SingleMenuItem
        classNames={props.classNames.sli}
        link={menuItem.link}
        target={menuItem.target}
        label={menuItem.label}
        onClickHandler={menuItem.onClickHandler}
        onClickHandlerParams={menuItem.onClickHandlerParams}
        key={menuItem.label}
      />
    );
  });
  return (
    <nav className={props.classNames.nav}>
      <ul className={props.classNames.ul}>
        {menuItems}
      </ul>
    </nav>
  );
}

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  classNames: PropTypes.objectOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  locale: PropTypes.string,
  setLocale: PropTypes.func,
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func,
  dataStorage: PropTypes.objectOf(PropTypes.any),
  services: PropTypes.objectOf(PropTypes.object),
};

Menu.defaultProps = {
  locale: undefined,
  setLocale: undefined,
  expandedMenuPath: null,
  setExpandedMenuPath: undefined,
  dataStorage: undefined,
  services: null,
};
