import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import FormInput from '../Form/FormInput';
import Icon from '../Layout/Icon';
import './Product.css';

export default function ProductOtyInput(props) {
  if (props.incrementHandler) {
    return (
      <div className={`grid-product-qty-buttons center${(props.showQuantity) ? '' : ' display-none'}`}>
        <div className="product-qty-input-button">
          <Button
            classNames="button button--small button--secondary"
            onClick={(e) => props.decrementHandler(e)}
            disabled={props.disableDecrementButton}
          >
            <Icon name="angle-down" viewBox="2 2 18 18" />
          </Button>
        </div>
        <div className="product-qty-input-unit">
          <span>
            {(props.numberFormat)
              ? Number(props.value).toLocaleString(
                props.numberFormat.locale,
                {
                  minimumFractionDigits: props.numberFormat.minimumFractionDigits,
                  maximumFractionDigits: props.numberFormat.maximumFractionDigits,
                }
              ) : props.value} {props.unitOfQuantity}
          </span>
        </div>
        <div className="product-qty-input-button">
          <Button
            classNames="button button--small button--secondary"
            onClick={(e) => props.incrementHandler(e)}
          >
            <Icon name="angle-up" viewBox="2 2 18 18" />
          </Button>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <div className={`grid-form product-qty-input${(props.showQuantity) ? '' : ' display-none'}`}>
        <FormInput
          id={props.id}
          value={props.value}
          callback={props.qtyChangeHandler}
          isValid
          errorMessageId="invalidOrderQuantityInteger"
          noErrorMessageIfEmpty={true}
          labelIsInvisble={true}
          numberFormat={props.numberFormat}
        />
      </div>
      <div className={`product-qty-input-unit${(props.showQuantity) ? '' : ' display-none'}`}>
        {props.unitOfQuantity}
      </div>
    </Fragment>
  );
}

ProductOtyInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  unitOfQuantity: PropTypes.string.isRequired,
  qtyChangeHandler: PropTypes.func.isRequired,
  incrementHandler: PropTypes.func,
  decrementHandler: PropTypes.func,
  disableDecrementButton: PropTypes.bool,
  numberFormat: PropTypes.objectOf(PropTypes.any),
  showQuantity: PropTypes.bool,
};

ProductOtyInput.defaultProps = {
  incrementHandler: undefined,
  decrementHandler: undefined,
  disableDecrementButton: true,
  numberFormat: undefined,
  showQuantity: true,
};
