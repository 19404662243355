import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { v4 as uuid } from 'uuid';
import Routes from './components/Routing/Routes';
import ScrollToTop from './components/Routing/ScrollToTop';
import Spinner from './components/Progress/Spinner';
import configService from './services/config';
import apiService from './services/api';
import dataStorageService from './services/dataStorage';
import listService from './services/list';
import searchService from './services/search';
import productService from './services/product';
import salesService from './services/sales';
import adminService from './services/admin';
import urlService from './services/url';
import fileService from './services/file';
import i18nService from './services/i18n';
import hoseConfiguratorService from './services/hoseConfigurator';
import ejectorConfiguratorService from './services/ejectorConfigurator';
import automationConfiguratorService from './services/automationConfigurator';
import en from './i18n/en.json';
import de from './i18n/de.json';
import './App.css';

const messages = { de, en };

export default function App() {
  const [locale, setLocale] = useState('');
  const [dataStorage, setDataStorage] = useState({
    initialDataLoaded: false,
    user: {},
    order: [],
    adminUser: {},
    products: [],
  });
  function changeLocaleHandler(params) {
    setLocale(params.locale);
  }
  function changeDataStorageHandler(newData) {
    setDataStorage(newData);
  }
  useEffect(() => {
    configService.setGeneralConfig({});
    apiService.setDependencies({ configService });
    productService.setDependencies({ configService, apiService, listService });
    hoseConfiguratorService.setDependencies({ configService, apiService, productService });
    ejectorConfiguratorService.setDependencies({ configService, productService });
    automationConfiguratorService.setDependencies({ configService, productService });
    salesService.setDependencies({
      configService,
      productService,
      customProductServices: {
        [hoseConfiguratorService.getConfigurationType()]: hoseConfiguratorService,
        [ejectorConfiguratorService.getConfigurationType()]: ejectorConfiguratorService,
      },
    });
    adminService.setDependencies({ configService, listService, salesService });
    urlService.setDependencies({ configService, productService, salesService });
    fileService.setDependencies({ listService });
    i18nService.setDependencies({ configService, productService });
    searchService.setDependencies({
      configService, productService, listService, apiService,
    });
    dataStorageService.setDependencies({ apiService, setDataStorage, uniqueIdentifier: uuid });
    apiService.post('/facade/frontend/init', {}).then((res) => {
      configService.setGeneralConfig(res.data.config);
      if (configService.visitorStatsIsEnabled()) {
        const aqu = new URLSearchParams(window.location.search).get(res.data.config.aquParaName);
        if (!localStorage.int) {
          localStorage.int = uuid();
          if (aqu) {
            localStorage.aqu = aqu;
          }
        }
        if (!sessionStorage.aqu && aqu) {
          sessionStorage.aqu = aqu;
        }
      }
      configService.setGeneralConfig(res.data.config);
      apiService.setInitLevel();
      configService.setPages(res.data.webpages);
      configService.setFrontendMenus(
        productService.enrichFrontendMenus(res.data.menus, res.data.productCategories)
      );
      setLocale(configService.getLanguageCodeByPath(window.location.pathname));
      setDataStorage(dataStorageService.getInitialDataStorage(
        res.data, res.data.config.sales.cartTTL
      ));
    });
  }, []);
  if (dataStorage.initialDataLoaded && locale) {
    return (
      <Router>
        <ScrollToTop />
        <IntlProvider
          locale={locale}
          messages={messages[locale]}
          defaultLocale={configService.getGeneralConfig().i18n.defaultLocale}
        >
          <div className="App">
            <Suspense fallback={<div className="center"><Spinner label="Loading" /></div>}>
              <Routes
                services={{
                  configService,
                  apiService,
                  dataStorageService,
                  listService,
                  searchService,
                  productService,
                  salesService,
                  urlService,
                  i18nService,
                  hoseConfiguratorService,
                  ejectorConfiguratorService,
                  automationConfiguratorService,
                  adminService,
                  fileService,
                }}
                locale={locale}
                setLocale={changeLocaleHandler}
                dataStorage={dataStorage}
                setDataStorage={changeDataStorageHandler}
              />
            </Suspense>
          </div>
        </IntlProvider>
      </Router>
    );
  }
  return (
    <div className="initial-app-load-container center"><Spinner label="Loading" /></div>
  );
}
