import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import './Product.css';

export default function ProductAttributeGroup(props) {
  const intl = useIntl();
  function getAttributes() {
    const labelValueObjArr = [];
    props.attributeIds.forEach((attributeId) => {
      const attributeData = props.services.productService.getAttributeData(
        props.product, attributeId, props.locale, intl
      );
      const value = props.services.productService.attributeValuesToString(
        attributeData.values,
        attributeData.measuringUnit,
        attributeData.noMeasuringUnitIfValueContains,
        intl
      );
      if (value) {
        labelValueObjArr.push({ label: attributeData.label, value });
      }
    });
    return labelValueObjArr.map((labelValueObj) => (
      <Fragment>
        <div className="product-attribute-label">
          {labelValueObj.label}:
        </div>
        <div className="product-attribute-values">
          {labelValueObj.value}
        </div>
      </Fragment>
    ));
  }
  return (
    <div className="product-attribute-list">
      {props.heading && (<strong>{props.heading}</strong>)}
      {getAttributes()}
    </div>
  );
}

ProductAttributeGroup.propTypes = {
  heading: PropTypes.string,
  product: PropTypes.arrayOf(PropTypes.object).isRequired,
  attributeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ProductAttributeGroup.defaultProps = {
  heading: undefined,
};
