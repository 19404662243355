import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import './Product.css';

export default function ProductCategoryListItem(props) {
  return (
    <div className="product-category-list-item">
      <CustomLink
        href={props.data.href}
      >
        <div className="grid-product-category-list-item">
          <img
            src={props.data.imgSrc}
            alt={props.data.label}
          />
          <div className="product-category-list-item__name center-vertically">{props.data.label}</div>
        </div>
      </CustomLink>
    </div>
  );
}

ProductCategoryListItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};
