import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import List from '../List/List';
import ListItem from './ProductListItem';
import ProductDialog from './ProductDialog';
import arrayHelper from '../../utils/helper/array-helper';
import './Product.css';

export default function ProductList(props) {
  const intl = useIntl();
  const [products, setProducts] = useState(props.products);
  const [activeFilters, setActiveFilters] = useState(props.presets.filter);
  const [activeSort, setActiveSort] = useState(props.presets.sort);
  const [activeLayout, setActiveLayout] = useState(props.presets.layout);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewedProduct, setViewedProduct] = useState(undefined);
  const [expandedFilter, setExpandedFilter] = useState(undefined);
  const [expandedFilterDropdownId, setExpandedFilterDropdownId] = useState(undefined);
  let layoutWasSetByQueryParam = false;
  useEffect(() => {
    if (!layoutWasSetByQueryParam) {
      setActiveLayout(props.presets.layout);
    } else {
      layoutWasSetByQueryParam = false;
    }
  }, [props.presets.layout]);
  const filterChangeHandler = (params) => {
    props.services.listService.filterChangeHandler(
      activeFilters,
      params.attributeId,
      params.changedValue,
      setActiveFilters,
      props.changeHandlers.filterChangeHandler
    );
  };
  const filtersSetHandler = (params) => {
    setActiveFilters(params.newFilters);
  };
  const filterDeleteHandler = (params) => {
    props.services.listService.filterDeleteHandler(
      activeFilters,
      params.attributeId,
      setActiveFilters,
      props.changeHandlers.filterChangeHandler
    );
  };
  const layoutChangeHandler = (params) => {
    setActiveLayout(params.layout);
    if (params.setByQuery) {
      layoutWasSetByQueryParam = true;
    } else {
      layoutWasSetByQueryParam = false;
    }
  };
  const sortChangeHandler = (params) => {
    props.services.listService.sortChangeHandler(
      params, setActiveSort, props.changeHandlers.sortChangeHandler
    );
  };
  const searchTermChangeHandler = (params) => {
    setSearchTerm(params.searchTerm);
  };
  const productSelectHandler = (params) => {
    setViewedProduct(undefined);
    props.changeHandlers.selectHandler(params);
  };
  const viewedProductChangeHandler = (params) => {
    setViewedProduct(params.product);
  };
  const expandedFilterChangeHandler = (params) => {
    props.services.listService.expandedFilterChangeHandler(
      params,
      expandedFilter,
      expandedFilterDropdownId,
      setExpandedFilter,
      setExpandedFilterDropdownId
    );
  };
  const newProductListRoutine = () => {
    if (products.length !== props.products.length
      || (props.products.length > 0 && products.length > 0
        && !arrayHelper.findObjectByPropNameAndValue(products, 'id', props.products[0].id))
    ) {
      filterDeleteHandler({});
      setSearchTerm('');
      setProducts(props.products);
    }
  };
  newProductListRoutine();
  const searchedProducts = props.services.productService.searchProducts(
    props.products, searchTerm, 'AND', props.locale, intl, false
  );
  function getActiveSortSafely() {
    if (props.services.listService.checkIfSortOptionExists(props.sortOptions, activeSort)) {
      return activeSort;
    }
    setActiveSort(props.presets.sort);
    return props.presets.sort;
  }
  const filteredProducts = props.services.productService.getFilteredAndSortedProducts(
    searchedProducts, activeFilters, getActiveSortSafely(), props.locale, props.dataStorage
  );
  function getProductListItems() {
    return filteredProducts.map((productItem) => (
      <ListItem
        services={props.services}
        dataStorage={props.dataStorage}
        locale={props.locale}
        currency={props.currency}
        product={productItem}
        qty={(props.customOrderQuantityInfo && props.customOrderQuantityInfo.default)
          ? props.customOrderQuantityInfo.default : undefined}
        selectHandler={
          (props.changeHandlers.selectHandler) ? viewedProductChangeHandler : undefined
        }
        hasHrefToCategoryPath={props.hrefToItemsWithCategoryPath}
        key={JSON.stringify(productItem)}
      />
    ));
  }
  function getDialog() {
    if (viewedProduct) {
      return (
        <ProductDialog
          active={viewedProduct}
          closeHandler={productSelectHandler}
          closeHandlerParams={{ product: undefined }}
          product={viewedProduct}
          showQuantity={props.showOrderQuantity}
          buttonTextTranslationId={props.buttonTextTranslationId}
          selectHandler={productSelectHandler}
          customOrderQuantityInfo={props.customOrderQuantityInfo}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
        />
      );
    }
    return undefined;
  }
  return (
    <List
      filters={props.services.productService.getFilterListMetaData(
        searchedProducts,
        props.filterAttributeIds,
        activeFilters,
        props.locale,
        props.dataStorage,
        intl
      )}
      activeFilters={activeFilters}
      expandedFilterId={expandedFilter}
      sortOptions={props.sortOptions}
      activeSortOption={activeSort}
      activeLayout={activeLayout}
      searchTerm={searchTerm}
      showSearch={props.showSearch}
      getFilteredAndSortedItemsFunc={props.services.productService.getFilteredAndSortedProducts}
      handlers={{
        filterChangeHandler,
        filtersSetHandler,
        filterDeleteHandler,
        sortChangeHandler,
        searchTermChangeHandler,
        expandedFilterChangeHandler,
        layoutChangeHandler,
      }}
      listItems={getProductListItems()}
      elementsPerPage={props.services.productService.getProductConfig().list.itemsPerPage}
      showItemCount={props.showProductCount}
      enableUrlParams={props.enableUrlParams}
      i18nIds={{
        itemType: 'articles',
        empty: 'noProductsFound',
      }}
      classNames={{
        listContainer: 'product-list-container',
        pageContainer: `grid-product-list${(activeLayout === 'table') ? ' grid-product-list--tabelle' : ' grid-product-list--raster'}`,
      }}
      dialog={getDialog()}
      services={props.services}
      dataStorage={props.dataStorage}
      locale={props.locale}
    />
  );
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSearch: PropTypes.bool,
  showProductCount: PropTypes.bool,
  showOrderQuantity: PropTypes.bool,
  customOrderQuantityInfo: PropTypes.objectOf(PropTypes.any),
  enableUrlParams: PropTypes.bool,
  hrefToItemsWithCategoryPath: PropTypes.bool,
  buttonTextTranslationId: PropTypes.string,
  currency: PropTypes.string.isRequired,
  changeHandlers: PropTypes.objectOf(PropTypes.any).isRequired,
  presets: PropTypes.objectOf(PropTypes.any).isRequired,
  filterAttributeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ProductList.defaultProps = {
  showSearch: false,
  showProductCount: false,
  showOrderQuantity: true,
  customOrderQuantityInfo: undefined,
  enableUrlParams: false,
  hrefToItemsWithCategoryPath: false,
  buttonTextTranslationId: 'addToShoppingCart',
};
