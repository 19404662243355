import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '../Button/LinkButton';
import CustomLink from '../Link/CustomLink';
import './Layout.css';

export default function SimpleHeroRow(props) {
  props.services.urlService.enrichPageBuilderParam(props.item, props.locale, props.dataStorage);
  function getText() {
    return (
      <div className="simple-hero-row-info-container">
        <div className="simple-hero-row-heading center-text-on-mobile-only">{props.item.heading}</div>
        <div className="simple-hero-row-text center-text-on-mobile-only">{props.item.text}</div>
        <LinkButton
          href={props.item.href || '#'}
          hrefIsExternal={props.item.hrefIsExternal}
          classNames="linkbutton margin-t20 center-div-on-mobile-only"
        >{props.item.buttonLabel}
        </LinkButton>
      </div>
    );
  }
  function getImage() {
    return (
      <CustomLink
        href={props.item.href || '#'}
        isExternal={props.item.hrefIsExternal}
        onClickHandler={props.item.clickHandler || undefined}
        onClickHandlerParams={props.item.clickHandlerParams || undefined}
      >
        <img
          src={props.item.imgSrc}
          alt={props.item.imgAlt}
        />
      </CustomLink>
    );
  }
  return (
    <div className={`simple-hero-row-container${(props.classNames.container) ? ` ${props.classNames.container}` : ''}`}>
      <div className={`grid-simple-hero-row${(props.classNames.grid) ? ` ${props.classNames.grid}` : ''}`}>
        <div className={(props.imgPosition === 'left') ? 'simple-hero-row-img-container center' : 'simple-hero-row-txt-container center-vertically'}>
          {(props.imgPosition === 'left') && getImage()}
          {(props.imgPosition !== 'left') && getText()}
        </div>
        <div className={(props.imgPosition === 'right') ? 'simple-hero-row-img-container center' : 'simple-hero-row-txt-container center-vertically'}>
          {(props.imgPosition === 'right') && getImage()}
          {(props.imgPosition !== 'right') && getText()}
        </div>
      </div>
    </div>
  );
}

SimpleHeroRow.propTypes = {
  classNames: PropTypes.string,
  imgPosition: PropTypes.string,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

SimpleHeroRow.defaultProps = {
  classNames: {},
  imgPosition: 'right',
};
