import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import Dropdown from '../Dropdown/Dropdown';
import Spinner from '../Progress/Spinner';
import LinkButton from '../Button/LinkButton';
import PageWrapper from '../Wrapper/PageWrapper';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';
import Message from '../Message/Message';
import './Page.css';

export default function CadDownload(props) {
  const intl = useIntl();
  const [status, setStatus] = useState('loading');
  const [productId, setProductId] = useState();
  const [cadFormats, setCadFormats] = useState();
  const [selectedFormat, setSelectedFormat] = useState();
  const [downloadLink, setDownloadLink] = useState();
  const cadFormatSelectHandler = (params) => {
    setStatus('loading');
    setDownloadLink(undefined);
    setSelectedFormat(params.cadFormat);
    fetch(
      `https://ws.tracepartsonline.net/tpowebservices/DownloadCADPath?UserEmail=j.schoenfeld@nonnenmann-gmbh.de&PartNumber=${productId}&ClassificationID=NONNENMANN&CADFormatId=${params.cadFormat.cadFormatId}&Language=${props.locale}&Format=json&ApiKey=p80EQi9ygkUT6F`,
      {
        method: 'GET',
      }
    ).then((response) => response.json()).then((data) => {
      setDownloadLink(data[1][0][1]);
      setStatus('waiting');
    }).catch((err) => console.log(err));
  };
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const { id } = query;
    if (id) {
      setProductId(id);
      fetch(
        `https://ws.tracepartsonline.net/tpowebservices/CADdataAvailability?PartNumber=${id}&ClassificationID=NONNENMANN&Language=${props.locale}&Format=json&Asynchronous=1&ApiKey=p80EQi9ygkUT6F`,
        {
          method: 'GET',
        }
      ).then((response) => response.json()).then((data) => {
        if (data.cadFormatList && data.cadFormatList.length > 0) {
          setCadFormats(data.cadFormatList);
          setStatus('waiting');
        } else {
          setStatus('nodata');
        }
      }).catch((err) => console.log(err));
    } else {
      setStatus('nodata');
    }
  }, []);
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <DefaultHeadingBar
        locale={props.locale}
        services={props.services}
      />
      <div className="content-container">
        <div className="form-and-heading">
          <div className="form-logo-container center"><img src="/logo192.png" alt={`${props.services.configService.getGeneralConfig().brand} logo`} className="form-logo" /></div>
          {productId !== undefined && (<h2 className="center"><span><FormattedMessage id="articleId" />: {productId}</span></h2>)}
          {status === 'waiting' && (
            <div className="margin-t10 center">
              <Dropdown
                isListItem={true}
                type="click"
                heading={intl.formatMessage({ id: 'selectFormat' })}
                items={cadFormats.map((cadFormat) => ({
                  label: cadFormat.cadFormatName,
                  href: '#',
                  onClickHandler: cadFormatSelectHandler,
                  onClickHandlerParams: { cadFormat },
                }))}
                headingSuffixIcon="angle-down"
                dropdownContentClasses="dropdown-filter-content--3cols"
              />
            </div>
          )}
          {downloadLink !== undefined && (
            <div className="margin-t10 center">
              <LinkButton
                href={downloadLink}
                download={true}
                hrefIsExternal={true}
              ><FormattedMessage id="downloadInFormat" values={{ format: selectedFormat.cadFormatName }} />
              </LinkButton>
            </div>
          )}
          {status === 'nodata' && (
            <div className="margin-t10 center">
              <Message type="warning">
                <FormattedMessage id="noCadDataAvailable" />
              </Message>
            </div>
          )}
          {status === 'loading' && (
            <div className="center"><Spinner label="Loading" /></div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

CadDownload.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
