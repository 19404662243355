import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Layout/Icon';
import './Tabs.css';

export default function Tabs(props) {
  const setActiveTabId = (id) => {
    if (props.isToggle) {
      props.activeTabIdChangeHandler((props.activeTabId === id) ? undefined : id);
    } else {
      props.activeTabIdChangeHandler(id);
    }
  };
  const tabs = props.items.map((item) => (
    <button
      className={`tab-button${(props.activeTabId === item.id) ? ' tab-button--active' : ''}${(!item.content) ? ' tab-button--disabled' : ''}`}
      type="button"
      onClick={() => setActiveTabId(item.id)}
      disabled={!item.content}
      key={`tabbutton${item.id}`}
    >
      {item.headingPrefixIcon && <Icon name={item.headingPrefixIcon} />}
      {item.headingText}
      {item.headingSuffixIcon && <Icon name={item.headingSuffixIcon} />}
    </button>
  ));
  const content = props.items.map((item) => (
    <div className={`tab-content${(props.activeTabId === item.id) ? ' tab-content--active' : ''}`} key={`tabcontent${item.id}`}>
      {item.content}
    </div>
  ));
  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs}
      </div>
      {content}
    </div>
  );
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeTabIdChangeHandler: PropTypes.func.isRequired,
  activeTabId: PropTypes.string,
  isToggle: PropTypes.bool,
};

Tabs.defaultProps = {
  activeTabId: undefined,
  isToggle: true,
};
