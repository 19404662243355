import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Textarea from './Textarea';
import InvalidInputMessage from './InvalidInputMessage';
import './Form.css';

export default function FormTextarea(props) {
  const intl = useIntl();
  return (
    <Fragment>
      <div className={`grid-form__label${(props.labelIsInvisble) ? ' input-label-invisible' : ''}`}>
        <label htmlFor={props.id}>
          {
            props.showIsRequired
              ? (
                <Fragment>
                  <FormattedMessage id={props.label || props.id} /><span className="is-required-asterisk"> *</span>
                </Fragment>
              ) : <FormattedMessage id={props.label || props.id} />
          }
        </label>
      </div>
      <div className="grid-form__input">
        <Textarea
          id={props.id}
          name={props.name}
          rows={props.rows}
          cols={props.cols}
          classNames={props.isValid || (props.value === '' && props.noErrorMessageIfEmpty) ? undefined : 'invalid-value'}
          disabled={props.disabled}
          callback={props.callback}
          value={props.value}
          maxLength={props.maxLength}
        />
      </div>
      <InvalidInputMessage
        display={!(props.isValid || (props.value === '' && props.noErrorMessageIfEmpty))}
        message={intl.formatMessage({ id: props.errorMessageId })}
      />
    </Fragment>
  );
}

FormTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number,
  cols: PropTypes.number,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  errorMessageId: PropTypes.string.isRequired,
  noErrorMessageIfEmpty: PropTypes.bool,
  showIsRequired: PropTypes.bool,
  labelIsInvisble: PropTypes.bool,
  maxLength: PropTypes.number,
};

FormTextarea.defaultProps = {
  label: undefined,
  name: undefined,
  rows: undefined,
  cols: undefined,
  disabled: false,
  noErrorMessageIfEmpty: true,
  showIsRequired: false,
  labelIsInvisble: false,
  maxLength: undefined,
};
