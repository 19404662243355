import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import Icon from '../Layout/Icon';
import './Dropdown.css';

export default function DropdownListItems(props) {
  if (props.layout === 'multipleColumns') {
    return props.items.map((item) => {
      const subitems = item.subitems.map((subitem) => (
        <li className="dropdown-wide-submenu__item" key={JSON.stringify(subitem)}>
          <CustomLink
            href={subitem.link}
            isExternal={subitem.isExternal}
            onClickHandler={subitem.onClickHandler}
            onClickHandlerParams={subitem.onClickHandlerParams}
            isActive={subitem.isActive}
          >{subitem.label}
          </CustomLink>
        </li>
      ));
      return (
        <ul className="dropdown-wide-submenu" key={JSON.stringify(item)}>
          {item.label && (
            <li className="dropdown-wide-submenu__heading" key={`${item.label}subitems`}>
              {(item.link) && (
                <CustomLink
                  href={item.link}
                  isExternal={item.isExternal}
                  onClickHandler={item.onClickHandler}
                  onClickHandlerParams={item.onClickHandlerParams}
                  isActive={item.isActive}
                >{item.label}
                </CustomLink>
              )}
              {(!item.link) && (item.label)}
            </li>
          )}
          {subitems}
        </ul>
      );
    });
  }
  return props.items.map((item) => {
    let subitems;
    if (item.subitems) {
      subitems = item.subitems.map((subitem) => (
        <li className="grid-dropdown-submenu__item" key={JSON.stringify(subitem)}>
          <Icon name="angle-right" />
          <CustomLink
            href={subitem.link}
            isExternal={subitem.isExternal}
            onClickHandler={subitem.onClickHandler}
            onClickHandlerParams={subitem.onClickHandlerParams}
            isActive={subitem.isActive}
          >{subitem.label}
          </CustomLink>
        </li>
      ));
      return (
        <li key={JSON.stringify(item)}>
          <CustomLink
            href={item.link}
            isExternal={item.isExternal}
            onClickHandler={item.onClickHandler}
            onClickHandlerParams={item.onClickHandlerParams}
            isActive={item.isActive}
          >{item.label}
          </CustomLink>
          <ul className="grid-dropdown-submenu">
            {subitems}
          </ul>
        </li>
      );
    }
    return (
      <li key={JSON.stringify(item)}>
        <CustomLink
          href={item.link}
          isExternal={item.isExternal}
          onClickHandler={item.onClickHandler}
          onClickHandlerParams={item.onClickHandlerParams}
          isActive={item.isActive}
        >{item.label}
        </CustomLink>
      </li>
    );
  });
}

DropdownListItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func,
  })).isRequired,
  layout: PropTypes.string,
};

DropdownListItems.defaultProps = {
  layout: undefined,
};
