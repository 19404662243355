import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button/Button';
import Icon from '../Layout/Icon';
import './List.css';

export default function FilterSummary(props) {
  const intl = useIntl();
  function getFormattedValue(filter) {
    if (filter.currency) {
      return (`${filter.selectedValues.min.toLocaleString(filter.locale, { style: 'currency', currency: filter.currency })} - ${filter.selectedValues.max.toLocaleString(filter.locale, { style: 'currency', currency: filter.currency })}`);
    }
    return (`${filter.selectedValues.min} ${filter.measuringUnit} ${intl.formatMessage({ id: 'rangeTo' })} ${filter.selectedValues.max} ${filter.measuringUnit}`);
  }
  function getSelectedValues(filter) {
    if (filter.selectedValues.min !== undefined && filter.selectedValues.max !== undefined) {
      return (
        <div className="active-filter-summary__value">
          {getFormattedValue(filter)}
          <Button
            classNames="button-active-filter-value-delete"
            onClick={() => props.deleteHandler(
              { attributeId: filter.attributeId }
            )}
          >
            <Icon name="close" size={20} />
          </Button>
        </div>
      );
    }
    return (filter.selectedValues.map((selectedOption) => (
      <div className="active-filter-summary__value" key={`${filter.attributeId}${selectedOption}`}>
        {selectedOption}{filter.measuringUnit}
        <Button
          classNames="button-active-filter-value-delete"
          onClick={() => props.changeHandler(
            { attributeId: filter.attributeId, changedValue: selectedOption }
          )}
        >
          <Icon name="close" size={20} />
        </Button>
      </div>
    )));
  }
  const summaries = props.activeFilters.map((filter) => (
    <div className="grid-active-filter-summary" key={`filtersummary${filter.heading}`}>
      <div className="active-filter-summary__heading">
        <strong>{filter.heading}:</strong>
      </div>
      <div className="active-filter-summary__values">
        {getSelectedValues(filter)}
      </div>
    </div>
  ));
  return (
    <div className="active-filters-container">
      <div className="grid-active-filters-container-heading">
        <div className="active-filters-container-heading"><FormattedMessage id="activeFilters" />:</div>
        <div className="active-filters-close-all-container">
          {props.activeFilters.length > 1 && (
            <Button
              classNames="button-active-filter-value-delete"
              onClick={() => props.deleteHandler({})}
            >
              <Icon name="close" size={20} />
            </Button>
          )}
        </div>
      </div>
      <div className="active-filters-list">
        {summaries.length > 0 && (summaries)}
        {props.searchTerm.length > 1 && (
          <div className="grid-active-filter-summary">
            <div className="active-filter-summary__heading">
              <strong><FormattedMessage id="search" />:</strong>
            </div>
            <div className="active-filter-summary__values">
              <div className="active-filter-summary__value">
                <span>&quot;{props.searchTerm}&quot;</span>
                <Button
                  classNames="button-active-filter-value-delete"
                  onClick={() => props.searchTermChangeHandler(
                    { searchTerm: '' }
                  )}
                >
                  <Icon name="close" size={20} />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FilterSummary.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
  searchTermChangeHandler: PropTypes.func.isRequired,
};

FilterSummary.defaultProps = {
  deleteHandler: undefined,
};
