import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ProviderAddress from './ProviderAddress';

export default function SimpleH2Sections(props) {
  function getLink(paragraph) {
    return (<a href={paragraph.link} target={paragraph.target || '_self'}>{paragraph.label || paragraph.link}.</a>);
  }
  function getParagraphs(paragraphs) {
    return paragraphs.map((paragraph) => {
      if (paragraph.contactInfo) {
        return (
          <ProviderAddress
            locale={props.locale}
            services={props.services}
          />
        );
      } else if (paragraph.ul) {
        return (
          <ul>
            {paragraph.ul.map((text) => (
              <li>{text}</li>
            ))}
          </ul>
        );
      } else if (!paragraph.strong) {
        return (<p>{paragraph.text} {paragraph.link && (getLink(paragraph))}</p>);
      }
      return (<p><strong>{paragraph.text}</strong> {paragraph.link && (getLink(paragraph))}</p>);
    });
  }
  function getContent(content) {
    return content.map((item) => (
      <Fragment>
        {item.h3 && (<h3>{item.h3}</h3>)}
        {getParagraphs(item.paragraphs)}
      </Fragment>
    ));
  }
  return props.data.sections.map((section) => (
    <section>
      <h2>{section.h2}</h2>
      {getContent(section.content)}
    </section>
  ));
}

SimpleH2Sections.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
