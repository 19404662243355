import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import Icon from '../Layout/Icon';
import './Breadcrumbs.css';

function Breadcrumbs(props) {
  function getBreadcrumbs() {
    return props.data.map((item) => (
      <span key={`breadcrumb${item.label}`}>
        <span> <Icon name="angle-right" size={20} viewBox="2 -3 20 20" /> </span>
        {item.href && (
          <CustomLink href={item.href}>
            {item.label}
          </CustomLink>
        )}
        {!item.href && (<span>{item.label}</span>)}
      </span>
    ));
  }
  return (
    <div className="breadcrumbs">
      <CustomLink href={props.services.configService.getPageByTranslationCodeAndLocale('home', props.locale).path}><FormattedMessage id="homepage" /></CustomLink>
      {getBreadcrumbs()}
    </div>
  );
}

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Breadcrumbs;
