import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import ProductList from '../ProductList';
import ProductCategoryMenu from '../ProductCategoryMenu';
import Message from '../../Message/Message';
import ProductAdditionalInfoMsgs from '../ProductAdditionalInfoMsgs';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';

export default function ProductCategory(props) {
  const intl = useIntl();
  const salesConfig = props.services.configService.getGeneralConfig().sales;
  const sortAttributeSettings = props.services.productService.getSortAttributeSettings(
    (props.productCategory.sorts && props.productCategory.sorts.length > 0)
      ? props.productCategory.sorts : props.productCategory.id
  );
  useEffect(() => {
    props.services.apiService.int({ param1: 'pcvi', param2: props.productCategory.id });
  }, [props.productCategory]);
  return (
    <div className="content-container">
      <Breadcrumbs
        data={props.services.productService.getCategoryBreadcrumbsData(
          props.productCategory, props.dataStorage.productCategories, props.locale
        )}
        locale={props.locale}
        services={props.services}
      />
      <h1>{props.productCategory.name[props.locale]}</h1>
      {(props.productCategory.pageParams && props.productCategory.pageParams.featuredImage) && (
        <img
          src={props.services.configService.getProductImgPath(
            props.productCategory.pageParams.featuredImage.src
          )}
          alt={props.productCategory.pageParams.featuredImage.alt[props.locale]}
          className="product-category-top-image"
        />
      )}
      {props.productCategory.menu && (
        <ProductCategoryMenu
          categoryMenu={props.productCategory.menu}
          sortSettings={(props.productCategory.pageParams)
            ? props.productCategory.pageParams.menuSortSettings : undefined}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
        />
      )}
      {(props.productCategory.pageParams
        && props.productCategory.pageParams.infoBoxes
        && Array.isArray(props.productCategory.pageParams.infoBoxes[props.locale])
        && props.productCategory.pageParams.infoBoxes[props.locale].length > 0
      ) && (
        <ProductAdditionalInfoMsgs
          messages={props.productCategory.pageParams.infoBoxes[props.locale]}
          locale={props.locale}
          services={props.services}
          dataStorage={props.dataStorage}
        />
      )}
      {(!props.dataStorage.user.firstname
        && salesConfig.listPriceInfo.enable
        && !salesConfig.listPriceInfo.excludeProductCategories.includes(
          props.productCategory.id
        )) && (
        <Message type="warning">
          <FormattedMessage id="listPriceLoginInfo" />
        </Message>
      )}
      {props.productCategory.products && (
        <ProductList
          products={props.services.productService.getProductsForCategory(
            props.dataStorage.products,
            props.productCategory.products.filters,
            props.locale
          )}
          currency={salesConfig.currency}
          changeHandlers={{
            filterChangeHandler: () => {},
            sortChangeHandler: () => {},
          }}
          presets={{
            filter: {},
            sort: props.services.productService.getDefaultProductListSortOption(
              sortAttributeSettings, intl, props.locale
            ),
            layout: (props.productCategory.pageParams)
              ? props.productCategory.pageParams.layout : undefined,
          }}
          filterAttributeIds={
            props.productCategory.filters || props.services.productService.buildFilterAttributeIds(
              props.productCategory.products.filters
            )
          }
          sortOptions={props.services.productService.getProductListSortOptions(
            sortAttributeSettings, intl, props.locale
          )}
          showSearch={true}
          showProductCount={true}
          hrefToItemsWithCategoryPath={true}
          enableUrlParams={true}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
        />
      )}
    </div>
  );
}

ProductCategory.propTypes = {
  locale: PropTypes.string.isRequired,
  productCategory: PropTypes.objectOf(PropTypes.any).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
