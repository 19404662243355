const adminAccountMainMenu = {
  de: [
    {
      label: 'Konto',
      subitems: [],
    },
  ],
  en: [
    {
      label: 'Account',
      subitems: [],
    },
  ],
};

const adminDrawerMainMenu = {
  de: [
    {
      id: 'admin-de-dashboard',
    },
    {
      label: 'Monitoring',
      subitems: [
        {
          id: 'admin-de-security-monitoring',
        },
      ],
    },
    {
      label: 'Shop',
      subitems: [
        {
          id: 'admin-de-bestellungen',
        },
        {
          id: 'admin-de-preisregeln',
        },
      ],
    },
    {
      id: 'admin-de-benutzer',
    },
    {
      label: 'Content',
      subitems: [
        {
          id: 'admin-de-medien',
        },
      ],
    },
    {
      label: 'System',
      subitems: [
        {
          id: 'admin-de-import-export',
        },
        {
          id: 'admin-de-werkzeuge',
        },
        {
          id: 'admin-de-backend-dateien',
        },
        {
          id: 'admin-de-backup',
        },
        {
          id: 'admin-de-systeminfo',
        },
        {
          id: 'admin-de-uninstall',
        },
      ],
    },
  ],
  en: [
    {
      id: 'admin-en-dashboard',
    },
    {
      label: 'Monitoring',
      subitems: [
        {
          id: 'admin-en-security-monitoring',
        },
      ],
    },
    {
      label: 'Shop',
      subitems: [
        {
          id: 'admin-en-orders',
        },
        {
          id: 'admin-en-price-rules',
        },
      ],
    },
    {
      id: 'admin-en-user',
    },
    {
      label: 'Content',
      subitems: [
        {
          id: 'admin-en-media',
        },
      ],
    },
    {
      label: 'System',
      subitems: [
        {
          id: 'admin-en-import-export',
        },
        {
          id: 'admin-en-tools',
        },
        {
          id: 'admin-en-backend-files',
        },
        {
          id: 'admin-en-backup',
        },
        {
          id: 'admin-en-systeminfo',
        },
        {
          id: 'admin-en-uninstall',
        },
      ],
    },
  ],
};

const adminDrawerAccountMenu = {
  de: [],
  en: [],
};

const adminAccountSubmenuLoggedIn = {
  de: [
    {
      isAccountSubmenu: true,
      label: 'Konto',
      headingPrefixIcon: 'user',
      subitems: [],
    },
  ],
  en: [
    {
      isAccountSubmenu: true,
      label: 'Account',
      headingPrefixIcon: 'user',
      subitems: [],
    },
  ],
};

const adminDesktopSecondaryMenu = {
  de: [
    {
      isAccountSubmenu: true,
      label: 'Konto',
      headingPrefixIcon: 'user',
      subitems: adminDrawerAccountMenu.de,
    },
    {
      languages: true,
    },
  ],
  en: [
    {
      isAccountSubmenu: true,
      label: 'Account',
      headingPrefixIcon: 'user',
      subitems: adminDrawerAccountMenu.en,
    },
    {
      languages: true,
    },
  ],
};

const adminMobileMainMenu = {
  de: [],
  en: [],
};

export default {
  adminDesktopSecondaryMenu,
  adminDrawerMainMenu,
  adminDrawerAccountMenu,
  adminAccountSubmenuLoggedIn,
  adminAccountMainMenu,
  adminMobileMainMenu,
};
