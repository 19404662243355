import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import PageWrapper from '../Wrapper/PageWrapper';
import SelfServiceConfirmation from '../Account/SelfServiceConfirmation';
import CustomLink from '../Link/CustomLink';
import './Page.css';

export default function RegistrationOptIn(props) {
  const intl = useIntl();
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
      usePavi={false}
    >
      <div className="content-container">
        <h1 className="center"><FormattedMessage id="registration" /></h1>
        <SelfServiceConfirmation
          locale={props.locale}
          services={props.services}
          dataStorage={props.dataStorage}
          apiPath="/user/email-confirm"
          successMsg={(
            <Fragment>
              <FormattedMessage id="registrationOptInSuccessMsg" />
              <CustomLink
                href={props.services.configService.getPageByTranslationCodeAndLocale('login', props.locale).path}
              >{intl.formatMessage({ id: 'registrationOptInSuccessMsg2' })}
              </CustomLink>.
            </Fragment>
          )}
          errorMsg={intl.formatMessage({ id: 'sscOptInErrorMsg' })}
          optionalParams={{ accountActivation: true }}
        />
      </div>
    </PageWrapper>
  );
}

RegistrationOptIn.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
