import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import ProductContentWrapper from './Block/ProductContentWrapper';
import Product from './Block/Product';
import ProductCategory from './Block/ProductCategory';
import ProductCategoryList from './ProductCategoryList';
import Spinner from '../Progress/Spinner';

export default function DynamicProductContent(props) {
  const history = useHistory();
  const getPageInfo = () => props.services.productService.getPageInfo(
    props.dataStorage.products, props.dataStorage.productCategories, props.locale
  );
  const [pageInfo, setPageInfo] = useState(getPageInfo());
  const [expandedMenuPath, setExpandedMenuPath] = useState(undefined);
  function expandPathHandler(togglePath) {
    if (expandedMenuPath && expandedMenuPath.indexOf(togglePath) === 0) {
      const pathArray = togglePath.split('|');
      pathArray.pop();
      setExpandedMenuPath(pathArray.join('|'));
    } else {
      setExpandedMenuPath(togglePath);
    }
  }
  useEffect(() => history.listen(() => {
    setPageInfo(getPageInfo());
  }), [history]);
  let headData;
  let content;
  if (pageInfo.product) {
    content = (
      <Product
        locale={props.locale}
        product={pageInfo.product}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
    headData = props.services.productService.getHeadData(
      pageInfo.product, props.locale, props.dataStorage
    );
  } else if (pageInfo.productCategory) {
    content = (
      <ProductCategory
        locale={props.locale}
        productCategory={pageInfo.productCategory}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
    headData = props.services.productService.getHeadData(
      pageInfo.productCategory,
      props.locale,
      props.dataStorage,
      true
    );
  } else if (pageInfo.webpage) {
    const productCategoryConfig = props.services.productService.getProductCategoryConfig();
    if (pageInfo.webpage.translationCode === productCategoryConfig.canonical.translationCode) {
      content = (
        <Fragment>
          <h1>{pageInfo.webpage.label}</h1>
          <ProductCategoryList
            productCategories={props.services.productService.sortProductCategoryArray(
              props.dataStorage.productCategories, props.locale
            )}
            locale={props.locale}
            dataStorage={props.dataStorage}
            services={props.services}
          />
        </Fragment>
      );
    } else {
      content = (
        <h1>{pageInfo.webpage.label}</h1>
      );
    }
  }
  if (pageInfo.completed && content) {
    return (
      <ProductContentWrapper
        locale={props.locale}
        setLocale={props.setLocale}
        services={props.services}
        dataStorage={props.dataStorage}
        setDataStorage={props.setDataStorage}
        headData={headData}
        expandedMenuPath={expandedMenuPath}
        setExpandedMenuPath={expandPathHandler}
      >
        {content}
      </ProductContentWrapper>
    );
  } else if (pageInfo.completed && !content) {
    return (
      <Redirect to={props.services.configService.getHomepage(props.locale)} />
    );
  }
  return (
    <div className="center"><Spinner label="Loading" /></div>
  );
}

DynamicProductContent.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
