import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import PageWrapper from '../Wrapper/PageWrapper';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';
import CartImport from '../Cart/CartImport';
import LinkButton from '../Button/LinkButton';
import ButtonDownloadBlob from '../Button/ButtonDownloadBlob';
import './Page.css';
import '../Cart/Cart.css';

export default function CartImportExport(props) {
  const intl = useIntl();
  const salesConfig = props.services.configService.getGeneralConfig().sales;
  const showExport = props.dataStorage.cart.positions
    && (props.dataStorage.cart.positions.length > 0);
  const getImportApiPath = () => {
    const apiPath = '/sales/cart/products/export';
    const importPositions = props.dataStorage.cart.positions.map((position) => ({
      articleId: position.productId,
      quantity: position.qty,
      customProductType: position.customProductType,
      flatConfiguration: position.customConfiguration,
    }));
    return `${apiPath}?positions=${JSON.stringify(importPositions)}&cartId=${props.dataStorage.cart.id}`;
  };
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <DefaultHeadingBar
        locale={props.locale}
        services={props.services}
      />
      <div className="content-container">
        {salesConfig.cartExportImport && (
          <div className="grid-cart-import-export">
            <div className="grid-cart-import-export-item">
              <h2><FormattedMessage id="import" /></h2>
              <CartImport
                services={props.services}
                dataStorage={props.dataStorage}
              />
            </div>
            <div className={(showExport) ? 'grid-cart-import-export-item' : ''}>
              {(showExport) && (
                <Fragment>
                  <h2><FormattedMessage id="export" /></h2>
                  <ButtonDownloadBlob
                    apiPath={getImportApiPath()}
                    filename="Cart.xlsx"
                    secondaryButton={false}
                    services={props.services}
                  />
                </Fragment>
              )}
            </div>
          </div>
        )}
        {!salesConfig.cartExportImport && (
          <FormattedMessage id="cartImportExportDisabledMsg" />
        )}
        <div className="cart-import-export-back">
          <LinkButton
            href={props.services.configService.getPageByTranslationCodeAndLocale('cart', props.locale).path}
            classNames="linkbutton linkbutton--secondary"
          >{intl.formatMessage({ id: 'backToCart' })}
          </LinkButton>
        </div>
      </div>
    </PageWrapper>
  );
}

CartImportExport.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
