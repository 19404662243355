import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function CustomRedirect(props) {
  if (!props.href.startsWith('/')) {
    if (props.href.startsWith('http')) {
      window.location.replace(props.href);
    } else {
      window.location.replace(`https://${props.href}`);
    }
    return null;
  }
  return (
    <Redirect to={props.href} />
  );
}

CustomRedirect.propTypes = {
  href: PropTypes.string.isRequired,
};
