import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import ProductList from '../Product/ProductList';
import AssemblySelection from './AssemblySelection';
import Message from '../Message/Message';
import Button from '../Button/Button';
import './HoseConfigurator.css';

export default function HosePartSelection(props) {
  const intl = useIntl();
  const instructionI18nIds = {
    coupling: 'hcPleaseSelectCoupling',
    screwFitting: 'hcPleaseSelectScrewFitting',
    hose: 'hcPleaseSelectHose',
    hoseInsulation: 'hcPleaseSelectInsulation',
  };
  const [currentProductCategory, setCurrentProductCategory] = useState((props.hosePartId === 'hose' ? 'hose' : 'coupling'));
  const [hosePartConfiguration, setHosePartConfiguration] = useState({});
  const [showAssemblyInstructions, setShowAssemblyInstructions] = useState(false);
  const [mirrorHoseOptionIsActive, setMirrorHoseOptionIsActive] = useState(false);
  const submitHoseConfigurationHandler = (mirrorHose, newHosePartConfiguration) => {
    const newHoseConfiguration = { ...props.hoseConfiguration };
    newHoseConfiguration[props.hosePartId] = newHosePartConfiguration || hosePartConfiguration;
    if (mirrorHose) {
      newHoseConfiguration[(props.hosePartId === 'endA') ? 'endB' : 'endA'] = newHosePartConfiguration || hosePartConfiguration;
    }
    props.handlers.hoseConfigurationChangeHandler({
      hoseConfiguration: newHoseConfiguration,
    });
  };
  const showMirrorHoseOptionOrSubmitConfig = (newHosePartConfiguration) => {
    const mirrorHoseOption = props.services.hoseConfiguratorService.showMirrorHoseOption(
      props.hoseConfiguration, props.hosePartId, mirrorHoseOptionIsActive
    );
    if (!mirrorHoseOption) {
      submitHoseConfigurationHandler(false, newHosePartConfiguration);
    }
    setMirrorHoseOptionIsActive(mirrorHoseOption);
  };
  const setProductHandler = (params) => {
    if (params.product) {
      props.handlers.filterChangeHandler({});
      const newHosePartConfiguration = { ...hosePartConfiguration };
      newHosePartConfiguration[currentProductCategory] = params.product;
      if (currentProductCategory === 'hose') {
        newHosePartConfiguration.hose.length = params.orderQuantity;
        if (!['wf', 'tn1'].includes(newHosePartConfiguration.hose.additionalAttributes.i18nId.hoseType)) {
          newHosePartConfiguration.hoseInsulation = { withoutHosePart: true };
        }
      } else if (currentProductCategory === 'coupling' && newHosePartConfiguration.coupling.additionalAttributes) {
        if (newHosePartConfiguration.coupling.additionalAttributes.i18nId.connection === 'hoseNozzle'
          || newHosePartConfiguration.coupling.additionalAttributes.i18nId.connection === 'pushlock'
        ) {
          newHosePartConfiguration.screwFitting = { withoutHosePart: true };
        } else if (!(props.hoseConfiguration.hose && props.hoseConfiguration.hose.hose
          && props.hoseConfiguration.hose.hose.additionalAttributes)
        ) {
          newHosePartConfiguration.screwFitting = { definitionPostpowned: true };
          if (newHosePartConfiguration.coupling.additionalAttributes.noI18n.angle !== 0) {
            newHosePartConfiguration.assemblyDirection = 'down';
          }
        }
      } else if (currentProductCategory === 'coupling' && newHosePartConfiguration.coupling.withoutHosePart) {
        if (props.hoseConfiguration.hose && props.hoseConfiguration.hose.hose
          && props.hoseConfiguration.hose.hose.additionalAttributes
          && props.services.hoseConfiguratorService.getHoseTypes().elastomerHoseTypes.includes(
            props.hoseConfiguration.hose.hose.additionalAttributes.i18nId.hoseType
          )
        ) {
          newHosePartConfiguration.screwFitting = { withoutHosePart: true };
        }
      }
      setHosePartConfiguration(newHosePartConfiguration);
      if (currentProductCategory === 'hose' && !newHosePartConfiguration.hoseInsulation) {
        setCurrentProductCategory('hoseInsulation');
      } else if (currentProductCategory === 'coupling' && !newHosePartConfiguration.screwFitting) {
        setCurrentProductCategory('screwFitting');
      } else if (
        !showAssemblyInstructions
        && props.services.hoseConfiguratorService.showAssemblyInstructions(
          newHosePartConfiguration, currentProductCategory, props.locale
        )
      ) {
        setShowAssemblyInstructions(true);
      } else {
        showMirrorHoseOptionOrSubmitConfig(newHosePartConfiguration);
      }
    }
  };
  function switchToHoseSelection() {
    setCurrentProductCategory('hose');
    setHosePartConfiguration({});
    setShowAssemblyInstructions(false);
    setMirrorHoseOptionIsActive(false);
    props.handlers.selectHosePartHandler(
      { part: 'hose' }
    );
  }
  const skipStep = () => {
    setProductHandler({ product: { withoutHosePart: true } });
  };
  const setAssemblyDirectionHandler = (params) => {
    const newHosePartConfiguration = { ...hosePartConfiguration };
    newHosePartConfiguration.assemblyDirection = params.direction;
    setHosePartConfiguration(newHosePartConfiguration);
    showMirrorHoseOptionOrSubmitConfig(newHosePartConfiguration);
    setShowAssemblyInstructions(false);
  };
  function getCustomOrderQuantityInfo() {
    if (currentProductCategory === 'hose') {
      return props.services.hoseConfiguratorService.getHoseOrderQuantityInfo();
    } else if (currentProductCategory === 'hoseInsulation') {
      return {
        hasDecimalOrderQuantity: true,
        default: hosePartConfiguration.hose.length,
      };
    }
    return undefined;
  }
  function stepSkippable() {
    if (!showAssemblyInstructions && !mirrorHoseOptionIsActive
      && ((currentProductCategory === 'coupling' && props.hoseConfiguration.hose
      && props.hoseConfiguration.hose.hose && props.hoseConfiguration.hose.hose.id)
      || currentProductCategory === 'hoseInsulation' || (
        currentProductCategory === 'screwFitting' && hosePartConfiguration.coupling.withoutHosePart))
    ) {
      return true;
    }
    return false;
  }
  function showSelectHoseButton() {
    return currentProductCategory === 'coupling' && !stepSkippable();
  }
  function getInstructionI18nIds() {
    if (showSelectHoseButton()) {
      return 'hcPleaseSelectCouplingOrHose';
    } else if (showAssemblyInstructions) {
      return 'hcPleaseSelectAssembly';
    } else if (mirrorHoseOptionIsActive) {
      return 'hcInstructionMirrorHoseEnd';
    }
    return instructionI18nIds[currentProductCategory];
  }
  function getSelection() {
    if (showAssemblyInstructions) {
      return (
        <AssemblySelection
          hosePartId={props.hosePartId}
          products={{
            coupling: hosePartConfiguration.coupling,
            screwFitting: hosePartConfiguration.screwFitting,
          }}
          setAssemblyDirectionHandler={setAssemblyDirectionHandler}
          hoseConfiguration={props.hoseConfiguration}
          services={props.services}
        />
      );
    } else if (mirrorHoseOptionIsActive) {
      return (
        <div>
          <Button classNames="button margin-b10 margin-r5" type="submit" onClick={() => submitHoseConfigurationHandler(true)}><FormattedMessage id="hcMirrorHoseEnd" /></Button>
          <Button classNames="button button--secondary margin-b10" type="submit" onClick={() => submitHoseConfigurationHandler(false)}><FormattedMessage id="hcMirrorHoseEndNot" /></Button>
        </div>
      );
    }
    return (
      <ProductList
        products={props.services.hoseConfiguratorService.getFilteredProducts(
          props.products,
          currentProductCategory,
          props.hoseConfiguration,
          hosePartConfiguration,
          intl,
          props.locale,
          props.dataStorage
        )}
        currency={props.services.configService.getGeneralConfig().sales.currency}
        changeHandlers={{
          filterChangeHandler: props.handlers.filterChangeHandler,
          sortChangeHandler: props.handlers.sortChangeHandler,
          selectHandler: setProductHandler,
        }}
        presets={{
          filter: {},
          sort: props.services.hoseConfiguratorService.getDefaultProductCategorySortOption(
            currentProductCategory, intl, props.locale
          ),
        }}
        filterAttributeIds={props.services.hoseConfiguratorService.getFilterAttributes(
          currentProductCategory
        )}
        sortOptions={props.services.hoseConfiguratorService.getProductCategorySortOptions(
          currentProductCategory, intl, props.locale
        )}
        showOrderQuantity={currentProductCategory === 'hose'}
        customOrderQuantityInfo={getCustomOrderQuantityInfo()}
        buttonTextTranslationId="select"
        showSearch={true}
        showProductCount={true}
        locale={props.locale}
        dataStorage={props.dataStorage}
        services={props.services}
      />
    );
  }
  return (
    <Fragment>
      <div className="hc-grid-hose-part-instructions">
        <Message type="info">
          <span><FormattedMessage id={getInstructionI18nIds()} /></span>
        </Message>
        {stepSkippable() && (
          <Button
            classNames="button button--secondary button--beside-message"
            onClick={skipStep}
          >
            <FormattedMessage id="skip" />
          </Button>
        )}
        {showSelectHoseButton() && (
          <Button
            classNames="button button--secondary button--beside-message"
            onClick={switchToHoseSelection}
          >
            {intl.formatMessage({ id: 'hcSelectHosePart' }, { part: intl.formatMessage({ id: 'hcHose' }) })}
          </Button>
        )}
      </div>
      {getSelection()}
    </Fragment>
  );
}

HosePartSelection.propTypes = {
  hosePartId: PropTypes.string.isRequired,
  hoseConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  handlers: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
