import Cookies from 'js-cookie';
import pagesConfig from '../config/pages';
import fixedGeneralConfig from '../config/general';
import adminMenus from '../config/menus';

let pages = [];
let generalConfig = {};

const frontendMenus = {
  desktopMainMenu: {},
  desktopSecondaryMenu: {},
  mobileMainMenu: {},
  drawerAccountMenu: {},
  accountMainMenu: {},
  accountSubmenuLoggedIn: {},
  shopSidebarMenu: {},
  footerMenu: {},
  legalMenu: {},
};

function setGeneralConfig(newGeneralConfig) {
  newGeneralConfig.apiCode = fixedGeneralConfig.apiCode;
  newGeneralConfig.baseUrl = fixedGeneralConfig.baseUrl;
  newGeneralConfig.apiBaseUrl = fixedGeneralConfig.apiBaseUrl;
  newGeneralConfig.defaultLocale = fixedGeneralConfig.defaultLocale;
  generalConfig = newGeneralConfig;
}

function getGeneralConfig() {
  return generalConfig;
}

function getPageImgPath(page) {
  return `/img/${page.image}`;
}

function getProductImgPath(productFilename) {
  return `${getGeneralConfig().product.image.path}/${productFilename}`;
}

function getGeneralDocumentPath(docFilename) {
  return `/documents/general/${docFilename}`;
}

function getLanguages() {
  return getGeneralConfig().i18n.languages;
}

function getAdminAreaLanguages() {
  return getGeneralConfig().adminArea.i18n.languages;
}

function setPages(frontendPages) {
  const finalAdminPages = pagesConfig.adminPages.map((adminPage) => {
    adminPage.id = `admin-${adminPage.id}`;
    adminPage.translationCode = `admin-${adminPage.translationCode}`;
    adminPage.path = `/${getGeneralConfig().adminSlug}${adminPage.path}`;
    if (adminPage.redirectTo !== undefined) {
      adminPage.redirectTo = `/${getGeneralConfig().adminSlug}${adminPage.redirectTo}`;
    }
    return adminPage;
  });
  const finalFrontendPages = frontendPages.map((frontendPage) => {
    if (!pagesConfig.frontendComponents[frontendPage.component]) {
      console.error(`${frontendPage.component} undefined in Frontend.`);
    }
    frontendPage.component = pagesConfig.frontendComponents[frontendPage.component];
    return frontendPage;
  });
  pages = finalFrontendPages.concat(finalAdminPages);
}

function getPages() {
  return pages;
}

function getPageById(id) {
  return pages.filter((page) => page.id === id)[0];
}

function getHomepage(locale) {
  return pages.filter((page) => page.translationCode === 'home' && page.languageCode === locale)[0];
}

function getUrl(pathOrUrl) {
  if (pathOrUrl && pathOrUrl.startsWith('/')) {
    return `${getGeneralConfig().baseUrl}${pathOrUrl}`;
  }
  return pathOrUrl;
}

function getCanconicalUrlByPage(page) {
  return getUrl(page.canonicalUrl) || getUrl(page.path);
}

function getPageByPath(path) {
  for (let i = 0; i < pages.length; i++) {
    if (pages[i].path === path) {
      return pages[i];
    }
    const pageSlugs = pages[i].path.split('/');
    const targetSlugs = path.split('/');
    if (targetSlugs[targetSlugs.length - 1] === '') {
      targetSlugs.pop();
    }
    if (pageSlugs.length === targetSlugs.length && pages[i].path.includes(':')) {
      let possibleMatch = true;
      for (let k = 0; k < targetSlugs.length && possibleMatch; k++) {
        if (pageSlugs[k].charAt(0) !== ':' && (targetSlugs[k] !== pageSlugs[k])) {
          possibleMatch = false;
        }
      }
      if (possibleMatch) {
        return pages[i];
      }
    } else if (pages[i].isNotExactPath) {
      if (path.startsWith(pages[i].path)) {
        return pages[i];
      }
    }
  }
  return undefined;
}

function getBreadcrumbsDataByPath(pathname, locale) {
  const breadcrumbsData = [];
  const slugs = pathname.split('/');
  slugs.shift();
  slugs.pop();
  if (slugs[0] === locale) {
    slugs.shift();
  }
  let currentSlug = '';
  slugs.forEach((slug) => {
    currentSlug = `${currentSlug}/${slug}`;
    const page = getPageByPath(currentSlug);
    if (page) {
      breadcrumbsData.push({
        label: page.label,
        href: currentSlug,
      });
    }
  });
  breadcrumbsData.push({
    label: getPageByPath(pathname).label,
  });
  return breadcrumbsData;
}

function getLanguageCodeByPagePath(path) {
  const page = getPageByPath(path);
  return (page) ? page.languageCode : false;
}

function getLanguageCodeByPath(path) {
  const langCode = getLanguageCodeByPagePath(path);
  if (langCode) {
    return langCode;
  }
  const i18nConfig = getGeneralConfig().i18n;
  for (let i = 0; i < i18nConfig.languages.length; i++) {
    if (path.startsWith(`/${i18nConfig.languages[i].code}/`) || path === `/${i18nConfig.languages[i].code}`) {
      return i18nConfig.languages[i].code;
    }
  }
  return i18nConfig.defaultLocale;
}

function getPagesByTranslationCode(translationCode) {
  return pages.filter((page) => page.translationCode === translationCode);
}

function getPageByTranslationCodeAndLocale(translationCode, locale) {
  const result = getPagesByTranslationCode(translationCode).filter(
    (page) => page.languageCode === locale
  );
  if (result.length === 1) {
    return result[0];
  }
  return false;
}

function setFrontendMenus(newFrontendMenus) {
  newFrontendMenus.forEach((frontendMenu) => {
    frontendMenus[frontendMenu.id] = frontendMenu.languageVersions;
  });
}

function enrichMenuItem(item) {
  const page = getPageById(item.id);
  if (!item.label) {
    item.label = page.label;
  }
  item.link = page.path;
  item.imgSrc = item.imgSrc || getPageImgPath(page);
}

function getMenu(rawMenu) {
  rawMenu.forEach((firstLevelItem) => {
    if (firstLevelItem.id) {
      enrichMenuItem(firstLevelItem);
    }
    if (firstLevelItem.image && firstLevelItem.image.id) {
      enrichMenuItem(firstLevelItem.image);
    }
    if (firstLevelItem.subitems) {
      firstLevelItem.subitems.forEach((secondLevelItem) => {
        if (secondLevelItem.id) {
          enrichMenuItem(secondLevelItem);
        }
        if (secondLevelItem.subitems) {
          secondLevelItem.subitems.forEach((thirdLevelItem) => {
            if (thirdLevelItem.id) {
              enrichMenuItem(thirdLevelItem);
            }
          });
        }
      });
    }
  });
  return rawMenu;
}

function getDesktopMainMenu(locale) {
  return getMenu(frontendMenus.desktopMainMenu[locale]);
}

function getDesktopSecondaryMenu(locale) {
  return getMenu(frontendMenus.desktopSecondaryMenu[locale]);
}

function getMobileMainMenu(locale) {
  return getMenu(frontendMenus.mobileMainMenu[locale]);
}

function getDrawerMainMenu(locale) {
  return getMenu(frontendMenus.drawerMainMenu[locale]);
}

function getDrawerAccountMenu(locale) {
  return getMenu(frontendMenus.drawerAccountMenu[locale]);
}

function getAccountMainMenu(locale) {
  return getMenu(frontendMenus.accountMainMenu[locale]);
}

function getAccountSubmenuLoggedIn(locale) {
  return getMenu(frontendMenus.accountSubmenuLoggedIn[locale])[0];
}

function getShopSidebarMenu(locale) {
  return getMenu(frontendMenus.shopSidebarMenu[locale]);
}

function getFooterMenu(locale) {
  return getMenu(frontendMenus.footerMenu[locale]);
}

function getLegalMenu(locale) {
  return getMenu(frontendMenus.legalMenu[locale]);
}

function getMenus(locale) {
  return {
    desktopMainMenu: getDesktopMainMenu(locale),
    desktopSecondaryMenu: getDesktopSecondaryMenu(locale),
    mobileMainMenu: getMobileMainMenu(locale),
    drawerMainMenu: getDrawerMainMenu(locale),
    drawerAccountMenu: getDrawerAccountMenu(locale),
    drawerLanguageMenu: getLanguages(),
    shopSidebarMenu: getShopSidebarMenu(locale),
    footerMenu: getFooterMenu(locale),
    legalMenu: getLegalMenu(locale),
  };
}

function getAdminDrawerMainMenu(locale) {
  return getMenu(adminMenus.adminDrawerMainMenu[locale]);
}

function getAdminMenus(locale) {
  return {
    desktopSecondaryMenu: getMenu(adminMenus.adminDesktopSecondaryMenu[locale]),
    mobileMainMenu: getMenu(adminMenus.adminMobileMainMenu[locale]),
    drawerMainMenu: getAdminDrawerMainMenu(locale),
    drawerAccountMenu: getMenu(adminMenus.adminDrawerAccountMenu[locale]),
    drawerLanguageMenu: getAdminAreaLanguages(),
  };
}

function getAdminAccountSubmenuLoggedIn(locale) {
  return getMenu(adminMenus.adminAccountSubmenuLoggedIn[locale])[0];
}

function cookieSettingsNotSet() {
  return getGeneralConfig().cookieCategories.length > 0 && !Cookies.get('cookieConsent');
}

function cookieCategoryHasConsent(categoryId) {
  const cookieConsent = Cookies.get('cookieConsent');
  let hasConsent = false;
  if (cookieConsent) {
    cookieConsent.split(';').forEach((catId) => {
      if (catId === categoryId) {
        hasConsent = true;
      }
    });
  }
  return hasConsent;
}

function visitorStatsIsEnabled() {
  return generalConfig.intLevel === 'complete'
    && (generalConfig.cookieCategories.length === 0 || cookieCategoryHasConsent('Stat'));
}

function removeDisabledCookies() {
  if (!cookieCategoryHasConsent('Stat')) {
    localStorage.removeItem('int');
    localStorage.removeItem('aqu');
    sessionStorage.removeItem('aqu');
  }
}

export default {
  setPages,
  getPages,
  getPageById,
  getHomepage,
  getPageByPath,
  getPageImgPath,
  getPagesByTranslationCode,
  getPageByTranslationCodeAndLocale,
  getBreadcrumbsDataByPath,
  getLanguageCodeByPagePath,
  getLanguageCodeByPath,
  setGeneralConfig,
  getGeneralConfig,
  getUrl,
  getCanconicalUrlByPage,
  getProductImgPath,
  getGeneralDocumentPath,
  getLanguages,
  setFrontendMenus,
  enrichMenuItem,
  getDesktopMainMenu,
  getDesktopSecondaryMenu,
  getMobileMainMenu,
  getDrawerMainMenu,
  getDrawerAccountMenu,
  getAccountMainMenu,
  getShopSidebarMenu,
  getAccountSubmenuLoggedIn,
  getMenus,
  getAdminDrawerMainMenu,
  getAdminMenus,
  getAdminAccountSubmenuLoggedIn,
  cookieSettingsNotSet,
  cookieCategoryHasConsent,
  visitorStatsIsEnabled,
  removeDisabledCookies,
};
