import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function HosePartLabel(props) {
  if (props.bold) {
    if (props.hosePartId === 'endA') {
      return (
        <strong><FormattedMessage id="hcEnd" /> A</strong>
      );
    } else if (props.hosePartId === 'endB') {
      return (
        <strong><FormattedMessage id="hcEnd" /> B</strong>
      );
    }
    return (
      <strong><FormattedMessage id="hcHose" /></strong>
    );
  }
  if (props.hosePartId === 'endA') {
    return (
      <span><FormattedMessage id="hcEnd" /> A</span>
    );
  } else if (props.hosePartId === 'endB') {
    return (
      <span><FormattedMessage id="hcEnd" /> B</span>
    );
  }
  return (
    <span><FormattedMessage id="hcHose" /></span>
  );
}

HosePartLabel.propTypes = {
  hosePartId: PropTypes.string.isRequired,
  bold: PropTypes.bool,
};

HosePartLabel.defaultProps = {
  bold: false,
};
