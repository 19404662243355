import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import ListPriceAndDiscount from './ListPriceAndDiscount';

export default function ProductListItem(props) {
  const intl = useIntl();
  const getQuantityByProductSettings = () => {
    const productTypeConfig = props.services.productService.getProductTypeConfigByProduct(
      props.product
    );
    if (productTypeConfig && productTypeConfig.orderQuantity
      && productTypeConfig.orderQuantity.default) {
      return productTypeConfig.orderQuantity.default;
    }
    return props.product.packagingUnit;
  };
  const qty = props.qty || getQuantityByProductSettings() || 1;
  const positionPriceInfo = props.services.salesService.getPositionPriceInfo(
    props.product, qty, props.dataStorage, props.locale
  );
  const availability = props.services.productService.getAvailabilityData(
    props.product, intl, qty
  ).label;
  const layoutConfig = props.services.productService.getProductConfig().category.layout;
  function getHref() {
    if (props.selectHandler) {
      return '#';
    } else if (props.hasHrefToCategoryPath) {
      return props.services.productService.getProductHrefOnProductItem(
        props.product, props.locale
      );
    }
    return props.services.productService.getCanonicalProductPath(props.product, props.locale);
  }
  function getImage() {
    return (
      <img
        className="product-list-item__img"
        src={props.services.productService.getImgPath(props.product)}
        alt={props.product.name[props.locale]}
      />
    );
  }
  function getDiscount() {
    return (
      <div className="product-list-item__discount">
        <ListPriceAndDiscount
          listPrice={positionPriceInfo.formattedListPrice}
          discount={positionPriceInfo.formattedDiscount}
        />
      </div>
    );
  }
  function getPrice() {
    return (
      <Fragment><strong>{positionPriceInfo.formattedPrice}</strong>{(qty !== 1) ? `/ ${props.services.productService.getFormattedQuantityAndUnit(qty, props.product, props.locale)}` : ''}</Fragment>
    );
  }
  function getProductId() {
    return (
      <Fragment><br /><span className="product-list-item__product-id"><FormattedMessage id="prdtId" />: {props.product.id}</span></Fragment>
    );
  }
  return (
    <div className="product-list-item">
      <CustomLink
        href={getHref()}
        onClickHandler={props.selectHandler || undefined}
        onClickHandlerParams={(props.selectHandler) ? { product: props.product } : undefined}
      >
        <div className="product-list-item__raster">
          <div className="product-list-item-img-container-raster">
            {getImage()}
          </div>
          <div className="product-list-item__info">
            <div>
              <div className="product-list-item__name">
                <strong>{props.product.name[props.locale]}</strong>
                {layoutConfig.grid.showProductId && (getProductId())}
              </div>
            </div>
            <div className="product-list-item__description">{props.services.productService.getListItemDescrition(props.product, props.locale, intl)}</div>
            {!props.product.isOnlyOnRequest && (
              <div>
                {positionPriceInfo.discount && (getDiscount())}
                <div className="product-list-item__stock-and-price-container">
                  <div className="product-list-item__availability">{availability}</div>
                  <div className="product-list-item__price">{getPrice()}</div>
                </div>
              </div>
            )}
            {(props.product.isOnlyOnRequest === true) && (
              <div className="product-list-item__stock-and-price-container">
                <div className="product-list-item__availability"><FormattedMessage id="onRequest" /></div>
                <div className="product-list-item__price" />
              </div>
            )}
          </div>
        </div>
        <div className="product-list-item__tabelle">
          <div>{getImage()}</div>
          <div>
            {props.product.name[props.locale]}
            {layoutConfig.table.showProductId && (getProductId())}
          </div>
          <div>
            {props.services.productService.getListItemDescrition(
              props.product, props.locale, intl
            )}
          </div>
          {!props.product.isOnlyOnRequest && (
            <div>
              {positionPriceInfo.discount && (getDiscount())}
              <div className="product-list-item__tabelle__price">{getPrice()}</div>
              <div className="product-list-item__tabelle__availability">{availability}</div>
            </div>
          )}
          {(props.product.isOnlyOnRequest === true) && (
            <div>
              <div className="product-list-item__tabelle__price" />
              <div className="product-list-item__tabelle__availability"><FormattedMessage id="onRequest" /></div>
            </div>
          )}
        </div>
      </CustomLink>
    </div>
  );
}

ProductListItem.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  qty: PropTypes.number,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  selectHandler: PropTypes.func,
  hasHrefToCategoryPath: PropTypes.bool,
};

ProductListItem.defaultProps = {
  qty: undefined,
  selectHandler: undefined,
  hasHrefToCategoryPath: false,
};
