import React from 'react';
import PropTypes from 'prop-types';
import AccountContentWrapper from '../Account/AccountContentWrapper';
import AddressCollection from '../Address/AddressCollection';
import './Page.css';

export default function AccountAddressbook(props) {
  return (
    <AccountContentWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <AddressCollection locale={props.locale} services={props.services} dataStorage={props.dataStorage} addresses={props.dataStorage.user.addresses} classNames="grid-address-collection" mode="addressbook" showTaxNoInCollection={true} showAddressItemSettings={true} />
    </AccountContentWrapper>
  );
}

AccountAddressbook.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
