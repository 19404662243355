import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function ProviderAddress(props) {
  const { brand, mail } = props.services.configService.getGeneralConfig();
  if (props.layout === 'headings') {
    return (
      <Fragment>
        <p className="provider-address-item">
          <h2><FormattedMessage id="address" /></h2>
          {brand.longName}<br />
          {brand.address.street}<br />
          {brand.address.zip} {brand.address.city}<br />
          <FormattedMessage id={brand.address.countryI18nId} />
        </p>
        <p className="provider-address-item">
          <h2><FormattedMessage id="phoneAndFax" /></h2>
          <FormattedMessage id="phoneShort" />: {brand.phone.central}<br />
          <FormattedMessage id="fax" />: {brand.phone.fax}<br />
        </p>
        <p className="provider-address-item">
          <h2><FormattedMessage id="email" /></h2>
          <a href={`mailto:${mail.mailAddresses.main}`}>{mail.mailAddresses.main}</a>
        </p>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <p>
        {brand.longName}<br />
        {brand.address.street}<br />
        {brand.address.zip} {brand.address.city}<br />
        <FormattedMessage id={brand.address.countryI18nId} />
      </p>
      <p>
        <FormattedMessage id="phoneShort" />: {brand.phone.central}<br />
        <FormattedMessage id="fax" />: {brand.phone.fax}<br />
        <FormattedMessage id="email" />: <a href={`mailto:${mail.mailAddresses.main}`}>{mail.mailAddresses.main}</a>
      </p>
      <p>
        <FormattedMessage id="imprintRepresentedBy" />: {brand.legal.personInCharge.name} ({brand.legal.personInCharge.position[props.locale]})<br />
      </p>
    </Fragment>
  );
}

ProviderAddress.propTypes = {
  layout: PropTypes.string,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

ProviderAddress.defaultProps = {
  layout: 'headings',
};
