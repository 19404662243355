import React from 'react';
import PropTypes from 'prop-types';

export default function Checkbox(props) {
  return (
    <div className={props.divClassNames}>
      <input
        id={props.id}
        className={props.inputClassNames}
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        readOnly={props.readOnly}
        onChange={({ target: { checked } }) => props.callback(props.callbackParams || checked)}
      />
      <label htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  divClassNames: PropTypes.string,
  inputClassNames: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  callbackParams: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  divClassNames: undefined,
  inputClassNames: undefined,
  callbackParams: undefined,
  disabled: false,
  readOnly: false,
};
