import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import Cart from './Cart';

export default function CartDropdown(props) {
  return (
    <Dropdown
      isListItem={true}
      type={props.type}
      heading={(props.dataStorage.cart.positions) ? props.dataStorage.cart.positions.length : ''}
      headingPrefixIcon="shopping-cart"
      mainClassNames={(props.dataStorage.cart.positions) ? 'dropdown dropdown--default cart-has-items' : undefined}
      dropdownContentClasses="dropdown__content--cart"
    >
      <Cart
        useCase="dropdown"
        locale={props.locale}
        services={props.services}
        dataStorage={props.dataStorage}
      />
    </Dropdown>
  );
}

CartDropdown.propTypes = {
  type: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
