import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageWrapper from '../Wrapper/PageWrapper';
import RegisterForm from '../Account/RegisterForm';
import './Page.css';

export default function Registration(props) {
  const brandConfig = props.services.configService.getGeneralConfig().brand;
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <div className="content-container">
        <div className="form-and-heading">
          <div className="form-logo-container center"><img src="/logo192.png" alt={`${brandConfig.shortName} logo`} className="form-logo" /></div>
          <h1 className="center"><FormattedMessage id="registration" /></h1>
          <RegisterForm
            services={props.services}
            locale={props.locale}
            siteOwner={brandConfig.longName}
            privacyPolicyLink={props.services.configService.getPageByTranslationCodeAndLocale('privacy-policy', props.locale).path}
          />
        </div>
      </div>
    </PageWrapper>
  );
}

Registration.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
