import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import FileInput from './FileInput';
import InvalidInputMessage from './InvalidInputMessage';
import './Form.css';

export default function FormFileInput(props) {
  const intl = useIntl();
  return (
    <Fragment>
      <div className={`grid-form__label${(props.labelIsInvisble) ? ' input-label-invisible' : ''}`}>
        <label htmlFor={props.id}>
          {
            props.showIsRequired
              ? (
                <Fragment>
                  <FormattedMessage id={props.label || props.id} /><span className="is-required-asterisk"> *</span>
                </Fragment>
              ) : <FormattedMessage id={props.label || props.id} />
          }
        </label>
      </div>
      <div className="grid-form__input">
        <FileInput
          id={props.id}
          classNames={props.isValid || (props.selectedFile === undefined && props.noErrorMessageIfEmpty) ? undefined : 'invalid-value'}
          disabled={props.disabled}
          callback={props.callback}
          multiple={props.multiple}
        />
      </div>
      <InvalidInputMessage
        display={!(props.isValid
          || (props.selectedFile === undefined && props.noErrorMessageIfEmpty))}
        message={intl.formatMessage({ id: props.errorMessageId })}
      />
    </Fragment>
  );
}

FormFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  selectedFile: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
  callback: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  errorMessageId: PropTypes.string.isRequired,
  noErrorMessageIfEmpty: PropTypes.bool,
  showIsRequired: PropTypes.bool,
  labelIsInvisble: PropTypes.bool,
};

FormFileInput.defaultProps = {
  selectedFile: undefined,
  label: undefined,
  disabled: false,
  multiple: false,
  noErrorMessageIfEmpty: true,
  showIsRequired: false,
  labelIsInvisble: false,
};
