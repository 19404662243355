import stringHelper from '../utils/helper/string-helper';
import objectHelper from '../utils/helper/object-helper';

const defaultSelectionSteps = ['type', 'headShape', 'material'];
const stepsWithoutImg = ['material', 'cr'];
const stepsWithSubtext = ['material'];
const diameterToCMap = {
  2: 1.5,
  2.1: 1.55,
  2.2: 1.6,
  2.3: 1.66,
  2.4: 1.7,
  2.5: 1.75,
  2.6: 1.8,
  2.7: 1.85,
  2.8: 1.9,
  2.9: 1.95,
  3: 2,
  3.1: 2.05,
  3.2: 2.1,
  3.3: 2.15,
  3.4: 2.2,
  3.5: 2.25,
  3.6: 2.3,
  3.7: 2.35,
  3.8: 2.4,
  3.9: 2.45,
  4: 2.5,
  4.1: 2.55,
  4.2: 2.6,
  4.3: 2.65,
  4.4: 2.7,
  4.5: 2.75,
  4.6: 2.75,
  4.7: 2.75,
  4.8: 2.75,
  4.9: 2.75,
  5: 3.5,
  5.1: 3.55,
  5.2: 3.6,
  5.3: 3.65,
  5.4: 3.7,
  5.5: 3.75,
  5.6: 3.8,
  5.7: 3.85,
  5.8: 3.9,
  5.9: 3.95,
  6: 4,
  6.1: 4.05,
  6.2: 4.1,
  6.3: 4.15,
  6.4: 4.2,
  6.5: 4.25,
  6.6: 4.3,
  6.7: 4.35,
  6.8: 4.4,
  6.9: 4.45,
  7: 4.5,
  8: 5,
  8.5: 5.25,
  9: 5.5,
  10: 6,
  11: 6.5,
  12: 7,
  12.1: 7.1,
  12.5: 7.5,
  13: 8,
  14: 8.5,
  15: 9,
  16: 9.5,
  17: 10,
  18: 10.5,
  20: 12.5,
  25: 15,
  32: 18,
};

let configService;
let productService;

function setDependencies(dependencies) {
  ({ configService, productService } = dependencies);
  return this;
}

function getConfigurationType() {
  return 'EC';
}

function getUnitOfQuantityI18nId() {
  return 'pieces';
}

function getInitialConfiguratorState() {
  return { steps: defaultSelectionSteps, selection: [] };
}

function getEjectorConfiguratorConfig() {
  return configService.getGeneralConfig().ejectorConfigurator;
}

function getProductTypeSettings(products) {
  const productTypeSettings = getEjectorConfiguratorConfig().products.productTypeSettings.map(
    (productTypeSetting) => JSON.parse(JSON.stringify(productTypeSetting))
  );
  products.forEach((product) => {
    let match = false;
    for (let i = 0; i < productTypeSettings.length && !match; i++) {
      if (product.productType.i18nId === productTypeSettings[i].id
          && !getEjectorConfiguratorConfig().products.exclude.ids.includes(product.id)) {
        match = true;
        if (productTypeSettings[i].products) {
          productTypeSettings[i].products.push(product);
        } else {
          productTypeSettings[i].products = [product];
        }
      }
    }
  });
  return productTypeSettings;
}

function getFilteredProductTypeSettings(productTypeSettings, configuratorState) {
  return productTypeSettings.filter((productTypeSetting) => {
    let match = true;
    for (let i = 0; i < configuratorState.selection.length && match; i++) {
      if (productTypeSetting[configuratorState.steps[i]] !== configuratorState.selection[i]
        && !(configuratorState.selection[i] === 'ecCylinderHeadOsAntiRotation'
        && productTypeSetting[configuratorState.steps[i]] === 'ecCylinderHead')
      ) {
        match = false;
      }
    }
    return match && productTypeSetting.products;
  });
}

function getCurrentStep(configuratorState) {
  return configuratorState.steps[configuratorState.selection.length];
}

function customConfigIsCoated(configuratorState) {
  return configuratorState.selection.includes('ecNitrided')
    || configuratorState.selection.includes('ecNitridedBlackOxidized')
    || configuratorState.selection.includes('ecHardnenedDlc');
}

function getCoatingI18nId(configuratorState) {
  if (configuratorState.shortenPriceRule && configuratorState.antiRotationPriceRule) {
    return 'ecNotCoatedSA';
  } else if (!configuratorState.shortenPriceRule && configuratorState.antiRotationPriceRule) {
    return 'ecNotCoatedA';
  }
  return 'ecNotCoatedS';
}

function customConfigIsFlatEjector(configuratorState) {
  return configuratorState.selection.includes('ecFlatEjectorPin');
}

function customConfigIsNotStraightEjector(configuratorState) {
  return customConfigIsFlatEjector(configuratorState) || configuratorState.selection.includes('ecEjectorPinOffset');
}

function getConfigurationSteps(selectionSteps) {
  const steps = selectionSteps.map(
    (step) => ({ labelTranslationId: `ec${stringHelper.capitalizeFirstLetter(step)}` })
  );
  steps.push({ labelTranslationId: 'ecDimensions' });
  return steps;
}

function getSelectionIdsForStep(filteredProductTypeSettings, step) {
  const selectionIds = [];
  filteredProductTypeSettings.forEach((productTypeSetting) => {
    if (!selectionIds.includes(productTypeSetting[step])) {
      selectionIds.push(productTypeSetting[step]);
      if (productTypeSetting[step] === 'ecCylinderHead') {
        selectionIds.push('ecCylinderHeadOsAntiRotation');
      }
    }
  });
  return selectionIds;
}

function getSelectionImg(selectionId, currentStep, filteredProductTypeSettings) {
  if (getEjectorConfiguratorConfig().images[selectionId]) {
    return getEjectorConfiguratorConfig().images[selectionId];
  }
  let img;
  for (let i = 0; i < filteredProductTypeSettings.length && !img; i++) {
    if (filteredProductTypeSettings[i][currentStep] === selectionId) {
      img = filteredProductTypeSettings[i].products[0].image;
    }
  }
  return img;
}

function getSelectionItems(
  productTypeSettings, configuratorState, ejectorSelectionChangeHandler, intl
) {
  const filteredProductTypeSettings = getFilteredProductTypeSettings(
    productTypeSettings, configuratorState
  );
  const currentStep = getCurrentStep(configuratorState);
  return getSelectionIdsForStep(
    filteredProductTypeSettings, currentStep
  ).map((selectionId) => ({
    selectionId,
    currentStep,
    text: intl.formatMessage({ id: selectionId }),
    subtext: (stepsWithSubtext.includes(currentStep))
      ? `(${intl.formatMessage({ id: `${selectionId}2` })})` : undefined,
    imgSrc: (stepsWithoutImg.includes(currentStep)) ? undefined : configService.getProductImgPath(
      getSelectionImg(
        selectionId, currentStep, filteredProductTypeSettings
      )
    ),
    onClickHandler: ejectorSelectionChangeHandler,
    onClickHandlerParams: { propName: currentStep, value: selectionId },
  })).sort((a, b) => {
    if (a.text > b.text) {
      return 1;
    } else if (b.text > a.text) {
      return -1;
    }
    return 0;
  });
}

function getEjectorPriceRule(ejectorConfiguration, processingType) {
  const config = getEjectorConfiguratorConfig();
  const diameter = ejectorConfiguration[ejectorConfiguration.diameterAttribute];
  for (let i = 0; i < config[processingType].priceRules.length; i++) {
    const priceRule = config[processingType].priceRules[i];
    if ((!priceRule.from || priceRule.from <= diameter)
      && (!priceRule.to || priceRule.to > diameter)
    ) {
      return priceRule;
    }
  }
  return undefined;
}

function getEjectorPriceRuleByArticleId(articleId, processingType) {
  const config = getEjectorConfiguratorConfig();
  for (let i = 0; i < config[processingType].priceRules.length; i++) {
    const priceRule = config[processingType].priceRules[i];
    if (priceRule.article === articleId) {
      return priceRule;
    }
  }
  return undefined;
}

function getDiameterAttributeName(product) {
  return (product.additionalAttributes.noI18n['cadData-d1']) ? 'cadData-d1' : 'cadData-d3';
}

function getProductsByB(products, attributeValue) {
  return products.filter((product) => Number(product
    .additionalAttributes.noI18n['cadData-b']) === Number(attributeValue));
}

function getProductsByBAndA(products, bValue, aValue) {
  const filteredByBProducts = getProductsByB(products, bValue);
  if (aValue) {
    return filteredByBProducts.filter((product) => Number(product
      .additionalAttributes.noI18n['cadData-a']) === Number(aValue));
  }
  return filteredByBProducts;
}

function getProductsByDiameter(products, configuratorState) {
  return products.filter((product) => Number(product
    .additionalAttributes.noI18n[configuratorState.diameterAttribute]) === Number(
    configuratorState[configuratorState.diameterAttribute]
  ));
}

function getProductsByLength(products, length, b, a, configuratorState) {
  let filteredProducts;
  if (configuratorState[configuratorState.diameterAttribute] && configuratorState['cadData-b'] && configuratorState['cadData-a']) {
    filteredProducts = getProductsByBAndA(products, b, a);
    filteredProducts = getProductsByDiameter(filteredProducts, configuratorState);
  } else if (configuratorState[configuratorState.diameterAttribute]) {
    filteredProducts = getProductsByDiameter(products, configuratorState);
  } else {
    filteredProducts = getProductsByBAndA(products, b, a);
  }
  const productsFilteredByLength = [];
  const shortenConstraints = getEjectorConfiguratorConfig().shorten.constraints;
  const antiRotationConstraints = getEjectorConfiguratorConfig().antiRotation.constraints;
  filteredProducts.forEach((product) => {
    const diameterValue = product.additionalAttributes.noI18n[configuratorState.diameterAttribute];
    const lengthValue = product.additionalAttributes.noI18n[configuratorState.lengthAttribute];
    if (Number(lengthValue) <= antiRotationConstraints.maxLength) {
      if (Number(lengthValue) === Number(length)) {
        productsFilteredByLength.push(product);
      } else if (lengthValue <= shortenConstraints.maxLength) {
        let minValue = (diameterValue > shortenConstraints.diameter.border)
          ? shortenConstraints.minLength.largeDiameter : shortenConstraints.minLength.smallDiameter;
        if (customConfigIsNotStraightEjector(configuratorState)) {
          minValue = shortenConstraints.minLength.largeDiameter + Number(product.additionalAttributes.noI18n['cadData-l2']);
        }
        if (minValue <= Number(length) && Number(lengthValue) > Number(length)) {
          productsFilteredByLength.push(product);
        }
      }
    }
  });
  return productsFilteredByLength;
}

function isStandardLength(configuratorState) {
  const products = getProductsByLength(
    configuratorState.filteredProductTypeSettings[0].products,
    configuratorState[configuratorState.lengthAttribute],
    configuratorState['cadData-b'],
    configuratorState['cadData-a'],
    configuratorState
  );
  for (let i = 0; i < products.length; i++) {
    if (Number(
      products[i].additionalAttributes.noI18n[configuratorState.lengthAttribute]
    ) === Number(configuratorState[configuratorState.lengthAttribute])) {
      return true;
    }
  }
  return false;
}

function getProductsByL2(products, configuratorState) {
  const filteredProducts = getProductsByLength(
    products,
    configuratorState[configuratorState.lengthAttribute],
    configuratorState['cadData-b'],
    configuratorState['cadData-a'],
    configuratorState
  );
  return filteredProducts.filter((product) => Number(product
    .additionalAttributes.noI18n[['cadData-l2']]) === Number(configuratorState['cadData-l2']));
}

function getProductsByDiameterForFlatEjector(products, configuratorState) {
  const filteredProducts = getProductsByL2(products, configuratorState);
  return filteredProducts.filter((product) => Number(product
    .additionalAttributes.noI18n[configuratorState.diameterAttribute]) === Number(
    configuratorState[configuratorState.diameterAttribute]
  ));
}

function addPriceRulesToNewState(newState) {
  newState.shortenPriceRule = getEjectorPriceRule(newState, 'shorten');
  if (newState.selection.includes('ecCylinderHeadOsAntiRotation') && newState.filteredProductTypeSettings[0].headShape !== 'ecCylinderHeadOsAntiRotation') {
    newState.antiRotationPriceRule = getEjectorPriceRule(newState, 'antiRotation');
  }
}

function getLengthAttributeName(product) {
  if (product.additionalAttributes.noI18n['cadData-L']) {
    return 'cadData-L';
  } else if (product.additionalAttributes.noI18n['cadData-L1']) {
    return 'cadData-L1';
  }
  return 'cadData-l1';
}

function addNewLengthValue(values, newValue) {
  const newValues = [];
  let match = false;
  for (let i = 0; i < values.length; i++) {
    if (objectHelper.isObject(values[i]) && objectHelper.isObject(newValue)) {
      if ((values[i].max >= newValue.min && values[i].min <= newValue.min)
        || (values[i].max >= newValue.max && values[i].min <= newValue.max)) {
        newValues.push({
          min: (newValue.min > values[i].min) ? values[i].min : newValue.min,
          max: (newValue.max < values[i].max) ? values[i].max : newValue.max,
        });
        match = true;
      } else {
        newValues.push(values[i]);
      }
    } else if (!objectHelper.isObject(values[i]) && objectHelper.isObject(newValue)) {
      if (values[i] < newValue.min || values[i] > newValue.max) {
        newValues.push(values[i]);
      }
    } else if (objectHelper.isObject(values[i]) && !objectHelper.isObject(newValue)) {
      if (newValue >= values[i].min && newValue <= values[i].max) {
        return values;
      }
      newValues.push(values[i]);
    } else {
      if (values[i] === newValue) {
        return values;
      }
      newValues.push(values[i]);
    }
  }
  if (!match) {
    newValues.push(newValue);
  }
  const purifiedValues = [];
  newValues.forEach((value) => {
    let purifiedMatch = false;
    let k = 0;
    purifiedValues.forEach((purifiedValue) => {
      if (objectHelper.isObject(value) && objectHelper.isObject(purifiedValue)) {
        if ((value.max >= purifiedValue.min && value.min <= purifiedValue.min)
          || (value.max >= purifiedValue.max && value.min <= purifiedValue.max)) {
          purifiedValues[k] = {
            min: (purifiedValue.min > value.min) ? value.min : purifiedValue.min,
            max: (purifiedValue.max < value.max) ? value.max : purifiedValue.max,
          };
          purifiedMatch = true;
        }
      }
      k += 1;
    });
    if (!purifiedMatch) {
      purifiedValues.push(value);
    }
  });
  return purifiedValues;
}

function sortLengthValues(values) {
  const objects = [];
  const numbers = [];
  values.forEach((value) => {
    if (objectHelper.isObject(value)) {
      objects.push(value);
    } else {
      numbers.push(value);
    }
  });
  numbers.sort((a, b) => a - b);
  return objects.concat(numbers);
}

function getLengthValues(configuratorState) {
  let lengthValues = [];
  let products;
  if (configuratorState[configuratorState.diameterAttribute]) {
    products = getProductsByDiameter(
      configuratorState.filteredProductTypeSettings[0].products,
      configuratorState
    );
  } else {
    products = getProductsByBAndA(
      configuratorState.filteredProductTypeSettings[0].products,
      configuratorState['cadData-b'],
      configuratorState['cadData-a']
    );
  }
  const shortenConstraints = getEjectorConfiguratorConfig().shorten.constraints;
  const antiRotationConstraints = getEjectorConfiguratorConfig().antiRotation.constraints;
  products.forEach((product) => {
    const diameterValue = product.additionalAttributes.noI18n[configuratorState.diameterAttribute];
    const lengthValue = product.additionalAttributes.noI18n[configuratorState.lengthAttribute];
    if (lengthValue <= antiRotationConstraints.maxLength) {
      if (lengthValue > shortenConstraints.maxLength) {
        lengthValues = addNewLengthValue(lengthValues, Number(lengthValue));
      } else {
        let minValue = (diameterValue > shortenConstraints.diameter.border)
          ? shortenConstraints.minLength.largeDiameter : shortenConstraints.minLength.smallDiameter;
        if (customConfigIsNotStraightEjector(configuratorState)) {
          minValue = shortenConstraints.minLength.largeDiameter + Number(product.additionalAttributes.noI18n['cadData-l2']);
        }
        if (minValue >= lengthValue) {
          lengthValues = addNewLengthValue(lengthValues, Number(lengthValue));
        } else {
          lengthValues = addNewLengthValue(
            lengthValues, { min: minValue, max: Number(lengthValue) }
          );
        }
      }
    }
  });
  return sortLengthValues(lengthValues);
}

function getLengthValuesAsString(configuratorState) {
  const lengthValues = configuratorState.validLengthValues;
  let lengthString = '';
  for (let i = 0; i < lengthValues.length; i++) {
    if (i > 0) {
      lengthString = `${lengthString}, `;
    }
    if (objectHelper.isObject(lengthValues[i])) {
      lengthString = `${lengthString}${lengthValues[i].min}-${lengthValues[i].max}mm`;
    } else {
      lengthString = `${lengthString}${lengthValues[i]}mm`;
    }
  }
  return lengthString;
}

function isValidLengthValue(value, configuratorState) {
  const lengthValues = getLengthValues(configuratorState);
  for (let i = 0; i < lengthValues.length; i++) {
    if (objectHelper.isObject(lengthValues[i])) {
      if (Number(value) >= lengthValues[i].min && Number(value) <= lengthValues[i].max) {
        return true;
      }
    } else if (lengthValues[i] === Number(value)) {
      return true;
    }
  }
  return false;
}

function lengthInputIsSelection(configuratorState) {
  const lengthValues = getLengthValues(configuratorState);
  for (let i = 0; i < lengthValues.length; i++) {
    if (objectHelper.isObject(lengthValues[i])) {
      return false;
    }
  }
  return true;
}

function getLengthValuesForSelection(configuratorState) {
  return getLengthValues(configuratorState).map((value) => [value, value]);
}

function isSuccessfullyCompleted(configuratorState) {
  if (isValidLengthValue(configuratorState[configuratorState.lengthAttribute], configuratorState)) {
    if (customConfigIsNotStraightEjector(configuratorState)) {
      if (customConfigIsFlatEjector(configuratorState)) {
        if (configuratorState[configuratorState.diameterAttribute]) {
          return true;
        }
      } else if (configuratorState['cadData-l2']) {
        return true;
      }
    } else {
      return true;
    }
  }
  return false;
}

function getBaseProductPrice(baseProduct, orderQuantity, dataStorage, locale) {
  return productService.getPositionPriceInfo(
    baseProduct, orderQuantity, dataStorage, locale
  ).price;
}

function addSolutionsToState(newState, products, orderQuantity, dataStorage, locale) {
  let cheapestSolution;
  let fastestSolution;
  addPriceRulesToNewState(newState);
  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    if (Number(product.additionalAttributes.noI18n[newState.lengthAttribute])
      === Number(newState[newState.lengthAttribute])) {
      if (!newState.antiRotationPriceRule) {
        newState.standardProduct = product;
        delete newState.cheapestSolution;
        delete newState.fastestSolution;
        delete newState.shortenPriceRule;
        return newState;
      }
      cheapestSolution = product;
      delete newState.shortenPriceRule;
    } else if (
      Number(
        product.additionalAttributes.noI18n[newState.lengthAttribute]
      ) > Number(newState[newState.lengthAttribute])
    ) {
      if (!cheapestSolution
        || getBaseProductPrice(
          cheapestSolution, orderQuantity, dataStorage, locale
        ) > getBaseProductPrice(
          product, orderQuantity, dataStorage, locale
        )
      ) {
        cheapestSolution = product;
      }
      if (product.quantityInStock >= orderQuantity
        && (!fastestSolution || getBaseProductPrice(
          fastestSolution, orderQuantity, dataStorage, locale
        ) > getBaseProductPrice(
          product, orderQuantity, dataStorage, locale
        ))
      ) {
        fastestSolution = product;
      }
    }
  }
  if (fastestSolution
    && ((getBaseProductPrice(
      cheapestSolution, 1, dataStorage, locale
    ) + 2) > getBaseProductPrice(
      fastestSolution, 1, dataStorage, locale
    ))
  ) {
    newState.cheapestSolution = fastestSolution;
  } else {
    newState.cheapestSolution = cheapestSolution;
    newState.fastestSolution = fastestSolution;
  }
  delete newState.standardProduct;
  return newState;
}

function getUpdatedConfiguratorState(
  params, configuratorState, productTypeSettings, orderQuantity, intl, locale, dataStorage
) {
  if (!params) {
    return getInitialConfiguratorState();
  }
  const newState = JSON.parse(JSON.stringify(configuratorState));
  if (params.attributeId) {
    newState[params.attributeId] = params.changedValue;
    if (params.attributeId === newState.diameterAttribute && !customConfigIsFlatEjector(newState)) {
      delete newState['cadData-l2'];
      delete newState[newState.lengthAttribute];
      delete newState['cadData-a'];
      delete newState['cadData-b'];
      newState.validLengthValues = getLengthValues(newState);
      addPriceRulesToNewState(newState);
    } else if (
      params.attributeId === newState.diameterAttribute && customConfigIsFlatEjector(newState)
    ) {
      const products = getProductsByDiameterForFlatEjector(
        newState.filteredProductTypeSettings[0].products,
        newState
      );
      addSolutionsToState(newState, products, orderQuantity, dataStorage);
    } else if (params.attributeId === 'cadData-l2' && customConfigIsFlatEjector(newState)) {
      delete newState[newState.diameterAttribute];
    } else if (params.attributeId === 'cadData-l2' && !customConfigIsFlatEjector(newState)) {
      const products = getProductsByL2(
        newState.filteredProductTypeSettings[0].products,
        newState
      );
      addSolutionsToState(newState, products, orderQuantity, dataStorage);
    } else if (params.attributeId === 'cadData-b') {
      delete newState['cadData-a'];
      delete newState[newState.lengthAttribute];
      delete newState['cadData-l2'];
      delete newState[newState.diameterAttribute];
    } else if (params.attributeId === 'cadData-a') {
      delete newState[newState.lengthAttribute];
      delete newState['cadData-l2'];
      delete newState[newState.diameterAttribute];
      newState.validLengthValues = getLengthValues(newState);
    } else if (params.attributeId === newState.lengthAttribute
        && isValidLengthValue(params.changedValue, newState)
      && !customConfigIsNotStraightEjector(newState)) {
      const products = getProductsByLength(
        newState.filteredProductTypeSettings[0].products,
        newState[newState.lengthAttribute],
        newState['cadData-b'],
        newState['cadData-a'],
        newState
      );
      addSolutionsToState(newState, products, orderQuantity, dataStorage);
    } else {
      delete newState.standardProduct;
      delete newState.cheapestSolution;
      delete newState.fastestSolution;
    }
    return newState;
  }
  newState.selection.push(params.value);
  newState.filteredProductTypeSettings = getFilteredProductTypeSettings(
    productTypeSettings, newState
  );
  const refProduct = newState.filteredProductTypeSettings[0].products[0];
  newState.diameterAttribute = getDiameterAttributeName(refProduct);
  newState.lengthAttribute = getLengthAttributeName(refProduct);
  newState.drawingPath = configService.getProductImgPath(
    refProduct.pageParams.featuredImage.src
  );
  newState.drawingLabel = refProduct.pageParams.featuredImage.alt[locale];
  if (configuratorState.steps.length !== configuratorState.selection.length) {
    if (params.value === 'ecFlatEjectorPin') {
      newState.steps.pop();
      newState.steps.push('cr');
      newState.steps.push('material');
    }
    if (newState.steps.length !== newState.selection.length) {
      const selectionItemsNextStep = getSelectionItems(
        productTypeSettings, newState, undefined, intl
      );
      if (selectionItemsNextStep.length === 1) {
        return getUpdatedConfiguratorState(
          {
            propName: selectionItemsNextStep[0].currentStep,
            value: selectionItemsNextStep[0].selectionId,
          },
          newState,
          productTypeSettings,
          orderQuantity,
          intl,
          locale,
          dataStorage
        );
      }
    }
  }
  return newState;
}

function sortDimValues(values, locale) {
  return values.sort((a, b) => (
    String(a).localeCompare(String(b), locale, {
      numeric: true,
      sensitivity: 'base',
    })
  )).map((value) => [value, value]);
}

function getBValues(configuratorState, locale) {
  const shortenConstraints = getEjectorConfiguratorConfig().shorten.constraints;
  const antiRotationConstraints = getEjectorConfiguratorConfig().antiRotation.constraints;
  const bValues = [];
  configuratorState.filteredProductTypeSettings[0].products.forEach((product) => {
    const bValue = product.additionalAttributes.noI18n['cadData-b'];
    const diameterValue = product.additionalAttributes.noI18n[configuratorState.diameterAttribute];
    const lengthValue = product.additionalAttributes.noI18n[configuratorState.lengthAttribute];
    if (!bValues.includes(bValue)
      && Number(lengthValue) <= antiRotationConstraints.maxLength
      && Number(diameterValue) >= shortenConstraints.diameter.min
      && Number(diameterValue) <= shortenConstraints.diameter.max
    ) {
      bValues.push(bValue);
    }
  });
  return sortDimValues(bValues, locale);
}

function getAValues(configuratorState, locale) {
  const shortenConstraints = getEjectorConfiguratorConfig().shorten.constraints;
  const antiRotationConstraints = getEjectorConfiguratorConfig().antiRotation.constraints;
  const aValues = [];
  getProductsByB(
    configuratorState.filteredProductTypeSettings[0].products,
    configuratorState['cadData-b']
  ).forEach((product) => {
    const value = product.additionalAttributes.noI18n['cadData-a'];
    const diameterValue = product.additionalAttributes.noI18n[configuratorState.diameterAttribute];
    const lengthValue = product.additionalAttributes.noI18n[configuratorState.lengthAttribute];
    if (!aValues.includes(value)
      && Number(lengthValue) <= antiRotationConstraints.maxLength
      && Number(diameterValue) >= shortenConstraints.diameter.min
      && Number(diameterValue) <= shortenConstraints.diameter.max
    ) {
      aValues.push(value);
    }
  });
  return sortDimValues(aValues, locale);
}

function getL2Values(configuratorState, locale) {
  const l2Values = [];
  getProductsByLength(
    configuratorState.filteredProductTypeSettings[0].products,
    configuratorState[configuratorState.lengthAttribute],
    configuratorState['cadData-b'],
    configuratorState['cadData-a'],
    configuratorState
  ).forEach((product) => {
    const value = product.additionalAttributes.noI18n['cadData-l2'];
    if (!l2Values.includes(value)) {
      l2Values.push(value);
    }
  });
  return sortDimValues(l2Values, locale);
}

function getDiameterValues(configuratorState, locale) {
  const diameterValues = [];
  if (customConfigIsFlatEjector(configuratorState)) {
    getProductsByL2(
      configuratorState.filteredProductTypeSettings[0].products,
      configuratorState
    ).forEach((product) => {
      const value = product.additionalAttributes.noI18n[configuratorState.diameterAttribute];
      if (!diameterValues.includes(value)) {
        diameterValues.push(value);
      }
    });
    return sortDimValues(diameterValues, locale);
  }
  const shortenConstraints = getEjectorConfiguratorConfig().shorten.constraints;
  const antiRotationConstraints = getEjectorConfiguratorConfig().antiRotation.constraints;
  configuratorState.filteredProductTypeSettings[0].products.forEach((product) => {
    const diameterValue = product.additionalAttributes.noI18n[configuratorState.diameterAttribute];
    const lengthValue = product.additionalAttributes.noI18n[configuratorState.lengthAttribute];
    if (!diameterValues.includes(diameterValue)
      && Number(lengthValue) <= antiRotationConstraints.maxLength
      && Number(diameterValue) >= shortenConstraints.diameter.min
      && Number(diameterValue) <= shortenConstraints.diameter.max
    ) {
      diameterValues.push(diameterValue);
    }
  });
  return sortDimValues(diameterValues, locale);
}

function getEjectorConfigurationObject(ejectorConfiguration) {
  return ejectorConfiguration;
}

function getFlatConfiguration(ejectorConfiguration) {
  return getEjectorConfigurationObject(ejectorConfiguration);
}

function getDeepConfigurationObject(flatEjectorConfiguration, dataStorage) {
  const deepObject = JSON.parse(JSON.stringify(flatEjectorConfiguration));
  deepObject.baseProduct = productService.getProduct(deepObject.baseProduct.id, dataStorage);
  return deepObject;
}

function getPositionPriceInfo(flatEjectorConfiguration, orderQuantity, dataStorage, locale) {
  const deepObject = getDeepConfigurationObject(flatEjectorConfiguration, dataStorage);
  let price = getBaseProductPrice(
    deepObject.baseProduct, orderQuantity, dataStorage, locale
  );
  let listPrice = deepObject.baseProduct.price * orderQuantity;
  let discount;
  if (deepObject.shortenId) {
    const shortenPrice = getEjectorPriceRule(deepObject, 'shorten').price * orderQuantity;
    price += shortenPrice;
    listPrice += shortenPrice;
  }
  if (deepObject.antiRotationId) {
    const antiRotationPrice = getEjectorPriceRule(deepObject, 'antiRotation').price * orderQuantity;
    price += antiRotationPrice;
    listPrice += antiRotationPrice;
  }
  if (price === listPrice) {
    listPrice = undefined;
  } else {
    discount = (price / listPrice - 1) * 100;
  }
  return productService.getPositionPriceInfoObject(locale, price, discount, listPrice);
}

function getTotalWeight(ejectorConfiguration) {
  return ejectorConfiguration.baseProduct.weight || 0;
}

function getCustomProductPath(customProduct, locale) {
  return `${configService.getPageByTranslationCodeAndLocale('ejector-configurator', locale).path}?ec=${customProduct.id}`;
}

function getSolutionObject(ejectorConfiguration, isCheapest) {
  const product = (isCheapest) ? ejectorConfiguration.cheapestSolution
    : ejectorConfiguration.fastestSolution || ejectorConfiguration.cheapestSolution;
  if (!product) {
    return undefined;
  }
  const solutionObject = {
    baseProduct: {
      id: product.id,
    },
    lengthAttribute: ejectorConfiguration.lengthAttribute,
    diameterAttribute: ejectorConfiguration.diameterAttribute,
    [ejectorConfiguration.lengthAttribute]:
      ejectorConfiguration[ejectorConfiguration.lengthAttribute],
    [ejectorConfiguration.diameterAttribute]:
      ejectorConfiguration[ejectorConfiguration.diameterAttribute],
  };
  if (ejectorConfiguration['cadData-l2']) {
    solutionObject['cadData-l2'] = ejectorConfiguration['cadData-l2'];
  }
  if (ejectorConfiguration['cadData-a']) {
    solutionObject['cadData-a'] = ejectorConfiguration['cadData-a'];
  }
  if (ejectorConfiguration['cadData-b']) {
    solutionObject['cadData-b'] = ejectorConfiguration['cadData-b'];
  }
  if (ejectorConfiguration.shortenPriceRule) {
    solutionObject.shortenId = ejectorConfiguration.shortenPriceRule.article;
  }
  if (ejectorConfiguration.antiRotationPriceRule) {
    solutionObject.antiRotationId = ejectorConfiguration.antiRotationPriceRule.article;
  }
  return solutionObject;
}

function getReadyForShipmentTime(deepConfigurationObject, orderQuantity) {
  let days = (orderQuantity > deepConfigurationObject.baseProduct.quantityInStock)
    ? Number(deepConfigurationObject.baseProduct.procurementTime) : 0;
  if (deepConfigurationObject.shortenId && deepConfigurationObject.antiRotationId) {
    days += 3;
  } else {
    days += 2;
  }
  return days;
}

function getAddToCartProductObject(ejectorCode, ejectorConfiguration, isCheapest) {
  return {
    id: ejectorCode,
    customProductType: getConfigurationType(),
    flatConfiguration: getEjectorConfigurationObject(
      getSolutionObject(
        ejectorConfiguration, isCheapest
      )
    ),
    unitOfQuantity: { i18nId: getUnitOfQuantityI18nId() },
  };
}

function addProductOrderQuantities(
  ejectorConfiguration,
  productAndOrderQuantities,
  positionOrderQuantity,
  addProductAndOrderQuantityFunc
) {
  const baseProduct = JSON.parse(JSON.stringify(ejectorConfiguration.baseProduct));
  baseProduct.id = `${baseProduct.id}${ejectorConfiguration[ejectorConfiguration.lengthAttribute]}${ejectorConfiguration.shortenId}${ejectorConfiguration.antiRotationId}`;
  baseProduct.quantityInStock = 0;
  baseProduct.procurementTime = getReadyForShipmentTime(
    ejectorConfiguration, positionOrderQuantity
  );
  return addProductAndOrderQuantityFunc(
    productAndOrderQuantities, baseProduct, positionOrderQuantity
  );
}

function generateCustomProductTitle(
  code, product, shorten, antiRotation, lengthAttribute, length, locale, intl
) {
  const customProductTitle = product.name[locale].replace(
    `x${product.additionalAttributes.noI18n[lengthAttribute]}`,
    `x${length}`
  );
  if (antiRotation && shorten) {
    return `${customProductTitle} ${intl.formatMessage({ id: 'ecOsAntiRotation' })}${intl.formatMessage({ id: 'ecTitleAdditionSA' }, {
      code,
      base: product.id,
      shorten,
      antiRotation,
    })}`;
  } else if (
    !antiRotation && shorten
  ) {
    return `${customProductTitle}${intl.formatMessage({ id: 'ecTitleAdditionS' }, {
      code,
      base: product.id,
      shorten,
    })}`;
  }
  return `${customProductTitle} ${intl.formatMessage({ id: 'ecOsAntiRotation' })}${intl.formatMessage({ id: 'ecTitleAdditionA' }, {
    code,
    base: product.id,
    antiRotation,
  })}`;
}

function getCustomProductTitle(customProduct, intl, locale) {
  return generateCustomProductTitle(
    customProduct.id,
    customProduct.customConfiguration.baseProduct,
    customProduct.customConfiguration.shortenId,
    customProduct.customConfiguration.antiRotationId,
    customProduct.customConfiguration.lengthAttribute,
    customProduct.customConfiguration[customProduct.customConfiguration.lengthAttribute],
    locale,
    intl
  );
}

function getDimC(diameter) {
  let dimC = diameterToCMap[Number(diameter)];
  if (dimC) {
    return dimC;
  }
  dimC = diameterToCMap[Number(String(diameter).split('.')[0])];
  if (dimC) {
    const dimCDecimals = String(diameter).split('.')[1];
    if (dimCDecimals) {
      return dimC + (Number(dimCDecimals) * 0.05);
    }
    return dimC;
  }
  return 0;
}

function getCustomEjectorProduct(ejectorConfiguration, code, isCheapest, intl) {
  const baseProduct = (isCheapest) ? ejectorConfiguration.cheapestSolution
    : ejectorConfiguration.fastestSolution;
  const product = JSON.parse(JSON.stringify(baseProduct));
  product.id = code;
  product.additionalAttributes.noI18n[ejectorConfiguration.lengthAttribute] = ejectorConfiguration[
    ejectorConfiguration.lengthAttribute
  ];
  if (ejectorConfiguration.antiRotationPriceRule) {
    const dimC = getDimC(ejectorConfiguration[ejectorConfiguration.diameterAttribute]);
    if (dimC !== 0) {
      product.additionalAttributes.noI18n['cadData-c'] = dimC;
    }
  }
  Object.keys(product.name).forEach((langCode) => {
    product.name[langCode] = generateCustomProductTitle(
      code,
      baseProduct,
      (ejectorConfiguration.shortenPriceRule)
        ? ejectorConfiguration.shortenPriceRule.article : undefined,
      (ejectorConfiguration.antiRotationPriceRule)
        ? ejectorConfiguration.antiRotationPriceRule.article : undefined,
      ejectorConfiguration.lengthAttribute,
      ejectorConfiguration[ejectorConfiguration.lengthAttribute],
      langCode,
      intl
    );
  });
  return product;
}

function getAttributeIds(product) {
  const attributeIds = productService.getAdditionalAttributeIds(product);
  attributeIds.unshift('id');
  return attributeIds.filter((attributeId) => !['erpProductGroupId', 'headShape', 'dinStandard'].includes(attributeId));
}

function getPositionImgArray(customProduct, locale) {
  const path = getCustomProductPath(customProduct, locale);
  return [
    {
      src: productService.getImgPath(customProduct.customConfiguration.baseProduct),
      alt: customProduct.id,
      href: path,
    },
  ];
}

function getConfiguratorStateFromSolutionObject(solutionObject, dataStorage, locale) {
  const configuratorState = getInitialConfiguratorState();
  configuratorState.lengthAttribute = solutionObject.lengthAttribute;
  configuratorState[solutionObject.lengthAttribute] = solutionObject[
    solutionObject.lengthAttribute
  ];
  configuratorState.cheapestSolution = productService.getProduct(
    solutionObject.baseProduct.id, dataStorage
  );
  configuratorState.diameterAttribute = getDiameterAttributeName(
    configuratorState.cheapestSolution
  );
  configuratorState[configuratorState.diameterAttribute] = configuratorState
    .cheapestSolution.additionalAttributes.noI18n[configuratorState.diameterAttribute];
  if (solutionObject['cadData-l2']) {
    configuratorState['cadData-l2'] = solutionObject['cadData-l2'];
  }
  if (solutionObject['cadData-a']) {
    configuratorState['cadData-a'] = solutionObject['cadData-a'];
  }
  if (solutionObject['cadData-b']) {
    configuratorState['cadData-b'] = solutionObject['cadData-b'];
  }
  if (solutionObject.shortenId) {
    configuratorState.shortenPriceRule = getEjectorPriceRuleByArticleId(solutionObject.shortenId, 'shorten');
  }
  if (solutionObject.antiRotationId) {
    configuratorState.antiRotationPriceRule = getEjectorPriceRuleByArticleId(solutionObject.antiRotationId, 'antiRotation');
  }
  configuratorState.drawingPath = configService.getProductImgPath(
    configuratorState.cheapestSolution.pageParams.featuredImage.src
  );
  configuratorState.drawingLabel = configuratorState
    .cheapestSolution.pageParams.featuredImage.alt[locale];
  return configuratorState;
}

function disableDiameterInput(configuratorState) {
  if (customConfigIsFlatEjector(configuratorState)) {
    return !configuratorState[configuratorState.lengthAttribute] || !configuratorState['cadData-l2'] || !configuratorState['cadData-a'] || !configuratorState['cadData-b'];
  }
  return false;
}

function disableLengthInput(configuratorState) {
  if (customConfigIsFlatEjector(configuratorState)) {
    return !configuratorState['cadData-b'] || !configuratorState['cadData-a'];
  } else if (customConfigIsNotStraightEjector(configuratorState)) {
    return !configuratorState[configuratorState.diameterAttribute];
  }
  return !configuratorState[configuratorState.diameterAttribute];
}

function getDrawingPath(configuratorState) {
  return (configuratorState.antiRotationPriceRule) ? configuratorState.drawingPath.replace('.jpg', '-V.jpg') : configuratorState.drawingPath;
}

export default {
  setDependencies,
  getInitialConfiguratorState,
  getConfigurationSteps,
  getProductTypeSettings,
  customConfigIsFlatEjector,
  customConfigIsNotStraightEjector,
  getSelectionItems,
  getUpdatedConfiguratorState,
  getDiameterValues,
  getL2Values,
  getAValues,
  getBValues,
  isValidLengthValue,
  lengthInputIsSelection,
  getLengthValuesForSelection,
  getConfigurationType,
  getUnitOfQuantityI18nId,
  getPositionPriceInfo,
  getTotalWeight,
  getCustomProductTitle,
  getCustomProductPath,
  getFlatConfiguration,
  getSolutionObject,
  getReadyForShipmentTime,
  getDeepConfigurationObject,
  getAddToCartProductObject,
  addProductOrderQuantities,
  getCustomEjectorProduct,
  getAttributeIds,
  getPositionImgArray,
  getConfiguratorStateFromSolutionObject,
  disableDiameterInput,
  disableLengthInput,
  isSuccessfullyCompleted,
  getLengthValuesAsString,
  customConfigIsCoated,
  getCoatingI18nId,
  getDrawingPath,
  isStandardLength,
};
