import React from 'react';
import PropTypes from 'prop-types';
import Discount from './Discount';
import './Product.css';

export default function ListPriceAndDiscount(props) {
  return (
    <div className="grid-list-price-and-discount">
      <div className="product-view-price product-view-price--reduced">{props.listPrice}</div>
      <Discount discount={props.discount} />
    </div>
  );
}

ListPriceAndDiscount.propTypes = {
  listPrice: PropTypes.number.isRequired,
  discount: PropTypes.string.isRequired,
};
