import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';

export default function LinkImage(props) {
  return (
    <CustomLink
      href={props.href || '#'}
      onClickHandler={props.onClickHandler}
      onClickHandlerParams={props.onClickHandlerParams}
    >
      <img className={props.classNames || 'linkimage-img'} src={props.src} alt={props.alt} />
    </CustomLink>
  );
}

LinkImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  href: PropTypes.string,
  onClickHandler: PropTypes.func,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.any),
};

LinkImage.defaultProps = {
  classNames: undefined,
  href: undefined,
  onClickHandler: undefined,
  onClickHandlerParams: undefined,
};
