let configService;
let productService;
let salesService;

function setDependencies(dependencies) {
  ({ configService, productService, salesService } = dependencies);
  return this;
}

function getUrl(category, id, locale, dataStorage) {
  if (category === 'webpage') {
    return configService.getPageById(id).path;
  } else if (category === 'productCategory') {
    return productService.getCanonicalProductCategoryPathById(
      id, dataStorage.productCategories, locale
    );
  }
  console.error(`urlService.getUrl | url not found | category: ${category} | id: ${id}`);
  return undefined;
}

function enrichPageBuilderParam(obj, locale, dataStorage) {
  if (obj.webpageId) {
    obj.href = getUrl('webpage', obj.webpageId);
  } else if (obj.productCategoryId) {
    productService.enrichPageBuilderParam(obj, dataStorage.productCategories, locale);
  }
  if (obj.webpageQuery) {
    obj.href = `${obj.href}?${obj.webpageQuery}`;
  }
}

export default {
  setDependencies,
  getUrl,
  enrichPageBuilderParam,
};
