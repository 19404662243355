import React from 'react';
import PropTypes from 'prop-types';
import './EjectorConfigurator.css';

function SelectionButton(props) {
  return (
    <div className="ec-selection-button" onClick={() => props.onClickHandler(props.onClickHandlerParams)} aria-hidden="true">
      {props.imgSrc && (
        <div className="ec-selection-button-content">
          <img className="ec-selection-button-img" src={props.imgSrc} alt={props.text} />
          <div className="ec-selection-button-text">{props.text}</div>
          {props.subtext && (
            <div className="ec-selection-button-subtext">{props.subtext}</div>
          )}
        </div>
      )}
      {!props.imgSrc && (
        <div className="ec-selection-button-content ec-selection-button-content--text-only">
          <div className="ec-selection-button-text">{props.text}</div>
          {props.subtext && (
            <div className="ec-selection-button-subtext">{props.subtext}</div>
          )}
        </div>
      )}
    </div>
  );
}

SelectionButton.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  imgSrc: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.string),
};

SelectionButton.defaultProps = {
  subtext: undefined,
  imgSrc: undefined,
  onClickHandlerParams: undefined,
};

export default SelectionButton;
