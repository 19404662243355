import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Validator from '../Form/Validator';
import FormInput from '../Form/FormInput';
import RequestProgress from '../Progress/RequestProgress';
import Button from '../Button/Button';

export default function ChangeUserForm(props) {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');

  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
    setEmailIsValid(Validator.isValidEmailAddress(newEmail));
  };
  const passwordChangeHandler = (newPassword) => {
    setPassword(newPassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (emailIsValid) {
      setViewState('loading');
      props.services.apiService.post('/user/email-new', {
        email,
        password,
        locale: props.locale,
      }).then((res) => {
        setViewState('result');
        if (res.data) {
          setResultMessageType('success');
          setResultMessageText(intl.formatMessage({ id: 'emailChangeRequestSuccessMsg' }));
        } else {
          setResultMessageType('error');
          setResultMessageText(intl.formatMessage({ id: 'incorrectPassword' }));
        }
      });
    }
  };
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className="form" onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormInput
              id="email"
              label="newEmail"
              value={email}
              callback={emailChangeHandler}
              isValid={emailIsValid}
              errorMessageId="invalidNewEmailMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
            <FormInput
              id="password"
              type="password"
              value={password}
              callback={passwordChangeHandler}
              isValid
              errorMessageId="mustEnterPassword"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
          </div>
          <Button classNames="button" type="submit"><FormattedMessage id="changeEmailAddress" /></Button>
        </form>
      )}
    </Fragment>
  );
}

ChangeUserForm.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
};
