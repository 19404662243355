import dateHelper from './date-helper';
import numberHelper from './number-helper';

function isValidPriceRule(priceRule, customerPricingGroup) {
  if ((!priceRule.customerPricingGroup || priceRule.customerPricingGroup === customerPricingGroup)
    && dateHelper.matchesTimePeriod(priceRule.dateFrom, priceRule.dateTo)
  ) {
    return true;
  }
  return false;
}

function getMoreImportantPositionPriceRuleByOptionalProperty(ruleA, ruleB, property) {
  if (!ruleA[property] && ruleB[property]) {
    return ruleB;
  }
  if (ruleA[property] && !ruleB[property]) {
    return ruleA;
  }
  return undefined;
}

function formatPrice(price, locale, currency) {
  return price.toLocaleString(locale, { style: 'currency', currency });
}

function formatPercentage(percentage, locale) {
  return (percentage / 100).toLocaleString(locale, { style: 'percent' });
}

function getPositionPriceInfoObject(locale, currency, price, discount, listPrice) {
  const postionPriceInfoObject = {
    price: numberHelper.round(price, 2),
    formattedPrice: formatPrice(price, locale, currency),
  };
  if (discount) {
    postionPriceInfoObject.discount = discount;
    postionPriceInfoObject.formattedDiscount = formatPercentage(discount, locale);
    postionPriceInfoObject.formattedListPrice = formatPrice(listPrice, locale, currency);
  }
  return postionPriceInfoObject;
}

function getFornattedVatRate(vatRate, locale, intl) {
  if (vatRate) {
    return formatPercentage(vatRate, locale);
  }
  return intl.formatMessage({ id: 'noneFemale' });
}

function getVat(subtotal, vatRate) {
  if (vatRate) {
    return (subtotal * (vatRate / 100));
  }
  return 0;
}

function getCartPriceInfoObject(data, locale, salesConfig, intl) {
  const {
    itemTotal, discountAmount, discountPercentage,
    couponCode, shippingCosts, paymentCosts, vatRate,
  } = data;
  const shoppingCartValue = (discountAmount)
    ? itemTotal + discountAmount : itemTotal;
  const subtotal = shoppingCartValue + shippingCosts + paymentCosts;
  const vat = getVat(subtotal, vatRate);
  const total = subtotal + vat;
  return {
    itemTotal,
    formattedItemTotal: formatPrice(itemTotal, locale, salesConfig.currency),
    minOrderValueIsReached: shoppingCartValue >= salesConfig.orderConstraints.minimalOrderValue,
    minOfferValueIsReached: shoppingCartValue >= (
      salesConfig.orderConstraints.minimalOfferValue === undefined
    ) ? salesConfig.orderConstraints.minimalOrderValue
      : salesConfig.orderConstraints.minimalOfferValue,
    discountAmount,
    formattedDiscountAmount: formatPrice(discountAmount || 0, locale, salesConfig.currency),
    discountPercentage,
    formattedDiscountPercentage: (discountPercentage)
      ? formatPercentage(discountPercentage * -1, locale) : undefined,
    couponCode,
    shoppingCartValue,
    formattedShoppingCartValue: formatPrice(shoppingCartValue, locale, salesConfig.currency),
    shippingCosts,
    formattedShippingCosts: formatPrice(shippingCosts, locale, salesConfig.currency),
    paymentCosts,
    formattedPaymentCosts: formatPrice(paymentCosts, locale, salesConfig.currency),
    subtotal,
    formattedSubtotal: formatPrice(subtotal, locale, salesConfig.currency),
    vatRate,
    formattedVatRate: getFornattedVatRate(vatRate, locale, intl),
    vat,
    formattedVat: formatPrice(vat, locale, salesConfig.currency),
    total,
    formattedTotal: formatPrice(total, locale, salesConfig.currency),
  };
}

export default {
  isValidPriceRule,
  getMoreImportantPositionPriceRuleByOptionalProperty,
  formatPrice,
  formatPercentage,
  getPositionPriceInfoObject,
  getCartPriceInfoObject,
};
