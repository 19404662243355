import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from './MultiSelectFilter';
import Range from './RangeFilter';

export default function Filters(props) {
  function getDropdownId(filter) {
    return `drpdwn-${filter.heading}${(props.dropdownIdSuffix) ? `-${props.dropdownIdSuffix}` : ''}`;
  }
  const filterList = props.filters.map((filter) => {
    if (filter.type === 'multiSelect') {
      return (
        <MultiSelect
          dropdownId={getDropdownId(filter)}
          attributeId={filter.id}
          heading={filter.heading}
          values={filter.values}
          measuringUnit={filter.measuringUnit}
          noMeasuringUnitIfValueContains={filter.noMeasuringUnitIfValueContains}
          selectedOptions={filter.selectedValues}
          handlers={props.handlers}
          listItemCount={props.listItemCount}
          isExpanded={filter.id === props.expandedFilterId}
          locale={props.locale}
          services={props.services}
          key={`${filter.id}${JSON.stringify(filter.values)}`}
        />
      );
    } else if (filter.type === 'range') {
      return (
        <Range
          dropdownId={getDropdownId(filter)}
          attributeId={filter.id}
          heading={filter.heading}
          values={filter.values}
          selectedRange={filter.selectedValues}
          filteredOutValues={filter.filteredOutValues}
          measuringUnit={filter.measuringUnit}
          currency={filter.currency}
          locale={filter.locale}
          hasFromToValues={filter.hasFromToValues}
          handlers={props.handlers}
          isExpanded={filter.id === props.expandedFilterId}
          key={`${filter.id}${JSON.stringify(filter.values)}`}
        />
      );
    }
    return undefined;
  });
  return (filterList);
}

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandedFilterId: PropTypes.string,
  listItemCount: PropTypes.number.isRequired,
  handlers: PropTypes.objectOf(PropTypes.func).isRequired,
  dropdownIdSuffix: PropTypes.string,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

Filters.defaultProps = {
  expandedFilterId: undefined,
  dropdownIdSuffix: undefined,
};
