import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Form/Checkbox';
import numberHelper from '../../utils/helper/number-helper';
import dropdownHelper from '../../utils/helper/dropdown-helper';
import './List.css';

export default function MultiSelectFilter(props) {
  const isNumber = numberHelper.allDefinedValuesAreNumeric(props.values);
  const [optionCounts] = useState(dropdownHelper.getOptionCounts(
    props.values,
    isNumber,
    props.listItemCount,
    props.locale,
    props.services.listService.replaceDivisionInSortValue
  ));
  const checkboxes = optionCounts.map((option) => {
    const measuringUnit = props.services.productService.getMeasuringUnitForAttrValue(
      option.value, props.measuringUnit, props.noMeasuringUnitIfValueContains
    );
    const label = (measuringUnit)
      ? `${option.value}${measuringUnit} (${option.count})`
      : `${option.value} (${option.count})`;
    return (
      <Checkbox
        id={`${props.attributeId}${option.value}`}
        divClassNames="filter-checkbox"
        checked={
          (props.selectedOptions)
            ? props.selectedOptions.includes(dropdownHelper.convert(option.value, isNumber))
            : false
        }
        label={label}
        callback={props.handlers.changeHandler}
        callbackParams={{
          attributeId: props.attributeId,
          changedValue: dropdownHelper.convert(option.value, isNumber),
        }}
        key={JSON.stringify(option.value)}
      />
    );
  });
  return (
    <Dropdown
      dropdownId={props.dropdownId}
      type={(Object.keys(optionCounts).length < 2) ? 'disabled' : 'click'}
      mainClassNames="dropdown dropdown--default dropdown-filter"
      heading={props.heading}
      headingLayout="filter"
      headingSuffixIcon="angle-down"
      dropdownContentClasses={`dropdown-filter-content${(checkboxes.length > 40) ? ' dropdown-filter-content--2cols' : ''}`}
      isExpanded={props.isExpanded}
      expandedFilterChangeHandler={props.handlers.expandedFilterChangeHandler}
      attributeId={props.attributeId}
    >
      {checkboxes}
    </Dropdown>
  );
}

MultiSelectFilter.propTypes = {
  dropdownId: PropTypes.string,
  attributeId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  measuringUnit: PropTypes.string,
  noMeasuringUnitIfValueContains: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.any),
  isExpanded: PropTypes.bool.isRequired,
  listItemCount: PropTypes.number.isRequired,
  handlers: PropTypes.objectOf(PropTypes.func).isRequired,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

MultiSelectFilter.defaultProps = {
  dropdownId: undefined,
  measuringUnit: undefined,
  noMeasuringUnitIfValueContains: undefined,
  selectedOptions: undefined,
};
