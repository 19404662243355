import React from 'react';
import Locations from '../components/Pages/Locations';
import Registration from '../components/Pages/Registration';
import Login from '../components/Pages/Login';
import Search from '../components/Pages/Search';
import Cart from '../components/Pages/Cart';
import CartImportExport from '../components/Pages/CartImportExport';
import Contact from '../components/Pages/Contact';
import Imprint from '../components/Pages/Imprint';
import PageBuilder from '../components/Pages/PageBuilder';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy';
import RegistrationOptIn from '../components/Pages/RegistrationOptIn';
import NewEmailOptIn from '../components/Pages/NewEmailOptIn';
import AccountDashboard from '../components/Pages/AccountDashboard';
import AccountOrders from '../components/Pages/AccountOrders';
import AccountOrder from '../components/Pages/AccountOrder';
import AccountAddressbook from '../components/Pages/AccountAddressbook';
import AccountUserDetails from '../components/Pages/AccountUserDetails';
import PasswordForgotten from '../components/Pages/PasswordForgotten';
import PasswordSetNew from '../components/Pages/PasswordSetNew';
import ProductAndCategory from '../components/Pages/ProductAndCategory';
import CombinableProducts from '../components/Pages/CombinableProducts';
import CadDownload from '../components/Pages/CadDownload';
import HoseConfigurator from '../components/Pages/HoseConfigurator';
import EjectorConfigurator from '../components/Pages/EjectorConfigurator';
import AutomationConfigurator from '../components/Pages/AutomationConfigurator';

const BarcodeScanToCart = React.lazy(() => import('../components/Pages/BarcodeScanToCart'));
const Checkout = React.lazy(() => import('../components/Pages/Checkout'));
const AdminInstall = React.lazy(() => import('../components/Admin/Pages/Install'));
const AdminUninstall = React.lazy(() => import('../components/Admin/Pages/Uninstall'));
const AdminLogin = React.lazy(() => import('../components/Admin/Pages/AdminLogin'));
const AdminDashboard = React.lazy(() => import('../components/Admin/Pages/AdminDashboard'));
const AdminOrders = React.lazy(() => import('../components/Admin/Pages/AdminOrders'));
const AdminOrder = React.lazy(() => import('../components/Admin/Pages/AdminOrder'));
const AdminUsers = React.lazy(() => import('../components/Admin/Pages/Users'));
const AdminUser = React.lazy(() => import('../components/Admin/Pages/User'));
const AdminMedia = React.lazy(() => import('../components/Admin/Pages/AdminMedia'));
const AdminSecurityMonitor = React.lazy(() => import('../components/Admin/Pages/AdminSecurityMonitor'));
const AdminTasks = React.lazy(() => import('../components/Admin/Pages/AdminTasks'));
const BackendFiles = React.lazy(() => import('../components/Admin/Pages/BackendFiles'));
const Backup = React.lazy(() => import('../components/Admin/Pages/Backup'));
const AdminUploadMedia = React.lazy(() => import('../components/Admin/Pages/AdminUploadMedia'));
const AdminUploadBackend = React.lazy(() => import('../components/Admin/Pages/AdminUploadBackend'));
const AdminSysteminfo = React.lazy(() => import('../components/Admin/Pages/Systeminfo'));
const AdminImportExport = React.lazy(() => import('../components/Admin/Pages/AdminImportExport'));
const PriceRules = React.lazy(() => import('../components/Admin/Pages/PriceRules'));

const frontendComponents = {
  AccountDashboard,
  AccountOrders,
  AccountOrder,
  AccountAddressbook,
  AccountUserDetails,
  Locations,
  Registration,
  Login,
  Search,
  Cart,
  CartImportExport,
  BarcodeScanToCart,
  Checkout,
  Contact,
  Imprint,
  PrivacyPolicy,
  PageBuilder,
  RegistrationOptIn,
  NewEmailOptIn,
  PasswordForgotten,
  PasswordSetNew,
  ProductAndCategory,
  CombinableProducts,
  CadDownload,
  HoseConfigurator,
  EjectorConfigurator,
  AutomationConfigurator,
};

const adminPages = [
  {
    id: 'de-install',
    label: 'Setup',
    path: '/setup/install',
    languageCode: 'de',
    translationCode: 'install',
    metaDescrition: 'Installation der Anwendung',
    image: '=ToDo=',
    component: AdminInstall,
    redirectIf: 'installed',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-install',
    label: 'Setup',
    path: '/en/setup/install',
    languageCode: 'en',
    translationCode: 'install',
    metaDescrition: 'Installation of the application',
    image: '=ToDo=',
    component: AdminInstall,
    redirectIf: 'installed',
    redirectTo: '/en/login',
  },
  {
    id: 'de-login',
    label: 'Anmeldung',
    path: '/anmeldung',
    languageCode: 'de',
    translationCode: 'login',
    metaDescrition: 'Anmeldung zum Backend',
    image: '=ToDo=',
    component: AdminLogin,
    redirectIf: 'admin-logged-in',
    redirectTo: '',
  },
  {
    id: 'en-login',
    label: 'Login',
    path: '/en/login',
    languageCode: 'en',
    translationCode: 'login',
    metaDescrition: 'Login to the backend',
    image: '=ToDo=',
    component: AdminLogin,
    redirectIf: 'admin-logged-in',
    redirectTo: '/en',
  },
  {
    id: 'de-dashboard',
    label: 'Dashboard',
    path: '',
    languageCode: 'de',
    translationCode: 'dashboard',
    metaDescrition: 'Dashboard des Backends',
    image: '=ToDo=',
    component: AdminDashboard,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-dashboard',
    label: 'Dashboard',
    path: '/en',
    languageCode: 'en',
    translationCode: 'dashboard',
    metaDescrition: 'Dashboard to the backend',
    image: '=ToDo=',
    component: AdminDashboard,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-security-monitoring',
    label: 'Security Monitoring',
    path: '/monitoring/security',
    languageCode: 'de',
    translationCode: 'security-monitoring',
    metaDescrition: 'Security Monitoring',
    image: '=ToDo=',
    component: AdminSecurityMonitor,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-security-monitoring',
    label: 'Security monitoring',
    path: '/en/monitoring/security',
    languageCode: 'en',
    translationCode: 'security-monitoring',
    metaDescrition: 'Security monitoring',
    image: '=ToDo=',
    component: AdminSecurityMonitor,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-bestellungen',
    label: 'Bestellungen',
    path: '/shop/bestellungen',
    languageCode: 'de',
    translationCode: 'orders',
    metaDescrition: 'Bestellungen',
    image: '=ToDo=',
    component: AdminOrders,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-orders',
    label: 'Orders',
    path: '/en/shop/orders',
    languageCode: 'en',
    translationCode: 'orders',
    metaDescrition: 'Orders',
    image: '=ToDo=',
    component: AdminOrders,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-bestellung',
    label: 'Bestellung',
    path: '/shop/bestellung',
    languageCode: 'de',
    translationCode: 'order',
    metaDescrition: 'Bestellung',
    image: '=ToDo=',
    component: AdminOrder,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-order',
    label: 'Order',
    path: '/en/shop/order',
    languageCode: 'en',
    translationCode: 'order',
    metaDescrition: 'Order',
    image: '=ToDo=',
    component: AdminOrder,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-preisregeln',
    label: 'Preisregeln',
    path: '/shop/preisregeln',
    languageCode: 'de',
    translationCode: 'price-rules',
    metaDescrition: 'Preisregeln',
    image: '=ToDo=',
    component: PriceRules,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-price-rules',
    label: 'Price rules',
    path: '/en/shop/price-rules',
    languageCode: 'en',
    translationCode: 'price-rules',
    metaDescrition: 'Price rules',
    image: '=ToDo=',
    component: PriceRules,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-benutzer',
    label: 'Benutzer',
    path: '/benutzer',
    languageCode: 'de',
    translationCode: 'users',
    metaDescrition: 'Benutzer',
    image: '=ToDo=',
    component: AdminUsers,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-user',
    label: 'Users',
    path: '/en/users',
    languageCode: 'en',
    translationCode: 'users',
    metaDescrition: 'Users',
    image: '=ToDo=',
    component: AdminUsers,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-benutzer-profil',
    label: 'Benutzerprofil',
    path: '/benutzer/profil',
    languageCode: 'de',
    translationCode: 'user-profile',
    metaDescrition: 'Frontendbenutzer',
    image: '=ToDo=',
    component: AdminUser,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-user-profile',
    label: 'User profile',
    path: '/en/users/profile',
    languageCode: 'en',
    translationCode: 'user-profile',
    metaDescrition: 'Frontend User',
    image: '=ToDo=',
    component: AdminUser,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-medien',
    label: 'Medien',
    path: '/content/medien',
    languageCode: 'de',
    translationCode: 'media',
    metaDescrition: 'Medien',
    image: '=ToDo=',
    component: AdminMedia,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-media',
    label: 'Media',
    path: '/en/content/media',
    languageCode: 'en',
    translationCode: 'media',
    metaDescrition: 'media',
    image: '=ToDo=',
    component: AdminMedia,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-werkzeuge',
    label: 'Werkzeuge',
    path: '/system/werkzeuge',
    languageCode: 'de',
    translationCode: 'tools',
    metaDescrition: 'Werkzeuge',
    image: '=ToDo=',
    component: AdminTasks,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-tools',
    label: 'Tools',
    path: '/en/system/tools',
    languageCode: 'en',
    translationCode: 'tools',
    metaDescrition: 'Tools',
    image: '=ToDo=',
    component: AdminTasks,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-backup',
    label: 'Backup',
    path: '/system/backup',
    languageCode: 'de',
    translationCode: 'backup',
    metaDescrition: 'Backup',
    image: '=ToDo=',
    component: Backup,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-backup',
    label: 'Backup',
    path: '/en/system/backup',
    languageCode: 'en',
    translationCode: 'backup',
    metaDescrition: 'Backup',
    image: '=ToDo=',
    component: Backup,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-medien-upload',
    label: 'Medien Hochladen',
    path: '/content/medien/upload',
    languageCode: 'de',
    translationCode: 'media-upload',
    metaDescrition: 'Medien Hochladen',
    image: '=ToDo=',
    component: AdminUploadMedia,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-media-upload',
    label: 'Media upload',
    path: '/en/content/media/upload',
    languageCode: 'en',
    translationCode: 'media-upload',
    metaDescrition: 'media upload',
    image: '=ToDo=',
    component: AdminUploadMedia,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-backend-dateien',
    label: 'Backend Dateien',
    path: '/system/backend-dateien',
    languageCode: 'de',
    translationCode: 'backend-files',
    metaDescrition: 'Backend Dateien',
    image: '=ToDo=',
    component: BackendFiles,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-backend-files',
    label: 'Backend files',
    path: '/en/system/backend-files',
    languageCode: 'en',
    translationCode: 'backend-files',
    metaDescrition: 'Backend files',
    image: '=ToDo=',
    component: BackendFiles,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-backend-upload',
    label: 'Backend Dateien Hochladen',
    path: '/system/backend-dateien/upload',
    languageCode: 'de',
    translationCode: 'backend-upload',
    metaDescrition: 'Backend Dateien Hochladen',
    image: '=ToDo=',
    component: AdminUploadBackend,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-backend-upload',
    label: 'Backend files upload',
    path: '/en/system/backend-files/upload',
    languageCode: 'en',
    translationCode: 'backend-upload',
    metaDescrition: 'Backend files upload',
    image: '=ToDo=',
    component: AdminUploadBackend,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-systeminfo',
    label: 'Systeminfo',
    path: '/system/info',
    languageCode: 'de',
    translationCode: 'systeminfo',
    metaDescrition: 'System information',
    image: '=ToDo=',
    component: AdminSysteminfo,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-systeminfo',
    label: 'System info',
    path: '/en/system/info',
    languageCode: 'en',
    translationCode: 'systeminfo',
    metaDescrition: 'System information',
    image: '=ToDo=',
    component: AdminSysteminfo,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-import-export',
    label: 'Import und Export',
    path: '/system/import-und-export',
    languageCode: 'de',
    translationCode: 'importexport',
    metaDescrition: 'Importieren und Exportieren von Daten',
    image: '=ToDo=',
    component: AdminImportExport,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-import-export',
    label: 'Import and Export',
    path: '/en/system/import-and-export',
    languageCode: 'en',
    translationCode: 'importexport',
    metaDescrition: 'Import and export to and from the database',
    image: '=ToDo=',
    component: AdminImportExport,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
  {
    id: 'de-uninstall',
    label: 'Deinstallation',
    path: '/system/deinstallation',
    languageCode: 'de',
    translationCode: 'uninstall',
    metaDescrition: 'Deinstallation',
    image: '=ToDo=',
    component: AdminUninstall,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/anmeldung',
  },
  {
    id: 'en-uninstall',
    label: 'Uninstallation',
    path: '/en/system/uninstallation',
    languageCode: 'en',
    translationCode: 'uninstall',
    metaDescrition: 'Uninstall',
    image: '=ToDo=',
    component: AdminUninstall,
    redirectIf: 'admin-not-logged-in',
    redirectTo: '/en/login',
  },
];

export default {
  frontendComponents,
  adminPages,
};
