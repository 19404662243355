import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PageWrapper from '../../Wrapper/PageWrapper';
import Menu from '../../Menu/Menu';
import '../Product.css';

function ProductContentWrapper(props) {
  const intl = useIntl();
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
      headData={props.headData}
    >
      <div className="content-container">
        <div className="grid-shop-sidebar-main">
          <div className="shop-sidebar">
            <div className="shop-category-menu-heading">
              {intl.formatMessage({ id: 'categories' })}
            </div>
            <Menu
              type="verticalMenu"
              classNames={{
                ul: 'shop-category-menu',
              }}
              items={props.services.configService.getShopSidebarMenu(props.locale)}
              expandedMenuPath={props.expandedMenuPath}
              setExpandedMenuPath={props.setExpandedMenuPath}
              services={props.services}
            />
          </div>
          <div className="shop-main">
            {props.children}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

ProductContentWrapper.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
  headData: PropTypes.objectOf(PropTypes.any).isRequired,
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

ProductContentWrapper.defaultProps = {
  expandedMenuPath: undefined,
};

export default ProductContentWrapper;
