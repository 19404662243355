import React from 'react';
import PropTypes from 'prop-types';
import './Backdrop.css';

export default function Backdrop(props) {
  return (
    <div aria-hidden="true" className="backdrop" onClick={props.backdropClickHandler} />
  );
}

Backdrop.propTypes = {
  backdropClickHandler: PropTypes.func.isRequired,
};
