import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from './Modal';
import Button from '../Button/Button';
import './Modal.css';

export default function ConfirmDialog(props) {
  return (
    <Modal active={props.active}>
      <strong>{props.heading}</strong>
      <p>{props.text}</p>
      <div className="dialog-button-container">
        <Button classNames="button button--small button--secondary margin-r5" type="submit" onClick={() => props.cancelHandler(-1)}><FormattedMessage id="cancel" /></Button>
        <Button classNames="button button--small" type="submit" onClick={() => props.confirmHandler(props.confirmHandlerParams)} disabled={props.confirmButtonDisabled}><FormattedMessage id={props.confirmButtonTranslationId} /></Button>
      </div>
    </Modal>
  );
}

ConfirmDialog.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  confirmButtonTranslationId: PropTypes.string.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  confirmHandlerParams: PropTypes.objectOf(PropTypes.any).isRequired,
  confirmButtonDisabled: PropTypes.bool,
  active: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  active: false,
  confirmButtonDisabled: undefined,
};
