import React from 'react';
import PropTypes from 'prop-types';
import LinkImage from './LinkImage';
import './Image.css';

export default function HorizontalImages(props) {
  function getImages() {
    if (props.hasLinks) {
      return props.imgArray.map((imgObj) => (
        <LinkImage
          href={imgObj.href}
          onClickHandler={imgObj.onClickHandler}
          onClickHandlerParams={imgObj.onClickHandlerParams}
          classNames={imgObj.classNames}
          src={imgObj.src}
          alt={imgObj.alt}
          key={`${imgObj.src}${imgObj.href}`}
        />
      ));
    }
    return props.imgArray.map((imgObj) => (
      <img src={imgObj.src} alt={imgObj.alt} />
    ));
  }
  return (
    <div className={`horizontal-images horizontal-images--${props.imgArray.length}`}>
      {getImages()}
    </div>
  );
}

HorizontalImages.propTypes = {
  imgArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasLinks: PropTypes.bool,
};

HorizontalImages.defaultProps = {
  hasLinks: true,
};
