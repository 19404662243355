import urlHelper from '../utils/helper/url-helper';

let configService;
let productService;

function setDependencies(dependencies) {
  ({ configService, productService } = dependencies);
  return this;
}

function getPageTranslationPaths(currentPath, dataStorage) {
  const currentPage = configService.getPageByPath(currentPath);
  const translatedPages = configService.getPagesByTranslationCode(currentPage.translationCode);
  const translationPaths = {};
  translatedPages.forEach((page) => {
    translationPaths[page.languageCode] = page.path;
    if (page.translationCode.startsWith(':')) {
      const productConfig = productService.getProductConfig();
      if (page.translationCode === productConfig.canonical.translationCode) {
        translationPaths[page.languageCode] = urlHelper.getTranslatedPathWith1Id(
          currentPath,
          page.path,
          currentPage.languageCode,
          page.languageCode,
          dataStorage.products
        );
      } else {
        translationPaths[page.languageCode] = productService.getTranslatedPagePath(
          currentPath,
          currentPage.path,
          page.path,
          currentPage.languageCode,
          page.languageCode,
          dataStorage.products,
          dataStorage.productCategories
        );
      }
    }
  });
  return translationPaths;
}

function getPageLanguageVersions(currentPath, dataStorage) {
  const pageLanguageVersions = [];
  const translationPaths = getPageTranslationPaths(currentPath, dataStorage);
  configService.getLanguages().forEach((language) => {
    pageLanguageVersions.push({
      code: language.code,
      label: language.label,
      link: translationPaths[language.code],
    });
  });
  return pageLanguageVersions;
}

export default {
  setDependencies,
  getPageTranslationPaths,
  getPageLanguageVersions,
};
