import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import AddressCollectionItem from './AddressCollectionItem';
import ConfirmDialog from '../Modal/ConfirmDialog';
import AddressForm from './AddressForm';
import Button from '../Button/Button';
import './Address.css';

export default function AddressCollection(props) {
  const intl = useIntl();
  const newAddress = () => ({
    nameLine1: '',
    nameLine2: '',
    street: '',
    postOfficeBox: '',
    zip: '',
    city: '',
    country: 'DE',
    taxNo: '',
  });
  const [viewState, setViewState] = useState((props.mode === 'checkout' && !props.addresses.length) ? 'form' : 'collection');
  const [modalItemId, setModalItemId] = useState(-1);
  const [addressInEdit, setAddressInEdit] = useState(newAddress());
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const getAddressById = (addressId) => {
    for (let i = 0; i < props.addresses.length; i++) {
      if (props.addresses[i].id === addressId) {
        return props.addresses[i];
      }
    }
    return undefined;
  };
  const viewStateChangeHandler = (newViewState) => {
    setViewState(newViewState);
    if (props.viewStateChangeHandler) {
      props.viewStateChangeHandler(newViewState);
    }
  };
  const modalItemIdHandler = (newModalItemId) => {
    if (newModalItemId === -1) {
      setDeleteModalActive(false);
    } else {
      setDeleteModalActive(true);
    }
    setModalItemId(newModalItemId);
  };
  const editAddressHandler = (addressId) => {
    if (addressId === false) {
      setAddressInEdit(newAddress());
    } else {
      setAddressInEdit(getAddressById(addressId));
    }
    viewStateChangeHandler('form');
  };
  const saveAddressInCheckoutHandler = (params) => {
    props.saveAddressInCheckoutHandler(params);
    setAddressInEdit(newAddress());
    viewStateChangeHandler('collection');
  };
  const cancelNewAddressHandler = () => {
    setAddressInEdit(newAddress());
    viewStateChangeHandler('collection');
  };
  const deleteAddressHandler = () => {
    if (props.services.dataStorageService.isLoggedIn(props.dataStorage.user)) {
      props.services.apiService.post('/user/deleteaddress', {
        id: modalItemId,
      }).then((res) => {
        if (res.data.user) {
          props.services.dataStorageService.setUser(res.data.user, props.dataStorage);
          modalItemIdHandler(-1);
        }
      });
    } else {
      props.deleteGuestUserAddress(modalItemId);
      modalItemIdHandler(-1);
    }
  };
  const addressItems = props.addresses.map((addressItem) => (
    <AddressCollectionItem
      address={addressItem}
      selected={props.selectedId === addressItem.id}
      setSelected={props.setSelected}
      selectButtonTranslationId={props.selectButtonTranslationId}
      setModalItemId={modalItemIdHandler}
      editAddressHandler={editAddressHandler}
      showTaxNo={props.showTaxNoInCollection}
      showSettings={props.showAddressItemSettings}
      showEditAndDeleteButton={props.showEditAndDeleteButton}
      key={JSON.stringify(addressItem)}
    />
  ));
  const newAddressButton = (additionalClassName) => (
    <div className={`address-collection-button-container ${additionalClassName}`}>
      <Button classNames={`button${(props.mode === 'checkout') ? ' button--secondary' : ''}`} type="submit" onClick={() => editAddressHandler(false)}><FormattedMessage id={(props.mode === 'checkout') ? 'createAnotherAddress' : 'newAddress'} /></Button>
    </div>
  );
  return (
    <Fragment>
      {(viewState === 'collection' && props.mode === 'addressbook' && !props.addresses.length) && (<p className="margin-t0"><FormattedMessage id="noAddressesYet" /></p>)}
      {(viewState === 'collection' && props.mode === 'addressbook') && (newAddressButton('address-collection-button-container--top'))}
      <div className={props.classNames}>
        {(viewState === 'collection') && (addressItems)}
      </div>
      {(viewState === 'collection' && props.mode === 'checkout') && (newAddressButton('address-collection-button-container--bottom'))}
      {(viewState === 'form') && (
        <AddressForm
          locale={props.locale}
          services={props.services}
          dataStorage={props.dataStorage}
          address={addressInEdit}
          cancelHandler={(props.mode === 'checkout' || props.addresses.length > 0) ? cancelNewAddressHandler : undefined}
          saveInCheckoutHandler={(props.mode === 'checkout') ? saveAddressInCheckoutHandler : undefined}
          mode={props.mode}
          showDiscardButton={!(props.mode === 'checkout' && !props.addresses.length)}
        />
      )}
      <ConfirmDialog heading={intl.formatMessage({ id: 'addressConfirmDeletionHeading' })} text={intl.formatMessage({ id: 'addressConfirmDeletionMsg' })} confirmButtonTranslationId="delete" active={deleteModalActive} cancelHandler={modalItemIdHandler} confirmHandler={deleteAddressHandler} confirmHandlerParams={{}} />
    </Fragment>
  );
}

AddressCollection.propTypes = {
  classNames: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  setSelected: PropTypes.func,
  saveAddressInCheckoutHandler: PropTypes.func,
  deleteGuestUserAddress: PropTypes.func,
  selectButtonTranslationId: PropTypes.string,
  showTaxNoInCollection: PropTypes.bool,
  showAddressItemSettings: PropTypes.bool,
  showEditAndDeleteButton: PropTypes.bool,
  mode: PropTypes.string,
  viewStateChangeHandler: PropTypes.func,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

AddressCollection.defaultProps = {
  classNames: undefined,
  selectedId: false,
  setSelected: undefined,
  saveAddressInCheckoutHandler: undefined,
  deleteGuestUserAddress: undefined,
  selectButtonTranslationId: undefined,
  showTaxNoInCollection: false,
  showAddressItemSettings: false,
  showEditAndDeleteButton: true,
  mode: 'checkout',
  viewStateChangeHandler: undefined,
};
