import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import HosePartLabel from './HosePartLabel';

export default function Summary(props) {
  const intl = useIntl();
  function getProductRow(product, qty, headingI18nId) {
    return (
      <div className="grid-hc-summary-product">
        <div className="hc-summary-product-image-container">
          <img
            src={props.services.productService.getImgPath(product)}
            alt={product.name[props.locale]}
          />
        </div>
        <div className="hc-summary-product-description-container">
          <div className="hc-summary-product-type">{(headingI18nId) ? intl.formatMessage({ id: headingI18nId }) : props.services.productService.getAttributeValueTranslation(product.productType.i18nId, undefined, props.locale)}</div>
          {product.name[props.locale]}
          <div className="hc-summary-product-id">{product.id}</div>
        </div>
        <div className="hc-summary-product-price-container">
          {props.services.productService.getPositionPriceInfo(
            product, qty, props.dataStorage, props.locale
          ).formattedPrice}
        </div>
      </div>
    );
  }
  function getHosePartHeading(hosePartId, hoseConfiguration) {
    if (hoseConfiguration[hosePartId] && hosePartId === 'hose') {
      return (
        <div className="hc-summary-hose-part-heading">
          <span>
            <strong><HosePartLabel hosePartId={hosePartId} /> (<FormattedMessage id="hcLength" />: {props.services.productService.getFormattedQuantityAndUnit(hoseConfiguration.hose.hose.length, hoseConfiguration.hose.hose, props.locale)}):</strong>
          </span>
        </div>
      );
    }
    return (
      <div className="hc-summary-hose-part-heading">
        <strong><HosePartLabel hosePartId={hosePartId} />:</strong>
      </div>
    );
  }
  function getHosePart(hosePartId, hoseConfiguration) {
    const hosePart = hoseConfiguration[hosePartId];
    const qty = (hosePart && hosePartId === 'hose')
      ? hoseConfiguration.hose.hose.length : 1;
    let productA;
    let productB;
    let productC;
    let productD;
    let withoutHosePart = false;
    if (hosePart) {
      productA = (hosePartId === 'hose') ? hosePart.hose : hosePart.coupling;
      if (hosePartId === 'hose') {
        productB = hosePart.hoseInsulation;
      } else {
        productB = (props.services.hoseConfiguratorService.hoseClipNeededOnHoseEnd(
          hosePartId, hoseConfiguration
        )) ? hosePart.hoseClip : hosePart.screwFitting;
        if (hoseConfiguration[hosePartId].socketForHose.id) {
          productC = hoseConfiguration[hosePartId].socketForHose;
        }
        if (hoseConfiguration[hosePartId].socketForHoseInsulation.id) {
          productD = hoseConfiguration[hosePartId].socketForHoseInsulation;
        }
      }
      withoutHosePart = productA.withoutHosePart && productB.withoutHosePart;
    }
    return (
      <Fragment>
        {getHosePartHeading(hosePartId, hoseConfiguration)}
        {!hosePart && (
          <p className="hc-summary-no-product"><FormattedMessage id="hcSummaryNotYetDefined" /></p>
        )}
        {(withoutHosePart) && (
          <p className="hc-summary-no-product"><FormattedMessage id="hcSummaryWithoutHosePart" /></p>
        )}
        {(productA && !productA.withoutHosePart) && (
          getProductRow(productA, qty)
        )}
        {(productB && !productB.withoutHosePart && !productB.definitionPostpowned) && (
          getProductRow(productB, qty)
        )}
        {(productC && !productC.withoutHosePart) && (
          getProductRow(productC, qty, 'hcSocketForHose')
        )}
        {(productD && !productD.withoutHosePart) && (
          getProductRow(productD, qty, 'hcSocketForInsulation')
        )}
      </Fragment>
    );
  }
  function getFinalPart() {
    return (
      <Fragment>
        <div className="grid-hc-summary-flat-row">
          <div>
            <FormattedMessage id="hcConfectioning" />
          </div>
          <div className="text-align-right">
            {props.services.hoseConfiguratorService.getAssemblyCosts(props.hoseConfiguration).toLocaleString(props.locale, { style: 'currency', currency: props.currency })}
          </div>
        </div>
        <div className="grid-hc-summary-flat-row grid-hc-summary-flat-row--net">
          <div>
            <strong><FormattedMessage id="netPrice" /></strong>
          </div>
          <div className="text-align-right">
            <strong>
              {props.services.hoseConfiguratorService.getPositionPriceInfo(
                props.hoseConfiguration, 1, props.dataStorage, props.locale
              ).formattedPrice}
            </strong>
          </div>
        </div>
      </Fragment>
    );
  }
  if (props.configuratorState === 'start') {
    return (
      <Fragment>
        <h2><FormattedMessage id="hcHoses" /></h2>
        <p><FormattedMessage id="hcSeoHoses" /></p>
        <h2><FormattedMessage id="hcCouplings" /></h2>
        <p><FormattedMessage id="hcSeoCouplings" /></p>
        <h2><FormattedMessage id="hcFittings" /></h2>
        <p><FormattedMessage id="hcSeoFittings" /></p>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <h2><FormattedMessage id="hcYourConfiguration" /></h2>
      {getHosePart('endA', props.hoseConfiguration)}
      {getHosePart('hose', props.hoseConfiguration)}
      {getHosePart('endB', props.hoseConfiguration)}
      {(
        props.hoseConfiguration.endA
        && props.hoseConfiguration.hose
        && props.hoseConfiguration.endB
      ) && (getFinalPart())}
    </Fragment>
  );
}

Summary.propTypes = {
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  configuratorState: PropTypes.string.isRequired,
  hoseConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
};
