import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export default function UserDetails(props) {
  return (
    <div className={props.classNames}>
      {props.user.company}<br />
      <FormattedMessage id={props.user.salutation} /> {props.user.firstname} {props.user.lastname}
      <br />
      <FormattedMessage id="email" />: {props.user.email}<br />
      {props.user.invoiceEmail && (<Fragment><FormattedMessage id="invoiceEmail" />: {props.user.invoiceEmail}<br /></Fragment>)}
      {props.user.customerId && (<Fragment><FormattedMessage id="customerId" />: {props.user.customerId}<br /></Fragment>)}
    </div>
  );
}

UserDetails.propTypes = {
  classNames: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

UserDetails.defaultProps = {
  classNames: undefined,
};
