function round(n, digits) {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n *= -1;
  }
  const multiplicator = 10 ** digits;
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(digits);
  if (negative) {
    n = (n * -1).toFixed(digits);
  }
  return Number(n);
}

function canBeConvertedToNumber(value) {
  try {
    if (typeof value === 'number' || (typeof value === 'string' && value.match(/^\d+(\.\d{1,2})?$/) !== null)) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

function canBeConvertedToInteger(value) {
  try {
    return Number.isInteger(Number(value));
  } catch (err) {
    return false;
  }
}

function modulo(n1, n2) {
  return (((n1 * 10000).toFixed(0)) % ((n2 * 10000)).toFixed(0)) / 10000;
}

function median(values) {
  if (values.length === 0) {
    return undefined;
  }
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) {
    return values[half];
  }
  return (values[half - 1] + values[half]) / 2.0;
}

function getDecimalSeparatorByLocale(locale) {
  return Number(1.1).toLocaleString(locale).replaceAll('1', '');
}

function getThousandSeparatorByLocale(locale) {
  return (getDecimalSeparatorByLocale(locale) === '.' ? ',' : '.');
}

function allDefinedValuesAreNumeric(values) {
  for (let i = 0; i < values.length; i++) {
    if (values[i]) {
      if (!canBeConvertedToNumber(values[i])
      || (values[i].toString()[0] === '0' && values[i].toString().length > 0)) {
        return false;
      }
    }
  }
  return true;
}

function findNumbersInString(str, decimalSeparator) {
  return str.match((decimalSeparator === ',') ? /\d+,*\d*/g : /\d+\.*\d*/g);
}

export default {
  round,
  canBeConvertedToNumber,
  canBeConvertedToInteger,
  modulo,
  median,
  getDecimalSeparatorByLocale,
  getThousandSeparatorByLocale,
  allDefinedValuesAreNumeric,
  findNumbersInString,
};
