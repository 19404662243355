import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import ProductOtyInput from '../Product/ProductOtyInput';
import CustomLink from '../Link/CustomLink';
import HorizontalImages from '../Image/HorizontalImages';
import PositionTitle from './PositionTitle';
import ListPriceAndDiscount from '../Product/ListPriceAndDiscount';
import Icon from '../Layout/Icon';
import numberHelper from '../../utils/helper/number-helper';
import './Cart.css';

export default function CartPosition(props) {
  const intl = useIntl();
  const [qty, setQty] = useState(props.qty);
  const [lastInputComma, setLastInputComma] = useState(false);
  const orderQuantityInfo = props.services.salesService.getOrderQuantityInfo(
    props.product, props.customProductType
  );
  const positionPriceInfo = props.services.salesService.getPositionPriceInfo(
    props.product, props.qty, props.dataStorage, props.locale, props.customProductType
  );
  const qtyChangeHandler = (newQty) => {
    let tmpQty = props.services.salesService.unifyInputIfDecimal(
      props.product, props.customProductType, newQty, props.locale
    );
    if (props.services.salesService.isValidOrderQuantity(tmpQty, props.product, props.customProductType) || tmpQty === '') {
      if (orderQuantityInfo.hasDecimalOrderQuantity) {
        if (!lastInputComma
          && newQty.endsWith(numberHelper.getDecimalSeparatorByLocale(props.locale))
        ) {
          setLastInputComma(true);
        } else if (lastInputComma && tmpQty.includes('.0') && !tmpQty.endsWith('.0')) {
          tmpQty = tmpQty.replace('.0', '.');
          setLastInputComma(false);
        } else {
          setLastInputComma(false);
        }
      }
      if (newQty !== '') {
        props.services.dataStorageService.updateCart(
          props.product, Number(tmpQty), props.dataStorage
        );
      }
      setQty(tmpQty);
      props.positionStatusHandler(props.product.id, newQty !== '');
    }
  };
  const incrementHandler = (e) => {
    e.preventDefault(e);
    qtyChangeHandler((Number(props.qty) + orderQuantityInfo.increment).toString());
  };
  const decrementHandler = (e) => {
    e.preventDefault(e);
    qtyChangeHandler((Number(props.qty) - orderQuantityInfo.increment).toString());
  };
  return (
    <div className="grid-cart-position">
      <div className="cart-position-image">
        <HorizontalImages
          imgArray={props.services.salesService.getPositionImgArray(
            props.product, props.locale, props.customProductType, intl
          )}
          hasLinks={props.hasProductLinks}
        />
      </div>
      <div className="cart-position-product-name">
        <PositionTitle
          product={props.product}
          customProductType={props.customProductType}
          locale={props.locale}
          services={props.services}
        />
        <div className="cart-position-product-id">(<FormattedMessage id="articleId" />: {props.product.id})</div>
      </div>
      <div className="cart-position-qty">
        <form className="form add-product-container">
          <ProductOtyInput
            id={`orderQuantity${props.index}`}
            value={(qty === '') ? qty : props.qty}
            unitOfQuantity={props.services.salesService.getPositionUnitOfQuantity(
              props.product, props.locale, props.customProductType
            )}
            qtyChangeHandler={qtyChangeHandler}
            incrementHandler={(orderQuantityInfo.showButtons) ? incrementHandler : undefined}
            decrementHandler={(orderQuantityInfo.showButtons) ? decrementHandler : undefined}
            disableDecrementButton={orderQuantityInfo.increment
              && Number(props.qty) - orderQuantityInfo.increment <= (orderQuantityInfo.min || 0)}
            numberFormat={(orderQuantityInfo.hasDecimalOrderQuantity)
              ? { locale: props.locale, minimumFractionDigits: (lastInputComma) ? 1 : undefined }
              : undefined}
          />
        </form>
      </div>
      <div className="cart-position-discount">
        {positionPriceInfo.discount && (
          <ListPriceAndDiscount
            listPrice={positionPriceInfo.formattedListPrice}
            discount={positionPriceInfo.formattedDiscount}
          />
        )}
      </div>
      <div className="cart-position-price">
        {positionPriceInfo.formattedPrice}
      </div>
      <div className="cart-position-delete">
        <div className="cart-delete-button">
          <CustomLink
            href="#"
            onClickHandler={props.positionDeleteHandler}
            onClickHandlerParams={{ product: props.product, dataStorage: props.dataStorage }}
          ><Icon name="close" size={20} />
          </CustomLink>
        </div>
      </div>
    </div>
  );
}

CartPosition.propTypes = {
  index: PropTypes.number.isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  customProductType: PropTypes.string,
  qty: PropTypes.number.isRequired,
  positionStatusHandler: PropTypes.func.isRequired,
  positionDeleteHandler: PropTypes.func.isRequired,
  hasProductLinks: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

CartPosition.defaultProps = {
  customProductType: undefined,
  hasProductLinks: true,
};
