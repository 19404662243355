import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import SingleMenuItem from '../Menu/SingleMenuItem';

function LanguageSwitcher(props) {
  const pageLangVersInfo = props.services.i18nService.getPageLanguageVersions(
    useLocation().pathname, props.dataStorage
  );
  const currentPageLangInfo = pageLangVersInfo.filter(
    (language) => language.code === props.locale
  )[0];
  const otherPageLangInfos = pageLangVersInfo.filter((language) => language.code !== props.locale);
  otherPageLangInfos.forEach((pageLangInfo) => {
    pageLangInfo.onClickHandler = props.setLocale;
    pageLangInfo.onClickHandlerParams = {
      locale: pageLangInfo.code,
    };
  });
  if (props.type === 'hover') {
    return (
      <Dropdown
        isListItem={true}
        type="hover"
        heading={(props.useCodeAsLabel)
          ? currentPageLangInfo.code.toUpperCase() : currentPageLangInfo.label}
        headingPrefixIcon="globe"
        items={otherPageLangInfos}
      />
    );
  }
  const listItems = otherPageLangInfos.map((pageLangInfo) => (
    <SingleMenuItem
      classNames={props.classNames.sli}
      link={pageLangInfo.link}
      label={(props.useCodeAsLabel) ? pageLangInfo.code.toUpperCase() : pageLangInfo.label}
      onClickHandler={pageLangInfo.onClickHandler}
      onClickHandlerParams={pageLangInfo.onClickHandlerParams}
      key={pageLangInfo.label}
    />
  ));
  return (
    <ul>
      <SingleMenuItem
        type="click"
        label={(props.useCodeAsLabel)
          ? currentPageLangInfo.code.toUpperCase() : currentPageLangInfo.label}
        key={currentPageLangInfo.label}
      />
      {listItems}
    </ul>
  );
}

LanguageSwitcher.propTypes = {
  type: PropTypes.string,
  useCodeAsLabel: PropTypes.bool,
  classNames: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

LanguageSwitcher.defaultProps = {
  type: undefined,
  useCodeAsLabel: false,
  classNames: undefined,
};

export default LanguageSwitcher;
