import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Menu from '../Menu/Menu';
import SocialChannelButtons from '../SocialCannelButtons/SocialChannelButtons';
import CookieModal from '../CookieModal/CookieModal';
import './Footer.css';

export default function Footer(props) {
  const date = new Date();
  const year = date.getFullYear();
  const [cookieModalActive, setCookieModalActive] = useState(false);
  const cookieModalOpenHandler = () => {
    setCookieModalActive(true);
  };
  const cookieModalCloseHandler = () => {
    setCookieModalActive(false);
  };
  function getLegalMenu() {
    return props.menus.legalMenu.map((item) => {
      if (item.hasCookieDialog) {
        item.link = '#';
        item.onClickHandler = cookieModalOpenHandler;
      }
      return item;
    });
  }
  return (
    <footer>
      <div className="footer-top">
        <div className="grid-footer-top">
          <div className="grid-footer-top__item grid-footer-top__item--1">
            <Menu
              type="flat"
              classNames={{
                ul: 'grid-footer-content',
                li: 'grid-footer-content__item',
                heading: 'footer-submenu-heading',
                subli: 'footer-submenu-item',
              }}
              items={props.menus.footerMenu}
            />
          </div>
          <SocialChannelButtons
            classNames="grid-footer-top__item grid-footer-top__item--2"
            socialChannels={props.socialChannels}
          />
        </div>
      </div>
      <div className="footer-bottom">
        <div className="grid-footer-bottom">
          <Menu
            classNames={{ nav: 'grid-footer-bottom__item grid-footer-bottom__item--1', ul: 'grid-legal', sli: 'grid-legal__item' }}
            type="dropdownHover"
            items={getLegalMenu()}
            locale={props.locale}
            setLocale={props.setLocale}
          />
          <div className="grid-footer-bottom__item grid-footer-bottom__item--2">
            &#169; <FormattedMessage id="copyrightFooter" values={{ year, company: props.siteOwner }} />
          </div>
          <CookieModal
            active={cookieModalActive}
            dialog="details"
            closeHandler={cookieModalCloseHandler}
            locale={props.locale}
            services={props.services}
          />
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  menus: PropTypes.objectOf(PropTypes.array).isRequired,
  socialChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteOwner: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
};
