import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../Menu/Menu';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import './Drawer.css';

export default function DrawerMenuContent(props) {
  if (props.menuId === 'main-menu') {
    return (
      <Menu
        type="verticalMenu"
        classNames={{ sli: 'drawer-single-item' }}
        items={props.menuItems}
        expandedMenuPath={props.expandedMenuPath}
        setExpandedMenuPath={props.setExpandedMenuPath}
      />
    );
  } else if (props.menuId === 'account-menu') {
    return (
      <Menu
        type="click"
        classNames={{ sli: 'drawer-single-item' }}
        items={props.menuItems}
        expandedMenuPath={props.expandedMenuPath}
        setExpandedMenuPath={props.setExpandedMenuPath}
        services={props.services}
      />
    );
  }
  return (
    <LanguageSwitcher
      classNames={{ sli: 'drawer-single-item' }}
      locale={props.locale}
      setLocale={props.setLocale}
      dataStorage={props.dataStorage}
      services={props.services}
      key="Drawer-LanguageSwitcher"
    />
  );
}

DrawerMenuContent.propTypes = {
  menuId: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func,
  dataStorage: PropTypes.objectOf(PropTypes.any),
  services: PropTypes.objectOf(PropTypes.any),
};

DrawerMenuContent.defaultProps = {
  expandedMenuPath: null,
  setExpandedMenuPath: undefined,
  dataStorage: undefined,
  services: null,
};
