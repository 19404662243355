import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import Checkbox from './Checkbox';
import './Form.css';

export default function FormBuyer(props) {
  const intl = useIntl();
  return (
    <Fragment>
      <FormInput
        id="company"
        value={props.company}
        showIsRequired={true}
        callback={props.companyChangeHandler}
        isValid={props.companyIsValid}
        errorMessageId="invalidCompanyNameMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      <FormInput
        id="customerId"
        value={props.customerId}
        callback={props.customerIdChangeHandler}
        disabled={props.customerIdIsDisabled}
        isValid={props.customerIdIsValid}
        errorMessageId="invalidCustomerIdMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      <FormSelect
        id="salutation"
        values={[
          ['mr', intl.formatMessage({ id: 'mr' })],
          ['mrs', intl.formatMessage({ id: 'mrs' })],
        ]}
        currentValue={props.salutation}
        showIsRequired={true}
        callback={props.salutationChangeHandler}
      />
      <FormInput
        id="firstname"
        value={props.firstname}
        showIsRequired={true}
        callback={props.firstnameChangeHandler}
        isValid={props.firstnameIsValid}
        errorMessageId="invalidNameMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      <FormInput
        id="lastname"
        value={props.lastname}
        showIsRequired={true}
        callback={props.lastnameChangeHandler}
        isValid={props.lastnameIsValid}
        errorMessageId="invalidNameMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      <FormInput
        id="email"
        value={props.email}
        showIsRequired={true}
        callback={props.emailChangeHandler}
        disabled={props.emailIsDisabled}
        isValid={props.emailIsValid}
        errorMessageId="invalidEmailMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      {props.showInvoiceEmail && (
      <Checkbox
        id="hasInvoiceEmail"
        divClassNames="form-checkbox margin-t20"
        checked={props.hasInvoiceEmail}
        label={intl.formatMessage({ id: 'invoicesToAlternativeEmail' })}
        callback={props.hasInvoiceEmailChangeHandler}
      />
      )}
      {(props.showInvoiceEmail && props.hasInvoiceEmail) && (
      <FormInput
        id="invoiceEmail"
        value={props.invoiceEmail}
        showIsRequired={true}
        callback={props.invoiceEmailChangeHandler}
        isValid={props.invoiceEmailIsValid}
        errorMessageId="invalidEmailMessage"
        noErrorMessageIfEmpty={!props.submitButtonIsClicked}
      />
      )}
    </Fragment>
  );
}

FormBuyer.propTypes = {
  customerIdIsDisabled: PropTypes.bool,
  emailIsDisabled: PropTypes.bool,
  showInvoiceEmail: PropTypes.bool,
  company: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  salutation: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  email: PropTypes.string,
  hasInvoiceEmail: PropTypes.bool,
  invoiceEmail: PropTypes.string,
  companyChangeHandler: PropTypes.func.isRequired,
  customerIdChangeHandler: PropTypes.func.isRequired,
  salutationChangeHandler: PropTypes.func.isRequired,
  firstnameChangeHandler: PropTypes.func.isRequired,
  lastnameChangeHandler: PropTypes.func.isRequired,
  emailChangeHandler: PropTypes.func,
  hasInvoiceEmailChangeHandler: PropTypes.func,
  invoiceEmailChangeHandler: PropTypes.func,
  companyIsValid: PropTypes.bool.isRequired,
  customerIdIsValid: PropTypes.bool.isRequired,
  firstnameIsValid: PropTypes.bool.isRequired,
  lastnameIsValid: PropTypes.bool.isRequired,
  emailIsValid: PropTypes.bool,
  invoiceEmailIsValid: PropTypes.bool,
  submitButtonIsClicked: PropTypes.bool.isRequired,
};

FormBuyer.defaultProps = {
  customerIdIsDisabled: false,
  emailIsDisabled: false,
  showInvoiceEmail: true,
  email: undefined,
  emailChangeHandler: undefined,
  emailIsValid: undefined,
  hasInvoiceEmail: false,
  hasInvoiceEmailChangeHandler: undefined,
  invoiceEmail: undefined,
  invoiceEmailChangeHandler: undefined,
  invoiceEmailIsValid: undefined,
};
