import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CustomLink.css';

function CustomLink(props) {
  function getInnerHtml(children, isActive) {
    return (isActive) ? (<span className="active-link">{children}</span>) : children;
  }
  if (props.isExternal) {
    return (
      <a href={props.href} target={props.target}>
        {getInnerHtml(props.children, props.isActive)}
      </a>
    );
  } else if (props.onClickHandler && props.onClickHandlerParams) {
    return (
      <Link
        to={props.href}
        target={props.target}
        onClick={() => props.onClickHandler(props.onClickHandlerParams)}
      >
        {getInnerHtml(props.children, props.isActive)}
      </Link>
    );
  } else if (props.onClickHandler) {
    return (
      <Link to={props.href} target={props.target} onClick={() => props.onClickHandler()}>
        {getInnerHtml(props.children, props.isActive)}
      </Link>
    );
  }
  return (
    <Link to={props.href} target={props.target} download={props.download}>
      {getInnerHtml(props.children, props.isActive)}
    </Link>
  );
}

CustomLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  isActive: PropTypes.bool,
  isExternal: PropTypes.bool,
  download: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
  onClickHandler: PropTypes.func,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.any),
};

CustomLink.defaultProps = {
  href: '#',
  target: undefined,
  isActive: false,
  isExternal: false,
  download: undefined,
  onClickHandler: undefined,
  onClickHandlerParams: undefined,
};

export default CustomLink;
