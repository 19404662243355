import React from 'react';
import PropTypes from 'prop-types';

export default function FileInput(props) {
  return (
    <input
      id={props.id}
      className={props.classNames}
      type="file"
      disabled={props.disabled}
      readOnly={props.readOnly}
      multiple={props.multiple}
      placeholder={props.placeholder}
      onChange={props.callback}
    />
  );
}

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
};

FileInput.defaultProps = {
  classNames: undefined,
  disabled: false,
  readOnly: false,
  multiple: false,
  placeholder: '',
};
