import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import PageWrapper from '../Wrapper/PageWrapper';
import Message from '../Message/Message';
import Menu from '../Menu/Menu';
import ProductList from '../Product/ProductList';
import './Page.css';

export default function CombinableProducts(props) {
  const intl = useIntl();
  const sortAttributeSettings = props.services.productService.getSortAttributeSettings([]);
  const [product, setProduct] = useState();
  const [relatedProducts, setRelatedProducts] = useState();
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const { id } = query;
    if (id) {
      const tmpProduct = props.services.productService.getProduct(id, props.dataStorage);
      const productTypeConfig = props.services.productService.getProductTypeConfigByProduct(
        tmpProduct
      );
      setProduct(tmpProduct);
      setRelatedProducts(props.services.productService.getRelatedProducts(
        productTypeConfig, tmpProduct, 'fitsTo', props.dataStorage.products, props.locale, 10000
      ));
    } else {
      setProduct(undefined);
    }
  }, []);
  function getFilterAttributeIds() {
    let productType;
    for (let i = 0; i < relatedProducts.length; i++) {
      if (!productType) {
        productType = relatedProducts[i].productType.i18nId;
      } else if (productType !== relatedProducts[i].productType.i18nId) {
        return props.services.productService.getFilterAttributes('default');
      }
    }
    return props.services.productService.getFilterAttributes(productType)
      || props.services.productService.getFilterAttributes('default');
  }
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <div className="content-container">
        <div className="grid-shop-sidebar-main">
          <div className="shop-sidebar">
            <div className="shop-category-menu-heading">
              {intl.formatMessage({ id: 'categories' })}
            </div>
            <Menu
              type="verticalMenu"
              classNames={{
                ul: 'shop-category-menu',
              }}
              items={props.services.configService.getShopSidebarMenu(props.locale)}
              services={props.services}
            />
          </div>
          <div className="shop-main">
            {product !== undefined && (
              <Fragment>
                <h1><FormattedMessage id="followingArticlesFit" /> {product.name[props.locale]}:</h1>
                <ProductList
                  products={relatedProducts}
                  currency={props.services.configService.getGeneralConfig().sales.currency}
                  changeHandlers={{
                    filterChangeHandler: () => {},
                    sortChangeHandler: () => {},
                  }}
                  presets={{
                    filter: {},
                    sort: props.services.productService.getDefaultProductListSortOption(
                      sortAttributeSettings, intl, props.locale
                    ),
                  }}
                  sortOptions={props.services.productService.getProductListSortOptions(
                    sortAttributeSettings, intl, props.locale
                  )}
                  filterAttributeIds={getFilterAttributeIds()}
                  showSearch={true}
                  showProductCount={true}
                  enableUrlParams={true}
                  locale={props.locale}
                  dataStorage={props.dataStorage}
                  services={props.services}
                />
              </Fragment>
            )}
            {product === undefined && (
              <Message type="error">
                <FormattedMessage id="productIdUnknown" />
              </Message>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

CombinableProducts.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
