import React from 'react';
import PropTypes from 'prop-types';
import './Layout.css';

export default function Kpis(props) {
  function getItems() {
    return props.items.map((kpi) => (
      <div className="kip">
        <div className="kpi-number center">{kpi.number}</div>
        <div className="kpi-label center">{kpi.label}</div>
      </div>
    ));
  }
  return (
    <div className={`kpi-grid${(props.classNames) ? ` ${props.classNames}` : ''}`}>
      {getItems()}
    </div>
  );
}

Kpis.propTypes = {
  classNames: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Kpis.defaultProps = {
  classNames: undefined,
};
