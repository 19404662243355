import React from 'react';
import PropTypes from 'prop-types';
import SelectionButton from './SelectionButton';
import './EjectorConfigurator.css';

function Selection(props) {
  function getSelectionButtons() {
    return props.items.map((item) => (
      <SelectionButton
        text={item.text}
        subtext={item.subtext}
        imgSrc={item.imgSrc}
        onClickHandler={item.onClickHandler}
        onClickHandlerParams={item.onClickHandlerParams}
        key={JSON.stringify(item)}
      />
    ));
  }
  return (
    <div className="ec-grid-selection">
      {getSelectionButtons()}
    </div>
  );
}

Selection.propTypes = {
  items: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Selection;
