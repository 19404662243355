import * as emailValidator from 'email-validator';

function isValidPersonName(name) {
  if (!name || name.length < 2) {
    return false;
  }
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u.test(name);
}

function isValidCompanyName(companyName) {
  return companyName.length > 1 && /[a-zA-Z]/.test(companyName);
}

function isValidCustomerId(customerId, isOptional) {
  if (isOptional && !customerId) {
    return true;
  }
  return /^\d{5}$/.test(customerId);
}

function isValidStreetNameAndNumber(streetAndNumber) {
  return streetAndNumber.length > 2 && /[a-zA-Z]/.test(streetAndNumber);
}

function isValidPhoneNumber(phoneNumber) {
  return phoneNumber.length > 6 && !/[a-zA-Z]/.test(phoneNumber);
}

function isValidPostOfficeBox(postOfficeBox) {
  return postOfficeBox.length > 0;
}

function isValidZipCode(zipCode) {
  return zipCode.length > 3;
}

function isValidCity(city) {
  if (!city) {
    return false;
  }
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u.test(city);
}

function isValidPassword(password) {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{12,20}$/.test(password);
}

function isValidEmailAddress(emailAddress, isOptional) {
  if (isOptional && !emailAddress) {
    return true;
  }
  // return /\S+@\S+\.\S+/.test(emailAddress);
  return emailValidator.validate(emailAddress);
}

function isValidUrl(url) {
  return /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(url);
}

export default {
  isValidPersonName,
  isValidCompanyName,
  isValidCustomerId,
  isValidStreetNameAndNumber,
  isValidPostOfficeBox,
  isValidZipCode,
  isValidCity,
  isValidPassword,
  isValidEmailAddress,
  isValidPhoneNumber,
  isValidUrl,
};
