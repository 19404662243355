import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';
import PageWrapper from '../Wrapper/PageWrapper';
import ProviderAddress from '../Layout/ProviderAddress';
import ContactForm from '../Form/ContactForm';
import './Page.css';

export default function Contact(props) {
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <DefaultHeadingBar
        locale={props.locale}
        services={props.services}
      />
      <div className="content-container">
        <div className="grid-contact">
          <div>
            <div className="form-and-heading">
              <div className="form-logo-container center"><img src="/logo192.png" alt={`${props.services.configService.getGeneralConfig().brand} logo`} className="form-logo" /></div>
              <h2 className="center"><FormattedMessage id="contactForm" /></h2>
              <ContactForm
                services={props.services}
                locale={props.locale}
                formId="main"
                siteOwner={props.services.configService.getGeneralConfig().brand.longName}
                privacyPolicyLink={props.services.configService.getPageByTranslationCodeAndLocale('privacy-policy', props.locale).path}
              />
            </div>
          </div>
          <div className="contact-address-info links-in-primary-theme-color">
            <ProviderAddress
              layout="headings"
              locale={props.locale}
              services={props.services}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

Contact.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
