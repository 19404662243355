import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '../Form/Checkbox';

export default function Address(props) {
  const intl = useIntl();
  return (
    <div className={props.classNames}>
      {props.address.nameLine1}<br />
      {(props.address.nameLine2) && (<Fragment>{props.address.nameLine2}<br /></Fragment>)}
      {props.address.postOfficeBox && <FormattedMessage id="postOfficeBox" />}{props.address.street || ` ${props.address.postOfficeBox}`}<br />
      {props.address.zip} {props.address.city}<br />
      <FormattedMessage id={`CC_${props.address.country}`} />
      {(props.showTaxNo && props.address.taxNo) && (
        <div className="address-tax-no"><FormattedMessage id="taxNo" />: {props.address.taxNo}</div>
      )}
      {(props.showSettings && props.address.isStandardShippingAddress) && (
        <Checkbox
          id="standardShippingAddress"
          divClassNames="form-checkbox form-checkbox--small"
          checked={props.address.isStandardShippingAddress}
          label={intl.formatMessage({ id: 'standardShippingAddress' })}
          callback={() => {}}
          disabled={true}
        />
      )}
      {(props.showSettings && props.address.isStandardBillingAddress) && (
        <Checkbox
          id="standardBillingAddress"
          divClassNames="form-checkbox form-checkbox--small"
          checked={props.address.isStandardBillingAddress}
          label={intl.formatMessage({ id: 'standardBillingAddress' })}
          callback={() => {}}
          disabled={true}
        />
      )}
    </div>
  );
}

Address.propTypes = {
  classNames: PropTypes.string,
  address: PropTypes.objectOf(PropTypes.any).isRequired,
  showSettings: PropTypes.bool,
  showTaxNo: PropTypes.bool,
};

Address.defaultProps = {
  classNames: undefined,
  showSettings: false,
  showTaxNo: false,
};
