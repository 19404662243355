import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

export default function Button(props) {
  return (
    <button
      type={props.type === 'button' ? 'button' : 'submit'}
      className={props.classNames}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      title={props.title}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  classNames: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

Button.defaultProps = {
  type: 'submit',
  classNames: undefined,
  onClick: undefined,
  title: undefined,
  disabled: false,
};
