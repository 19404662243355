import React from 'react';
import PropTypes from 'prop-types';
import DynamicProductContent from '../Product/DynamicProductContent';
import './Page.css';

export default function ProductAndCategory(props) {
  return (
    <DynamicProductContent
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    />
  );
}

ProductAndCategory.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
