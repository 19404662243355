import React from 'react';
import PropTypes from 'prop-types';
import StepperProgressItem from './StepperProgressItem';
import './Stepper.css';

export default function StepperProgressBar(props) {
  const steps = props.steps.map((step, index) => (
    <StepperProgressItem
      number={index + 1}
      labelTranslationId={step.labelTranslationId}
      activeStep={props.activeStep}
      isLastStep={props.steps.length === index + 1}
      key={JSON.stringify(step)}
    />
  ));

  return (
    <div className="grid-stepper-progress-bar">
      {steps}
    </div>
  );
}

StepperProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.any).isRequired,
  activeStep: PropTypes.number.isRequired,
};
