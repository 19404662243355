import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Filters from './Filters';
import FilterSummary from './FilterSummary';
import SearchInput from '../Search/SearchInput';
import SortBy from './SortBy';
import Tabs from '../Tabs/Tabs';
import Button from '../Button/Button';
import Icon from '../Layout/Icon';
import './List.css';

export default function FiltersSearchAndSort(props) {
  const intl = useIntl();
  const [activeTabId, setActiveTabId] = useState(undefined);
  const activeTabIdChangeHandler = (id) => {
    setActiveTabId(id);
  };
  const getFilters = (idSuffix) => (
    <Filters
      filters={props.filters}
      expandedFilterId={props.expandedFilterId}
      listItemCount={props.listItemCount}
      handlers={{
        changeHandler: props.handlers.filterChangeHandler,
        deleteHandler: props.handlers.filterDeleteHandler,
        expandedFilterChangeHandler: props.handlers.expandedFilterChangeHandler,
      }}
      dropdownIdSuffix={idSuffix}
      locale={props.locale}
      services={props.services}
    />
  );
  const getSearch = () => (
    <SearchInput
      value={props.searchTerm}
      onFocusHandler={() => {}}
      onChangeHandler={props.handlers.searchTermChangeHandler}
    />
  );
  const getTabItems = () => {
    const items = [
      {
        id: 'filters',
        headingText: intl.formatMessage({ id: 'filters' }),
        headingPrefixIcon: 'filter',
        headingSuffixIcon: 'angle-down',
        content: getFilters('mobile'),
      },
    ];
    if (props.showSearch) {
      items.push(
        {
          id: 'search',
          headingText: intl.formatMessage({ id: 'search' }),
          headingPrefixIcon: 'search',
          headingSuffixIcon: 'angle-down',
          content: (
            <div className="list-search-container-mobile">
              {getSearch()}
            </div>
          ),
        }
      );
    }
    return items;
  };
  return (
    <Fragment>
      <div className="list-filter-container desktop-only">
        <div className="list-filter-heading"><Icon name="filter" size="20" /><FormattedMessage id="filters" />:</div>
        {getFilters('desktop')}
        {(Object.keys(props.activeFilters).length || props.searchTerm.length > 1) > 0 && (
          <FilterSummary
            activeFilters={props.services.listService.getFilterSummary(
              props.activeFilters, props.filters
            )}
            changeHandler={props.handlers.filterChangeHandler}
            deleteHandler={props.handlers.filterDeleteHandler}
            searchTerm={props.searchTerm}
            searchTermChangeHandler={props.handlers.searchTermChangeHandler}
          />
        )}
      </div>
      <div className="list-filter-search-mobile mobile-only">
        <Tabs
          items={getTabItems()}
          activeTabIdChangeHandler={activeTabIdChangeHandler}
          activeTabId={activeTabId}
          isToggle={true}
        />
        {(Object.keys(props.activeFilters).length || props.searchTerm.length > 1) > 0 && (
          <FilterSummary
            activeFilters={props.services.listService.getFilterSummary(
              props.activeFilters, props.filters
            )}
            changeHandler={props.handlers.filterChangeHandler}
            deleteHandler={props.handlers.filterDeleteHandler}
            searchTerm={props.searchTerm}
            searchTermChangeHandler={props.handlers.searchTermChangeHandler}
          />
        )}
      </div>
      <div className="list-search-sort-layout-container">
        {props.handlers.layoutChangeHandler && (
          <div className="list-layout-float">
            <Button classNames="button button--small button--secondary button--right button--list-layout" type="submit" onClick={() => props.handlers.layoutChangeHandler({ layout: 'table' })} disabled={props.activeLayout === 'table'} title={intl.formatMessage({ id: 'arrangeLikeTable' })}><Icon name="menu" size={16} /></Button>
            <Button classNames="button button--small button--secondary button--right button--list-layout" type="submit" onClick={() => props.handlers.layoutChangeHandler({ layout: 'grid' })} disabled={props.activeLayout === 'grid'} title={intl.formatMessage({ id: 'arrangeLikeGrid' })}><Icon name="grid-2col" size={16} /></Button>
          </div>
        )}
        <div className="list-sort-float center-vertically">
          <SortBy
            items={props.services.listService.getSortDropdownItems(
              props.sortOptions, props.activeSortOption, props.handlers.sortChangeHandler
            )}
          />
        </div>
        {props.showSearch && (
          <div className="list-desktop-search desktop-only">
            {getSearch()}
          </div>
        )}
      </div>
    </Fragment>
  );
}

FiltersSearchAndSort.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  expandedFilterId: PropTypes.string,
  listItemCount: PropTypes.number.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchTerm: PropTypes.string.isRequired,
  activeSortOption: PropTypes.objectOf(PropTypes.any).isRequired,
  activeLayout: PropTypes.string,
  handlers: PropTypes.objectOf(PropTypes.func).isRequired,
  showSearch: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

FiltersSearchAndSort.defaultProps = {
  expandedFilterId: undefined,
  showSearch: false,
  activeLayout: 'grid',
};
