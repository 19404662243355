import urlHelper from '../utils/helper/url-helper';

const queryParamName = 'searchterm';
const separator = '_';
let configService;
let productService;
let listService;
let apiService;
const cache = {};

function setDependencies(dependencies) {
  ({
    configService, productService, listService, apiService,
  } = dependencies);
  return this;
}

function getExternalSearchSources(locale) {
  const generalSearchConfig = configService.getGeneralConfig().search;
  if (generalSearchConfig.externalSources
    && Array.isArray(generalSearchConfig.externalSources[locale])
  ) {
    return generalSearchConfig.externalSources[locale];
  }
  return [];
}

function searchExternalWebpageCompareFunc(webpage, searchTermParts, searchTermPartWeights) {
  searchTermParts.forEach((searchTermPart) => {
    if (webpage.label.toUpperCase() === searchTermPart) {
      searchTermPartWeights[searchTermPart] += 8;
    } else if (webpage.label.toUpperCase().startsWith(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 4;
    } else if (webpage.label.toUpperCase().includes(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 1;
    }
    if (webpage.synonyms) {
      webpage.synonyms.forEach((synonym) => {
        if (synonym.toUpperCase() === searchTermPart) {
          searchTermPartWeights[searchTermPart] += 6;
        } else if (synonym.toUpperCase().startsWith(searchTermPart)) {
          searchTermPartWeights[searchTermPart] += 3;
        } else if (synonym.toUpperCase().includes(searchTermPart)) {
          searchTermPartWeights[searchTermPart] += 1;
        }
      });
    }
  });
}

function searchWebpageCompareFunc(webpage, searchTermParts, searchTermPartWeights) {
  searchTermParts.forEach((searchTermPart) => {
    if (webpage.label.toUpperCase() === searchTermPart) {
      searchTermPartWeights[searchTermPart] += 10;
    } else if (webpage.label.toUpperCase().startsWith(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 5;
    } else if (webpage.label.toUpperCase().includes(searchTermPart)) {
      searchTermPartWeights[searchTermPart] += 1;
    }
    if (webpage.search.synonymGroups) {
      webpage.search.synonymGroups.forEach((synonymGroup) => {
        synonymGroup.synonyms.forEach((synonym) => {
          if (synonym.toUpperCase() === searchTermPart) {
            searchTermPartWeights[searchTermPart] += synonymGroup.weight + 2;
          } else if (synonym.toUpperCase().startsWith(searchTermPart)) {
            searchTermPartWeights[searchTermPart] += synonymGroup.weight + 1;
          } else if (synonym.toUpperCase().includes(searchTermPart)) {
            searchTermPartWeights[searchTermPart] += synonymGroup.weight;
          }
        });
      });
    }
  });
}

function searchWebpages(searchTerm, locale) {
  const webpages = configService.getPages().filter((webpage) => (
    webpage.isActive
    && webpage.languageCode === locale
    && webpage.search && webpage.search.enabled
  ));
  const webpageMatches = listService.search(
    webpages, searchTerm, 'OR', locale, searchWebpageCompareFunc, undefined, true
  );
  const externalSearchSources = getExternalSearchSources(locale);
  if (externalSearchSources.length > 0) {
    return webpageMatches.concat(listService.search(
      externalSearchSources, searchTerm, 'AND', locale, searchExternalWebpageCompareFunc, undefined, true
    ));
  }
  return webpageMatches;
}

function getSearchTerm() {
  const paramValue = urlHelper.getQueryParamValue(queryParamName);
  return (paramValue) ? paramValue.replace(separator, ' ') : undefined;
}

function getHeadData() {
  const page = configService.getPageByPath(urlHelper.getCurrentPath());
  const searchTerm = getSearchTerm();
  return {
    label: searchTerm || page.label,
    description: page.metaDescription.replace('<searchterm>', (searchTerm) ? `: ${searchTerm}` : ''),
  };
}

function isSuccessfulSearch(searchResults) {
  if ((!searchResults.products || !searchResults.products.length)
    && (!searchResults.productCategories || !searchResults.productCategories.length)
    && (!searchResults.others || !searchResults.others.length)
  ) {
    return false;
  }
  return true;
}

function getSearchResults(products, productCategories, searchTerm, locale, intl) {
  if (!searchTerm || searchTerm.length < 2) {
    return {
      products: [],
      productCategories: [],
      others: [],
    };
  }
  if (!cache[searchTerm]) {
    cache[searchTerm] = {
      products: productService.searchProducts(products, searchTerm, undefined, locale, intl, true),
      productCategories: productService.searchProductCategories(
        productCategories, searchTerm, locale, intl, true
      ),
      others: searchWebpages(searchTerm, locale),
    };
    apiService.int({
      param1: 'sear',
      param2: searchTerm,
      param4: { hasResults: isSuccessfulSearch(cache[searchTerm]) },
    });
  }
  return cache[searchTerm];
}

export default {
  setDependencies,
  getHeadData,
  getSearchResults,
  isSuccessfulSearch,
};
