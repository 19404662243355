import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import './Image.css';

export default function OverlayEffectImage(props) {
  return (
    <CustomLink
      href="#"
      onClickHandler={props.onClickHandler}
      onClickHandlerParams={props.onClickHandlerParams}
    >
      <div className={props.containerClassNames}>
        <img className={props.imageClassNames} src={props.imgSrc} alt={props.imgAlt} />
        <div className={props.overlayContainerClassNames}>
          <div className={props.overlayInfoContainerClassNames}>
            {props.children}
          </div>
        </div>
      </div>
    </CustomLink>
  );
}

OverlayEffectImage.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.any).isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  containerClassNames: PropTypes.string.isRequired,
  imageClassNames: PropTypes.string.isRequired,
  overlayContainerClassNames: PropTypes.string.isRequired,
  overlayInfoContainerClassNames: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};
