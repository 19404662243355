import React from 'react';
import PropTypes from 'prop-types';

export default function Textarea(props) {
  return (
    <textarea
      id={props.id}
      name={props.name}
      className={props.classNames}
      value={props.value}
      rows={props.rows}
      cols={props.cols}
      disabled={props.disabled}
      readOnly={props.readOnly}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onChange={({ target: { value } }) => props.callback(value)}
    />
  );
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  classNames: PropTypes.string,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

Textarea.defaultProps = {
  classNames: undefined,
  name: undefined,
  rows: undefined,
  cols: undefined,
  disabled: false,
  readOnly: false,
  placeholder: '',
  maxLength: undefined,
};
