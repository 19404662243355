import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Message from '../Message/Message';
import Tabs from '../Tabs/Tabs';
import ProductList from '../Product/ProductList';
import ProductListItem from '../Product/ProductListItem';
import ProductCategoryList from '../Product/ProductCategoryList';
import ProductCategoryListItem from '../Product/ProductCategoryListItem';
import WebsiteSearchListItem from './WebpageSearchListItem';
import LinkButton from '../Button/LinkButton';

export default function SearchResultTabs(props) {
  const intl = useIntl();
  const [activeTabId, setActiveTabId] = useState(props.activeTabId);
  const activeTabIdChangeHandler = (id) => {
    setActiveTabId(id);
  };
  const sortAttributeSettings = props.services.productService.getSortAttributeSettings([{
    name: {
      de: 'Relevanz',
      en: 'Relevance',
    },
    preset: true,
  }]);
  function getItemCount(itemType) {
    return (props.searchResults[itemType]) ? props.searchResults[itemType].length : 0;
  }
  function getItemCountForHeading(itemType) {
    const itemCount = getItemCount(itemType);
    if (itemCount === 1000) {
      return `${itemCount}+`;
    }
    return itemCount;
  }
  function getActiveTabId() {
    if (getItemCount('productCategories') > 0) {
      return 'categories';
    } else if (getItemCount('products') > 0) {
      return 'products';
    } else if (getItemCount('others') > 0) {
      return 'miscellaneous';
    }
    return undefined;
  }
  const showAllItemsHandler = () => {
    props.services.dataStorageService.setSearchTerm(props.searchTerm, props.dataStorage);
  };
  function getTooManyResultsMsg() {
    return (
      <Message type="warning">
        <FormattedMessage id="searchTooManyResults" />
      </Message>
    );
  }
  function getCategoryContent() {
    if (!props.searchResults
      || !props.searchResults.productCategories
      || props.searchResults.productCategories.length === 0
    ) {
      return undefined;
    } else if (props.isDropdown) {
      return (
        <Fragment>
          {props.searchResults.productCategories.length === 1000 && getTooManyResultsMsg()}
          <div className="grid-search-dropdown-list grid-search-dropdown-list--product-category">
            {props.searchResults.productCategories.slice(0, 4).map((productCategory) => (
              <ProductCategoryListItem
                data={props.services.productService.getProductCategoryListItemData(
                  productCategory, props.dataStorage.productCategories, props.locale
                )}
                key={JSON.stringify(productCategory)}
              />
            ))}
          </div>
          {(props.searchResults.productCategories.length > 4) && (
            <LinkButton
              href={`${props.services.configService.getPageByTranslationCodeAndLocale('search', props.locale).path}?search=${props.searchTerm}&tab=categories`}
              onClickHandler={showAllItemsHandler}
              classNames="linkbutton linkbutton--full-width"
            >{intl.formatMessage({ id: 'showAllProductCategories' })}
            </LinkButton>
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        {props.searchResults.productCategories.length === 1000 && getTooManyResultsMsg()}
        <ProductCategoryList
          productCategories={props.searchResults.productCategories}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
        />
      </Fragment>
    );
  }
  function getProductContent() {
    if (!props.searchResults
      || !props.searchResults.products
      || props.searchResults.products.length === 0
    ) {
      return undefined;
    } else if (props.isDropdown) {
      return (
        <Fragment>
          {props.searchResults.products.length === 1000 && getTooManyResultsMsg()}
          <div className="grid-search-dropdown-list">
            {props.searchResults.products.slice(0, 6).map((productItem) => (
              <ProductListItem
                services={props.services}
                locale={props.locale}
                dataStorage={props.dataStorage}
                currency={props.services.configService.getGeneralConfig().sales.currency}
                product={productItem}
                key={JSON.stringify(productItem)}
              />
            ))}
          </div>
          {(props.searchResults.products.length > 6) && (
            <LinkButton
              href={`${props.services.configService.getPageByTranslationCodeAndLocale('search', props.locale).path}?search=${props.searchTerm}&tab=products`}
              onClickHandler={showAllItemsHandler}
              classNames="linkbutton linkbutton--full-width"
            >{intl.formatMessage({ id: 'showAllProducts' })}
            </LinkButton>
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        {props.searchResults.products.length === 1000 && getTooManyResultsMsg()}
        <ProductList
          products={props.searchResults.products}
          currency={props.services.configService.getGeneralConfig().sales.currency}
          changeHandlers={{
            filterChangeHandler: () => {},
            sortChangeHandler: () => {},
          }}
          presets={{
            filter: {},
            sort: props.services.productService.getDefaultProductListSortOption(
              sortAttributeSettings, intl, props.locale
            ),
          }}
          sortOptions={props.services.productService.getProductListSortOptions(
            sortAttributeSettings, intl, props.locale
          )}
          filterAttributeIds={props.services.productService.getFilterAttributes('default')}
          enableUrlParams={true}
          locale={props.locale}
          dataStorage={props.dataStorage}
          services={props.services}
        />
      </Fragment>
    );
  }
  function getMiscellaneousContent() {
    if (!props.searchResults
      || !props.searchResults.others
      || props.searchResults.others.length === 0
    ) {
      return undefined;
    } else if (props.isDropdown) {
      return (
        <Fragment>
          <div className="grid-search-dropdown-list grid-search-dropdown-list--product-category">
            {props.searchResults.others.slice(0, 4).map((webpage) => (
              <WebsiteSearchListItem
                webpage={webpage}
                services={props.services}
                key={webpage.id}
              />
            ))}
          </div>
          {(props.searchResults.others.length > 4) && (
            <LinkButton
              href={`${props.services.configService.getPageByTranslationCodeAndLocale('search', props.locale).path}?search=${props.searchTerm}&tab=miscellaneous`}
              onClickHandler={showAllItemsHandler}
              classNames="linkbutton linkbutton--full-width"
            >{intl.formatMessage({ id: 'showAllSearchResults' })}
            </LinkButton>
          )}
        </Fragment>
      );
    }
    return (
      <div className="grid-product-category-list">
        {props.searchResults.others.map((webpage) => (
          <WebsiteSearchListItem
            webpage={webpage}
            services={props.services}
            key={webpage.id}
          />
        ))}
      </div>
    );
  }
  if (!props.services.searchService.isSuccessfulSearch(props.searchResults)) {
    return (
      <Message type="warning">
        <FormattedMessage id="noSearchResults" />
      </Message>
    );
  }
  return (
    <Tabs
      activeTabId={activeTabId || getActiveTabId()}
      activeTabIdChangeHandler={activeTabIdChangeHandler}
      items={[
        {
          id: 'categories',
          headingText: `${intl.formatMessage({ id: 'categories' })} (${getItemCountForHeading('productCategories')})`,
          content: getCategoryContent(),
        },
        {
          id: 'products',
          headingText: `${intl.formatMessage({ id: 'products' })} (${getItemCountForHeading('products')})`,
          content: getProductContent(),
        },
        {
          id: 'miscellaneous',
          headingText: `${intl.formatMessage({ id: 'miscellaneous' })} (${getItemCountForHeading('others')})`,
          content: getMiscellaneousContent(),
        },
      ]}
      isToggle={false}
    />
  );
}

SearchResultTabs.propTypes = {
  searchTerm: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDropdown: PropTypes.bool,
  activeTabId: PropTypes.string,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

SearchResultTabs.defaultProps = {
  searchTerm: '',
  isDropdown: false,
  activeTabId: undefined,
};
