import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import './Search.css';

export default function WebsiteSearchListItem(props) {
  if (props.webpage.id) {
    return (
      <div className="webpage-search-list-item">
        <CustomLink
          href={props.webpage.path}
        >
          <div className="grid-webpage-search-list-item">
            <img
              src={props.services.configService.getPageImgPath(props.webpage)}
              alt={props.webpage.label}
            />
            <div className="webpage-search-list-item__name center-vertically">{props.webpage.label}</div>
          </div>
        </CustomLink>
      </div>
    );
  }
  return (
    <div className="webpage-search-list-item">
      <a href={props.webpage.href} target="_blank" rel="noreferrer">
        <div className="grid-webpage-search-list-item grid-webpage-search-list-item--external">
          <div className="webpage-search-list-item__name">{props.webpage.label}</div>
          <div className="webpage-search-list-item__source">{props.webpage.href.replace('https://www.', '').split('/')[0]}</div>
        </div>
      </a>
    </div>
  );
}

WebsiteSearchListItem.propTypes = {
  webpage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
