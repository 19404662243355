import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from '../Modal/Modal';
import CookieCategory from './CookieCategory';
import Button from '../Button/Button';
import CustomLink from '../Link/CustomLink';
import './CookieModal.css';

export default function CookieModal(props) {
  const intl = useIntl();
  const path = useLocation().pathname;
  const generalConfig = props.services.configService.getGeneralConfig();
  const [dialog, setDialog] = useState(props.dialog);
  const [isActive, setIsActive] = useState(undefined);
  const [statCookiesEnabled, setStatCookiesEnabled] = useState(false);
  const [perCookiesEnabled, setPerCookiesEnabled] = useState(false);
  const [adsCookiesEnabled, setAdsCookiesEnabled] = useState(false);
  const statCookiesChangeHandler = (newSetting) => {
    setStatCookiesEnabled(newSetting);
  };
  const perCookiesChangeHandler = (newSetting) => {
    setPerCookiesEnabled(newSetting);
  };
  const adsCookiesChangeHandler = (newSetting) => {
    setAdsCookiesEnabled(newSetting);
  };
  function categoryIsActive(categoryId) {
    for (let i = 0; i < generalConfig.cookieCategories.length; i++) {
      if (generalConfig.cookieCategories[i].id === categoryId) {
        return true;
      }
    }
    return false;
  }
  const cookieConsentHandler = (newSetting) => {
    let consentValue = 'Tec';
    if (newSetting === 'all') {
      if (categoryIsActive('Stat')) {
        consentValue = `${consentValue};Stat`;
      }
      if (categoryIsActive('Per')) {
        consentValue = `${consentValue};Per`;
      }
      if (categoryIsActive('Ads')) {
        consentValue = `${consentValue};Ads`;
      }
    } else {
      if (statCookiesEnabled) {
        consentValue = `${consentValue};Stat`;
      }
      if (perCookiesEnabled) {
        consentValue = `${consentValue};Per`;
      }
      if (adsCookiesEnabled) {
        consentValue = `${consentValue};Ads`;
      }
    }
    const reloadPage = consentValue.includes('Stat') && !props.services.configService.cookieCategoryHasConsent('Stat');
    Cookies.set('cookieConsent', consentValue, { expires: 360 });
    props.services.configService.removeDisabledCookies();
    if (props.closeHandler) {
      props.closeHandler();
    } else {
      setIsActive(false);
    }
    props.services.apiService.int({ param1: 'ckse', param2: consentValue, param4: { path } }).then(() => {
      if (reloadPage) {
        window.location.reload(false);
      }
    });
  };
  const dialogChangeHandler = (params) => {
    setDialog(params.dialog);
  };
  useEffect(() => {
    setStatCookiesEnabled(props.services.configService.cookieCategoryHasConsent('Stat'));
    setPerCookiesEnabled(props.services.configService.cookieCategoryHasConsent('Per'));
    setAdsCookiesEnabled(props.services.configService.cookieCategoryHasConsent('Ads'));
  }, [props.active]);
  function getCookieExplId() {
    let explId = '';
    generalConfig.cookieCategories.forEach((cookieCategory) => {
      if (cookieCategory.id !== 'Tec') {
        explId = `${explId}${cookieCategory.id}`;
      }
    });
    return explId;
  }
  function getMain() {
    return (
      <Fragment>
        <div>
          <p>{`${intl.formatMessage({ id: 'cookieModalExplText1' })} ${intl.formatMessage({ id: `cookieModalExplText2${getCookieExplId()}` })} ${intl.formatMessage({ id: 'cookieModalActionText' })}`}</p>
        </div>
        <div className="grid-cookie-modal-buttons">
          <Button classNames="button button--full-width button--secondary margin-r5" type="submit" onClick={() => dialogChangeHandler({ dialog: 'details' })}><FormattedMessage id="cookieModalSettingsText" /></Button>
          <Button classNames="button button--full-width" type="submit" onClick={() => cookieConsentHandler('all')}><FormattedMessage id="cookieModalAcceptText" /></Button>
        </div>
      </Fragment>
    );
  }
  function getDetails() {
    return (
      <Fragment>
        <p><FormattedMessage id="cookieModalDetailsText" /></p>
        <div className="cookie-categories">
          <CookieCategory id="Tec" changeHandler={() => {}} enabled={true} readOnly={true} />
          {categoryIsActive('Stat') && (<CookieCategory id="Stat" changeHandler={statCookiesChangeHandler} enabled={statCookiesEnabled} />)}
          {categoryIsActive('Per') && (<CookieCategory id="Per" changeHandler={perCookiesChangeHandler} enabled={perCookiesEnabled} />)}
          {categoryIsActive('Ads') && (<CookieCategory id="Ads" changeHandler={adsCookiesChangeHandler} enabled={adsCookiesEnabled} />)}
        </div>
        <div className="grid-cookie-modal-buttons">
          <Button classNames="button button--full-width button--secondary margin-r5" type="submit" onClick={() => cookieConsentHandler()}><FormattedMessage id="save" /></Button>
          <Button classNames="button button--full-width" type="submit" onClick={() => cookieConsentHandler('all')}><FormattedMessage id="cookieModalAcceptText" /></Button>
        </div>
      </Fragment>
    );
  }
  return (
    <Modal active={(isActive === undefined) ? props.active : isActive} showOverlay={false} classNames="cookie-modal" showCloseX={dialog === 'details'} closeHandler={props.closeHandler || dialogChangeHandler} closeHandlerParams={{ dialog: 'main' }}>
      <div className="cookie-modal">
        <div className="cookie-modal-logo-container"><img src="/logo.svg" alt={`${generalConfig.brand.longName} logo`} className="cookie-modal-logo" /></div>
        <div className="cookie-modal-heading"><strong><FormattedMessage id="cookieModalHeading" /></strong></div>
        {dialog === 'main' && (getMain())}
        {dialog === 'details' && (getDetails())}
        <div className="cookie-modal-footer">
          <span><CustomLink href={props.services.configService.getPageByTranslationCodeAndLocale('privacy-policy', props.locale).path}><FormattedMessage id="privacyPolicy" /></CustomLink> | <CustomLink href={props.services.configService.getPageByTranslationCodeAndLocale('imprint', props.locale).path}><FormattedMessage id="imprint" /></CustomLink></span>
        </div>
      </div>
    </Modal>
  );
}

CookieModal.propTypes = {
  active: PropTypes.bool.isRequired,
  dialog: PropTypes.string,
  closeHandler: PropTypes.func,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
};

CookieModal.defaultProps = {
  dialog: 'main',
  closeHandler: undefined,
};
