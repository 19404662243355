import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from './Select';
import './Form.css';

export default function FormSelect(props) {
  return (
    <Fragment>
      <div className="grid-form__label">
        <label htmlFor={props.id}>
          {
            props.showIsRequired
              ? (
                <Fragment>
                  <FormattedMessage id={props.label || props.id} /><span className="is-required-asterisk"> *</span>
                </Fragment>
              ) : <FormattedMessage id={props.label || props.id} />
          }
        </label>
      </div>
      <div className={`grid-form__input${(props.unit) ? ' grid-form__input--unit' : ''}`}>
        <Select
          id={props.id}
          values={props.values}
          callback={props.callback}
          currentValue={props.currentValue}
          noPreselect={props.noPreselect}
          disabled={props.disabled}
        />
        {props.unit && (
          <div className="center-vertically">{props.unit}</div>
        )}
      </div>
    </Fragment>
  );
}

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.array).isRequired,
  currentValue: PropTypes.string.isRequired,
  noPreselect: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  showIsRequired: PropTypes.bool,
  unit: PropTypes.string,
  disabled: PropTypes.bool,
};

FormSelect.defaultProps = {
  label: undefined,
  noPreselect: false,
  showIsRequired: false,
  unit: undefined,
  disabled: false,
};
