function provideDownload(filename, data) {
  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', data);
  linkElement.setAttribute('download', filename);
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.remove();
}

function provideBlobDownload(filename, data) {
  provideDownload(
    filename,
    window.URL.createObjectURL(new Blob([data]))
  );
}

function provideJsonDownload(filename, data) {
  try {
    provideDownload(
      filename,
      `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data, null, 2))}`
    );
  } catch (err) {
    console.error(err.stack);
  }
}

module.exports = {
  provideBlobDownload,
  provideJsonDownload,
};
