import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Validator from '../Form/Validator';
import FormInput from '../Form/FormInput';
import FormSelectCountry from '../Form/FormSelectCountry';
import Button from '../Button/Button';
import Checkbox from '../Form/Checkbox';
import RequestProgress from '../Progress/RequestProgress';

export default function AddressForm(props) {
  const intl = useIntl();
  const initIsValid = () => {
    if (props.address.nameLine1) {
      return true;
    }
    return false;
  };
  const [id] = useState(props.address.id);
  const [nameLine1, setNameLine1] = useState(props.address.nameLine1);
  const [nameLine2, setNameLine2] = useState(props.address.nameLine2);
  const [street, setStreet] = useState(props.address.street);
  const [hasPostOfficeBox, setHasPostOfficeBox] = useState(
    props.address.postOfficeBox
  );
  const [postOfficeBox, setPostOfficeBox] = useState(props.address.postOfficeBox);
  const [zip, setZip] = useState(props.address.zip);
  const [city, setCity] = useState(props.address.city);
  const [country, setCountry] = useState(props.address.country);
  const [taxNo, setTaxNo] = useState(props.address.taxNo);
  const [isStandardShippingAddress, setIsStandardShippingAddress] = useState(
    props.address.isStandardShippingAddress
  );
  const [isStandardBillingAddress, setIsStandardBillingAddress] = useState(
    props.address.isStandardBillingAddress
  );
  const [submitButtonIsClicked, setSubmitButtonIsClicked] = useState(false);
  const [nameLine1IsValid, setNameLine1IsValid] = useState(initIsValid());
  const [streetIsValid, setStreetIsValid] = useState(initIsValid());
  const [postOfficeBoxIsValid, setPostOfficeBoxIsValid] = useState(initIsValid());
  const [zipIsValid, setZipIsValid] = useState(initIsValid());
  const [cityIsValid, setCityIsValid] = useState(initIsValid());
  const [viewState, setViewState] = useState('form');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');

  const nameLine1ChangeHandler = (newNameLine1) => {
    setNameLine1(newNameLine1);
    setNameLine1IsValid(Validator.isValidCompanyName(newNameLine1));
  };
  const nameLine2ChangeHandler = (newNameLine2) => {
    setNameLine2(newNameLine2);
  };
  const streetChangeHandler = (newStreet) => {
    setStreet(newStreet);
    setStreetIsValid(Validator.isValidStreetNameAndNumber(newStreet));
  };
  const postOfficeBoxChangeHandler = (newPostOfficeBox) => {
    setPostOfficeBox(newPostOfficeBox);
    setPostOfficeBoxIsValid(Validator.isValidPostOfficeBox(newPostOfficeBox));
  };
  const hasPostOfficeBoxChangeHandler = (newHasPostOfficeBox) => {
    setHasPostOfficeBox(newHasPostOfficeBox);
    setStreet('');
    setPostOfficeBox('');
  };
  const zipChangeHandler = (newZip) => {
    setZip(newZip);
    setZipIsValid(Validator.isValidZipCode(newZip));
  };
  const cityChangeHandler = (newCity) => {
    setCity(newCity);
    setCityIsValid(Validator.isValidCity(newCity));
  };
  const countryChangeHandler = (newCountry) => {
    setCountry(newCountry);
  };
  const taxNoChangeHandler = (newTaxNo) => {
    setTaxNo(newTaxNo);
  };
  const isStandardShippingAddressChangeHandler = (isStandardShippingAddressValue) => {
    setIsStandardShippingAddress(isStandardShippingAddressValue);
  };
  const isStandardBillingAddressChangeHandler = (isStandardBillingAddressValue) => {
    setIsStandardBillingAddress(isStandardBillingAddressValue);
  };
  const getAddressObj = () => (
    {
      id,
      nameLine1,
      nameLine2,
      street,
      postOfficeBox,
      zip,
      city,
      country,
      taxNo,
      isStandardShippingAddress,
      isStandardBillingAddress,
    }
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonIsClicked(true);
    if (nameLine1IsValid && (streetIsValid || postOfficeBoxIsValid) && zipIsValid && cityIsValid) {
      if (props.services.dataStorageService.isLoggedIn(props.dataStorage.user)) {
        setViewState('loading');
        props.services.apiService.post('/user/saveaddress', {
          address: getAddressObj(),
          locale: props.locale,
        }).then((res) => {
          setViewState('result');
          if (res.data.user) {
            props.services.dataStorageService.setUser(res.data.user, props.dataStorage);
            if (props.mode === 'checkout') {
              props.saveInCheckoutHandler({
                address: (id)
                  ? getAddressObj() : res.data.user.addresses[res.data.user.addresses.length - 1],
              });
            } else {
              setResultMessageText(intl.formatMessage({ id: 'addressSavedSuccessMsg' }));
              setResultMessageType('success');
            }
          } else {
            setResultMessageText(intl.formatMessage({ id: 'errorHasOccurred' }));
            setResultMessageType('error');
          }
        });
      } else {
        props.saveInCheckoutHandler({ address: getAddressObj() });
      }
    }
  };
  return (
    <Fragment>
      {(props.mode === 'addressbook' && props.address.nameLine1.length === 0) && (<h2><FormattedMessage id="newAddress" /></h2>)}
      {(props.mode === 'addressbook' && props.address.nameLine1.length > 0) && (<h2><FormattedMessage id="editAddress" /></h2>)}
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} centerMessage={false} />
      {(props.mode === 'addressbook' && viewState === 'result' && resultMessageType === 'success') && (<Button classNames="button" type="submit" onClick={() => props.cancelHandler()}><FormattedMessage id="next" /></Button>)}
      {(viewState === 'form' || resultMessageType === 'error') && (
        <form className="form" onSubmit={handleSubmit}>
          <div className="grid-form">
            <FormInput
              id="nameLine1"
              value={nameLine1}
              callback={nameLine1ChangeHandler}
              isValid={nameLine1IsValid}
              errorMessageId="invalidInput"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              showIsRequired={true}
            />
            <FormInput
              id="nameLine2"
              value={nameLine2}
              callback={nameLine2ChangeHandler}
              isValid={true}
              errorMessageId="invalidInput"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              labelIsInvisble={true}
            />
            {!hasPostOfficeBox && (
            <FormInput
              id="street"
              value={street}
              callback={streetChangeHandler}
              isValid={streetIsValid}
              errorMessageId="invalidStreetMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              showIsRequired={true}
            />
            )}
            <Checkbox
              id="hasPostOfficeBox"
              divClassNames="form-checkbox margin-t20"
              checked={hasPostOfficeBox}
              label={intl.formatMessage({ id: 'postOfficeBox' })}
              callback={hasPostOfficeBoxChangeHandler}
            />
            {hasPostOfficeBox && (
            <FormInput
              id="postOfficeBox"
              value={postOfficeBox}
              callback={postOfficeBoxChangeHandler}
              isValid={postOfficeBoxIsValid}
              errorMessageId="invalidPostOfficeBoxMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              showIsRequired={true}
            />
            )}
            <FormInput
              id="zip"
              value={zip}
              callback={zipChangeHandler}
              isValid={zipIsValid}
              errorMessageId="invalidZipCodeMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              showIsRequired={true}
            />
            <FormInput
              id="city"
              value={city}
              callback={cityChangeHandler}
              isValid={cityIsValid}
              errorMessageId="invalidCityMessage"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
              showIsRequired={true}
            />
            <FormSelectCountry
              currentValue={country}
              callback={countryChangeHandler}
              showIsRequired={true}
            />
            <FormInput
              id="taxNo"
              value={taxNo}
              callback={taxNoChangeHandler}
              isValid={true}
              errorMessageId="invalidInput"
              noErrorMessageIfEmpty={!submitButtonIsClicked}
            />
          </div>
          {props.services.dataStorageService.isLoggedIn(props.dataStorage.user) && (
            <Fragment>
              <Checkbox
                id="isStandardShippingAddress"
                divClassNames="form-checkbox"
                checked={isStandardShippingAddress}
                label={intl.formatMessage({ id: 'standardShippingAddress' })}
                callback={isStandardShippingAddressChangeHandler}
              />
              <Checkbox
                id="isStandardBillingAddress"
                divClassNames="form-checkbox"
                checked={isStandardBillingAddress}
                label={intl.formatMessage({ id: 'standardBillingAddress' })}
                callback={isStandardBillingAddressChangeHandler}
              />
            </Fragment>
          )}
          {props.cancelHandler && (<Button classNames="button button--secondary" type="submit" onClick={() => props.cancelHandler()}><FormattedMessage id="discard" /></Button>)}
          <Button classNames="button button--right" type="submit"><FormattedMessage id={(props.mode === 'addressbook') ? 'save' : 'apply'} /></Button>
        </form>
      )}
    </Fragment>
  );
}

AddressForm.propTypes = {
  address: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string,
  cancelHandler: PropTypes.func.isRequired,
  saveInCheckoutHandler: PropTypes.func,
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

AddressForm.defaultProps = {
  mode: 'checkout',
  saveInCheckoutHandler: undefined,
};
