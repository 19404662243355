import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageWrapper from '../Wrapper/PageWrapper';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';
import SimpleH2Sections from '../Layout/SimpleH2Section';
import ProviderAddress from '../Layout/ProviderAddress';
import CustomLink from '../Link/CustomLink';
import LoadWebpageParams from '../Progress/LoadWebpageParams';
import './Page.css';

export default function Imprint(props) {
  const { brand } = props.services.configService.getGeneralConfig();
  const [data, setData] = useState(undefined);
  const dataChangeHandler = (newData) => {
    setData(newData);
  };
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <DefaultHeadingBar
        locale={props.locale}
        services={props.services}
      />
      <div className="content-container links-in-primary-font-color">
        <LoadWebpageParams
          webpageParamsHandler={dataChangeHandler}
          services={props.services}
          dataStorage={props.dataStorage}
        >
          <h2><FormattedMessage id="imprintOperatedBy" /></h2>
          <ProviderAddress
            locale={props.locale}
            services={props.services}
          />
          {(brand.legal.commercialRegister || brand.legal.vatId || brand.legal.dunsId) && (
            <p>
              {brand.legal.commercialRegister && (<Fragment><FormattedMessage id="imprintCommercialRegister" values={{ location: brand.legal.commercialRegister.location, no: brand.legal.commercialRegister.no }} /><br /></Fragment>)}
              {brand.legal.vatId && (<Fragment><FormattedMessage id="imprintVatId" />: {brand.legal.vatId}<br /></Fragment>)}
              {brand.legal.dunsId && (<Fragment><FormattedMessage id="imprintDunsId" />: {brand.legal.dunsId}</Fragment>)}
            </p>
          )}
          <SimpleH2Sections
            locale={props.locale}
            data={data}
          />
          {props.locale !== brand.legal.langVersionThatApplies.code && (
            <section className="links-in-primary-theme-color">
              <h2><FormattedMessage id="imprintValidLangVersionHeading" values={{ name: brand.legal.langVersionThatApplies.label[props.locale] }} /></h2>
              <FormattedMessage id="imprintValidLangVersionText" values={{ name: brand.legal.langVersionThatApplies.label[props.locale] }} />: <CustomLink href={props.services.configService.getPageByTranslationCodeAndLocale('imprint', brand.legal.langVersionThatApplies.code).path} onClickHandler={props.setLocale} onClickHandlerParams={{ locale: brand.legal.langVersionThatApplies.code }}><FormattedMessage id="imprintValidLangVersionHeading" values={{ name: brand.legal.langVersionThatApplies.label[props.locale] }} /></CustomLink>
            </section>
          )}
        </LoadWebpageParams>
      </div>
    </PageWrapper>
  );
}

Imprint.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
