import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function Pavi(props) {
  const location = useLocation();
  let subdirs;
  useEffect(() => {
    if (subdirs !== location.pathname) {
      props.apiService.pavi(location.pathname);
      subdirs = location.pathname;
    }
  }, [location.pathname]);
  return props.children;
}

Pavi.propTypes = {
  apiService: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

export default Pavi;
