import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Order from '../Checkout/Order';

export default function OrderByUrlParam(props) {
  const history = useHistory();
  const getOrder = () => props.services.salesService.getOrderByUrlQuery(props.dataStorage.orders);
  const [order, setOrder] = useState(getOrder());
  const pathToOrdersPage = props.services.configService.getPageByTranslationCodeAndLocale('account-orders', props.locale).path;
  useEffect(() => history.listen(() => {
    setOrder(getOrder());
  }), [history]);

  return (
    <Order
      order={order}
      pathToParentPage={pathToOrdersPage}
      showOrderAgainButton={true}
      locale={props.locale}
      services={props.services}
      dataStorage={props.dataStorage}
    />
  );
}

OrderByUrlParam.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
