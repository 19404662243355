import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomLink from '../Link/CustomLink';

function OrderList(props) {
  const intl = useIntl();
  function getOrders() {
    const sortedOrders = [];
    props.dataStorage.orders.forEach((order) => (
      sortedOrders.unshift(
        <div className="grid-account-order-item">
          <div className="account-order-item-id">
            {order.id}
          </div>
          <div className="account-order-item-date">
            {props.services.salesService.getFormattedOrderDate(
              order.orderDate, props.locale
            )}
          </div>
          <div className="account-order-item-customer-order-id">
            {order.additionalInformation.customerOrderId}
          </div>
          <div className="account-order-item-total">
            {props.services.salesService.formatPrice(order.price.total, props.locale)}
          </div>
          <div className="account-order-item-actions">
            <CustomLink
              href={`${props.services.configService.getPageByTranslationCodeAndLocale('account-order', props.locale).path}?oid=${order.id}`}
            >{intl.formatMessage({ id: 'view' })}
            </CustomLink>
          </div>
        </div>
      )
    ));
    return sortedOrders;
  }

  if (!props.dataStorage.orders || props.dataStorage.orders.length === 0) {
    return (
      <FormattedMessage id="noOrdersYet" />
    );
  }

  return (
    <Fragment>
      <div className="grid-account-order-items-heading">
        <div>
          <strong><FormattedMessage id="orderId" /></strong>
        </div>
        <div>
          <strong className="center"><FormattedMessage id="date" /></strong>
        </div>
        <div>
          <strong className="center"><FormattedMessage id="yourOrderId" /></strong>
        </div>
        <div className="text-align-right">
          <strong><FormattedMessage id="grossSum" /></strong>
        </div>
        <div className="text-align-right">
          <strong><FormattedMessage id="actions" /></strong>
        </div>
      </div>
      {getOrders()}
    </Fragment>
  );
}

OrderList.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderList;
