import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Input from './Input';
import InvalidInputMessage from './InvalidInputMessage';
import './Form.css';

export default function FormSpamProtection(props) {
  const intl = useIntl();
  const [antiSpamInput, setAntiSpamInput] = useState('');
  const [number1] = useState(Math.floor(Math.random() * 10) + 1);
  const [number2] = useState(Math.floor(Math.random() * 10) + 1);
  const [operator] = useState(() => {
    const operatorId = Math.floor(Math.random() * 2) + 1;
    if (operatorId === 1) {
      return '+';
    }
    return '-';
  });
  const [correctResult] = useState(() => {
    if (operator === '+') {
      return number1 + number2;
    }
    if (number2 > number1) {
      return number2 - number1;
    }
    return number1 - number2;
  });
  const [rightAnswerGiven, setRightAnswerGiven] = useState(false);

  const spamProtectionChangeHandler = (newAntiSpamInput) => {
    setAntiSpamInput(newAntiSpamInput);
    const isRightAnswer = newAntiSpamInput === correctResult.toString();
    setRightAnswerGiven(isRightAnswer);
    props.callback(isRightAnswer);
  };

  return (
    <Fragment>
      <div className="grid-form__label">
        <label htmlFor="schutz">
          {
            props.showIsRequired
              ? (
                <Fragment>
                  <FormattedMessage id="spamProtection" /><span className="is-required-asterisk"> *</span>
                </Fragment>
              ) : <FormattedMessage id="spamProtection" />
          }
        </label>
      </div>
      <div className="grid-form__input">
        <div className="form-schutz">
          <div className="form-schutz__hint">
            {
              number1 >= number2
                ? (
                  <Fragment>
                    {number1} {operator} {number2} =
                  </Fragment>
                ) : (
                  <Fragment>
                    {number2} {operator} {number1} =
                  </Fragment>
                )
            }
          </div>
          <Input
            id="schutz"
            type="text"
            classNames={rightAnswerGiven || (antiSpamInput === '' && props.noErrorMessageIfEmpty) ? 'form-schutz__input' : 'form-schutz__input invalid-value'}
            callback={spamProtectionChangeHandler}
            value={antiSpamInput}
          />
        </div>
      </div>
      <InvalidInputMessage
        display={!(rightAnswerGiven || (antiSpamInput === '' && props.noErrorMessageIfEmpty))}
        message={intl.formatMessage({ id: 'invalidAntiSpamMessage' })}
      />
    </Fragment>
  );
}

FormSpamProtection.propTypes = {
  showIsRequired: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  noErrorMessageIfEmpty: PropTypes.bool,
};

FormSpamProtection.defaultProps = {
  showIsRequired: false,
  noErrorMessageIfEmpty: true,
};
