import React from 'react';
import PropTypes from 'prop-types';
import OverlayEffectImage from './OverlayEffectImage';

export default function SlideOverlayEffectImage(props) {
  return (
    <OverlayEffectImage
      onClickHandler={props.onClickHandler}
      onClickHandlerParams={props.onClickHandlerParams}
      imgSrc={props.imgSrc}
      imgAlt={props.imgAlt}
      containerClassNames={props.containerClassNames}
      imageClassNames={props.imageClassNames}
      overlayContainerClassNames={props.overlayContainerClassNames}
      overlayInfoContainerClassNames={props.overlayInfoContainerClassNames}
    >
      {props.children}
    </OverlayEffectImage>
  );
}

SlideOverlayEffectImage.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  onClickHandlerParams: PropTypes.objectOf(PropTypes.any).isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  containerClassNames: PropTypes.string,
  imageClassNames: PropTypes.string,
  overlayContainerClassNames: PropTypes.string,
  overlayInfoContainerClassNames: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

SlideOverlayEffectImage.defaultProps = {
  containerClassNames: 'img-overlay-effect-container',
  imageClassNames: 'img-overlay-effect-img',
  overlayContainerClassNames: 'img-overlay-container',
  overlayInfoContainerClassNames: 'img-overlay-info-container',
};
