import React from 'react';
import PropTypes from 'prop-types';
import DropdownListitems from './DropdownListitems';
import CustomLink from '../Link/CustomLink';
import './Dropdown.css';

export default function DropdownContent(props) {
  const layout = (props.layout === 'wide' && props.items[0].subitems) ? 'multipleColumns' : undefined;
  let dropdownListitemsElement;
  if (props.items) {
    dropdownListitemsElement = (
      <DropdownListitems
        items={props.items}
        layout={layout}
      />
    );
  }
  const content = dropdownListitemsElement || props.children;
  function getSubitemHtml() {
    if (layout === 'multipleColumns') {
      return (
        <div className={`grid-dropdown__item2 dropdown-submenus-with-heading${(props.items[0] > 2) ? 'dropdown-submenus-with-heading--3col' : ''}`}>
          {content}
        </div>
      );
    }
    return (
      <ul className="grid-dropdown__item2">
        {content}
      </ul>
    );
  }

  if (props.items && props.layout === 'wide') {
    return (
      <div className={props.classNames}>
        <div className="grid-dropdown">
          <div className="grid-dropdown__item1">
            <span className="dropdown-content-heading">
              <CustomLink
                href={props.headingLink}
              >{props.heading}
              </CustomLink>
            </span>
          </div>
          {getSubitemHtml()}
          <div className="grid-dropdown__item3 center">
            {props.children}
          </div>
        </div>
      </div>
    );
  } else if (props.items && !props.layout === 'wide') {
    return (
      <ul className={props.classNames}>
        {content}
      </ul>
    );
  }
  return (
    <ul className={props.classNames}>
      {content}
    </ul>
  );
}

DropdownContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.string,
  classNames: PropTypes.string.isRequired,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  headingLink: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]),
};

DropdownContent.defaultProps = {
  items: undefined,
  layout: undefined,
  heading: undefined,
  headingLink: undefined,
  children: undefined,
};
