import React from 'react';
import PropTypes from 'prop-types';
import PageWrapper from '../Wrapper/PageWrapper';
import Builder from '../Layout/PageBuilder';
import './Page.css';

export default function PageBuilder(props) {
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <Builder
        locale={props.locale}
        services={props.services}
        dataStorage={props.dataStorage}
      />
    </PageWrapper>
  );
}

PageBuilder.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
