import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Layout/Icon';

export default function VeMeDropdownHeading(props) {
  const dropDownPath = props.parentHeading ? `${props.parentHeading}|${props.heading}` : props.heading;
  const isExpanded = props.expandedMenuPath && props.expandedMenuPath.indexOf(dropDownPath) === 0;
  let classNames = 'dropdown__heading';
  if (props.layout === 'subitem') {
    classNames = `${classNames} dropdown__heading--subitem`;
  }
  return (
    <div className={classNames} onClick={() => props.setExpandedMenuPath(dropDownPath)} role="button" tabIndex="0" onKeyDown={() => props.setExpandedMenuPath(dropDownPath)}>
      {props.headingPrefixIcon && <Icon name={props.headingPrefixIcon} />}
      {props.heading}
      {props.headingSuffixIcon && <Icon name={props.headingSuffixIcon} />}
      {isExpanded ? <Icon name="angle-up" /> : <Icon name="angle-down" /> }
    </div>
  );
}

VeMeDropdownHeading.propTypes = {
  layout: PropTypes.string,
  parentHeading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headingPrefixIcon: PropTypes.string,
  headingSuffixIcon: PropTypes.string,
  expandedMenuPath: PropTypes.string,
  setExpandedMenuPath: PropTypes.func,
};

VeMeDropdownHeading.defaultProps = {
  layout: undefined,
  parentHeading: undefined,
  headingPrefixIcon: undefined,
  headingSuffixIcon: undefined,
  expandedMenuPath: null,
  setExpandedMenuPath: undefined,
};
