import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import RequestProgress from '../Progress/RequestProgress';
import ChangePasswordForm from './ChangePasswordForm';

export default function PasswordSetNewWithLink(props) {
  const intl = useIntl();
  const [code, setCode] = useState('');
  const [viewState, setViewState] = useState('loading');
  const [resultMessageType, setResultMessageType] = useState('');
  const [resultMessageText, setResultMessageText] = useState('');
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.ssc) {
      setCode(query.ssc);
      const reqParams = {
        code: query.ssc,
        locale: props.locale,
      };
      props.services.apiService.post('/user/pwd-check-code', reqParams).then((res) => {
        if (res.data) {
          setViewState('form');
        } else {
          setViewState('result');
          setResultMessageType('error');
          setResultMessageText(intl.formatMessage({ id: 'sscOptInErrorMsg' }));
        }
      });
    } else {
      setViewState('result');
      setResultMessageType('error');
      setResultMessageText(intl.formatMessage({ id: 'sscOptInErrorMsg' }));
    }
  }, []);
  return (
    <Fragment>
      <RequestProgress viewState={viewState} processingLabel={intl.formatMessage({ id: 'requestIsBeingProcessed' })} messageType={resultMessageType} messageText={resultMessageText} />
      {(viewState === 'form') && (
        <ChangePasswordForm
          services={props.services}
          locale={props.locale}
          dataStorage={props.dataStorage}
          pwdForgotten={true}
          resetCode={code}
        />
      )}
    </Fragment>
  );
}

PasswordSetNewWithLink.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
};
