import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageWrapper from '../Wrapper/PageWrapper';
import PasswordForgottenForm from '../Account/PasswordForgottenForm';
import './Page.css';

export default function PasswordForgotten(props) {
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <div className="content-container">
        <h1 className="center"><FormattedMessage id="passwordForgotten" /></h1>
        <PasswordForgottenForm
          services={props.services}
          locale={props.locale}
        />
      </div>
    </PageWrapper>
  );
}

PasswordForgotten.propTypes = {
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
