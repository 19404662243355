import React from 'react';
import PropTypes from 'prop-types';
import './Layout.css';

export default function DiagonalBox(props) {
  return (
    <div className={`diagonal-box${(props.classNames) ? ` ${props.classNames}` : ''}`}>
      <svg className="diagonal-box-svg-top" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="white" points="100,0 0,100 0,0" />
      </svg>
      <div className="diagonal-box-content">
        {props.children}
      </div>
      <svg className="diagonal-box-svg-bottom" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="white" points="0,100 100,0 100,100" />
      </svg>
    </div>
  );
}

DiagonalBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  classNames: PropTypes.string,
};

DiagonalBox.defaultProps = {
  classNames: undefined,
};
