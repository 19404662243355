import React from 'react';
import PropTypes from 'prop-types';
import YouTubeVideo from '../Video/YouTubeVideo';
import './Layout.css';

export default function SimpleVideoRow(props) {
  return (
    <div className={`simple-video-row-container${(props.classNames) ? ` ${props.classNames}` : ''}`}>
      <div className="grid-simple-video-row">
        <div className="simple-video-row-txt-container center-vertically">
          <div className="simple-video-row-info-container">
            {props.item.rows.map((row) => (
              <div className={row.classNames}>{row.text}</div>
            ))}
          </div>
        </div>
        <div className="simple-video-row-vid-container center">
          <YouTubeVideo src={props.item.src} title={props.item.title} />
        </div>
      </div>
    </div>
  );
}

SimpleVideoRow.propTypes = {
  classNames: PropTypes.string,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

SimpleVideoRow.defaultProps = {
  classNames: undefined,
};
