import React from 'react';
import PropTypes from 'prop-types';
import ProductCategoryListItem from './ProductCategoryListItem';

export default function ProductCategoryList(props) {
  function getListItems() {
    return props.productCategories.map((productCategory) => (
      <ProductCategoryListItem
        data={props.services.productService.getProductCategoryListItemData(
          productCategory, props.dataStorage.productCategories, props.locale
        )}
      />
    ));
  }
  return (
    <div className="grid-product-category-list">
      {getListItems()}
    </div>
  );
}

ProductCategoryList.propTypes = {
  productCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
