import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Checkbox from './Checkbox';
import InvalidInputMessage from './InvalidInputMessage';
import './Form.css';

export default function FormLegalCheckbox(props) {
  const intl = useIntl();
  return (
    <Fragment>
      <Checkbox
        id={props.id}
        divClassNames="form-checkbox"
        checked={props.checked}
        label={props.label}
        callback={props.callback}
      />
      <InvalidInputMessage
        display={!props.checked && props.submitButtonIsClicked}
        message={intl.formatMessage({ id: props.errorMessageId })}
        classNames={{ label: 'display-none', input: 'form-legal-error-message' }}
      />
    </Fragment>
  );
}

FormLegalCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  errorMessageId: PropTypes.string.isRequired,
  submitButtonIsClicked: PropTypes.bool.isRequired,
};
