import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageWrapper from '../Wrapper/PageWrapper';
import DefaultHeadingBar from '../Heading/DefaultHeadingBar';
import SimpleH2Sections from '../Layout/SimpleH2Section';
import CustomLink from '../Link/CustomLink';
import LoadWebpageParams from '../Progress/LoadWebpageParams';
import './Page.css';

export default function PrivacyPolicy(props) {
  const { brand } = props.services.configService.getGeneralConfig();
  const [data, setData] = useState(undefined);
  const dataChangeHandler = (newData) => {
    setData(newData);
  };
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <DefaultHeadingBar
        locale={props.locale}
        services={props.services}
      />
      <div className="content-container links-in-primary-theme-color">
        <LoadWebpageParams
          webpageParamsHandler={dataChangeHandler}
          services={props.services}
          dataStorage={props.dataStorage}
        >
          <SimpleH2Sections
            locale={props.locale}
            services={props.services}
            data={data}
          />
          {props.locale !== brand.legal.langVersionThatApplies.code && (
            <section className="links-in-primary-theme-color">
              <h2><FormattedMessage id="imprintValidLangVersionHeading" values={{ name: brand.legal.langVersionThatApplies.label[props.locale] }} /></h2>
              <FormattedMessage id="imprintValidLangVersionText" values={{ name: brand.legal.langVersionThatApplies.label[props.locale] }} />: <CustomLink href={props.services.configService.getPageByTranslationCodeAndLocale('privacy-policy', brand.legal.langVersionThatApplies.code).path} onClickHandler={props.setLocale} onClickHandlerParams={{ locale: brand.legal.langVersionThatApplies.code }}><FormattedMessage id="imprintValidLangVersionHeading" values={{ name: brand.legal.langVersionThatApplies.label[props.locale] }} /></CustomLink>
            </section>
          )}
        </LoadWebpageParams>
      </div>
    </PageWrapper>
  );
}

PrivacyPolicy.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
};
