import React from 'react';
import PropTypes from 'prop-types';
import PageWrapper from '../Wrapper/PageWrapper';
import Menu from '../Menu/Menu';
import H1 from '../Heading/H1';
import './AccountContentWrapper.css';

function AccountContentWrapper(props) {
  return (
    <PageWrapper
      locale={props.locale}
      setLocale={props.setLocale}
      services={props.services}
      dataStorage={props.dataStorage}
      setDataStorage={props.setDataStorage}
    >
      <div className="content-container">
        <div className="grid-user-account">
          <div className="user-account-sidebar">
            <Menu
              type="flat"
              classNames={{
                ul: 'user-account-menu',
                li: 'user-account-menu__item',
                heading: 'user-account-menu-heading',
                subli: 'user-account-menu-item',
              }}
              items={props.services.configService.getAccountMainMenu(props.locale)}
            />
          </div>
          <div className="user-account-main">
            <H1 configService={props.services.configService} />
            {props.children}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

AccountContentWrapper.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  setDataStorage: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
};

export default AccountContentWrapper;
