function getCurrentPath() {
  return window.location.pathname.toLowerCase();
}

function getCurrentPathWithoutQuery() {
  return window.location.pathname.split('?')[0];
}

function getQueryParamValue(queryParamName) {
  return new URLSearchParams(window.location.search).get(queryParamName);
}

function getItemByUrlQuery(queryParamName, itemAttributeName, items) {
  const paramValue = getQueryParamValue(queryParamName);
  if (paramValue) {
    for (let i = 0; i < items.length; i++) {
      if (paramValue === items[i][itemAttributeName]) {
        return items[i];
      }
    }
  }
  return undefined;
}

function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  const newString = string.replaceAll('ä', 'ae').replaceAll('ö', 'oe').replaceAll('ü', 'ue');
  return newString.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace('.', '_') // Replace . with _
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

function getUrlSlug(item, locale) {
  if (item.urlSlug && item.urlSlug[locale]) {
    return item.urlSlug[locale];
  }
  return slugify(item.name[locale]);
}

function getItemByUrlSlug(items, slug, locale) {
  for (let i = 0; i < items.length; i++) {
    if (slug === getUrlSlug(items[i], locale)) {
      return items[i];
    }
  }
  return null;
}

function translateSlug(slug, currentLocale, targetLocale, items) {
  const item = getItemByUrlSlug(items, slug, currentLocale);
  return (item) ? getUrlSlug(item, targetLocale) : null;
}

function getTranslatedPathWith1Id(
  currentPath, targetTemplatePath, currentLocale, targetLocale, items
) {
  const currentSlugs = currentPath.split('/');
  const targetSlugs = targetTemplatePath.split('/');
  if (currentSlugs[currentSlugs.length - 1] === '') {
    currentSlugs.pop();
  }
  if (targetSlugs[targetSlugs.length - 1] === '') {
    targetSlugs.pop();
  }
  if (targetSlugs[targetSlugs.length - 1].startsWith(':')) {
    targetSlugs.pop();
  }
  const slug = translateSlug(
    currentSlugs[currentSlugs.length - 1], currentLocale, targetLocale, items
  );
  if (slug) {
    targetSlugs.push(slug);
  } else {
    console.error(`getTranslatedPathWith1Id | Current path:${currentPath} Target template path:${targetTemplatePath}`);
  }
  return targetSlugs.join('/');
}

export default {
  getCurrentPath,
  getCurrentPathWithoutQuery,
  getQueryParamValue,
  getItemByUrlQuery,
  slugify,
  getUrlSlug,
  getItemByUrlSlug,
  translateSlug,
  getTranslatedPathWith1Id,
};
