import queryString from 'query-string';
import arrayHelper from '../utils/helper/array-helper';
import numberHelper from '../utils/helper/number-helper';
import urlHelper from '../utils/helper/url-helper';

const searchUrlQueryParamName = 'listSearch';
const sortUrlQueryParamName = 'listSort';
const pageUrlQueryParamName = 'listPage';
const layoutUrlQueryParamName = 'listLayout';
const alphanumSortTokenMap = {
  '1/2': '0.5',
  '1/4': '0.2',
  '3/4': '0.7',
  '1/8': '0.1',
  '3/8': '0.3',
  '5/8': '0.6',
  '7/8': '0.8',
};

function replaceDivisionInSortValue(val) {
  if (val.length > 2 && val.includes('/')) {
    const divTokens = Object.keys(alphanumSortTokenMap);
    for (let i = 0; i < divTokens.length; i++) {
      if (val.includes(divTokens[i])) {
        return val.replace(divTokens[i], alphanumSortTokenMap[divTokens[i]]);
      }
    }
  }
  return val;
}

function differentTypeButSameValue(valA, valB) {
  if (typeof valA !== typeof valB && valA && valB) {
    if (typeof valA !== 'string' && valA.toString() === valB) {
      return true;
    }
    if (typeof valB !== 'string' && valB.toString() === valA) {
      return true;
    }
  }
  return false;
}

function attributeMatchesArrayFilter(attributeValue, filterValues, locale) {
  const value = (typeof attributeValue === 'object' && !Array.isArray(attributeValue))
    ? attributeValue[locale] : attributeValue;
  if (filterValues.length === 0) {
    return true;
  }
  if (Array.isArray(value)) {
    for (let i = 0; i < filterValues.length; i++) {
      let match = false;
      for (let k = 0; k < value.length && !match; k++) {
        if (
          filterValues[i] === value[k]
          || differentTypeButSameValue(value[k], filterValues[i])
        ) {
          match = true;
        }
      }
      if (!match) {
        return false;
      }
    }
    return true;
  }
  for (let i = 0; i < filterValues.length; i++) {
    if (filterValues[i] === value || differentTypeButSameValue(value, filterValues[i])) {
      return true;
    }
  }
  return false;
}

function attributeMatchesMinMaxFilter(attributeValue, filterValues) {
  if (attributeValue.from !== undefined && attributeValue.to !== undefined) {
    return (attributeValue.from <= filterValues.min && attributeValue.to >= filterValues.max);
  }
  return (attributeValue >= filterValues.min && attributeValue <= filterValues.max);
}

function multiSelectChangeHandler(selectedOptions, changedOption) {
  const newSelectedOptions = [];
  let match = false;
  if (selectedOptions) {
    for (let i = 0; i < selectedOptions.length; i++) {
      if (selectedOptions[i] === changedOption) {
        match = true;
      } else {
        newSelectedOptions.push(selectedOptions[i]);
      }
    }
  }
  if (!match) {
    newSelectedOptions.push(changedOption);
  }
  return newSelectedOptions;
}

function filterChangeHandler(
  filters, filterId, changedValue, setActiveFilters, parentFilterChangeHandler
) {
  const newFilters = { ...filters };
  if (changedValue && changedValue.min !== undefined && changedValue.max !== undefined) {
    newFilters[filterId] = changedValue;
  } else {
    newFilters[filterId] = multiSelectChangeHandler(
      newFilters[filterId],
      changedValue
    );
    if (newFilters[filterId].length === 0) {
      delete newFilters[filterId];
    }
  }
  setActiveFilters(newFilters);
  parentFilterChangeHandler(newFilters);
}

function filterDeleteHandler(filters, filterId, setActiveFilters, parentFilterChangeHandler) {
  let newFilters = {};
  if (filterId) {
    newFilters = { ...filters };
    delete newFilters[filterId];
  }
  setActiveFilters(newFilters);
  parentFilterChangeHandler(newFilters);
}

function getFilterSummary(activeFilters, filters) {
  const summaries = [];
  const activeFilterIds = Object.keys(activeFilters);
  for (let i = 0; i < activeFilterIds.length; i++) {
    let match = false;
    for (let k = 0; k < filters.length && !match; k++) {
      if (activeFilterIds[i] === filters[k].id) {
        summaries.push({
          attributeId: activeFilterIds[i],
          selectedValues: activeFilters[activeFilterIds[i]],
          heading: filters[k].heading,
          measuringUnit: filters[k].measuringUnit,
          currency: filters[k].currency,
          locale: filters[k].locale,
        });
        match = true;
      }
    }
  }
  return summaries;
}

function attributeMatchesFilter(attributeValue, filter, locale) {
  if (Array.isArray(filter)) {
    return attributeMatchesArrayFilter(attributeValue, filter, locale);
  }
  if (filter.min !== undefined && filter.max !== undefined) {
    return attributeMatchesMinMaxFilter(attributeValue, filter);
  }
  return false;
}

function getListItemAttributeValues(item, filterAttributeId, locale, dataStorage, customFunc) {
  if (customFunc) {
    return customFunc(item, filterAttributeId, locale, dataStorage);
  }
  return item[filterAttributeId];
}

function getFilteredItemList(items, filters, locale, dataStorage, customItemAttributeValuesFunc) {
  const filteredItems = [];
  const filterAttributeIds = Object.keys(filters);
  items.forEach((item) => {
    let match = true;
    for (let i = 0; i < filterAttributeIds.length; i++) {
      const itemAttributeValues = getListItemAttributeValues(
        item, filterAttributeIds[i], locale, dataStorage, customItemAttributeValuesFunc
      );
      if (!attributeMatchesFilter(
        itemAttributeValues,
        filters[filterAttributeIds[i]],
        locale
      )
      ) {
        match = false;
        break;
      }
    }
    if (match) {
      filteredItems.push(item);
    }
  });
  return filteredItems;
}

function getFilterListMetaData(
  items,
  filterAttributeIds,
  selectedValues,
  locale,
  dataStorage,
  intl,
  customItemAttributeValuesFunc
) {
  const filterdItems = getFilteredItemList(
    items, selectedValues, locale, dataStorage, customItemAttributeValuesFunc
  );
  return filterAttributeIds.map((attributeId) => {
    const heading = intl.formatMessage({ id: attributeId });
    const values = arrayHelper.concatInnerArray(filterdItems.map((item) => (
      getListItemAttributeValues(
        item, attributeId, locale, dataStorage, customItemAttributeValuesFunc
      )
    )));
    const originalValues = arrayHelper.concatInnerArray(items.map((item) => (
      getListItemAttributeValues(
        item, attributeId, locale, dataStorage, customItemAttributeValuesFunc
      )
    )));
    return {
      type: 'multiSelect',
      id: attributeId,
      heading,
      values,
      selectedValues: selectedValues[attributeId],
      filteredOutValues: originalValues.filter((originalValue) => !values.includes(originalValue)),
      locale,
      hasFromToValues: false,
    };
  });
}

function expandedFilterChangeHandler(
  params, expandedFilter, expandedFilterDropdownId, setExpandedFilter, setExpandedFilterDropdownId
) {
  if (params.isExpanded) {
    setTimeout(() => {
      setExpandedFilter(params.attributeId);
      setExpandedFilterDropdownId(params.dropdownId);
    }, 1000);
  } else if (expandedFilter === params.attributeId
    && expandedFilterDropdownId === params.dropdownId) {
    setExpandedFilter(undefined);
    setExpandedFilterDropdownId(undefined);
  }
}

function getSortCompareAttributeValues(a, b, propertyPath, locale) {
  let valueA = a;
  let valueB = b;
  propertyPath.forEach((part) => {
    valueA = valueA[part];
    valueB = valueB[part];
  });
  if (valueA[locale]) {
    valueA = valueA[locale];
    valueB = valueB[locale];
  }
  return { a: valueA, b: valueB };
}

function sortList(objectArray, propertyPath, order, locale) {
  if (!propertyPath) {
    return objectArray;
  }
  const sortedArray = objectArray;
  sortedArray.sort((a, b) => {
    const values = getSortCompareAttributeValues(a, b, propertyPath, locale);
    if (values.a.localeCompare) {
      return values.a.localeCompare(values.b, locale, {
        numeric: true,
        sensitivity: 'base',
      });
    }
    return values.a - values.b;
  });
  if (order !== 'ASC') {
    sortedArray.reverse();
  }
  return sortedArray;
}

function getFilteredAndSortedItems(
  items, activeFilters, activeSortOption, locale, dataStorage, getAttributeValuesFunc
) {
  return sortList(
    getFilteredItemList(items, activeFilters, locale, dataStorage, getAttributeValuesFunc),
    activeSortOption.propertyPath,
    activeSortOption.order,
    locale
  );
}

function getSortOption(sortAttributeSetting, order, intl, locale) {
  let name = sortAttributeSetting.name[locale];
  if (sortAttributeSetting.type === 'String') {
    name = `${name} ${intl.formatMessage({ id: (order === 'ASC') ? 'sortOrderAZ' : 'sortOrderZA' })}`;
  } else if (sortAttributeSetting.type === 'Number') {
    name = `${name} ${intl.formatMessage({ id: (order === 'ASC') ? 'ascending' : 'descending' })}`;
  }
  return {
    name,
    propertyPath: sortAttributeSetting.propertyPath,
    order,
  };
}

function getSortOptions(sortAttributeSettings, intl, locale) {
  const sortOptions = [];
  for (let i = 0; i < sortAttributeSettings.length; i++) {
    if (!sortAttributeSettings[i].orders) {
      sortOptions.push(getSortOption(sortAttributeSettings[i], 'ASC', intl, locale));
    } else {
      sortAttributeSettings[i].orders.forEach((order) => {
        sortOptions.push(getSortOption(sortAttributeSettings[i], order, intl, locale));
      });
    }
  }
  return sortOptions;
}

function getDefaultSortOption(sortAttributeSettings, intl, locale) {
  for (let i = 0; i < sortAttributeSettings.length; i++) {
    if (sortAttributeSettings[i].preset) {
      return getSortOption(sortAttributeSettings[i], sortAttributeSettings[i].preset, intl, locale);
    }
  }
  return undefined;
}

function checkIfSortOptionExists(sortOptions, activeSort) {
  for (let i = 0; i < sortOptions.length; i++) {
    if (sortOptions[i].name === activeSort.name) {
      return true;
    }
  }
  return false;
}

function getSortDropdownItems(sortOptions, activeSortOption, onClickHandler) {
  return sortOptions.map((sortOption) => {
    const dropDownItem = {
      label: sortOption.name,
      href: '#',
      onClickHandler,
      onClickHandlerParams: { sortOption },
    };
    if (sortOption.name === activeSortOption.name) {
      dropDownItem.isActive = true;
    }
    return dropDownItem;
  });
}

function sortChangeHandler(params, setActiveSort, parentSortChangeHandler) {
  setActiveSort(params.sortOption);
  parentSortChangeHandler(params.sortOption);
}

function getAdjustedSearchTerm(searchTerm, termAdjustments) {
  let adjustedSearchTerm = searchTerm.toUpperCase();
  if (Array.isArray(termAdjustments)) {
    termAdjustments.forEach((termAdjustment) => {
      let remainingSearchTerm;
      const matchTokenArr = [];
      let targetTokenArr = [];
      const origPattern = termAdjustment.originalPattern;
      const { targetPattern } = termAdjustment;
      if (origPattern[0].value && adjustedSearchTerm.includes(
        origPattern[0].value.toUpperCase()
      )) {
        matchTokenArr.push(origPattern[0].value);
        targetTokenArr.push(targetPattern[0].value);
        remainingSearchTerm = adjustedSearchTerm.replace(
          `${searchTerm.split(origPattern[0].value)[0]}${origPattern[0].value}`, ''
        ).toUpperCase();
      } else if (origPattern[0].type === 'Number') {
        const numbers = numberHelper.findNumbersInString(
          searchTerm, origPattern[0].decimalSeparator
        );
        if (numbers.length > 0) {
          matchTokenArr.push(numbers[0]);
          targetTokenArr.push(targetPattern[0].value || numbers[0]);
          remainingSearchTerm = adjustedSearchTerm.replace(`${adjustedSearchTerm.split(numbers[0])[0]}${numbers[0]}`, '').toUpperCase();
        }
      }
      if (origPattern.length > 1) {
        for (let i = 1; i < origPattern.length && targetTokenArr.length > 0; i++) {
          if (origPattern[i].value && remainingSearchTerm.startsWith(
            origPattern[i].value.toUpperCase()
          )) {
            matchTokenArr.push(origPattern[i].value);
            targetTokenArr.push(targetPattern[i].value);
            remainingSearchTerm = searchTerm.replace(origPattern[i].value, '');
          } else if (origPattern[i].type === 'Number') {
            const numbers = numberHelper.findNumbersInString(
              searchTerm, origPattern[i].decimalSeparator
            );
            if (Array.isArray(numbers) && numbers.length > 0
              && remainingSearchTerm.startsWith(numbers[0])
            ) {
              matchTokenArr.push(numbers[0]);
              targetTokenArr.push(targetPattern[i].value || numbers[0]);
              remainingSearchTerm = searchTerm.replace(numbers[0], '');
            }
          } else {
            targetTokenArr = [];
          }
        }
      }
      if (targetTokenArr.length > 0) {
        adjustedSearchTerm = adjustedSearchTerm.replace(matchTokenArr.join(''), targetTokenArr.join(''));
      }
    });
  }
  return adjustedSearchTerm.replaceAll('  ', ' ');
}

function getSearchTermParts(searchTerm) {
  const parts = searchTerm.toUpperCase().split(' ');
  if (parts[0] !== searchTerm.toUpperCase()) {
    parts.unshift(searchTerm.toUpperCase());
  }
  return parts.filter((part) => part.length > 1);
}

function getSearchTermPartWeights(searchTermParts) {
  const searchTermPartWeights = {};
  for (let i = 0; i < searchTermParts.length; i++) {
    searchTermPartWeights[searchTermParts[i]] = 0;
  }
  return searchTermPartWeights;
}

function compareSearchTermPartAttribute(
  searchTermPart, propertyPath, item, searchTermPartWeights, weightExactMatch, weightPartialMatch
) {
  let property = item;
  propertyPath.forEach((propertyName) => {
    property = property[propertyName];
  });

  if (property.toUpperCase() === searchTermPart) {
    searchTermPartWeights[searchTermPart] += weightExactMatch;
  }
  if (property.toUpperCase().includes(searchTermPart)) {
    searchTermPartWeights[searchTermPart] += weightPartialMatch;
  }
}

function basicSearchCompareFunc(
  item, searchTermParts, searchTermPartWeights, compareFuncCustomParams
) {
  searchTermParts.forEach((searchTermPart) => {
    compareFuncCustomParams.forEach((compareProperty) => {
      compareSearchTermPartAttribute(
        searchTermPart,
        compareProperty.path,
        item,
        searchTermPartWeights,
        compareProperty.exact,
        compareProperty.partial
      );
    });
  });
}

function updateWeightedMatches(
  weightedMatches, maxWeight, searchTermPartWeights, searchTermParts, logicOperator, item
) {
  let weight = 0;
  const matchesFullSearchTerm = searchTermPartWeights[searchTermParts[0]] > 0;
  let matchesAllOtherSearchTermParts = searchTermParts.length > 1;
  for (let i = 0; i < searchTermParts.length; i++) {
    weight += searchTermPartWeights[searchTermParts[i]];
    if (i > 0 && searchTermPartWeights[searchTermParts[i]] === 0) {
      matchesAllOtherSearchTermParts = false;
    }
  }
  if ((maxWeight - 10 <= weight) && ((logicOperator === 'OR' && weight) || (logicOperator === 'AND' && (matchesFullSearchTerm || matchesAllOtherSearchTermParts)))) {
    weightedMatches.push({
      item,
      weight,
    });
    return weight;
  }
  return 0;
}

function cleanUpWeightedMatches(weightedMatches, maxWeight) {
  const weightedMatchesTmp = [];
  for (let i = 0; i < weightedMatches.length && weightedMatchesTmp.length < 1000; i++) {
    if (weightedMatches[i].weight >= maxWeight - 10) {
      weightedMatchesTmp.push(weightedMatches[i]);
    }
  }
  return weightedMatchesTmp;
}

function search(
  items, searchTerm, logicOperator, locale, compareFunc, compareFuncCustomParams, sortListFinally
) {
  const searchTermParts = getSearchTermParts(searchTerm);
  if (!searchTermParts.length) {
    return items;
  }
  let weightedMatches = [];
  let maxWeight = 0;
  for (let i = 0; i < items.length && weightedMatches.length < 100000; i++) {
    const searchTermPartWeights = getSearchTermPartWeights(searchTermParts);
    compareFunc(items[i], searchTermParts, searchTermPartWeights, compareFuncCustomParams);
    const itemWeight = updateWeightedMatches(
      weightedMatches, maxWeight, searchTermPartWeights, searchTermParts, logicOperator, items[i]
    );
    if (itemWeight > maxWeight) {
      maxWeight = itemWeight;
    }
  }
  if (!weightedMatches.length) {
    return [];
  }
  if (sortListFinally) {
    weightedMatches = cleanUpWeightedMatches(weightedMatches, maxWeight);
    const sortedList = sortList(weightedMatches, ['weight'], 'DESC', locale).map((weightedMatch) => (weightedMatch.item));
    return sortedList;
  }
  return weightedMatches.map((weightedMatch) => (weightedMatch.item));
}

function setListSettingsByUrlQueryParameters(
  filters,
  sortOptions,
  handlers,
  currentPageIndexChangeHandler
) {
  const searchTerm = urlHelper.getQueryParamValue(searchUrlQueryParamName);
  if (searchTerm && handlers.searchTermChangeHandler) {
    handlers.searchTermChangeHandler({ searchTerm: searchTerm.replaceAll('+', ' ') });
  }
  const sortOptionParam = urlHelper.getQueryParamValue(sortUrlQueryParamName);
  if (sortOptionParam && handlers.sortChangeHandler) {
    const sortOptionName = sortOptionParam.replaceAll('+', ' ');
    let sortOptionMatch = false;
    for (let i = 0; i < sortOptions.length && !sortOptionMatch; i++) {
      if (sortOptions[i].name === sortOptionName) {
        sortOptionMatch = true;
        handlers.sortChangeHandler({ sortOption: sortOptions[i] });
      }
    }
  }
  const activeLayout = urlHelper.getQueryParamValue(layoutUrlQueryParamName);
  if (activeLayout && handlers.layoutChangeHandler) {
    handlers.layoutChangeHandler({ layout: activeLayout, setByQuery: true });
  }
  if (handlers.filtersSetHandler) {
    const newFilters = {};
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === 'range') {
        const min = urlHelper.getQueryParamValue(`lf-${filters[i].id}-min`);
        const max = urlHelper.getQueryParamValue(`lf-${filters[i].id}-max`);
        if (min && max) {
          newFilters[filters[i].id] = { min: Number(min), max: Number(max) };
        }
      } else {
        const filterValues = new URLSearchParams(window.location.search).getAll(`lf-${filters[i].id}`);
        if (filterValues && filterValues.length > 0) {
          const isNumber = numberHelper.allDefinedValuesAreNumeric(filters[i].values);
          const formattedFilterValues = filterValues.map(
            (value) => ((isNumber) ? Number(value) : value.replaceAll('+', ' '))
          );
          newFilters[filters[i].id] = formattedFilterValues;
        }
      }
    }
    if (Object.keys(newFilters).length > 0) {
      handlers.filtersSetHandler({ newFilters });
    }
  }
  const currentPageIndex = urlHelper.getQueryParamValue(pageUrlQueryParamName);
  if (currentPageIndex > 0) {
    currentPageIndexChangeHandler({ index: currentPageIndex, setByQuery: true });
  }
}

function getCurrentQueryStringWithoutListSettings() {
  const currentQueryObj = queryString.parse(window.location.search);
  const filteredQueryArr = [];
  Object.keys(currentQueryObj).forEach((paramName) => {
    if (!paramName.startsWith('lf-')
      && paramName !== searchUrlQueryParamName
      && paramName !== sortUrlQueryParamName
      && paramName !== pageUrlQueryParamName
      && paramName !== layoutUrlQueryParamName
    ) {
      filteredQueryArr.push(`${paramName}=${currentQueryObj[paramName]}`);
    }
  });
  if (filteredQueryArr.length > 0) {
    return filteredQueryArr.join('&');
  }
  return undefined;
}

function getPathAndQueryParamsBasedOnListSettings(
  activeFilters, searchTerm, activeSortOption, currentPageIndex, activeLayout
) {
  let newPath = `${window.location.pathname}?`;
  let hasQueryParams = false;
  Object.keys(activeFilters).forEach((filterId) => {
    if (Array.isArray(activeFilters[filterId])) {
      activeFilters[filterId].forEach((filterValue) => {
        newPath = `${newPath}${(hasQueryParams) ? '&lf-' : 'lf-'}${filterId}=${(typeof filterValue === 'string') ? filterValue.replaceAll(' ', '+') : filterValue}`;
        hasQueryParams = true;
      });
    } else {
      newPath = `${newPath}${(hasQueryParams) ? '&lf-' : 'lf-'}${filterId}-min=${activeFilters[filterId].min}&lf-${filterId}-max=${activeFilters[filterId].max}`;
      hasQueryParams = true;
    }
  });
  if (searchTerm) {
    newPath = `${newPath}${(hasQueryParams) ? '&' : ''}${searchUrlQueryParamName}=${searchTerm.replaceAll(' ', '+')}`;
    hasQueryParams = true;
  }
  if (activeSortOption) {
    newPath = `${newPath}${(hasQueryParams) ? '&' : ''}${sortUrlQueryParamName}=${activeSortOption.name.replaceAll(' ', '+')}`;
    hasQueryParams = true;
  }
  if (currentPageIndex > 0) {
    newPath = `${newPath}${(hasQueryParams) ? '&' : ''}${pageUrlQueryParamName}=${currentPageIndex}`;
    hasQueryParams = true;
  }
  if (activeLayout) {
    newPath = `${newPath}${(hasQueryParams) ? '&' : ''}${layoutUrlQueryParamName}=${activeLayout}`;
  }
  const noListQueryParamString = getCurrentQueryStringWithoutListSettings();
  if (noListQueryParamString) {
    newPath = `${newPath}${(hasQueryParams) ? '&' : ''}${noListQueryParamString}`;
  }
  return newPath;
}

export default {
  replaceDivisionInSortValue,
  attributeMatchesArrayFilter,
  attributeMatchesMinMaxFilter,
  multiSelectChangeHandler,
  filterChangeHandler,
  filterDeleteHandler,
  getFilterSummary,
  getFilteredItemList,
  getFilterListMetaData,
  expandedFilterChangeHandler,
  sortList,
  getFilteredAndSortedItems,
  getSortOptions,
  getDefaultSortOption,
  checkIfSortOptionExists,
  getSortDropdownItems,
  sortChangeHandler,
  getAdjustedSearchTerm,
  compareSearchTermPartAttribute,
  basicSearchCompareFunc,
  getSearchTermParts,
  getSearchTermPartWeights,
  search,
  setListSettingsByUrlQueryParameters,
  getPathAndQueryParamsBasedOnListSettings,
};
