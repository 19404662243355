import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Layout/Icon';

export default function InvalidInputMessage(props) {
  return (
    <Fragment>
      <div className={props.display ? props.classNames.label : 'display-none'} />
      <div className={props.display ? props.classNames.input : 'display-none'}>
        <div className="form-invalid-input-message">
          <Icon name="close" size={20} />
          {props.message}
        </div>
      </div>
    </Fragment>
  );
}

InvalidInputMessage.propTypes = {
  classNames: PropTypes.objectOf(PropTypes.string),
  display: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

InvalidInputMessage.defaultProps = {
  classNames: {
    label: 'grid-form__label',
    input: 'grid-form__input',
  },
};
