import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import MainNavStepItem from './MainNavStepItem';
import HosePartMenuButtons from './HosePartMenuButtons';

export default function MainNav(props) {
  const intl = useIntl();
  const hoseEndATranslation = `${intl.formatMessage({ id: 'hcEnd' })} A`;
  const hoseEndBTranslation = `${intl.formatMessage({ id: 'hcEnd' })} B`;
  const hoseEndASelectButtonText = intl.formatMessage({ id: 'hcSelectHosePart' }, { part: hoseEndATranslation });
  const hoseSelectButtonText = intl.formatMessage({ id: 'hcSelectHosePart' }, { part: intl.formatMessage({ id: 'hcHose' }) });
  const hoseEndBSelectButtonText = intl.formatMessage({ id: 'hcSelectHosePart' }, { part: hoseEndBTranslation });
  return (
    <Fragment>
      <div className="grid-hc-composed-image">
        <div className="grid-hc-composed-image-img1">
          <MainNavStepItem
            locale={props.locale}
            configuratorState={props.configuratorState}
            hosePartId="endA"
            hoseConfiguration={props.hoseConfiguration}
            hosePartUndefinedImgAltText={`${hoseEndATranslation} ${intl.formatMessage({ id: 'hcNotSelected' })}`}
            selectButtonText={hoseEndASelectButtonText}
            handlers={props.handlers}
            services={props.services}
          />
        </div>
        <div className="grid-hc-composed-image-img2">
          <MainNavStepItem
            locale={props.locale}
            configuratorState={props.configuratorState}
            hosePartId="hose"
            hoseConfiguration={props.hoseConfiguration}
            hosePartUndefinedImgAltText={`${intl.formatMessage({ id: 'hcHose' })} ${intl.formatMessage({ id: 'hcNotSelected' })}`}
            selectButtonText={hoseSelectButtonText}
            handlers={props.handlers}
            services={props.services}
          />
        </div>
        <div className="grid-hc-composed-image-img2">
          <MainNavStepItem
            locale={props.locale}
            configuratorState={props.configuratorState}
            hosePartId="endB"
            hoseConfiguration={props.hoseConfiguration}
            hosePartUndefinedImgAltText={`${hoseEndBTranslation} ${intl.formatMessage({ id: 'hcNotSelected' })}`}
            selectButtonText={hoseEndBSelectButtonText}
            handlers={props.handlers}
            services={props.services}
          />
        </div>
      </div>
      <div className="grid-hc-mobile-main-nav mobile-only">
        <HosePartMenuButtons
          configuratorState={props.configuratorState}
          hosePartId="endA"
          hosePartConfig={props.hoseConfiguration.endA}
          selectButtonText={hoseEndASelectButtonText}
          containerClassNames="hc-mobile-step-nav"
          handlers={props.handlers}
          services={props.services}
        />
        <HosePartMenuButtons
          configuratorState={props.configuratorState}
          hosePartId="hose"
          hosePartConfig={props.hoseConfiguration.hose}
          selectButtonText={hoseSelectButtonText}
          containerClassNames="hc-mobile-step-nav"
          handlers={props.handlers}
          services={props.services}
        />
        <HosePartMenuButtons
          configuratorState={props.configuratorState}
          hosePartId="endB"
          hosePartConfig={props.hoseConfiguration.endB}
          selectButtonText={hoseEndBSelectButtonText}
          containerClassNames="hc-mobile-step-nav"
          handlers={props.handlers}
          services={props.services}
        />
      </div>
    </Fragment>
  );
}

MainNav.propTypes = {
  locale: PropTypes.string.isRequired,
  configuratorState: PropTypes.string.isRequired,
  hoseConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
  handlers: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};
