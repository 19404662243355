import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../Form/FormInput';

export default function HoseCodeSearch(props) {
  return (
    <div className="grid-form">
      <FormInput
        id="hoseCode"
        label="hcHoseCode:"
        value={props.hoseCode}
        callback={props.hoseCodeChangeHandler}
        isValid={props.isValidHoseCode}
        errorMessageId="invalidHoseCodeMessage"
        noErrorMessageIfEmpty={true}
      />
    </div>
  );
}

HoseCodeSearch.propTypes = {
  hoseCode: PropTypes.string,
  hoseCodeChangeHandler: PropTypes.func.isRequired,
  isValidHoseCode: PropTypes.bool.isRequired,
};

HoseCodeSearch.defaultProps = {
  hoseCode: undefined,
};
