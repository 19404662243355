import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '../Layout/Icon';
import './Location.css';

export default function LocationList(props) {
  function getAddress(address) {
    return address.map((line) => (<Fragment>{line}<br /></Fragment>));
  }
  function getLocations() {
    return props.locations.map((location) => (
      <div className="location-container">
        <img
          src={`/img/${location.img}`}
          alt={location.name[props.locale]}
        />
        <h2 className="location-name">
          {location.name[props.locale]}
          {(props.locale !== location.cc) && (<span>, <FormattedMessage id={`CC_${location.cc.toUpperCase()}`} /></span>)}
        </h2>
        <div className="location-info">
          <div className="location-address">
            {getAddress(location.address)}
            {(props.locale !== location.cc) && (<FormattedMessage id={`CC_${location.cc.toUpperCase()}`} />)}
          </div>
          <div className="location-contact-info">
            <Icon name="phone" />{location.phone}<br />
            <Icon name="mail" /><a href={`mailto:${location.mail}`}>{location.mail}</a><br />
            <Icon name="chrome" /><a href={location.web}>{location.web}</a>
          </div>
        </div>
      </div>
    ));
  }
  return (
    <div className="grid-locations content-container links-in-primary-font-color">
      {getLocations()}
    </div>
  );
}

LocationList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
};
