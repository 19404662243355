import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

export default function Spinner(props) {
  const hmtlSpinner = (
    <div className="center">
      <div className="spinner-dual-ring" />
    </div>
  );
  let resultHtml = hmtlSpinner;
  if (props.label) {
    resultHtml = (
      <div className="spinner-and-label">
        {props.showLogo && (<div className="center"><img src="/logo.svg" alt="Spinner Logo" className="spinner-logo" /></div>)}
        {hmtlSpinner}
        <p className="spinner-label">{props.label}</p>
      </div>
    );
  }
  return (resultHtml);
}

Spinner.propTypes = {
  label: PropTypes.string,
  showLogo: PropTypes.bool,
};

Spinner.defaultProps = {
  label: undefined,
  showLogo: false,
};
