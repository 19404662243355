function isObject(object) {
  return object && typeof object === 'object' && object.constructor === Object;
}

function isEmptyObject(object) {
  return isObject(object) && Object.keys(object).length === 0;
}

function isPopulatedObject(object) {
  return isObject(object) && Object.keys(object).length > 0;
}

function mapKeysToAssociativeArray(object) {
  if (!isPopulatedObject(object)) {
    return false;
  }
  const array = [];
  Object.keys(object).forEach((key) => {
    array[key] = key;
  });
  return array;
}

function compareProperties(object1, object2, propertiesToCompare) {
  if (!isPopulatedObject(object1) || !isPopulatedObject(object2)) {
    return false;
  }

  if (Array.isArray(propertiesToCompare) && propertiesToCompare.length === 0) {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  if (Array.isArray(propertiesToCompare) && propertiesToCompare.length > 0) {
    for (let i = 0; i < propertiesToCompare.length; i++) {
      if (object1[propertiesToCompare[i]] !== object2[propertiesToCompare[i]]) {
        return false;
      }
    }
    return true;
  }

  return false;
}

module.exports = {
  isObject,
  isEmptyObject,
  isPopulatedObject,
  mapKeysToAssociativeArray,
  compareProperties,
};
