import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import './SocialChannelButtons.css';
import Icon from '../Layout/Icon';

export default function SocialCannelButtons(props) {
  const intl = useIntl();
  const buttons = props.socialChannels.map((channel) => {
    const divClassNames = `social-media-button social-media-button--${channel.name}`;
    const link = `https://www.${channel.name}.com/${channel.id}`;
    return (
      <a className="social-media-button-link" href={link} target="_blank" rel="noreferrer" key={channel.name}>
        <div className={divClassNames}>
          <Icon name={channel.name} />
          <span className="sr-only">{`${channel.name} ${intl.formatMessage({ id: 'channel' })}`}</span>
        </div>
      </a>
    );
  });
  return (
    <div className={props.classNames}>
      {buttons}
    </div>
  );
}

SocialCannelButtons.propTypes = {
  classNames: PropTypes.string.isRequired,
  socialChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
};
