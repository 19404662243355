import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';
import AdvancedHeroRow from '../Layout/AdvancedHeroRow';
import './Slider.css';

export default function Slider(props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  const prevNextSlide = (params) => {
    let newActiveSlideIndex = activeSlideIndex + params.change;
    if (newActiveSlideIndex > props.slides.length) {
      newActiveSlideIndex = 1;
    }
    if (newActiveSlideIndex < 1) {
      newActiveSlideIndex = props.slides.length;
    }
    setActiveSlideIndex(newActiveSlideIndex);
  };
  const clickDotHandler = (params) => {
    setActiveSlideIndex(params.newIndex);
  };
  function getSlides() {
    return props.slides.map((slide, index) => (
      <div className={`slide slide-fade${(index + 1 === activeSlideIndex) ? ' slide--active' : ''}`} key={`slide${slide.heading}`}>
        {slide.type === 'ahr' && (
          <AdvancedHeroRow
            item={slide}
            locale={props.locale}
            dataStorage={props.dataStorage}
            services={props.services}
          />
        )}
        {slide.type !== 'ahr' && (
          <Fragment>
            {slide.content}
            <img src={slide.bgSrc} alt={slide.bgAlt} className="slide-bg" />
          </Fragment>
        )}
      </div>
    ));
  }
  function getSliderDots() {
    return props.slides.map((slide, index) => (
      <CustomLink
        href="#"
        onClickHandler={clickDotHandler}
        onClickHandlerParams={{ newIndex: index + 1 }}
        key={`slidedot${slide.heading}`}
      >
        <span className={`slider-dot${(index + 1 === activeSlideIndex) ? ' slider-dot--active' : ''}`} aria-hidden="true" />
        <span className="sr-only">{`slide ${index + 1}`}</span>
      </CustomLink>
    ));
  }
  useEffect(() => {
    const interval = setInterval(() => {
      prevNextSlide({ change: 1 });
    }, props.interval);
    return () => clearInterval(interval);
  }, [activeSlideIndex]);
  return (
    <div className="slider">
      {getSlides()}
      <div className="slide-prev">
        <CustomLink
          href="#"
          onClickHandler={prevNextSlide}
          onClickHandlerParams={{ change: -1 }}
        >&#10094;
        </CustomLink>
      </div>
      <div className="slide-next">
        <CustomLink
          href="#"
          onClickHandler={prevNextSlide}
          onClickHandlerParams={{ change: 1 }}
        >&#10095;
        </CustomLink>
      </div>
      <div className="slider-dotsbox">
        {getSliderDots()}
      </div>
    </div>
  );
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  interval: PropTypes.number,
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

Slider.defaultProps = {
  interval: 7000,
};
