import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PositionRow from './PositionRow';
import stringHelper from '../../utils/helper/string-helper';

export default function Summary(props) {
  function getAutomationPartHeading(stepId) {
    return (
      <div className="hc-summary-hose-part-heading">
        <strong><FormattedMessage id={`ac${stringHelper.capitalizeFirstLetter(stepId)}`} />:</strong>
      </div>
    );
  }
  function getAutomationPart(stepId, automationConfiguration) {
    const componentsOfStep = [];
    Object.keys(automationConfiguration[stepId]).forEach((secondaryStepId) => {
      if (Array.isArray(automationConfiguration[stepId][secondaryStepId])) {
        automationConfiguration[stepId][secondaryStepId].forEach((secStepConf) => {
          componentsOfStep.push(
            <PositionRow
              product={secStepConf.product}
              qty={secStepConf.qty}
              locale={props.locale}
              services={props.services}
              dataStorage={props.dataStorage}
            />
          );
        });
      } else {
        componentsOfStep.push(
          <PositionRow
            product={automationConfiguration[stepId][secondaryStepId]}
            locale={props.locale}
            services={props.services}
            dataStorage={props.dataStorage}
          />
        );
      }
    });
    return (
      <Fragment>
        {getAutomationPartHeading(stepId)}
        {componentsOfStep}
      </Fragment>
    );
  }
  function getFinalPart() {
    return (
      <div className="grid-hc-summary-flat-row grid-hc-summary-flat-row--net">
        <div>
          <strong><FormattedMessage id="sum" /></strong>
          <div className="ac-vat-shipping"><FormattedMessage id="plusVatAndShipping" /></div>
        </div>
        <div className="text-align-right">
          <strong>
            {props.services.automationConfiguratorService.getNetPriceInfo(
              props.automationConfiguration, props.dataStorage, props.locale
            ).formattedPrice}
          </strong>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <h2><FormattedMessage id="hcYourConfiguration" /></h2>
      {props.services.automationConfiguratorService.getInitialConfiguratorState().steps.filter(
        (stepId) => Object.keys(props.automationConfiguration).includes(stepId)
      ).map((stepId) => getAutomationPart(stepId, props.automationConfiguration))}
      {getFinalPart()}
    </Fragment>
  );
}

Summary.propTypes = {
  locale: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.object).isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any).isRequired,
  automationConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
};
