import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '../Link/CustomLink';

export default function ProductCategoryMenuItem(props) {
  return (
    <div className="product-list-item center">
      <CustomLink
        href={props.href}
      >
        {props.imgSrc && (
        <div className="grid-product-category-menu-item">
          <div className="center">
            <img
              src={props.imgSrc}
              alt={props.label}
            />
          </div>
          <div className="product-category-menu-item__name">{props.label}</div>
        </div>
        )}
        {!props.imgSrc && (
          <div className="product-category-menu-item__name product-category-menu-item__name--only center">{props.label}</div>
        )}
      </CustomLink>
    </div>
  );
}

ProductCategoryMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};
